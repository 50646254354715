import React from 'react';
import { Select, Tag } from 'antd';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import ExpiWarningIcon from './ExpiWarningIcon';
import { ExpiAnalysis } from '../../../../types/extendedInfo';
import { getValueAnalysis } from './extendedInfoUtils';

type ExpiSelectTagsProps = {
  name: string;
  values: string[];
  label?: React.ReactNode;
  placeholder?: string;
  onChange?: (value: any) => void;
  maxLength: number;
  minLength: number;
  analyses: ExpiAnalysis[];
  itemId: number;
  existingTypeValues: Record<string, any>;
};

const ExpiSelectTags: React.FC<ExpiSelectTagsProps> = props => {
  const [focus, setFocus] = React.useState<boolean>(false);
  const [keyWordLength, setKeyWordLength] = React.useState(0);
  const { name, placeholder, values, label, onChange, analyses, itemId, existingTypeValues } =
    props;

  const renderExpiIcon = (typeName: string, valueTags: string[]) => {
    const inlineAnalysis = getValueAnalysis(
      analyses,
      itemId,
      typeName,
      valueTags,
      existingTypeValues
    );
    return <ExpiWarningIcon inlineAnalysis={inlineAnalysis} />;
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const labelChildren = typeof label !== 'string' ? label?.props?.children : undefined;
    const labelProps = labelChildren ? labelChildren[0].props : undefined;

    return (
      <Tag
        className={classNames({
          'extended-info__select_tag_blue': !labelProps?.inlineAnalysis,
          'extended-info__select_tag_yellow': labelProps?.inlineAnalysis?.alert_type_id === 2,
          'extended-info__select_tag_red': labelProps?.inlineAnalysis?.alert_type_id === 3,
        })}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { setFieldValue } = form;
        return (
          <div className="extended-info__select-tag flex items-center flex-1">
            <div className="extended-info__label pl-3 self-start flex-1">{label}</div>
            <div className="extended-info__select-tag_wrapper flex justify-between flex-1">
              <Select
                size="small"
                mode="tags"
                popupClassName="extended-info__select-dropdown"
                placeholder={placeholder}
                value={field.value || (field.value === null ? undefined : field.value)}
                onChange={(value: any) => {
                  setKeyWordLength(value.length > 0 ? value[value.length - 1].length : 0);
                  onChange
                    ? onChange(value)
                    : setFieldValue(name, value === undefined ? null : value);
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onSearch={text => setKeyWordLength(text.length)}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                dropdownRender={menu =>
                  values.length === 0 && !keyWordLength ? <div /> : <div>{menu}</div>
                }
                tagRender={tagRender}
                data-testid="expi-select-tags"
                options={values.map((value: string) => ({
                  key: value,
                  value,
                  label: (
                    <React.Fragment>
                      {renderExpiIcon(name, [value])}
                      <span>{value}</span>
                    </React.Fragment>
                  ),
                }))}
              />
              <div
                className={classNames('flex-grow', {
                  'extended-info__select-tag_text-warning':
                    keyWordLength !== 0 &&
                    (keyWordLength > props.maxLength || keyWordLength < props.minLength),
                  'extended-info__input_highlight': focus,
                })}
              >
                <div className="extended-info__select-tag_length text-gray-600">
                  {`${keyWordLength} / ${props.maxLength}`}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default ExpiSelectTags;
