import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { BrandGlobal } from '../../../types/brandResources';

export type BrandResourcesState = {
  readonly global: BrandGlobal;
};

const initialState = {
  global: {
    users: [],
  },
};

const reducer: Reducer<BrandResourcesState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_BRAND_RESOURCES_FULFILLED': {
      const { data } = action.payload;
      return {
        global: data.global,
      };
    }
  }
  return state;
};

export default reducer;
