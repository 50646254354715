import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AnalysisType } from '../../../../types/resources';
import {
  NEW_TRANSMISSION_ANALYSIS_ID,
  NEW_ENGINE_ANALYSIS_ID,
  ENGINE_BASE_ANALYSIS_ID,
  SUB_CONFIG_ANALYSIS_ID,
} from '../../../constants/InboxConstants';

type QuickfixTitleProps = {
  analysisTypeResource: AnalysisType;
  analysis: any;
  fetchReviewItems: (groupKey: string) => void;
};

const QuickfixTitle: React.FC<QuickfixTitleProps> = ({
  analysisTypeResource,
  analysis,
  fetchReviewItems,
}) => {
  const { t } = useTranslation();

  const alertTypeId = analysisTypeResource.alert_type_id;
  const itemCount = analysis.item_count;
  const application = analysis.item_application;
  const isNewEngineValidation = analysis.type_id === NEW_ENGINE_ANALYSIS_ID;
  const isNewTransmissionValidation = analysis.type_id === NEW_TRANSMISSION_ANALYSIS_ID;
  const baseVehicleValidation = isNewEngineValidation || isNewTransmissionValidation;
  const titleCss = classNames('analyses__title', {
    blue: alertTypeId === 1,
    yellow: alertTypeId === 2,
    red: alertTypeId === 3,
  });

  return (
    <div className="analyses__title-wrapper">
      <span className={titleCss}>
        <Tooltip title={analysisTypeResource.description}>
          <span className="analyses__title-name">{analysisTypeResource.name}</span>
        </Tooltip>
        {(analysis.type_id === ENGINE_BASE_ANALYSIS_ID ||
          analysis.type_id === SUB_CONFIG_ANALYSIS_ID) && (
          <span className="analyses__title-application">
            {`${t('quickfix:for')} ${application.make_name} ${application.model_name} `}
          </span>
        )}
        {!baseVehicleValidation && (
          <span className="analyses__part-tag">
            {itemCount > 100 ? '100+' : itemCount}{' '}
            {itemCount > 1 ? t('quickfix:products') : t('quickfix:product')}
          </span>
        )}
        {itemCount > 100 && (
          <SyncOutlined
            className={classNames('analyses__reload-list')}
            spin={analysis.refresh}
            onClick={() => fetchReviewItems(analysis.group_key)}
          />
        )}
      </span>
    </div>
  );
};

export default QuickfixTitle;
