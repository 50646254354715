import { Field, FieldProps, FastField } from 'formik';
import { Form, Input } from 'antd';
import React from 'react';
import { get as _get } from 'lodash';
import { InputProps } from 'antd/lib/input';

type FormInputProps = {
  name: string;
  label?: React.ReactNode;
  className?: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  fastField?: boolean;
  addonAfterName?: string;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  type?: string;
  testId?: string;
  maxLength?: number;
  handleOnBlur?: () => void;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  autoFocus?: boolean;
} & InputProps;

const FormInput: React.FC<FormInputProps> = props => {
  const {
    name,
    label,
    className,
    id,
    placeholder,
    required,
    disabled,
    fastField,
    addonBefore,
    addonAfterName,
    addonAfter,
    type,
    size,
    handleOnBlur,
    handleChange,
    testId,
    maxLength,
    min,
    step,
    variant,
  } = props;

  const fieldContent = ({ field, form }: FieldProps) => {
    const { onChange, onBlur } = field;
    const { errors, touched } = form;
    // use lodash because name can include dots eg. foo.bar
    const showValidationInfo = addonAfterName
      ? !!(_get(errors, name) && _get(touched, name) && _get(touched, addonAfterName))
      : !!(_get(errors, name) && _get(touched, name));

    return (
      <Form.Item
        label={label}
        className={className}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
        required={required}
      >
        <Input
          id={id}
          autoFocus={props.autoFocus}
          className={type === 'number' ? 'form-number-input' : ''}
          data-testid={testId}
          type={type}
          size={size}
          name={name}
          placeholder={placeholder}
          value={field.value}
          onChange={handleChange || onChange}
          onBlur={handleOnBlur || onBlur}
          disabled={disabled}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          maxLength={maxLength}
          min={min}
          step={step}
          variant={variant}
        />
      </Form.Item>
    );
  };

  return fastField ? (
    <FastField name={name}>{fieldContent}</FastField>
  ) : (
    <Field name={name}>{fieldContent}</Field>
  );
};

export default FormInput;
