import React from 'react';
import {
  CloudSyncOutlined,
  CloudOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Tag } from 'antd';
import AntTable from '../../global/AntTable';
import translationConstants from '../../../constants/ChannelTranslation.json';
import { sum } from '../../../utils/Array.ts';
import { fillInPlaceholders } from '../../../utils/String.ts';
import generalTranslationConstants from '../../../constants/GeneralTranslation.json';
import AntTooltip from '../../global/AntTooltip';
import BrandNestedTable from './BrandNestedTable';
import {
  CHANNEL_IDS_WITH_BRAND_CONNECTIVITY,
  HIDDEN_MANUFACTURER_CHANNEL_IDS,
  HIDDEN_RECEIVER_CHANNEL_IDS,
} from '../../../constants/ChannelConstants';
import ShopifyNestedTable from './ShopifyNestedTable';

class ChannelTable extends React.Component {
  areAllSelected = () =>
    this.props.selectedPlans.length ===
    this.props.channels.map(({ plans }) => plans).reduce((a, b) => a.concat(b), []).length;

  isChannelSelected = channelId => {
    const { channels, selectedPlans } = this.props;
    const { plans } = channels.find(({ id }) => id === channelId) || [];
    return selectedPlans.some(p => plans.map(({ id }) => id).includes(p));
  };

  expandedRowRender = record => {
    const {
      fetchingChannelShopify,
      selectedPlans,
      brands,
      channelResources,
      showShopifyEditDrawer,
      shopifyChannels,
      handleDeleteShopifyChannel,
    } = this.props;
    if (record.channel.id === 9)
      return (
        <ShopifyNestedTable
          shopifyChannels={shopifyChannels}
          handleEditShopifyChannel={id => showShopifyEditDrawer(id)}
          fetchingChannelShopify={fetchingChannelShopify}
          handleDeleteShopifyChannel={id => handleDeleteShopifyChannel(id)}
        />
      );
    return (
      <BrandNestedTable
        record={record}
        deliveryFrequencies={channelResources.delivery_frequencies}
        fileTypes={channelResources.file_types}
        selectedPlans={selectedPlans}
        submissionTypes={channelResources.submission_types}
        brands={brands}
        allAccessableBrands={this.props.allAccessableBrands}
        pushChannelUpdate={this.props.pushChannelUpdate}
        downloadChannelUpdate={this.props.downloadChannelUpdate}
        handleEditPlan={this.props.handleEditPlan}
        handlePlanSelect={this.props.handlePlanSelect}
        handlePlanUnselect={this.props.handlePlanUnselect}
        handlePlanDelete={this.props.handlePlanDelete}
      />
    );
  };

  rowExpandable = record => !CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(record.key);

  createChannelInfo = channel => (
    <div className="channel-table-header__profile-info">
      <span>{channel.name}</span>
      {(channel.integration_type_id || channel.integrated) && (
        <Tag className="channel__integrated-tag">{translationConstants.integrated}</Tag>
      )}
    </div>
  );

  createProfileInfo = plans => (
    <div className="channel-table-header__profile-info">
      {plans.map(({ items_count: itemsCount }) => itemsCount).reduce(sum, 0) > 0 ? (
        <CloudSyncOutlined className="icon__yellow" />
      ) : (
        <CloudOutlined className="icon__green" />
      )}
      <span className="channel-table-header__profile-info__text">
        {fillInPlaceholders(translationConstants.profileInfoText, {
          planCount: plans.length || 0,
          updateCount: plans.map(({ items_count: itemsCount }) => itemsCount || 0).reduce(sum, 0),
        })}
      </span>
    </div>
  );

  createActionButtons = channel => {
    const { deletePending, handleEdit, handleDelete } = this.props;

    return (
      <div className="channel-table-header__button-group">
        {channel.integrated &&
        (!CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(channel.id) || channel.id === 9) ? (
          <Button
            className="channel-table-header__button-group__button"
            icon={<FileAddOutlined />}
            size="small"
            onClick={e => {
              e.stopPropagation();
              if (channel.id === 9) this.props.showShopifyURLModal();
              else this.props.showCreateDrawer(channel.id);
            }}
            htmlType="button"
          >
            {generalTranslationConstants.create}
          </Button>
        ) : (
          <React.Fragment>
            <Button
              className="channel-table-header__button-group__button"
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                e.stopPropagation();
                handleEdit(channel.id);
              }}
              htmlType="button"
            >
              {generalTranslationConstants.edit}
            </Button>
            {!channel.integration_type_id &&
              !CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(channel.id) && (
                <Button
                  className="channel-table-header__button-group__button"
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    handleDelete(channel.id);
                  }}
                  disabled={deletePending.includes(channel.id)}
                  htmlType="button"
                  ghost
                >
                  {generalTranslationConstants.delete}
                </Button>
              )}
          </React.Fragment>
        )}
      </div>
    );
  };

  createPushSelectedButton = () => {
    const { selectedPlans, handleSelectAllChannels, handleUnselectAllChannels, pushChannelUpdate } =
      this.props;

    return (
      <div>
        <AntTooltip title={translationConstants.noActionSelected} hide={selectedPlans.length > 0}>
          <Button
            disabled={selectedPlans.length === 0}
            onClick={() => pushChannelUpdate(selectedPlans)}
            htmlType="button"
            size="small"
          >
            {translationConstants.pushSelected}
          </Button>
        </AntTooltip>
        <Checkbox
          size="small"
          className="channel-table-main-header__push-selected-checkbox"
          checked={this.areAllSelected()}
          onChange={({ target: { checked } }) =>
            checked ? handleSelectAllChannels() : handleUnselectAllChannels()
          }
        />
      </div>
    );
  };

  createPushSelectedCheckbox = channelId => {
    const { handleChannelSelect, handleChannelUnselect } = this.props;

    return (
      <Checkbox
        value={channelId}
        checked={this.isChannelSelected(channelId)}
        onClick={e => e.stopPropagation()}
        onChange={({ target: { value, checked } }) =>
          checked ? handleChannelSelect(value) : handleChannelUnselect(value)
        }
      />
    );
  };

  render() {
    const { channels, isReceiverUser } = this.props;

    const columns = [
      { name: translationConstants.channel, width: '35%' },
      { name: translationConstants.profileInfo, width: '30%' },
      { name: translationConstants.channelActions, width: '20%' },
      {
        name: translationConstants.pushSelected,
        width: '15%',
        title: this.createPushSelectedButton(),
        align: 'right',
      },
    ];

    // temporarily no sdc, opticat, channel-advisor, npw, walmart channel for receiver user
    const filteredChannels = isReceiverUser
      ? channels.filter(
          channel =>
            !HIDDEN_RECEIVER_CHANNEL_IDS.includes(
              channel.integration_type_id || (channel.integrated && channel.id)
            )
        )
      : channels.filter(
          channel =>
            !HIDDEN_MANUFACTURER_CHANNEL_IDS.includes(
              channel.integration_type_id || (channel.integrated && channel.id)
            )
        );

    const dataSource = filteredChannels.map(({ plans, ...channel }) => {
      if (channel.id === 9)
        return {
          key: channel.id,
          [translationConstants.channel]: this.createChannelInfo(channel),
          [translationConstants.channelActions]: this.createActionButtons(channel),
          channel,
        };
      return {
        key: channel.id,
        [translationConstants.channel]: this.createChannelInfo(channel),
        [translationConstants.profileInfo]:
          !CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(channel.id) && channel.id !== 9
            ? this.createProfileInfo(plans)
            : '', // exclude channels with brand connectivity
        [translationConstants.channelActions]: this.createActionButtons(channel),
        [translationConstants.pushSelected]:
          !CHANNEL_IDS_WITH_BRAND_CONNECTIVITY.includes(channel.id) && channel.id !== 9
            ? this.createPushSelectedCheckbox(channel.id)
            : '',
        plans,
        channel,
      };
    });

    return (
      <div className="channel-content__wrapper">
        <AntTable
          className="channel-table channel-table--full-width"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          expandedRowRender={this.expandedRowRender}
          rowExpandable={this.rowExpandable}
          size="small"
        />
      </div>
    );
  }
}

export default ChannelTable;
