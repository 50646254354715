import React from 'react';
import { Select } from 'antd';
import { chain as _chain } from 'lodash';

import catalogueTranslations from '../../../constants/CatalogueTranslations.json';

class CatalogueAddTag extends React.Component {
  state = {
    value: [],
  };

  componentDidMount() {
    this.updateValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkedItemIds.toString() !== this.props.checkedItemIds.toString()) {
      this.updateValue();
    }
  }

  updateValue = () => {
    if (this.props.allItemsChecked) {
      this.props.fetchItemTags().then(({ value }) => {
        const tagValue = value.data.map(({ name }) => name);
        this.setState({ value: tagValue });
      });
    } else {
      const value = _chain(this.props.checkedItems)
        .map(checkedItem => checkedItem.tag_ids)
        .flatten()
        .uniq()
        .map(tagId => {
          const brandTag = this.props.brandItemTags.find(tag => tag.id === tagId);
          const brandTagName = brandTag ? brandTag.name : '';
          return brandTagName;
        })
        .value();
      this.setState({ value });
    }
  };

  handleDeselect = value => {
    this.setState(prevState => ({
      value: prevState.value.filter(val => val !== value),
    }));
    this.props.handleRemoveTag(value);
  };

  handleOnSelect = value => {
    this.setState(prevProps => ({ value: [...prevProps.value, value] }));
    this.props.handleAddTag(value);
  };

  render() {
    return (
      <Select
        id={this.props.id}
        mode="tags"
        className="catalogue-add-tag"
        data-testid="select-tag"
        placeholder={catalogueTranslations.selectTag}
        onSelect={this.handleOnSelect}
        onDeselect={this.handleDeselect}
        value={this.state.value || []}
        defaultValue={[]}
        allowClear
        getPopupContainer={trigger => trigger.parentNode}
        options={this.props.brandItemTags.map(({ name }) => ({
          key: name,
          value: name,
          label: name,
        }))}
        style={{ width: '200px' }}
      />
    );
  }
}

export default CatalogueAddTag;
