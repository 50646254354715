import { Form, Select, Input } from 'antd';
import React from 'react';

import { SelectValue } from 'antd/lib/select';
import { withTranslation, WithTranslation } from 'react-i18next';
import AntSelect, { AntSelectEntries } from '../../global/AntSelect';
import {
  MULTI_SELECT_DEFAULT_VALUE_IDS,
  STRING_DEFAULT_VALUE_IDS,
  VCDB_DEFAULT_VALUE_ID,
} from '../../../constants/DefaultValueConstants';
import { DefaultValue } from '../../../../types/brand_settings';
import InfoTooltip from '../../global/InfoTooltip';
import AntMultiSelect from '../../global/AntMultiSelect';

const FormItem = Form.Item;

export type SingleDefaultValueProps = {
  canManageDefaultValues: boolean;
  resources: { [key: string]: AntSelectEntries };
  value: DefaultValue;
  updateDefaultValue: (id: number, selected?: boolean | SelectValue | string | null) => void;
} & WithTranslation;

type SingleDefaultValueState = {
  inputValue?: string;
};

class SingleDefaultValue extends React.Component<SingleDefaultValueProps, SingleDefaultValueState> {
  constructor(props: SingleDefaultValueProps) {
    super(props);
    this.state = {
      inputValue: STRING_DEFAULT_VALUE_IDS.includes(props.value.id)
        ? props.value.value || ''
        : undefined,
    };
  }

  render() {
    const { resources, value, canManageDefaultValues, t } = this.props;
    const { inputValue } = this.state;
    const formItemLayout = {
      labelCol: { xs: { span: 24 }, xl: { span: 24 }, xxl: { span: 4 } },
      wrapperCol: { xs: { span: 7 }, xxl: { span: 7 } },
    };
    const handleChange = (selectedValue: SelectValue) =>
      this.props.updateDefaultValue(value.id, selectedValue);

    const handleBooleanValueChange = (selectedValue: SelectValue) => {
      if (selectedValue === '0') {
        this.props.updateDefaultValue(value.id, false);
      }
      if (selectedValue === '1') {
        this.props.updateDefaultValue(value.id, true);
      }
      if (selectedValue === undefined) {
        this.props.updateDefaultValue(value.id, null);
      }
    };

    const handleInputChange = (inputValue: string) => {
      this.props.updateDefaultValue(value.id, inputValue);
    };

    const stringValueType = STRING_DEFAULT_VALUE_IDS.includes(value.id);

    const getResourceValues = (value: DefaultValue) => {
      if (VCDB_DEFAULT_VALUE_ID === value.id) return resources.vcdb_versions;
      return resources[value.resource_table!];
    };

    const displayInfo = (name: string) => (
      <InfoTooltip
        className="ml-3 self-center"
        overlay={t('settings:infoDefaultValue', { name })}
      />
    );

    const infoPlaceholder = <div className="ml-3" style={{ width: '14px' }} />;

    const booleanValueText =
      value?.accountDefaultValue === '1'
        ? t('common:yes')
        : (value?.accountDefaultValue === '0' && t('common:no')) || t('common:noValue');

    const getMultiValueText = (ids: number[], res: any) => {
      const filterRes = res.filter((r: any) => ids.includes(r.id));
      return filterRes.map((r: any) => r.name).join(',');
    };

    return (
      <FormItem {...formItemLayout} label={value.name}>
        {((value.resource_table && !MULTI_SELECT_DEFAULT_VALUE_IDS.includes(value.id)) ||
          VCDB_DEFAULT_VALUE_ID === value.id) && (
          <div className="flex">
            <AntSelect
              elements={getResourceValues(value)}
              onChange={handleChange}
              value={value.value ? Number(value.value) : value.value}
              disabled={!canManageDefaultValues}
              allowClear
              showSearch
              placeholder={value.accountDefaultName}
            />
            {value.accountDefaultName ? displayInfo(value.accountDefaultName!) : infoPlaceholder}
          </div>
        )}
        {!value.resource_table && !stringValueType && VCDB_DEFAULT_VALUE_ID !== value.id && (
          <div className="flex">
            <AntSelect
              elements={[
                { id: '1', name: t('common:yes') },
                { id: '0', name: t('common:no') },
              ]}
              onChange={handleBooleanValueChange}
              value={value.value}
              allowClear
              showSearch
              placeholder={booleanValueText}
            />
            {value.accountDefaultValue ? displayInfo(booleanValueText) : infoPlaceholder}
          </div>
        )}
        {stringValueType && (
          <div className="flex">
            <Input
              value={inputValue}
              onChange={e => this.setState({ inputValue: e.target.value })}
              onBlur={() => handleInputChange(inputValue!)}
              placeholder={value.accountDefaultValue!}
            />
            {value.accountDefaultValue ? displayInfo(value.accountDefaultValue!) : infoPlaceholder}
          </div>
        )}
        {MULTI_SELECT_DEFAULT_VALUE_IDS.includes(value.id) && (
          <div className="flex">
            <AntMultiSelect
              elements={getResourceValues(value)}
              onChange={(ids: number[]) => this.props.updateDefaultValue(value.id, ids.join(','))}
              selectedElements={value.value ? value.value.split(',').map(id => Number(id)) : []}
              placeholder={getMultiValueText(
                value.accountDefaultValue
                  ? value.accountDefaultValue!.split(',').map(id => Number(id))
                  : [],
                getResourceValues(value)
              )}
            />
            {value.accountDefaultValue &&
              displayInfo(
                getMultiValueText(
                  value.accountDefaultValue
                    ? value.accountDefaultValue!.split(',').map(id => Number(id))
                    : [],
                  getResourceValues(value)
                )
              )}
          </div>
        )}
      </FormItem>
    );
  }
}

export default withTranslation()(SingleDefaultValue);
