import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

export default class AntSelectGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filteredElements: this.props.elements };
  }

  handleSearch = value => {
    const filteredElements = this.props.elements.map(group => ({
      ...group,
      types: group.types.filter(
        option => option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
      ),
    }));
    this.setState({ filteredElements });
  };

  render() {
    const {
      className,
      popupClassName,
      onChange,
      selectedElement,
      placeholder,
      size,
      disabled,
      showSearch,
      allowClear,
    } = this.props;

    return (
      <Select
        value={selectedElement}
        onChange={onChange}
        placeholder={placeholder}
        size={size}
        allowClear={allowClear}
        showSearch={showSearch}
        onSearch={this.handleSearch}
        filterOption={(input, option) =>
          option?.options?.find(opt => opt.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
        disabled={disabled}
        className={className}
        popupClassName={popupClassName}
        options={this.state.filteredElements.map(element => ({
          key: element.id,
          value: element.id,
          label: element.name,
          options: element.types.map(option => ({
            key: `${option.id}_${element.id}`,
            value: option.id,
            label: option.name,
          })),
        }))}
      />
    );
  }
}

AntSelectGroups.propTypes = {
  // string that is aditionally added to default className
  className: PropTypes.string,
  // array => [{id: "Int", name: "String"}]
  elements: PropTypes.array,
  // function that is called onChange and returns the new selected Element
  onChange: PropTypes.func,
  // id
  selectedElement: PropTypes.number,
  // placeholder string
  placeholder: PropTypes.string,
  // dropdown is disabled
  disabled: PropTypes.bool,
  // show search input
  showSearch: PropTypes.bool,
  // selected value can be cleared
  allowClear: PropTypes.bool,
};

AntSelectGroups.defaultProps = {
  elements: [],
  onChange: () => {},
  className: '',
  selectedElement: undefined,
  placeholder: '',
  disabled: false,
  showSearch: false,
  allowClear: false,
};
