import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { PriceSheet, UseType } from '../../../types/price_sheet';

export type PriceSheetsState = {
  priceSheets: PriceSheet[];
  priceSheetsBrandId?: number;
  fetchingPriceSheets: boolean;
  brandPriceSheetIds: number[];
  uses: UseType[];
  assignPriceSheetToReceivers: number;
  fetchingBrandPriceSheets: boolean;
};

const initialState = {
  priceSheets: [],
  priceSheetsBrandId: undefined,
  fetchingPriceSheets: false,
  fetchingBrandPriceSheets: false,
  brandPriceSheetIds: [],
  uses: [],
  assignPriceSheetToReceivers: 0,
};

const reducer: Reducer<PriceSheetsState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PRICE_SHEETS_PENDING': {
      return {
        ...state,
        priceSheets: [],
        priceSheetsBrandId: undefined,
        fetchingPriceSheets: true,
      };
    }
    case 'FETCH_PRICE_SHEETS_FULFILLED': {
      return {
        ...state,
        priceSheets: action.payload.data,
        priceSheetsBrandId: action.meta.brandId,
        fetchingPriceSheets: false,
      };
    }
    case 'FETCH_BRAND_PRICE_SHEETS_PENDING': {
      return { ...state, brandPriceSheetIds: [], fetchingBrandPriceSheets: true };
    }
    case 'FETCH_BRAND_PRICE_SHEETS_FULFILLED': {
      return { ...state, brandPriceSheetIds: action.payload.data, fetchingBrandPriceSheets: false };
    }
    case 'FETCH_PRICE_SHEET_USES_FULFILLED': {
      return { ...state, uses: action.payload.data };
    }
    case 'UPDATE_PRICE_SHEET_TO_RECEIVER_FULFILLED': {
      return { ...state, assignPriceSheetToReceivers: action.meta.sheetId };
    }
    case 'CREATE_PRICE_SHEET_FULFILLED': {
      const newSheet = action.payload.data;
      return { ...state, priceSheets: [newSheet, ...state.priceSheets] };
    }
    case 'UPDATE_PRICE_SHEET_FULFILLED': {
      const priceSheets = state.priceSheets.map(sheet => {
        if (sheet.id === action.meta.sheetId) return action.payload.data;
        return sheet;
      });
      return { ...state, priceSheets };
    }
    case 'DELETE_PRICE_SHEET_FULFILLED': {
      return {
        ...state,
        priceSheets: state.priceSheets.filter(priceSheet => priceSheet.id !== action.meta.sheetId),
      };
    }
  }
  return state;
};

export default reducer;
