import React, { ReactNode } from 'react';
import { Input, Form, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Select, { SelectValue } from 'antd/lib/select';
import MultiEditButton from './MultiEditButton';

type PackageDimensionInputsProps = {
  label?: ReactNode;
  inputNames: string[];
  heightValue: string | null;
  widthValue: string | null;
  lengthValue: string | null;
  dimensionUomValue: number | null;
  options: {
    id: string | number;
    name: string | number;
  }[];
  borderInvisible?: boolean;
  disabled?: boolean;
  inputChange: (value: string, name: string) => void;
  selectChange: (value: SelectValue) => void;
  handleDrawerVisibility?: (drawerVisible: boolean) => void;
  multipleValueField?: boolean;
  showMultiEditIcon?: boolean;
  handleOnBlur?: () => void;
};

const PackageDimensionInputs: React.FC<PackageDimensionInputsProps> = ({
  label,
  inputNames,
  options,
  borderInvisible,
  disabled,
  multipleValueField,
  heightValue,
  widthValue,
  lengthValue,
  dimensionUomValue,
  inputChange,
  selectChange,
  handleDrawerVisibility,
  showMultiEditIcon,
  handleOnBlur,
}) => {
  const { t } = useTranslation();

  const renderXInput = () => (
    <Input
      style={{
        width: 20,
        borderLeft: 0,
        borderRight: 0,
        pointerEvents: 'none',
        paddingLeft: 6,
      }}
      placeholder="x"
      disabled
      variant={borderInvisible ? 'borderless' : 'outlined'}
    />
  );

  const renderMultiValueEditLink = () => (
    <div className="flex items-center flex-1">
      <Button
        type="link"
        onClick={() => handleDrawerVisibility!(true)}
        style={{ padding: 0 }}
        disabled={disabled}
        data-testid="multiple-values"
      >
        {t('packageSeg:multipleValues')}
      </Button>
    </div>
  );

  const renderDimensionFields = () => (
    <Space.Compact size="small" block>
      <Input
        disabled={disabled}
        style={{ width: 60 }}
        value={heightValue || ''}
        onChange={e => {
          const value = e.target.value;
          inputChange(value, inputNames[0]);
        }}
        size="small"
        onBlur={handleOnBlur}
        variant={borderInvisible ? 'borderless' : 'outlined'}
      />
      {renderXInput()}
      <Input
        disabled={disabled}
        size="small"
        style={{ width: 60 }}
        value={widthValue || ''}
        onChange={e => {
          const value = e.target.value;
          inputChange(value, inputNames[1]);
        }}
        onBlur={handleOnBlur}
        variant={borderInvisible ? 'borderless' : 'outlined'}
      />
      {renderXInput()}
      <Input
        disabled={disabled}
        size="small"
        style={{ width: 60 }}
        value={lengthValue || ''}
        onChange={e => {
          const value = e.target.value;
          inputChange(value, inputNames[2]);
        }}
        onBlur={handleOnBlur}
        variant={borderInvisible ? 'borderless' : 'outlined'}
      />
      <Select
        style={{ width: 145 }}
        value={dimensionUomValue || undefined}
        variant={borderInvisible ? 'borderless' : 'filled'}
        size="small"
        allowClear
        onChange={(value: SelectValue) => selectChange(value)}
        disabled={disabled}
        onBlur={handleOnBlur}
        options={options.map(uom => ({
          key: uom.id,
          value: uom.id,
          label: uom.name,
        }))}
      />
    </Space.Compact>
  );

  return (
    <Form.Item label={label}>
      {!multipleValueField ? (
        <div className="flex-row inline-flex">
          {renderDimensionFields()}
          {showMultiEditIcon && (
            <MultiEditButton handleOnClick={() => handleDrawerVisibility!(true)} />
          )}
        </div>
      ) : (
        multipleValueField && renderMultiValueEditLink()
      )}
    </Form.Item>
  );
};

export default PackageDimensionInputs;
