import { Tabs, Button } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditChannelPlan from './EditChannelPlan';
import EditEbayPlan from './EditEbayPlan';
import { ChannelFileExportOption, Plan } from '../../../../types/channel';
import NamingConventionDrawer from './namingConvention/NamingConventionDrawer';
import { FileExportOption } from '../../../../types/import_export';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

type ChannelPlanProps = {
  selectedMenuKey: string;
  initialSelectedPlanId?: number;
  plans: Plan[];
  integrationTypeId: number;
  defaultFile?: ChannelFileExportOption[];
  defaultTemplateId?: number | null;
  defaultFtp: any;
  defaultSchedule: any;
  defaultIntegrationSettings: any;
  fileTypes: FileExportOption[];
  createPlan: (
    values: any,
    defaults: any,
    fileExportOptions: ChannelFileExportOption[]
  ) => Promise<any>;
  deletePlans: (ids: number[]) => void;
  updatePlan: (
    id: number,
    values: any,
    defaults: any,
    fileExportOptions: ChannelFileExportOption[]
  ) => Promise<any>;
  deleteExportFileSettings: (id: number) => void;
  deleteExportFtpSettings: (id: number) => void;
  deleteSchedule: (id: number) => void;
  deleteIntegratedSettings: (id: number) => void;
  onClose: (force?: boolean) => void;
  isReceiverUser: boolean;
  showWhitelabel: boolean;
} & WithTranslation &
  WithAntdContextProps;

type ChannelPlanState = {
  activeKey?: string;
  tempPlan: boolean;
  createPlanPending: boolean;
  showNamingConventionDrawer: boolean;
  planCache?: Plan[];
};

export class ChannelPlanTabs extends React.Component<ChannelPlanProps, ChannelPlanState> {
  constructor(props: ChannelPlanProps) {
    super(props);
    this.state = {
      activeKey:
        props.plans.length > 0
          ? (props.initialSelectedPlanId && props.initialSelectedPlanId.toString()) ||
            props.plans[0].id.toString()
          : 'undefined',
      tempPlan: false,
      createPlanPending: false,
      planCache: undefined,
      showNamingConventionDrawer: false,
    };
  }

  componentDidUpdate(prevProps: ChannelPlanProps) {
    const { plans } = this.props;
    if (prevProps.selectedMenuKey !== this.props.selectedMenuKey) {
      this.setState({
        activeKey: plans.length > 0 ? plans[0].id.toString() : 'undefined',
        tempPlan: false,
      });
    }
  }

  updatePlan = (params: any, defaults: any, fileExportOptions: ChannelFileExportOption[]) => {
    const { activeKey } = this.state;
    return this.props.updatePlan(Number(activeKey!), params, defaults, fileExportOptions);
  };

  createPlan = async (values: any, defaults: any, fileExportOptions: ChannelFileExportOption[]) => {
    const { t, plans } = this.props;
    const currentPlans = [...plans, { id: 0, name: t('channel:newPlan') } as Plan];

    // new plans should be visible when create is complete (create file, ftp and schedule settings)
    // => cache current plans till all calls are completed
    this.setState({ createPlanPending: true, planCache: currentPlans });
    const promise = await this.props.createPlan(values, defaults, fileExportOptions);
    this.setState({ createPlanPending: false, planCache: undefined });
    return promise;
  };

  selectNewPlan = (key: string) => {
    this.setState({ activeKey: key, tempPlan: false });
  };

  onChange = (activeKey: string) => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey: any, action: string) => {
    if (action === 'add') this.add();
    if (action === 'remove') this.remove(targetKey);
  };

  add = () => {
    this.setState({ tempPlan: true, activeKey: '0' });
  };

  remove = (targetKey: string) => {
    const { t } = this.props;
    const { modal } = this.props.appContext;
    const { deletePlan } = this;

    if (targetKey !== '0') {
      modal.confirm({
        title: t('channel:deletePlanMessage'),
        okText: t('common:delete'),
        cancelText: t('common:cancel'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deletePlan(targetKey);
        },
      });
    } else {
      this.deletePlan(targetKey);
    }
  };

  deletePlan = (targetKey: string) => {
    const { plans } = this.props;
    const { activeKey } = this.state;

    if (activeKey === targetKey) {
      const index = plans.findIndex(plan => plan.id.toString() === targetKey);
      const lastIndexSelected = index === plans.length - 1;
      let nextIndex = index + 1;
      if (lastIndexSelected) nextIndex = index - 1;
      if (targetKey === '0') nextIndex = plans.length - 1;

      this.setState({
        activeKey:
          plans.length > 1 || (plans.length === 1 && targetKey === '0')
            ? plans[nextIndex].id.toString()
            : undefined,
      });
    }

    if (targetKey === '0') this.setState({ tempPlan: false });
    else this.props.deletePlans([Number(targetKey)]);
  };

  openNamingConventionDrawer = () => this.setState({ showNamingConventionDrawer: true });

  addNewButton = () => (
    <Button
      className="mr-5"
      onClick={this.add}
      size="small"
      ghost
      disabled={this.state.tempPlan}
      type="primary"
    >
      {this.props.t('channel:newPlan')}
    </Button>
  );

  render() {
    const { t, plans, integrationTypeId } = this.props;
    const { tempPlan, createPlanPending, planCache } = this.state;
    let planTabs = tempPlan ? [...plans, { id: 0, name: t('channel:newPlan') } as Plan] : plans;
    if (createPlanPending && planCache) planTabs = planCache;

    return (
      <React.Fragment>
        <Tabs
          className="channel__plan-tabs"
          tabBarExtraContent={this.addNewButton()}
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          onEdit={this.onEdit}
          hideAdd
          items={planTabs.map(plan => ({
            key: plan.id.toString(),
            label: plan.name,
            children: (
              <React.Fragment>
                {integrationTypeId === 3 && (
                  <EditEbayPlan
                    create={tempPlan}
                    plan={!tempPlan ? plan : ({} as Plan)}
                    defaultIntegrationSettings={this.props.defaultIntegrationSettings}
                    defaultSchedule={this.props.defaultSchedule}
                    createPlan={this.createPlan}
                    updatePlan={this.updatePlan}
                    selectPlan={this.selectNewPlan}
                    deleteIntegratedSettings={this.props.deleteIntegratedSettings}
                    deleteSchedule={this.props.deleteSchedule}
                    onClose={this.props.onClose}
                  />
                )}
                {integrationTypeId !== 3 && (
                  <EditChannelPlan
                    create={tempPlan}
                    plan={!tempPlan ? plan : ({} as Plan)}
                    isReceiverUser={this.props.isReceiverUser}
                    showWhitelabel={this.props.showWhitelabel}
                    defaultFtp={this.props.defaultFtp}
                    defaultFile={this.props.defaultFile}
                    defaultTemplateId={this.props.defaultTemplateId}
                    defaultSchedule={this.props.defaultSchedule}
                    fileTypes={this.props.fileTypes.map(f => ({ id: f.id, name: f.name }))}
                    updatePlan={this.updatePlan}
                    selectPlan={this.selectNewPlan}
                    createPlan={this.createPlan}
                    deletePlan={this.deletePlan}
                    openNamingConventionDrawer={this.openNamingConventionDrawer}
                    deleteExportFtpSettings={this.props.deleteExportFtpSettings}
                    deleteExportFileSettings={this.props.deleteExportFileSettings}
                    deleteSchedule={this.props.deleteSchedule}
                    onClose={this.props.onClose}
                  />
                )}
              </React.Fragment>
            ),
          }))}
        />

        <NamingConventionDrawer
          visible={this.state.showNamingConventionDrawer}
          onClose={() => this.setState({ showNamingConventionDrawer: false })}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(withAntdContext(ChannelPlanTabs));
