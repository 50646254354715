import React from 'react';
import { useSelector } from 'react-redux';
import { Popover, Button, Form, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ApplicationState } from '../../../reducers';
import { StandardResourceCodeAuth } from '../../../../types/resources';

type SourceFilterPopoverProps = {
  sourceFilterId?: number;
  handleChange: (sourceId?: number) => void;
};

const SourceFilterPopover: React.FC<SourceFilterPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const { receiverSources } = useSelector((state: ApplicationState) => ({
    receiverSources: state.resources.data.receiver.external_data_providers,
  }));

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="receiver__source-filter-popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Formik
          initialValues={{
            sourceId: props.sourceFilterId,
          }}
          onSubmit={values => {
            props.handleChange(values.sourceId);
            handleVisibleChange(false);
          }}
        >
          {({ handleSubmit, setFieldValue, dirty, isSubmitting, values }) => {
            return (
              <div>
                <Form layout="vertical">
                  <div className="p-3">
                    <Radio.Group
                      onChange={e => setFieldValue('sourceId', e.target.value)}
                      value={values.sourceId}
                    >
                      <Space direction="vertical">
                        {receiverSources.map((source: StandardResourceCodeAuth) => (
                          <Radio value={source.id} key={source.id}>
                            {source.name}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                </Form>

                <div className="flex-col items-end p-3">
                  <div>
                    <Button
                      onClick={() => {
                        setFieldValue('sourceId', undefined);
                        handleSubmit();
                      }}
                      className="drawer-submit__bottom-cancel"
                    >
                      {t('common:reset')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleSubmit()}
                      disabled={!dirty || isSubmitting}
                    >
                      {t('common:ok')}
                    </Button>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default SourceFilterPopover;
