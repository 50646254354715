import React from 'react';
import classNames from 'classnames';
import { Input, Spin, List, Checkbox, message, Button, Tooltip, Badge } from 'antd';
import { FilterFilled, CopyOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as utils from '../../../utils/Utils';
import constants from '../../../constants/ApplicationTranslation.json';
import AntSelect from '../../global/AntSelect';
import QualifierPopover from './QualifierPopover';
import {
  Qualifier,
  ListQualifier,
  ApplicationNote,
  QualifierValue,
  QualifierPart,
} from '../../../../types/application';
import { StandardResource } from '../../../../types/resources';
import { ItemCategory } from '../../../../types/item';

type ApplicationQualifiersProps = {
  qualifiers: ListQualifier[];
  qualifierTypes: StandardResource[];
  qualifierMetaUoms: StandardResource[];
  selectedQualifiers: (Qualifier & { temp?: boolean })[];
  notes?: ApplicationNote[];
  category?: ItemCategory;
  fetching: boolean;
  fetchQualifiers: (keywords: string, typeId?: number, includesValues?: boolean) => void;
  fetchNextQualifiers: (
    event: React.UIEvent,
    keywords: string,
    typeId?: number,
    includesValues?: boolean
  ) => void;
  updateQualifiers: (qualifierId: number, values?: any[], recordNumber?: number) => void;
  duplicateQualifier?: (qualifierId: number) => void;
  removeUsedQualifiers: (qualifierId: number, recordNumber?: number) => void;
  updateNote?: (recordNumber: number, note: string) => void;
  addNote?: (noteValue?: string) => void;
  removeNote?: (recordNumber: number) => void;
} & WithTranslation;

type ApplicationQualifiersState = {
  keywords: string;
  includesValues?: boolean;
  filterTypeId?: number;
  tempQualifier?: TempQualifier;
  dividerId?: number;
  selectedNoteRecord?: number;
  popoverVisible: boolean;
};

type TempQualifier = {
  id?: number;
  qualifier_id: number;
  qualifier_text: string;
  type_id: number;
  values: QualifierValue[];
  parts: QualifierPart[];
  record_number?: number;
  used_by_brand?: number;
};

class ApplicationQualifiers extends React.Component<
  ApplicationQualifiersProps,
  ApplicationQualifiersState
> {
  constructor(props: ApplicationQualifiersProps) {
    super(props);
    this.state = {
      keywords: '',
      includesValues: undefined,
      filterTypeId: undefined,
      tempQualifier: undefined,
      dividerId: undefined,
      selectedNoteRecord: undefined,
      popoverVisible: false,
    };
  }

  componentDidUpdate(prevProps: ApplicationQualifiersProps) {
    const { qualifiers, notes } = this.props;
    if (prevProps.qualifiers !== qualifiers && qualifiers.length > 0) {
      this.setDividerId(qualifiers);
    }
    if (
      notes &&
      notes.length > 0 &&
      ((prevProps.notes && prevProps.notes.length < notes.length) ||
        (!prevProps.notes && notes.length === 1))
    ) {
      const newNote = notes[notes.length - 1];
      if (newNote.note === '') {
        this.selectNote(newNote.record_number);
        this.setState({ popoverVisible: true });
      }
    }
  }

  setDividerId = (qualifiers: ListQualifier[]) => {
    const appQualifierIds = this.props.selectedQualifiers.map(q => q.qualifier_id);
    qualifiers = qualifiers.filter(qual => !appQualifierIds.includes(qual.id));
    const divider = qualifiers.find(
      (qual, i) =>
        qual.used_by_brand === 0 && qualifiers[i - 1] && qualifiers[i - 1].used_by_brand > 0
    );
    if (divider) {
      this.setState({ dividerId: divider.id });
    }
  };

  handleKeywordsChange = (value: string) => {
    const { filterTypeId, includesValues } = this.state;
    this.setState({ keywords: value });
    utils.typingDone(() => this.props.fetchQualifiers(value, filterTypeId, includesValues));
  };

  handleFilterValue = (status: string) => {
    const { keywords, filterTypeId } = this.state;
    const includesValues = utils.convertStringBooleanToBoolean(status);
    this.setState({ includesValues });
    this.props.fetchQualifiers(keywords, filterTypeId, includesValues);
  };

  handleFilterDropdownChange = (typeId: number | undefined) => {
    typeId = typeId || undefined;
    this.setState({ filterTypeId: typeId });

    this.props.fetchQualifiers(this.state.keywords, typeId, this.state.includesValues);
  };

  handleNoteChange = (recordNumber: number, note: string) => {
    const { updateNote } = this.props;
    if (updateNote) updateNote(recordNumber, note);
  };

  handleValueChange = (
    type: string,
    qualifierId: number,
    recordNumber: number,
    valueString: string,
    uomId?: number,
    qualifierRecordNumber?: number
  ) => {
    const { qualifierMetaUoms } = this.props;
    const { tempQualifier } = this.state;

    if (type === 'num') valueString = valueString.replace(/[^0-9-,./]/g, '');
    const metaUom = uomId ? qualifierMetaUoms.find(uom => uom.id === uomId) : undefined;

    const oldValues = tempQualifier?.values || [];
    const values =
      oldValues.length === 0 || !oldValues.find(v => v.record_number === Number(recordNumber))
        ? [
            ...oldValues,
            {
              value: valueString,
              meta_uom_id: uomId,
              meta_uom: metaUom?.name,
              record_number: Number(recordNumber),
            },
          ]
        : oldValues.map(value =>
            value.record_number === recordNumber
              ? { ...value, meta_uom_id: uomId, meta_uom: metaUom?.name, value: valueString }
              : value
          );

    this.setState({ tempQualifier: { ...tempQualifier!, values: values || [] } });

    // testing directly updating the qualifier and validate later (updateQualifiers() replacing)
    this.props.updateQualifiers(qualifierId, values, qualifierRecordNumber);
  };

  displayWarningNotAllValuesFilled = () => message.info(constants.qualifier_values_missing);

  checkQualifier = (qualifierId: number, qualifierRecordNumber?: number) => {
    const { selectedQualifiers } = this.props;
    const { tempQualifier } = this.state;

    const selected = selectedQualifiers.find(
      q => q.qualifier_id === qualifierId && q.record_number === qualifierRecordNumber
    );
    const tempSelected =
      tempQualifier?.qualifier_id === qualifierId &&
      tempQualifier?.record_number === qualifierRecordNumber;
    if (selected) this.removeQualifier(qualifierId, qualifierRecordNumber);
    if (tempSelected) this.setState({ tempQualifier: undefined });
    else this.selectQualifier(qualifierId, qualifierRecordNumber);
  };

  checkNote = (recordNumber: number) => {
    const { removeNote } = this.props;
    if (removeNote) removeNote(recordNumber);
  };

  selectNote = (recordNumber?: number) => {
    this.setState({ selectedNoteRecord: recordNumber });
  };

  selectQualifier = (qualifierId: number, qualifierRecordNumber?: number) => {
    const { selectedQualifiers, qualifiers } = this.props;
    const { tempQualifier } = this.state;
    this.selectNote(undefined);
    const values =
      tempQualifier && tempQualifier.values
        ? tempQualifier.values.filter(value => value.value !== '')
        : [];
    const selected = selectedQualifiers.find(
      q => q.qualifier_id === qualifierId && q.record_number === qualifierRecordNumber
    );
    // selected qualifier is not used yet
    if (!selected) {
      const qualifier = qualifiers.find(qualifier => qualifier.id === qualifierId);
      const parameterCount = this.getNumberOfParameters(qualifier!);
      if (parameterCount === 0 && !tempQualifier) {
        // save qualfier without values
        this.props.updateQualifiers(qualifierId);
      } else if (
        tempQualifier &&
        (tempQualifier.qualifier_id !== qualifierId ||
          tempQualifier.record_number !== qualifierRecordNumber)
      ) {
        // check if values are used
        if (
          (values.length === 0 &&
            !selectedQualifiers.find(qual => qual.qualifier_id === tempQualifier.qualifier_id)) ||
          values.length === this.getNumberOfParameters(tempQualifier)
        ) {
          if (parameterCount === 0) this.setState({ tempQualifier: undefined });
          // qualifier has no values
          else
            this.setState({
              tempQualifier: { ...qualifier!, values: [], qualifier_id: qualifier!.id },
            }); // select qualifier
        } else {
          this.displayWarningNotAllValuesFilled();
        }
      } else if (!tempQualifier) {
        this.setState({
          tempQualifier: { ...qualifier!, values: [], qualifier_id: qualifier!.id },
        }); // select qualifier
      }
    } else {
      // selected qualfier is already used
      const parameterCount = this.getNumberOfParameters(selected!);
      if (
        tempQualifier &&
        (tempQualifier.qualifier_id !== qualifierId ||
          tempQualifier.record_number !== qualifierRecordNumber)
      ) {
        if (
          (values.length === 0 &&
            !selectedQualifiers.find(
              q =>
                q.qualifier_id === tempQualifier.qualifier_id &&
                q.record_number === tempQualifier.record_number
            )) ||
          values.length === this.getNumberOfParameters(tempQualifier)
        ) {
          if (parameterCount !== 0) this.setState({ tempQualifier: selected });
          else this.setState({ tempQualifier: undefined }); // qualifier has no params can't be selected
        } else {
          this.displayWarningNotAllValuesFilled();
        }
      } else if (parameterCount !== 0 && !tempQualifier) {
        this.setState({ tempQualifier: selected });
      }
    }
  };

  removeQualifier = (qualifierId: number, qualifierRecordNumber?: number) => {
    this.props.removeUsedQualifiers(qualifierId, qualifierRecordNumber);
    this.setState({ tempQualifier: undefined });
  };

  isRowSelected = (id: number, qualifierRecordNumber?: number) =>
    this.state.tempQualifier?.qualifier_id === id &&
    this.state.tempQualifier.record_number === qualifierRecordNumber;

  getNumberOfParameters = (qualifier: { parts: any[] }) => {
    const parameters = qualifier.parts.filter(part => part.type === 'parameter');
    return parameters.length;
  };

  inputs = (
    partsList: any[],
    qualifierId: number,
    qRecordNumber?: number,
    values?: QualifierValue[]
  ) => {
    const selected = this.isRowSelected(qualifierId, qRecordNumber);
    // if (selected) values = this.state.tempQualifier?.values;

    return partsList.map((part, i) => {
      if (part.parameter) {
        const parameter = part.parameter;
        const recordNumber = parameter.record_number;
        const value =
          values && values.find(value => value.record_number === recordNumber)
            ? values && values.find(value => value.record_number === recordNumber)
            : undefined;
        const valueString = value?.value || '';

        return (
          <QualifierPopover
            translationTypeId={4} // qualifier type Id for translations
            key={recordNumber}
            value={valueString}
            type={parameter.type}
            metaUomId={value?.meta_uom_id || undefined}
            valueTranslations={value?.translations || []}
            handleChange={({ value, metaUomId }) => {
              this.handleValueChange(
                parameter.type,
                qualifierId,
                recordNumber,
                value,
                metaUomId,
                qRecordNumber
              );
              // this.updateQualifiers(qualifierId);
            }}
            onOpen={() => this.setState({ popoverVisible: true })}
            onClose={() => this.setState({ popoverVisible: false })}
          >
            <span
              className={classNames('application__qualifier-input inline-block', {
                'application__qualifier-input-border': !valueString || selected,
                'application__qualifier-input-empty': !valueString,
                'application__qualifier-input-required': selected && !valueString,
              })}
            >
              {valueString || parameter.type}
              {value?.meta_uom ? ` ${value?.meta_uom}` : ''}
            </span>
          </QualifierPopover>
        );
      }
      return (
        <span key={`t${i}`} className="application__qualifier-text">
          {part.text}
        </span>
      );
    });
  };

  qualifierText = (
    qualifierId: number,
    text: string,
    parts: any[],
    qRecordNumber?: number,
    values?: any[]
  ) => {
    const parameters = parts.filter(part => part.type === 'parameter');
    if (parameters.length) return this.inputs(parts, qualifierId, qRecordNumber, values);

    return text;
  };

  qualifierType = (id: number) => {
    const type = this.props.qualifierTypes.find(type => type.id === id);
    return type ? type.name : '';
  };

  qualifierRow = (qualifier: TempQualifier) => {
    const { t, duplicateQualifier, qualifiers, category } = this.props;

    if (qualifier.hasOwnProperty('id')) qualifier = { ...qualifier, qualifier_id: qualifier.id! };
    const selectedQualifier = this.props.selectedQualifiers.find(
      q => q.qualifier_id === qualifier.qualifier_id
    );
    const usedByBrand = selectedQualifier
      ? qualifiers.find(q => q.id === qualifier.qualifier_id)?.used_by_brand
      : qualifier.used_by_brand;
    const cssClasses = classNames('application__qualifier-row', {
      divider: qualifier.qualifier_id === this.state.dividerId,
    });
    const qual = qualifier as Qualifier;
    const parameterCount = this.getNumberOfParameters(qualifier!);

    return (
      <List.Item
        className={cssClasses}
        onClick={() => this.selectQualifier(qualifier.qualifier_id, qual.record_number)}
      >
        <div onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={!!selectedQualifier}
            indeterminate={
              !selectedQualifier && this.isRowSelected(qualifier.qualifier_id, qual.record_number)
            }
            onChange={() => this.checkQualifier(qualifier.qualifier_id, qual.record_number)}
          />
        </div>
        <div className="application__qualifiers-container">
          {this.qualifierText(
            qualifier.qualifier_id,
            qualifier.qualifier_text,
            qualifier.parts,
            qual.record_number,
            qual.values
          )}
          {!!usedByBrand && (
            <div className="inline-block ml-1">
              <Tooltip
                title={`${t('application:qualifier.usedByBrandHint', {
                  count: usedByBrand,
                  partType: category?.level3_name,
                })}`}
              >
                <Badge
                  className="ant-blue-batch cursor-default"
                  overflowCount={9999}
                  count={usedByBrand}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="application__qualifiers-type">
          {this.qualifierType(qualifier.type_id)}
          {!!selectedQualifier && parameterCount ? (
            <Tooltip
              title={
                <React.Fragment>
                  <div>{t('application:qualifier.duplicate')}</div>
                  <div className="mt-2">{t('application:qualifier.duplicateHint')}</div>
                </React.Fragment>
              }
              placement="topRight"
            >
              <CopyOutlined
                className="application__qualifier-copy ml-1"
                onClick={() => duplicateQualifier && duplicateQualifier(qualifier.qualifier_id)}
              />
            </Tooltip>
          ) : (
            <div className="inline-block ml-1" style={{ width: '19px' }} />
          )}
        </div>
      </List.Item>
    );
  };

  noteRow = (note: ApplicationNote) => {
    const { t } = this.props;
    const { selectedNoteRecord, popoverVisible } = this.state;
    const cssClasses = classNames('application__qualifier-row');
    const selected = note.record_number === selectedNoteRecord;

    return (
      <List.Item
        className={cssClasses}
        key={note.record_number}
        onClick={() => this.selectNote(note.record_number)}
      >
        <div onClick={e => e.stopPropagation()}>
          <Checkbox checked onChange={() => this.checkNote(note.record_number)} />
        </div>
        <div className="application__qualifiers-container">
          <QualifierPopover
            translationTypeId={5} // notes translation type id
            type="note"
            value={note.note}
            initialVisible={selected && popoverVisible}
            handleChange={({ value }) => this.handleNoteChange(note.record_number, value)}
            valueTranslations={note.translations || []}
            onOpen={() => this.setState({ popoverVisible: true })}
            onClose={() => this.setState({ popoverVisible: false })}
          >
            <span
              className={classNames('application__qualifier-input inline-block', {
                'application__qualifier-input-border': !note.note || selected,
                'application__qualifier-input-empty': !note.note,
                'application__qualifier-input-required': selected && !note.note,
              })}
            >
              {note.note}
            </span>
          </QualifierPopover>
        </div>
        <div className="application__qualifiers-type mr-6">{t('application:note')}</div>
      </List.Item>
    );
  };

  render() {
    const { t, selectedQualifiers, notes, addNote } = this.props;
    const { keywords, filterTypeId, includesValues } = this.state;
    const filteredSelectedQualifiers = selectedQualifiers.filter(q => !q.temp);
    const duplicateTempIds = selectedQualifiers
      .filter(q => q.temp)
      .map(q => q.qualifier_id)
      .filter((id, i, a) => a.indexOf(id) !== i);
    const uniqueDuplicateTempIds = [...new Set(duplicateTempIds)];
    const listQualifiers: any = [...this.props.qualifiers];

    if (uniqueDuplicateTempIds.length) {
      uniqueDuplicateTempIds.forEach(id => {
        const index = listQualifiers.findIndex((q: any) => q.id === id);
        const dQualifiers = selectedQualifiers.filter(q => q.qualifier_id === id);
        listQualifiers.splice(index, 1, ...dQualifiers);
      });
    }
    const filteredQualifiers = listQualifiers
      .filter(
        (qualifier: any) => !filteredSelectedQualifiers.find(q => qualifier.id === q.qualifier_id)
      )
      .map((qualifier: any) => {
        const selectedQualifier = selectedQualifiers.find(
          q => qualifier.id === q.qualifier_id && q.record_number === qualifier.record_number
        );
        return selectedQualifier || qualifier;
      });

    return (
      <div className="application__qualifiers-table-container flex-1 flex flex-col overflow-hidden">
        <div className="application__qualifiers-filter flex bg-gray-200">
          <div className="application__qualifiers-value-filter flex-1">
            <AntSelect
              className="application__qualifiers-value-filter"
              placeholder={<span>{constants.placeholder.filter_value}</span>}
              elements={[
                { id: 'false', name: constants.requires_no_value },
                { id: 'true', name: constants.requires_value },
              ]}
              value={includesValues === undefined ? includesValues : includesValues.toString()}
              onChange={value => this.handleFilterValue(value)}
              size="small"
              allowClear
              variant="borderless"
              suffixIcon={<FilterFilled />}
            />
          </div>
          <div className="application__qualifiers-type-filter">
            <AntSelect
              placeholder={<span>{constants.placeholder.filter_type}</span>}
              elements={this.props.qualifierTypes}
              value={this.state.filterTypeId}
              onChange={value => this.handleFilterDropdownChange(value)}
              size="small"
              allowClear
              variant="borderless"
              suffixIcon={<FilterFilled />}
            />
          </div>
        </div>

        <div className="application__qualifiers-filter flex items-center">
          <div className="application__qualifiers-search flex">
            <Input.Search
              autoFocus
              value={this.state.keywords}
              placeholder={constants.search}
              onChange={e => this.handleKeywordsChange(e.target.value)}
              allowClear
            />
          </div>
          {addNote && (
            <Button type="primary" ghost onClick={() => addNote(this.state.keywords)}>
              {t('application:qualifier.add')}
            </Button>
          )}
        </div>
        <div
          className="application__qualifiers-table"
          onScroll={e => {
            if (!this.state.popoverVisible)
              this.props.fetchNextQualifiers(e, keywords, filterTypeId, includesValues);
          }}
        >
          <List
            className="analyses__item-list"
            dataSource={[...filteredSelectedQualifiers, ...(notes || []), ...filteredQualifiers]}
            renderItem={item =>
              item.hasOwnProperty('note')
                ? this.noteRow(item as ApplicationNote)
                : this.qualifierRow(item as Qualifier)
            }
            size="small"
          />
          {this.props.fetching && <Spin />}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ApplicationQualifiers);
