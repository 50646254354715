import React from 'react';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../reducers';
import { ReceiverDetails } from '../../../../types/receiver';
import { StandardResourceCodeDescription } from '../../../../types/resources';
import {
  COMPANY_INFORMATION,
  SOCIAL_INFORMATION,
  WAREHOUSE_DISTRIBUTOR,
} from '../../../constants/CompanyProfileConstants';
import RequestProfileDetailsButton from './RequestProfileDetailsButton';
import { mapResourcesToReceivers } from '../../../selectors/receiver/receiverSelector';

type ReceiverProfileProps = {
  receiverDetails?: ReceiverDetails;
  approved?: boolean;
  receiverUser?: boolean;
  handleEdit?: (name: string) => void;
};

const ReceiverProfile: React.FC<ReceiverProfileProps> = ({
  receiverDetails,
  approved,
  receiverUser,
  handleEdit,
}) => {
  const { t } = useTranslation();

  const { receiverWarehouseList, receiverResources, receivers } = useSelector(
    (state: ApplicationState) => {
      return {
        receiverWarehouseList: state.receiver.receivers.receiverWarehouseList,
        receiverResources: state.resources.data.receiver,
        receivers: mapResourcesToReceivers(state),
      };
    }
  );

  const receiver = (receiverId: number) => receivers.find(r => r.receiver_id === receiverId);

  const getIndustryNames = () => {
    const industryNames = receiverDetails?.industry_ids.map(
      id =>
        receiverResources.industries.find(
          (industry: StandardResourceCodeDescription) => industry.id === id
        )?.name
    );
    return industryNames?.join(', ');
  };

  const getSalesChannelNames = () => {
    const channelNames = receiverDetails?.sales_channel_ids.map(
      id =>
        receiverResources.sales_channels.find(
          (channel: StandardResourceCodeDescription) => channel.id === id
        ).name
    );
    return channelNames?.join(', ');
  };

  const getMainWarehouseDistributorName = () => {
    return receiverWarehouseList.find(w => w.id === receiverDetails?.warehouse_ids[0])?.name;
  };

  const getWarehouseDistributorNames = () => {
    const distributorNames = receiverDetails?.warehouse_ids
      .slice(1)
      .map(id => receiverWarehouseList.find(w => w.id === id)?.name);
    return distributorNames?.join(', ');
  };

  const getRevenueName = () =>
    receiverResources.revenue_ranges.find(
      (range: StandardResourceCodeDescription) => range.id === receiverDetails?.revenue_range_id
    )?.name;

  const linkButton = (url: string) => (
    <Button
      href={`${url.includes('//') ? '' : '//'}${url}`}
      target="_blank"
      type="link"
      rel="noopener noreferrer"
    >
      {url}
    </Button>
  );

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-1/2 bg-white p-8 receiver__contact-info flex-col">
        <div className="receiver__modal-grid">
          <div>
            <div className="title">{t('common:contact')}</div>
            {!approved && !receiverUser && (
              <Alert message={t('receiver:contactInfo')} type="info" />
            )}
            {(approved || receiverUser) && (
              <React.Fragment>
                {receiverDetails?.primary_contact_name ||
                receiverDetails?.primary_contact_email ||
                receiverDetails?.phone_number ? (
                  <React.Fragment>
                    <div>{receiverDetails?.primary_contact_name}</div>
                    <div>{receiverDetails?.primary_contact_email}</div>
                    <div>{receiverDetails?.phone_number}</div>
                  </React.Fragment>
                ) : (
                  <div>{t('receiver:noInformation')}</div>
                )}
              </React.Fragment>
            )}
          </div>
          <div>
            <div className="title">{t('common:address')}</div>
            <div>
              {receiverDetails?.address_1 ? (
                <React.Fragment>
                  <div>{`${receiverDetails?.address_1} ${receiverDetails?.address_2}`}</div>
                  <div>{`${receiverDetails?.town}, ${receiverDetails?.state} ${receiverDetails?.zip}`}</div>
                </React.Fragment>
              ) : (
                <div>{t('receiver:noInformation')}</div>
              )}
            </div>
          </div>
          <div>
            <div className="title">{t('common:industry')}</div>
            <div>{getIndustryNames() || t('receiver:noInformation')}</div>
          </div>
          <div>
            <div className="title">{t('receiver:annualRevenue')}</div>
            <div>{getRevenueName() || t('receiver:noInformation')}</div>
          </div>
          <div>
            <div className="title">{t('receiver:salesChannel')}</div>
            <div>{getSalesChannelNames() || t('receiver:noInformation')}</div>
          </div>
        </div>
        <div className="about-us">
          <div className="title">{t('receiver:aboutUs')}</div>
          <div>{receiverDetails?.description || t('receiver:noInformation')}</div>
        </div>
        {receiverUser && (
          <Button
            data-testid="company-info"
            className="receiver__profile-edit-button"
            icon={<EditOutlined />}
            size="small"
            onClick={() => handleEdit!(COMPANY_INFORMATION)}
          />
        )}
      </div>

      <div className="w-1/2 pl-8">
        <div className="p-8 social-profile bg-white">
          <div className="title">{t('receiver:socialProfiles')}</div>

          <div className="receiver__modal-grid mt-4">
            <div>
              <div>{t('receiver:website')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.website_url ? (
                  linkButton(receiverDetails?.website_url)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
            <div>
              <div>{t('receiver:ebayStore')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.ebay_seller_name ? (
                  linkButton(receiverDetails?.ebay_seller_name)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
            <div>
              <div>{t('receiver:amazonStore')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.amazon_seller_name ? (
                  linkButton(receiverDetails?.amazon_seller_name)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
            <div>
              <div>{t('receiver:linkedIn')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.linkedin_name ? (
                  linkButton(receiverDetails?.linkedin_name)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
            <div>
              <div>{t('receiver:facebook')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.facebook_name ? (
                  linkButton(receiverDetails?.facebook_name)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
            <div>
              <div>{t('receiver:instagram')}</div>
              <div className="receiver__profile-link">
                {receiverDetails?.instagram_name ? (
                  linkButton(receiverDetails?.instagram_name)
                ) : (
                  <span className="text-gray-700">{t('receiver:noInformation')}</span>
                )}
              </div>
            </div>
          </div>
          {receiverUser && (
            <Button
              data-testid="social-info"
              className="receiver__profile-edit-button"
              icon={<EditOutlined />}
              size="small"
              onClick={() => handleEdit!(SOCIAL_INFORMATION)}
            />
          )}
        </div>

        <div className="receiver__modal-divider" />

        <div className="p-8 warehouse-distributor bg-white">
          <div className="title">{t('receiver:warehouseDistributors')}</div>
          <div>
            <span className="font-semibold">{`${t('receiver:mainSource')}: `}</span>
            {getMainWarehouseDistributorName() || t('receiver:noInformation')}
          </div>
          <div>
            <span className="font-semibold">{`${t('common:others')}: `}</span>
            {getWarehouseDistributorNames() || t('receiver:noInformation')}
          </div>
          {receiverUser && (
            <Button
              data-testid="warehouse-distributor"
              className="receiver__profile-edit-button"
              icon={<EditOutlined />}
              size="small"
              onClick={() => handleEdit!(WAREHOUSE_DISTRIBUTOR)}
            />
          )}
        </div>
      </div>
      {!receiverUser && receiverDetails && (
        <div className="w-full mt-2">
          <RequestProfileDetailsButton
            receiverId={receiverDetails!.id}
            receiverName={receiverDetails?.name || ''}
            disableOnAcknowledge={
              receiver(receiverDetails!.id)?.receiver_profile_requested_acknowledged === 1
            }
            disableOnReq={!!receiver(receiverDetails!.id)?.receiver_profile_requested_at}
          />
        </div>
      )}
    </div>
  );
};

export default ReceiverProfile;
