import React from 'react';
import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type InfoTooltipProps = {
  className?: string;
  popupClassName?: string;
  elements: { id: number; name: string; options: { id: number; name: string }[] }[];
  selectedElements: any;
  placeholder?: string;
  size?: SizeType;
  disabled?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
  onChange: any;
  onSelect?: any;
  onDeselect: any;
};

const AntMultiSelectGroups: React.FC<InfoTooltipProps> = ({
  className,
  popupClassName,
  elements,
  selectedElements,
  placeholder,
  size,
  disabled,
  showSearch,
  allowClear,
  onChange,
  onSelect,
  onDeselect,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedElements}
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      placeholder={placeholder}
      size={size}
      allowClear={allowClear}
      showSearch={showSearch}
      filterOption={(input, option: any) =>
        option?.options?.find(
          (opt: any) => opt.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
      disabled={disabled}
      className={className}
      popupClassName={popupClassName}
      getPopupContainer={trigger => (trigger && trigger.parentNode) || document.body}
      options={elements.map(element => ({
        key: element.id,
        label: element.name,
        value: element.id,
        options: element.options.map(option => ({
          key: `${option.id}_${element.id}`,
          value: option.id,
          label: option.name,
        })),
      }))}
    />
  );
};

export default AntMultiSelectGroups;
