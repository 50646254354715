import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Card, Button, Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { PlusCircleFilled } from '@ant-design/icons';
import { ApplicationState } from '../../../../reducers';
import FormSelect from '../../../global/Forms/FormSelect';
import { AsyncDispatch } from '../../../../../types/global';
import { PartTypeAttribute } from '../../../../../types/attributes';
import { getPageLimit, typingDone } from '../../../../utils/Utils';
import { fetchAttributesByKeyword } from '../../../../actions/parent/attribute/fetch';

type AddAttributePopoverProps = {
  attributeIds: number[];
  disabled?: boolean;
  selectedCategoryId?: number;
  addAttribute: (attribute: PartTypeAttribute) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const AddAttributePopover: React.FC<AddAttributePopoverProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [searchKeywords, setSearchKeywords] = React.useState('');
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const { attributes, fetchingSearchAttributes } = useSelector((state: ApplicationState) => ({
    attributes: state.parent.attributeManagement.searchAttributes,
    fetchingSearchAttributes: state.parent.attributeManagement.fetchingSearchAttributes,
  }));

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('attributeSearchInput');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
    if (visible && props.onOpen) props.onOpen();
    else if (props.onClose) props.onClose();
  };

  const handleSearch = (keywords: string) => {
    typingDone(() =>
      dispatch(
        fetchAttributesByKeyword({
          category_id: props.selectedCategoryId!,
          filters: keywords,
          limit: getPageLimit(),
          page: 1,
          value_limit: 0,
        })
      )
    );
    setSearchKeywords(keywords);
  };

  return (
    <Popover
      open={props.disabled ? false : popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="help application_listing__popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="qualifier__value-popover" bordered={false}>
          <Formik
            initialValues={{
              selectedAttributeId: undefined,
              name: '',
            }}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);

              if (values.selectedAttributeId === 0) {
                props.addAttribute({
                  id: undefined,
                  name: values.name,
                  source_id: 7, // 7 = custom
                  tags: [],
                  hide: 0,
                  tempId: Math.max(...props.attributeIds) + 1,
                  item_count: null,
                });
              } else {
                const attribute = attributes.find(a => a.id === values.selectedAttributeId);

                props.addAttribute({
                  id: attribute!.id!,
                  name: attribute!.name,
                  source_id: 7, // 7 = custom
                  tags: [],
                  hide: 0,
                  item_count: null,
                });
              }

              setSubmitting(false);
              handleVisibleChange(false);
            }}
          >
            {({ handleSubmit, setFieldValue, dirty, isSubmitting, values }) => {
              if (!popoverVisible) return null;

              const attributeValues =
                !dropdownOpen && values.name
                  ? [
                      ...attributes,
                      {
                        id: 0,
                        name: values.name,
                        source_id: 7, // 7 = custom
                        tags: [],
                        hide: 0,
                        item_count: null,
                      },
                    ]
                  : attributes;

              return (
                <div>
                  <Form layout="vertical">
                    <FormSelect
                      id="attributeSearchInput"
                      name="selectedAttributeId"
                      popupClassName="attributes-managing__select-dropdown"
                      values={attributeValues.map(a => ({ id: a.id!, name: a.name }))}
                      label={t('attributes:attribute')}
                      filterOption={false}
                      onSearch={val => handleSearch(val)}
                      notFoundContent={<span />}
                      open={dropdownOpen}
                      onDropdownVisibleChange={open => setDropdownOpen(open)}
                      showSearch
                      dropdownRender={menu => (
                        <React.Fragment>
                          {fetchingSearchAttributes && (
                            <span className="ml-3">
                              <Spin size="small" />
                            </span>
                          )}
                          {attributes.length > 0 && menu}
                          {searchKeywords && !fetchingSearchAttributes && (
                            <div
                              className="attributes-managing__add-attribute flex text-primary-600"
                              onClick={() => {
                                setFieldValue('name', searchKeywords);
                                setFieldValue('selectedAttributeId', 0);
                                setDropdownOpen(false);
                              }}
                            >
                              <div className="attributes-managing__add-attribute-icon">
                                <PlusCircleFilled />
                              </div>
                              <div className="flex-1">
                                <div className="text-xs pt-1">
                                  {t('attributes:addAttributeToDatabase')}
                                </div>
                                <span className="text-base pb-1" data-testid="keyword">
                                  {searchKeywords}
                                </span>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    />
                  </Form>

                  <div className="flex-col items-end">
                    <div>
                      <Button
                        onClick={() => handleVisibleChange(false)}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                        data-testid="ok"
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default AddAttributePopover;
