import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

export type ScoreCardsState = {
  validatedAnalysisTypeIds: number[];
  filterId: null | number;
  filterAnalysesResources: {
    selectedSegmentId?: number;
    selectedAlertTypeId?: number;
    selectedScorecardId?: number;
  };
};

const initialState = {
  validatedAnalysisTypeIds: [],
  filterId: null,
  filterAnalysesResources: {
    selectedSegmentId: undefined,
    selectedAlertTypeId: undefined,
    selectedScorecardId: undefined,
  },
};

const reducer: Reducer<ScoreCardsState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_VALIDATED_ANALYSIS_TYPES_FULFILLED':
      return {
        ...state,
        validatedAnalysisTypeIds: action.payload.data.analysis_types,
        filterId: action.payload.data.filter_id,
      };
    case 'CREATE_ANALYSIS_TYPE_FOR_BRAND_PENDING': {
      return {
        ...state,
        validatedAnalysisTypeIds: [
          ...state.validatedAnalysisTypeIds,
          ...action.meta.analysisTypeIds,
        ],
        filterId: action.meta.filterId,
      };
    }
    case 'CREATE_ANALYSIS_TYPE_FOR_BRAND_REJECTED': {
      return {
        ...state,
        validatedAnalysisTypeIds: state.validatedAnalysisTypeIds.filter(
          typeId => !action.meta.analysisTypeIds.includes(typeId)
        ),
        filterId: state.filterId,
      };
    }
    case 'UPDATE_SELECTED_SEGMENT_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedSegmentId:
            state.filterAnalysesResources.selectedSegmentId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'UPDATE_SELECTED_ALERT_TYPE_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedAlertTypeId:
            state.filterAnalysesResources.selectedAlertTypeId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'UPDATE_SELECTED_SCORECARD_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedScorecardId:
            state.filterAnalysesResources.selectedScorecardId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'DELETE_ANALYSIS_TYPE_FOR_BRAND_PENDING': {
      return {
        ...state,
        validatedAnalysisTypeIds: state.validatedAnalysisTypeIds.filter(
          typeId => !action.meta.analysisTypeIds.includes(typeId)
        ),
      };
    }
    case 'DELETE_ANALYSIS_TYPE_FOR_BRAND_REJECTED': {
      return {
        ...state,
        validatedAnalysisTypeIds: [
          ...state.validatedAnalysisTypeIds,
          ...action.meta.analysisTypeIds,
        ],
      };
    }
  }
  return state;
};

export default reducer;
