import React from 'react';
import { connect } from 'react-redux';
import { FormikHelpers, FormikValues } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/application';
import { fetchLinkingItems } from '../../actions/items/application/fetch';
import { updateApplication } from '../../actions/items/application/update';
import resourcesMap from '../../constants/ApplicationResourcesMapping.json';
import {
  getVehicleResources,
  invalidVehicleSubconfigs,
  mergeApplicationRanks,
  getValidResources,
} from '../../selectors/applications/applicationsSelector';
import ApplicationDetails from '../../components/body/application/ApplicationDetails';
import VcdbDrawer from '../../components/body/application/VcdbDrawer';
import DrawerFormik from '../../components/global/drawer/DrawerFormik';
import { ApplicationState } from '../../reducers';
import { UserType } from '../../../types/user';
import { AsyncDispatch } from '../../../types/global';
import { Item } from '../../../types/item';
import {
  Application,
  PartTypeCategory,
  RankedApplications,
  ApplicationCategory,
  ApplicationDigitalAsset,
  ApplicationType,
} from '../../../types/application';
import { Vehicle, VehicleStructureClass } from '../../../types/resources';
import { Brand } from '../../../types/brand';
import { DefaultValue } from '../../../types/brand_settings';
import { mapConfigToApplication, convertConfigNameToIds } from '../../utils/ApplicationUtils';
import { hasPermission } from '../../utils/Permissions';
import { typingDone } from '../../utils/Utils';
import { intercomEvent } from '../../utils/IntercomUtils';
import ChangeApplicationModal from '../../components/body/application/ChangeApplicationModal';
import IntercomFormEventHandler from '../../components/global/page/IntercomFormEventHandler';
import withAntdContext, { WithAntdContextProps } from '../withAntdContext';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

const resourcesMapping = resourcesMap as { [key: string]: string };

type ApplicationEditDrawerProps = {
  dispatch: AsyncDispatch;
  visible: boolean;
  createType?: ApplicationType;
  hideDelete: boolean;
  hideActionButtons: boolean;
  user: UserType;
  selectedItem: Item;
  brandId: number;
  partTypeCategories: PartTypeCategory[];
  applications: Application[];
  rankedApplications: RankedApplications;
  invalidVehicleSubconfigs: any[];
  validBaseVehicleResources: RankedApplications;
  getRecommendations: boolean;
  selectedApplicationId: number | null;
  initialSelectedCategory: string;
  resources: Vehicle;
  applicationStructure: VehicleStructureClass;
  brands: Brand[];
  applicationChildItemIds: number[];
  selectedVcdbs: number[];
  updatingApplication: boolean;
  defaultValues: DefaultValue[];
  applicationDigitalAssets: any[];
  selectedApplicationDigitalAssetId?: number;
  backToOverview?: (refetchApplications?: boolean) => void;
  createApplication?: () => void;
  selectNextApplication?: (id: number) => void;
  selectPreviousApplication?: (id: number) => void;
} & WithTranslation &
  WithAntdContextProps;

type ApplicationEditDrawerState = {
  detailsKeywords: string;
  selectedSubconfig: string | null;
  vcdbDrawerVisisble: boolean;
  changeModalVisisbleType?: string;
  vehicleEquipmentType?: ApplicationType;
  showOnlyValidSubConfigs: boolean;
  emptyArray: any[];
};

class ApplicationEditDrawer extends React.Component<
  ApplicationEditDrawerProps,
  ApplicationEditDrawerState
> {
  constructor(props: ApplicationEditDrawerProps) {
    super(props);
    this.state = {
      detailsKeywords: '',
      selectedSubconfig: null,
      vcdbDrawerVisisble: false,
      changeModalVisisbleType: undefined,
      vehicleEquipmentType: undefined,
      showOnlyValidSubConfigs:
        props.defaultValues.find((d: DefaultValue) => d.id === 12)?.value === '1',
      emptyArray: [],
    };
  }

  componentDidMount() {
    const { selectedItem, selectedApplicationId } = this.props;
    if (selectedItem.id) this.props.dispatch(fetchLinkingItems(selectedItem.id));
    if (selectedApplicationId) this.initializeApplication();
  }

  componentDidUpdate(prevProps: ApplicationEditDrawerProps) {
    const { selectedApplicationId } = this.props;
    if (selectedApplicationId && prevProps.selectedApplicationId !== selectedApplicationId) {
      this.initializeApplication();
    }
    if (this.props.selectedItem.id && prevProps.selectedItem.id !== this.props.selectedItem.id) {
      this.props.dispatch(fetchLinkingItems(this.props.selectedItem.id));
    }
  }

  componentWillUnmount() {
    this.selectSubconfig(null);
  }

  initializeApplication = async () => {
    const { selectedApplicationId, initialSelectedCategory, applications } = this.props;

    const selectedConfig = initialSelectedCategory || 'vehicle_bases';
    this.selectSubconfig(selectedConfig);

    const application = applications.find(a => a.item_application_id === selectedApplicationId);

    const type = Object.keys(application || {}).find(key =>
      ['mfr_ids', 'equipment_model_ids'].includes(key)
    )
      ? ApplicationType.EQUIPMENT
      : ApplicationType.VEHICLE;
    await this.setState({ vehicleEquipmentType: type });

    if (selectedApplicationId) this.fetchInitialMakeModelYearData(selectedApplicationId);
  };

  fetchInitialMakeModelYearData = (applicationId: number) => {
    const { applications, selectedVcdbs } = this.props;
    const application = applications.find(app => app.item_application_id === applicationId);
    const makeId = application && application.make_ids ? application.make_ids[0] : undefined;
    const modelId = application && application.model_ids ? application.model_ids[0] : undefined;

    if (makeId || modelId) {
      this.props.dispatch(actions.fetchApplicationYears(selectedVcdbs, makeId, modelId));
    }
    if (
      application!.hasOwnProperty('make_ids') ||
      application!.hasOwnProperty('model_ids') ||
      application!.hasOwnProperty('sub_model_ids') ||
      application!.hasOwnProperty('mfr_ids') ||
      application!.hasOwnProperty('equipment_model_ids') ||
      application!.hasOwnProperty('vehicle_type_ids') ||
      application!.hasOwnProperty('years') ||
      application!.hasOwnProperty('region_ids')
    ) {
      Promise.all([
        this.getRankedApplications(application!),
        this.getRankedBaseApplications(application!),
      ]).then(() => {
        this.props.dispatch(actions.setRecommendations(true));
      });
    }
  };

  refetchYears = (application: Application) => {
    const { selectedVcdbs } = this.props;
    const makeId = application && application.make_ids ? application.make_ids[0] : undefined;
    const modelId = application && application.model_ids ? application.model_ids[0] : undefined;

    if (makeId || modelId)
      this.props.dispatch(actions.fetchApplicationYears(selectedVcdbs, makeId, modelId));
  };

  setRecommendations = (status: boolean) => this.props.dispatch(actions.setRecommendations(status));

  closeDrawer = (refetchApplications?: boolean) => {
    const {
      applications,
      selectedApplicationId,
      applicationDigitalAssets,
      selectedApplicationDigitalAssetId,
    } = this.props;

    this.props.dispatch(actions.selectApplication(null));

    // assets are not handled with the save concept => we need to check for changes on close
    if (!refetchApplications && selectedApplicationDigitalAssetId) {
      const application = applications.find(a => a.item_application_id === selectedApplicationId);
      const applicationAssets = (application?.digital_assets || [])
        .map((a: ApplicationDigitalAsset) => a.file_name)
        .sort();
      const updatedAssets = applicationDigitalAssets.map((a: any) => a.file_name).sort();
      refetchApplications = !isEqual(applicationAssets, updatedAssets);
    }

    if (this.props.backToOverview) this.props.backToOverview(refetchApplications);
  };

  selectMake = async (
    makeId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations, selectedVcdbs } = this.props;

    const selectedMakes = application.make_ids || [];
    const makeIds = selectedMakes.length === 1 && selectedMakes[0] === makeId ? [] : [makeId];
    const modelIds = application.model_ids || [];

    const updatedApplication = mapConfigToApplication(application, 'make_ids', makeIds);

    setFieldValue('application', updatedApplication);

    if (modelIds.length > 0 || makeIds.length === 1) {
      this.props.dispatch(actions.fetchApplicationYears(selectedVcdbs, makeIds[0], modelIds[0]));
    }

    this.getRankedBaseApplications(updatedApplication);
    await this.getRankedApplications(updatedApplication);
    if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
  };

  selectModel = async (
    modelId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations, selectedVcdbs } = this.props;

    const selectedModels = application.model_ids || [];
    const modelIds = selectedModels.length === 1 && selectedModels[0] === modelId ? [] : [modelId];
    const makeId = application?.make_ids && application.make_ids[0];

    const updatedApplication = mapConfigToApplication(application, 'model_ids', modelIds);

    setFieldValue('application', updatedApplication);

    if (modelIds.length > 0) {
      this.props.dispatch(actions.fetchApplicationYears(selectedVcdbs, makeId, modelId));
    }

    this.getRankedBaseApplications(updatedApplication);
    await this.getRankedApplications(updatedApplication);
    if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
  };

  selectType = async (
    id: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations } = this.props;

    const selectedTypes = application.vehicle_type_ids || [];
    const typeIds = selectedTypes.length === 1 && selectedTypes[0] === id ? [] : [id];

    let updatedApplication = mapConfigToApplication(application, 'vehicle_type_ids', typeIds);

    if (typeIds.length > 0) {
      const type = this.allSubconfigValues('vehicle_types').find((t: any) => t.id === id);
      const group = this.allSubconfigValues('model_type_groups').find(
        (g: any) => g.name === type.vehicle_group
      );

      updatedApplication = mapConfigToApplication(updatedApplication, 'vehicle_group_ids', [
        group.id,
      ]);
    }

    setFieldValue('application', updatedApplication);

    await this.getRankedApplications(updatedApplication);
    if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
  };

  selectSubmodels = async (
    ids: any,
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue'],
    save?: boolean
  ) => {
    const { getRecommendations } = this.props;

    const submodelIds = ids instanceof Array ? ids : [ids];
    let selectedSubmodelIds = application.sub_model_ids || [];
    if (addValues) {
      // keyboard button pressed
      if (
        submodelIds.length === 1 &&
        selectedSubmodelIds.find((id: number) => id === submodelIds[0])
      ) {
        selectedSubmodelIds = selectedSubmodelIds.filter((id: number) => id !== submodelIds[0]); // remove
      } else {
        selectedSubmodelIds = [...submodelIds, ...selectedSubmodelIds]; // add
      }
    } else if (submodelIds.length === 1 && selectedSubmodelIds.length === 1) {
      // select or unselect single submodel
      selectedSubmodelIds = selectedSubmodelIds[0] === submodelIds[0] ? [] : submodelIds;
    } else {
      // select multiple submodels
      selectedSubmodelIds = submodelIds;
    }

    selectedSubmodelIds = [...new Set(selectedSubmodelIds)]; // remove duplicates

    const updatedApplication = mapConfigToApplication(
      application,
      'sub_model_ids',
      selectedSubmodelIds
    );
    setFieldValue('application', updatedApplication);
    if (save || save === undefined) await this.getRankedApplications(updatedApplication);
    if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
  };

  selectRegion = async (
    ids: any,
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue'],
    save?: boolean
  ) => {
    const { getRecommendations } = this.props;

    const regionIds = ids instanceof Array ? ids : [ids];
    let selectedRegionIds = application.region_ids || [];
    if (addValues) {
      if (regionIds.length === 1 && selectedRegionIds.find((id: number) => id === regionIds[0])) {
        selectedRegionIds = selectedRegionIds.filter((id: number) => id !== regionIds[0]); // remove
      } else {
        selectedRegionIds = [...regionIds, ...selectedRegionIds]; // add
      }
    } else if (regionIds.length === 1 && selectedRegionIds.length === 1) {
      // select or unselect single region
      selectedRegionIds = selectedRegionIds[0] === regionIds[0] ? [] : regionIds;
    } else {
      // select multiple regions
      selectedRegionIds = regionIds;
    }

    const updatedApplication = mapConfigToApplication(application, 'region_ids', selectedRegionIds);
    setFieldValue('application', updatedApplication);
    if (save || save === undefined) await this.getRankedApplications(updatedApplication);
    if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
  };

  selectYears = async (
    year: number | number[],
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations } = this.props;

    let years = year instanceof Array ? Array.from(new Set(year)) : [year];
    const selectedYears = application.years || [];

    if (years.length === 1 && selectedYears.length === 1) {
      years = years[0] === selectedYears[0] ? [] : years;
    }

    if (addValues) {
      years = [...selectedYears];
      if (year instanceof Array) {
        years = years.concat(year);
        years = Array.from(new Set(years));
      } else if (years.includes(year)) {
        years = years.filter(element => element !== year);
      } else {
        years.push(year);
      }
    }

    const updatedApplication = mapConfigToApplication(application, 'years', years);
    setFieldValue('application', updatedApplication);

    typingDone(async () => {
      this.getRankedBaseApplications(updatedApplication);
      await this.getRankedApplications(updatedApplication);
      if (!getRecommendations) this.props.dispatch(actions.setRecommendations(true));
    }, 100);
  };

  selectSubconfig = (subConfig: string | null) => {
    if (subConfig && this.state.selectedSubconfig !== subConfig) {
      this.setState({ selectedSubconfig: subConfig });
    }
  };

  handleCategoryUpdate = (
    category: ApplicationCategory | undefined,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations } = this.props;
    let updatedApplication;

    if (category) {
      updatedApplication = { ...application, category, category_id: category.id };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { category: x, category_id, ...rest } = application;
      updatedApplication = rest;
    }
    setFieldValue('application', updatedApplication);
    if (getRecommendations && updatedApplication) this.getRankedApplications(updatedApplication);
  };

  mapConfigToApplication = (
    subconfigName: string,
    subconfigId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations, brandId, selectedVcdbs } = this.props;

    const idName = convertConfigNameToIds(subconfigName);

    const mappedValueIds = application[idName] || [];

    const newValues = mappedValueIds.includes(subconfigId)
      ? mappedValueIds.filter((id: number) => id !== subconfigId)
      : [...mappedValueIds, subconfigId];

    const updatedApplication = mapConfigToApplication(application, idName, newValues);
    setFieldValue('application', updatedApplication);

    // handle recommendation fetch status for equipment
    if (
      idName === 'mfr_ids' ||
      idName === 'equipment_model_ids' ||
      idName === 'vehicle_type_ids' ||
      idName === 'region_ids' ||
      idName === 'years'
    ) {
      const eMfr = updatedApplication.hasOwnProperty('mfr_ids');
      const eModel = updatedApplication.hasOwnProperty('equipment_model_ids');
      const vehicleType = updatedApplication.hasOwnProperty('vehicle_type_ids');
      const years = updatedApplication.hasOwnProperty('years');
      const regions = updatedApplication.hasOwnProperty('region_ids');

      if ((eMfr || eModel || vehicleType || years || regions) && !getRecommendations) {
        this.getRankedBaseApplications(updatedApplication!);
        this.getRankedApplications(updatedApplication).then(() => {
          this.props.dispatch(actions.setRecommendations(true));
        });
      } else if (!(eMfr || eModel || vehicleType || years || regions) && getRecommendations)
        this.props.dispatch(actions.setRecommendations(false));
    }

    if (idName === 'mfr_ids' || idName === 'equipment_model_ids' || idName === 'vehicle_type_ids') {
      this.props.dispatch(
        actions.fetchEquipmentYears(
          brandId,
          selectedVcdbs,
          updatedApplication.mfr_ids,
          updatedApplication.equipment_model_ids,
          updatedApplication.vehicle_type_ids
        )
      );
    }

    if (getRecommendations) {
      if (idName === 'mfr_ids' || idName === 'equipment_model_ids' || idName === 'years') {
        this.getRankedBaseApplications(updatedApplication!);
      }
      this.getRankedApplications(updatedApplication, idName);
    }
  };

  updateItemApplicationAttributes = (
    param: { [x: string]: any },
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const paramName = Object.keys(param)[0];
    const value = param[paramName];

    if (application && application[paramName] !== value) {
      if (value !== undefined) {
        setFieldValue('application', { ...application, [paramName]: value });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [paramName]: x, ...rest } = application;
        setFieldValue('application', rest);
      }
    }
  };

  deleteApplication = () => {
    const { t, selectedApplicationId, dispatch } = this.props;
    const { modal } = this.props.appContext;
    const { closeDrawer } = this;

    modal.confirm({
      title: t('application:deleteMessage'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        closeDrawer();
        dispatch(actions.deleteApplication({ applicationId: selectedApplicationId! }));
      },
    });
  };

  filterMakes = (keywords: string) => {
    const { brandId, selectedVcdbs } = this.props;
    this.props.dispatch(actions.fetchApplicationMakes(brandId, selectedVcdbs, keywords));
  };

  filterModels = (application: Application, keywords: string) => {
    const { brandId, selectedVcdbs } = this.props;
    const makeId = application?.make_ids?.length === 1 ? application.make_ids[0] : null;

    this.props.dispatch(actions.fetchApplicationModels(brandId, selectedVcdbs, makeId, keywords));
  };

  filterSubmodels = (application: Application, keywords: string) => {
    const { brandId, selectedVcdbs } = this.props;
    const makeId = application?.make_ids?.length === 1 ? application.make_ids[0] : undefined;
    const modelId = application?.model_ids?.length === 1 ? application.model_ids[0] : undefined;

    this.props.dispatch(
      actions.fetchApplicationSubmodels(brandId, selectedVcdbs, makeId, modelId, keywords)
    );
  };

  getRankedApplications = (
    application: Application,
    newConfigName?: string,
    vcdbIds?: number[],
    keywords?: string
  ) => {
    const selectedVcdbs = vcdbIds || this.props.selectedVcdbs;
    const { createType } = this.props;
    const { detailsKeywords, vehicleEquipmentType, selectedSubconfig } = this.state;

    if (keywords !== undefined) this.setState({ detailsKeywords: keywords! });

    keywords = keywords !== undefined ? keywords : detailsKeywords;
    const rankEquipment =
      createType === ApplicationType.EQUIPMENT ||
      vehicleEquipmentType === ApplicationType.EQUIPMENT;

    this.props.dispatch(
      actions.fetchRankedApplications(application, selectedVcdbs, keywords, rankEquipment)
    );
    return this.props.dispatch(
      actions.fetchVisibleRanks(
        application,
        selectedSubconfig!,
        selectedVcdbs,
        rankEquipment,
        newConfigName
      )
    );
  };

  getRankedBaseApplications = (application: Application) => {
    const { selectedVcdbs, createType } = this.props;
    const { vehicleEquipmentType, showOnlyValidSubConfigs } = this.state;

    if (showOnlyValidSubConfigs) {
      const rankEquipment =
        createType === ApplicationType.EQUIPMENT ||
        vehicleEquipmentType === ApplicationType.EQUIPMENT;
      this.props.dispatch(actions.fetchValidConfigs(application, selectedVcdbs, rankEquipment));
    }
  };

  handleSubmit = async (values: any) => {
    const { selectedItem } = this.props;
    const { changeModalVisisbleType } = this.state;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'application_edit',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    await this.props.dispatch(updateApplication(values.application, selectedItem.id));
    if (!changeModalVisisbleType) this.closeDrawer(true);
  };

  allSubconfigValues = (configName: string) => {
    // get all values to a specific config name e.g. position
    // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
    if (resourcesMapping.hasOwnProperty(configName)) {
      configName = resourcesMapping[configName];
    }

    return this.props.resources ? this.props.resources[configName] : [];
  };

  render() {
    const {
      t,
      selectedApplicationId,
      brandId,
      brands,
      selectedItem,
      applicationChildItemIds,
      applications,
      rankedApplications,
      resources,
      user,
      selectedVcdbs,
      invalidVehicleSubconfigs,
    } = this.props;
    const { changeModalVisisbleType, showOnlyValidSubConfigs } = this.state;
    const application = applications.find(app => app.item_application_id === selectedApplicationId);

    const brand = brands.find(brand => brand.id === brandId);
    const linkedItemMessage = applicationChildItemIds.length
      ? ` (${t('application:inheritedTo')} ${applicationChildItemIds.length} ${t(
          'glossary:product',
          { count: applicationChildItemIds.length }
        )})`
      : '';

    if (!selectedApplicationId || !application) return null;

    const canManageEquipment = hasPermission(user, 'can_manage_equipment');
    const canManageVcdb = hasPermission(user, 'can_manage_custom_vcdb');

    return (
      <DrawerFormik
        className="application__details-drawer"
        title={`${brand?.name}: ${selectedItem.part_number || ''}${linkedItemMessage}`}
        width="80%"
        visible={this.props.visible}
        onClose={this.closeDrawer}
        destroyOnClose
        enableReinitialize
        initialValues={{
          application,
        }}
        onSubmit={values => this.handleSubmit(values)}
        push={false}
      >
        {({ setFieldValue, submitForm, values, dirty }) => {
          const showInlineAnalyses =
            invalidVehicleSubconfigs.length &&
            isEqual(application?.make_ids, values.application.make_ids) &&
            isEqual(application?.model_ids, values.application.model_ids) &&
            isEqual(application?.years, values.application.years);

          const mergedRankedApplications: any = mergeApplicationRanks(
            values.application,
            rankedApplications,
            resources,
            showInlineAnalyses ? invalidVehicleSubconfigs : undefined
          );

          const allValidSubconfigValues = (configName: string) => {
            const { validBaseVehicleResources, resources, getRecommendations } = this.props;

            let configKey = configName;
            // get all values to a specific config name e.g. position
            if (resourcesMapping.hasOwnProperty(configName)) {
              configKey = resourcesMapping[configName];
            }

            if (getRecommendations) {
              if (validBaseVehicleResources?.hasOwnProperty(configKey)) {
                const valueIds = values.application[convertConfigNameToIds(configName)];
                const validResources = validBaseVehicleResources[configKey];
                const rankedValues = mergedRankedApplications[configName];
                // invalid selections can be missing in valid resources
                const missingValues = valueIds
                  ?.filter((id: number) => !validResources.find((v: any) => v.id === id))
                  .map((id: number) => rankedValues.find((v: any) => v.id === id));

                if (missingValues?.length > 0) return [...missingValues, ...validResources];
                return validBaseVehicleResources[configKey];
              }

              return this.state.emptyArray;
            }

            return resources[configKey];
          };

          const getRankedApplications = (keywords?: string, vcdbIds?: number[]) =>
            this.getRankedApplications(values.application, '', vcdbIds, keywords);

          const handleCloseVcdbDrawer = () => {
            this.getRankedApplications(values.application);
            this.refetchYears(values.application);
            this.setState({ vcdbDrawerVisisble: false });
          };

          const refetchYears = () => {
            this.refetchYears(values.application);
          };

          const resetEqipment = () => {
            /*  eslint-disable @typescript-eslint/no-unused-vars */
            const { mfr_ids, equipment_model_ids, vehicle_type_ids, region_ids, years, ...rest } =
              values.application;
            /*  eslint-enable @typescript-eslint/no-unused-vars */
            setFieldValue('application', rest);
            this.props.dispatch(actions.fetchApplicationMakes(brandId, selectedVcdbs));
          };

          const resetVehicle = () => {
            const { application } = values;
            /*  eslint-disable @typescript-eslint/no-unused-vars */
            const { make_ids, model_ids, sub_model_ids, region_ids, years, ...rest } = application;
            /*  eslint-enable @typescript-eslint/no-unused-vars */
            setFieldValue('application', rest);
          };

          const selectNextApplication = (id: number) => {
            const { selectNextApplication } = this.props;
            if (dirty) {
              this.setState({ changeModalVisisbleType: 'next' });
            } else selectNextApplication!(id);
          };

          const selectPreviousApplication = (id: number) => {
            const { selectPreviousApplication } = this.props;
            if (dirty) {
              this.setState({ changeModalVisisbleType: 'prev' });
            } else selectPreviousApplication!(id);
          };

          const createApplication = () => {
            const { createApplication } = this.props;
            if (dirty) {
              this.setState({ changeModalVisisbleType: 'add' });
            } else createApplication!();
          };

          return (
            <React.Fragment>
              <ApplicationDetails
                singleApplicationEdit={this.props.hideActionButtons}
                canManageEquipment={canManageEquipment}
                canManageVcdb={canManageVcdb}
                selectedItem={selectedItem}
                application={values.application}
                applications={this.props.applications}
                applicationStructure={this.props.applicationStructure}
                getRankedApplications={getRankedApplications}
                refetchYears={refetchYears}
                getRecommendations={this.props.getRecommendations}
                rankedApplications={mergedRankedApplications}
                invalidVehicleSubconfigs={invalidVehicleSubconfigs}
                allSubconfigValues={
                  showOnlyValidSubConfigs ? allValidSubconfigValues : this.allSubconfigValues
                }
                selectedSubconfig={this.state.selectedSubconfig}
                selectMake={(makeId: number) =>
                  this.selectMake(makeId, values.application, setFieldValue)
                }
                selectModel={(modelId: number) =>
                  this.selectModel(modelId, values.application, setFieldValue)
                }
                selectType={(id: number) => this.selectType(id, values.application, setFieldValue)}
                selectSubmodel={(subModelIds: number | number[], add: boolean, save: boolean) =>
                  this.selectSubmodels(subModelIds, values.application, add, setFieldValue, save)
                }
                selectRegion={(regionIds: number | number[], add: boolean, save: boolean) =>
                  this.selectRegion(regionIds, values.application, add, setFieldValue, save)
                }
                selectYears={(years: number | number[], addValues: boolean) =>
                  this.selectYears(years, values.application, addValues, setFieldValue)
                }
                selectSubconfig={this.selectSubconfig}
                mapConfigToApplication={(name: string, id: number) =>
                  this.mapConfigToApplication(name, id, values.application, setFieldValue)
                }
                filterMakes={this.filterMakes}
                filterModels={(keywords: string) => this.filterModels(values.application, keywords)}
                filterSubmodels={(keywords: string) =>
                  this.filterSubmodels(values.application, keywords)
                }
                setRecommendations={this.setRecommendations}
                updateItemApplicationAttributes={(param: { [x: string]: any }) =>
                  this.updateItemApplicationAttributes(param, values.application, setFieldValue)
                }
                createApplication={createApplication}
                deleteApplication={!this.props.hideDelete ? this.deleteApplication : undefined}
                selectNextApplication={selectNextApplication}
                selectPreviousApplication={selectPreviousApplication}
                partTypeCategories={this.props.partTypeCategories}
                resetEqipment={resetEqipment}
                resetVehicle={resetVehicle}
                handleCategoryUpdate={(category?: ApplicationCategory) =>
                  this.handleCategoryUpdate(category, values.application, setFieldValue)
                }
                showVcdbDrawer={() => this.setState({ vcdbDrawerVisisble: true })}
                vehicleEquipmentType={
                  this.state.vehicleEquipmentType === ApplicationType.VEHICLE
                    ? 'vehicle'
                    : 'equipment'
                }
                setVehicleEquipment={(type: string) => {
                  const newType =
                    type === 'vehicle' ? ApplicationType.VEHICLE : ApplicationType.EQUIPMENT;
                  return this.setState({ vehicleEquipmentType: newType });
                }}
              />

              {this.state.vcdbDrawerVisisble && (
                <VcdbDrawer
                  visible={this.state.vcdbDrawerVisisble}
                  onClose={() => handleCloseVcdbDrawer()}
                />
              )}

              <IntercomFormEventHandler
                segment="application"
                partNr={selectedItem.part_number}
                brandCode={selectedItem.brand_code}
              />

              <ChangeApplicationModal
                showModal={!!changeModalVisisbleType}
                // isSubmitting is always false in combination with enableReinitialize => use
                // updatingApplication till this is fixed
                saving={this.props.updatingApplication}
                onCancel={() => this.setState({ changeModalVisisbleType: undefined })}
                selectNext={() => {
                  if (changeModalVisisbleType === 'next')
                    this.props.selectNextApplication!(selectedApplicationId);
                  if (changeModalVisisbleType === 'prev')
                    this.props.selectPreviousApplication!(selectedApplicationId);
                  if (changeModalVisisbleType === 'add') this.props.createApplication!();

                  this.setState({ changeModalVisisbleType: undefined });
                }}
                saveAndSelectNext={async () => {
                  try {
                    await submitForm();

                    if (changeModalVisisbleType === 'next')
                      this.props.selectNextApplication!(selectedApplicationId);
                    if (changeModalVisisbleType === 'prev')
                      this.props.selectPreviousApplication!(selectedApplicationId);
                    if (changeModalVisisbleType === 'add') this.props.createApplication!();

                    this.setState({ changeModalVisisbleType: undefined });
                  } catch (error) {
                    this.setState({ changeModalVisisbleType: undefined });
                  }
                }}
              />
            </React.Fragment>
          );
        }}
      </DrawerFormik>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: state.user.user,
    partTypeCategories: state.items.application.partTypeCategories,
    applications: state.items.application.applications,
    rankedApplications: state.items.application.rankedApplications,
    invalidVehicleSubconfigs: invalidVehicleSubconfigs(state),
    applicationDigitalAssets: state.items.application.applicationDigitalAssets,
    selectedApplicationDigitalAssetId: state.items.application.selectedApplicationDigitalAssetId,
    validBaseVehicleResources: getValidResources(state),
    getRecommendations: state.items.application.getRecommendations,
    applicationChildItemIds: state.items.application.applicationChildItemIds,
    selectedVcdbs: state.items.application.selectedVcdbs,
    updatingApplication: state.items.application.updatingApplication,
    resources: getVehicleResources(state),
    applicationStructure: state.resources.data.vehicle_structure,
    brandId: state.parent.brands.selectedBrandId,
    brands: state.parent.brands.brands,
    defaultValues: brandAccDefaultValues(state),
  };
}

export { ApplicationEditDrawer };
export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(withAntdContext(ApplicationEditDrawer)))
);
