import React from 'react';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import FilterDropdown from './FilterDropdown';
import headerConstants from '../../constants/HeaderTranslation.json';
import applConstants from '../../constants/ApplicationTranslation.json';
import {
  FilterCustomParameterValue,
  FilterCustomReference,
  FilterTypeGo,
} from '../../../types/filter';
import { PopulationStatus } from '../../../types/resources';
import { BrandItemTag } from '../../../types/brand_catalogue';
import { AvailableChannel } from '../../../types/channel';
import { AvailableReceiver } from '../../../types/receiver';

const applicationConstants = applConstants as { [key: string]: any };
const constants = headerConstants as { [key: string]: any };

type FilterBarProps = {
  filter: FilterTypeGo;
  populationStatuses: PopulationStatus[];
  brandItemTags: BrandItemTag[];
  availableChannels: AvailableChannel[];
  availableReceivers: AvailableReceiver[];
  removeFilterParameterValue: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number;
    filterCustomReferenceId?: number;
    value: string;
  }) => void;
  updateFilterOption: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number;
    filterCustomReferenceId?: number;
    optionId: number;
  }) => void;
  removeFilterSection: (sectionKey: string) => void;
  removeFilterParameter: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number;
    filterCustomReferenceId?: number;
  }) => void;
  getCustomFieldName: (id: number) => void;
  getExpiName: (id: number) => void;
  getExpiValueName: (id: number, code: string) => void;
  getSubBrandName: (id: number) => void;
  parameterIsBool: (parameter: any) => boolean;
  readOnlyFilter: boolean;
  fetchingCustomFields: boolean;
} & WithTranslation;

type FilterBarState = {
  dropdownOpen: boolean;
};

class FilterBar extends React.Component<FilterBarProps, FilterBarState> {
  filterBlock: any;

  constructor(props: FilterBarProps) {
    super(props);
    this.filterBlock = {};
    this.state = { dropdownOpen: false };
  }

  setDropdownState = (state: boolean) => {
    this.setState({ dropdownOpen: state });
  };

  getChannelReceiverName = (parameter: {
    filter_custom_reference_id: number;
    reference_id: number;
  }) => {
    const { availableChannels, availableReceivers } = this.props;
    if (
      parameter.filter_custom_reference_id === FilterCustomReference.SUBMITTED_CHANNEL ||
      parameter.filter_custom_reference_id === FilterCustomReference.ONLINE_CHANNEL
    ) {
      const channel = availableChannels.find(({ id }) => id === parameter.reference_id);
      return channel ? channel.name : '';
    }
    if (
      parameter.filter_custom_reference_id === FilterCustomReference.SUBMITTED_RECEIVER ||
      parameter.filter_custom_reference_id === FilterCustomReference.ONLINE_RECEIVER
    ) {
      const receiver = availableReceivers.find(({ id }) => id === parameter.reference_id);
      return receiver ? receiver.name : '';
    }
  };

  getSubmittedStatus = (parameter: { option_id: number }) => {
    return parameter.option_id === 3
      ? constants.filter_custom.submitted
      : constants.filter_custom.not_submitted;
  };

  getOnlineStatus = (parameter: { option_id: number }) => {
    return parameter.option_id === 1
      ? constants.filter_custom.online
      : constants.filter_custom.offline;
  };

  getCustomFilterName = (parameter: {
    filter_custom_reference_id: number;
    reference_id: number;
    option_id: number;
  }) => {
    const customReferenceId = parameter.filter_custom_reference_id;
    if (
      [
        FilterCustomReference.UNIVERSAL_PART,
        FilterCustomReference.POPULATION_STATUS,
        FilterCustomReference.TAGS,
        FilterCustomReference.SUB_BRAND,
      ].includes(customReferenceId)
    )
      return constants.filter_custom[customReferenceId];
    if (
      [FilterCustomReference.SUBMITTED_CHANNEL, FilterCustomReference.SUBMITTED_RECEIVER].includes(
        customReferenceId
      )
    ) {
      const channelReceiverName = this.getChannelReceiverName(parameter);
      const submittedStatus = this.getSubmittedStatus(parameter);
      return (
        <span>
          {channelReceiverName}: {submittedStatus}
        </span>
      );
    }
    if (
      [FilterCustomReference.ONLINE_CHANNEL, FilterCustomReference.ONLINE_RECEIVER].includes(
        customReferenceId
      )
    ) {
      const channelReceiverName = this.getChannelReceiverName(parameter);
      const onlineStatus = this.getOnlineStatus(parameter);
      return (
        <span>
          {channelReceiverName}: {onlineStatus}
        </span>
      );
    }
    if (customReferenceId === FilterCustomReference.CUSTOM_FIELDS)
      return this.props.getCustomFieldName(parameter.reference_id);
    if (customReferenceId === FilterCustomReference.EXTENDED_INFORMATIONS)
      return this.props.getExpiName(parameter.reference_id);
  };

  getCustomFilterValue = (
    parameter: {
      filter_custom_reference_id?: FilterCustomReference;
      reference_id?: number;
      option_id?: number;
    },
    value: { value: string }
  ) => {
    if (this.props.parameterIsBool(parameter)) {
      if (parameter.filter_custom_reference_id === FilterCustomReference.EXTENDED_INFORMATIONS) {
        // expi boolean is Yes and No instead of true and false
        return this.props.getExpiValueName(parameter.reference_id!, value.value);
      }
      if (parameter.filter_custom_reference_id === FilterCustomReference.CUSTOM_FIELDS) {
        return parameter.option_id === 1
          ? constants.filter_custom.true
          : constants.filter_custom.false;
      }
      return Number(value.value) ? constants.filter_custom.true : constants.filter_custom.false;
    }
    if (parameter.filter_custom_reference_id === FilterCustomReference.POPULATION_STATUS) {
      const status = this.props.populationStatuses.find(state => state.id === Number(value.value));
      return status?.name;
    }
    if (parameter.filter_custom_reference_id === FilterCustomReference.EXTENDED_INFORMATIONS) {
      return this.props.getExpiValueName(parameter.reference_id!, value.value);
    }
    if (parameter.filter_custom_reference_id === FilterCustomReference.TAGS) {
      const tag = this.props.brandItemTags.find(tag => tag.id === Number(value.value));
      return tag ? tag.name : '';
    }
    if (parameter.filter_custom_reference_id === FilterCustomReference.SUB_BRAND) {
      return this.props.getSubBrandName(Number(value.value));
    }
    return value.value;
  };

  values = (
    sectionKey: string,
    parameter: {
      filter_custom_reference_id?: number;
      reference_id?: number;
      resource?: string;
      level?: number;
      option_id: number;
    },
    values: { [x: string]: any }[]
  ) => {
    if (
      parameter.filter_custom_reference_id === FilterCustomReference.ONLINE_RECEIVER ||
      parameter.filter_custom_reference_id === FilterCustomReference.ONLINE_CHANNEL
    )
      return '';
    return values.map((value, i) => {
      const name =
        sectionKey === 'filter_custom'
          ? this.getCustomFilterValue(parameter, value as FilterCustomParameterValue)
          : value.category_name || value.resource_name || value.analysis_name || value.value;
      return (
        <div key={i} className="header__filter-value">
          {name}
          {/* @ts-ignore */}
          {i !== 'temp' && (
            <CloseOutlined
              className="filter__delete-value"
              onClick={() =>
                // @ts-ignore
                this.props.removeFilterParameterValue({
                  sectionKey,
                  referenceId: parameter.reference_id,
                  resource: parameter.resource,
                  level: parameter.level,
                  filterCustomReferenceId: parameter.filter_custom_reference_id,
                  value:
                    value.category_name ||
                    value.resource_name ||
                    value.analysis_name ||
                    value.value,
                })
              }
            />
          )}
        </div>
      );
    });
  };

  attributesBlock = (parameters: { [x: string]: any }[], sectionKey: string) => {
    let paramName = '';
    const level2: any[] = [];
    parameters.forEach(parameter => {
      if (parameter.level === 1) {
        if (parameter.values.length > 0) {
          paramName = parameter.values[0].resource_name;
        }
      }
      if (parameter.level === 2) {
        level2.push(parameter);
      }
    });

    return level2.map(parameter => (
      <div key={parameter.id} className="header__filter-parameter">
        <div className="header__filter-parameter-name float__left">{paramName}</div>
        <FilterDropdown
          optionId={parameter.option_id}
          optionName={parameter.option_name}
          sectionKey={sectionKey}
          updateFilterOption={({
            sectionKey,
            optionId,
          }: {
            sectionKey: string;
            optionId: number;
          }) =>
            this.props.updateFilterOption({
              sectionKey,
              referenceId: parameter.reference_id,
              resource: parameter.resource,
              level: parameter.level,
              filterCustomReferenceId: parameter.filter_custom_reference_id,
              optionId,
            })
          }
        />
        <div className="header__filter-values">
          {this.values(sectionKey, parameter, parameter.values)}
        </div>
      </div>
    ));
  };

  block = (parameters: any[], sectionKey: string) =>
    parameters.map(parameter => {
      let name = '';
      if (sectionKey === 'filter_application') name = applicationConstants[parameter.resource];
      if (sectionKey === 'filter_category') name = constants[sectionKey][parameter.level];
      if (sectionKey === 'filter_analysis') name = constants[sectionKey][parameter.reference_id];
      if (sectionKey === 'filter_custom') name = this.getCustomFilterName(parameter);
      return (
        <div
          key={name + parameter.reference_id + parameter.filter_custom_reference_id}
          className="header__filter-parameter"
        >
          <div className="filter__parameter-data">
            <div className="header__filter-parameter-name float__left">
              {name || parameter.resource}
            </div>
            {!(sectionKey === 'filter_custom' && this.props.parameterIsBool(parameter)) && (
              <FilterDropdown
                optionId={parameter.option_id}
                optionName={parameter.option_name}
                position={this.filterBlock[sectionKey] && this.filterBlock[sectionKey].scrollLeft}
                sectionKey={sectionKey}
                updateFilterOption={({
                  sectionKey,
                  optionId,
                }: {
                  sectionKey: string;
                  optionId: number;
                }) =>
                  this.props.updateFilterOption({
                    sectionKey,
                    referenceId: parameter.reference_id,
                    resource: parameter.resource,
                    level: parameter.level,
                    filterCustomReferenceId: parameter.filter_custom_reference_id,
                    optionId,
                  })
                }
                setDropdownState={this.setDropdownState}
              />
            )}
            {parameter.reference_id !== 'temp' && (
              <button
                className="filter__parameter-delete"
                onClick={() =>
                  this.props.removeFilterParameter({
                    sectionKey,
                    referenceId: parameter.reference_id,
                    resource: parameter.resource,
                    level: parameter.level,
                    filterCustomReferenceId: parameter.filter_custom_reference_id,
                  })
                }
              >
                <CloseOutlined />
              </button>
            )}
          </div>
          {parameter.filter_custom_reference_id === FilterCustomReference.CUSTOM_FIELDS &&
          this.props.fetchingCustomFields ? (
            <Skeleton.Input active size="small" style={{ width: '40px' }} />
          ) : (
            <div className="header__filter-values">
              {this.values(sectionKey, parameter, parameter.values)}
            </div>
          )}
        </div>
      );
    });

  section = (parameters: { [x: string]: any }[], sectionKey: string) => (
    <div
      key={sectionKey}
      className={classNames(
        'header__filter-block',
        { 'scrollable--horizontal': !this.state.dropdownOpen },
        'flex'
      )}
      ref={filterBlock => (this.filterBlock = { ...this.filterBlock, [sectionKey]: filterBlock })}
    >
      {sectionKey === 'filter_part_attribute'
        ? this.attributesBlock(parameters, sectionKey)
        : this.block(parameters, sectionKey)}
    </div>
  );

  sections = () => {
    const { t, filter } = this.props;

    const sections = ['filter_category', 'filter_application', 'filter_analysis', 'filter_custom'];
    if (filter.id) {
      let noParamsCount = 0;
      return Object.keys(filter).map(section => {
        if (sections.includes(section) && filter[section as keyof FilterTypeGo]) {
          const sectionFilter = filter[section as keyof FilterTypeGo];

          const parameters = (Array.isArray(sectionFilter) && sectionFilter[0].parameters) || [];
          if (Object.keys(parameters).length > 0) {
            return (
              <div key={section} className="header__filter-section">
                <span className="filter__section-top">
                  <span className="title filter__section-title">{constants.filter[section]}</span>
                  {parameters.length > 1 && ( // delete-all button only if more then 1 filter
                    <button
                      className="filter__section-delete"
                      onClick={() => this.props.removeFilterSection(section)}
                    >
                      <CloseOutlined />
                      <span>{t('filter:deleteAll')}</span>
                    </button>
                  )}
                </span>
                {this.section(parameters, section)}
              </div>
            );
          }
          noParamsCount += 1;
        }
        if (noParamsCount === 4) return <div key="no-filter">{t('filter:noFilterSet')}</div>;
      });
    }
  };

  render() {
    return (
      <div className={`filter-bar__container ${this.props.readOnlyFilter ? 'disabled' : ''}`}>
        <div className="filter-bar flex">{this.sections()}</div>
      </div>
    );
  }
}

export default withTranslation()(FilterBar);
