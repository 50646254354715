import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withContainerWrapper } from '../ContainerWrapper';
import itemAnalysisActions from '../../actions/items/analyses';
import catalogueActions from '../../actions/catalogue/catalogue/index.ts';
import * as utils from '../../utils/Utils';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

class CounterContainer extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateCounter > this.props.updateCounter) {
      this.debouncedUpdate();
    }
    if (utils.itemsChanged(this.props.selectedItems, nextProps.selectedItems)) {
      const updateIds = this.props.selectedItems.map(item => item.id);
      if (this.props.updateCounter !== 0) {
        this.debouncedUpdate.cancel();
        const langId = this.props.defaultValues.find(d => d.resource_table === 'languages')?.value;
        if (updateIds.length)
          this.props.dispatch(catalogueActions.fetchItemsShortNames(updateIds, langId));
        // trigger analyses update
        const itemIds = nextProps.selectedItems.map(item => item.id);
        if (itemIds.length) this.props.dispatch(itemAnalysisActions.triggerAnalysesUpdate(itemIds));
      }
    }
  }

  handleCounterExpired = () => {
    const { selectedItems, selectedItem, activeArea, defaultValues } = this.props;
    if (selectedItems.length > 0) {
      const langId = defaultValues.find(d => d.resource_table === 'languages')?.value;
      this.props.dispatch(
        catalogueActions.fetchItemsDataAfterUpdate(selectedItems, selectedItem, activeArea, langId)
      );
    }
  };

  debouncedUpdate = debounce(this.handleCounterExpired, 2000, { leading: true });

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const selectedItems = getSelectedItems(state);
  return {
    selectedItems,
    selectedItem: selectedItems[0],
    activeArea: state.app.navigationBar.activeArea,
    updateCounter: state.catalogue.updateManager.updateCounter,
    defaultValues: brandAccDefaultValues(state),
  };
}
export default connect(mapStateToProps)(withContainerWrapper(CounterContainer));
