import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { importVcdbExcelFile } from '../../../../actions/parent/vcdb/update';

type CustomVcdbImportModalProps = {
  handleOnCancel: () => void;
  showImportModal?: boolean;
  values: FormikValues;
};

const CustomVcdbImportModal: React.FC<CustomVcdbImportModalProps> = ({
  handleOnCancel,
  showImportModal,
  values,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const readFile = (file: any) => {
    handleOnCancel();
    dispatch(importVcdbExcelFile(file, values.configTypeIds, values.versionId));
  };

  const handleChange = (info: any) => {
    readFile(info.file);
  };

  const renderUploadButton = () => (
    <div>
      <FileExcelOutlined className="background-icon-excel" />
      <div className="flex items-center">
        <PlusOutlined />
        <div className="ml-2">
          {t('common:upload')} {t('common:excel')}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      width="50%"
      title={`${t('common:import')}`}
      open={showImportModal}
      onCancel={() => {
        handleOnCancel();
      }}
      footer={null}
      className="vcdb-import-modal"
    >
      <Upload
        accept=".xlsx"
        listType="picture-card"
        showUploadList={false}
        customRequest={handleChange}
      >
        {renderUploadButton()}
      </Upload>
    </Modal>
  );
};

export default CustomVcdbImportModal;
