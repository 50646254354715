import React from 'react';
import { Select, Tag } from 'antd';

import catalogueTranslation from '../../../constants/CatalogueTranslations.json';

class CatalogueFilterTags extends React.Component {
  state = {
    values: [],
  };

  componentDidMount() {
    this.updateValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterId !== this.props.filterId) {
      this.updateValue();
    }
    if (prevProps.filterTags !== this.props.filterTags) {
      this.updateValue();
    }
  }

  updateValue = () => {
    const { filterTags, brandItemTags } = this.props;
    const values = filterTags.map(tagId => {
      const tag = brandItemTags.find(tag => tag.id === tagId);
      return tag ? tag.name : '';
    });

    this.setState({ values });
  };

  handleDeselect = value => {
    this.setState(prevState => ({ values: prevState.values.filter(val => val !== value) }));
    const tag = this.props.brandItemTags.find(tag => tag.name === value);
    if (tag) this.props.handleRemoveTag(tag.id);
  };

  handleOnSelect = value => {
    this.setState(prevProps => ({ values: [...prevProps.values, value] }));
    this.props.handleAddTag(value);
  };

  tagRender = props => {
    const { label, onClose, closable } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="processing"
        closable={closable}
        onClose={onClose}
        onMouseDown={onPreventMouseDown}
      >
        {label}
      </Tag>
    );
  };

  render() {
    return (
      <Select
        mode="multiple"
        className="catalogue-bar__tags-select select-blue-tags"
        placeholder={catalogueTranslation.filterByTag}
        suffixIcon={null}
        onSelect={this.handleOnSelect}
        onDeselect={this.handleDeselect}
        value={this.state.values || []}
        disabled={this.props.disabled}
        defaultValue={[]}
        size="small"
        options={this.props.brandItemTags.map(({ name }) => ({
          key: name,
          value: name,
          label: name,
        }))}
        tagRender={this.tagRender}
      />
    );
  }
}

export default CatalogueFilterTags;
