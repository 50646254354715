import React from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Alert } from 'antd';
import CatalogueBarFilter from '../../components/body/catalogue/CatalogueBarFilter';
import filterActions from '../../actions/catalogue/filter';
import { getOrderStringToInt, typingDone } from '../../utils/Utils';
import FilterContainer from '../header/FilterContainer';
import { getAllProductsFilter } from '../../selectors/catalogue/filterSelector';
import filterTranslations from '../../constants/FilterTranslation.json';
import catalogueTranslation from '../../constants/CatalogueTranslations.json';
import ReceiverCatalogueList from '../../components/body/receiver_data_stream/ReceiverCatalogueList';
import { CatalogueResetFilterButton } from '../../components/body/catalogue/CatalogueResetFilterButton';
import { fillInPlaceholders } from '../../utils/String';
import { mapFilterName } from '../../utils/FilterUtils';
import { ApplicationState } from '../../reducers';
import { Resources } from '../../../types/resources';
import { FilterType, FilterCustomReference, FilterTypeGo } from '../../../types/filter';
import {
  ReceiverCatalogueBrandType,
  ReceiverPendingRequests,
} from '../../../types/receiver_data_stream';
import { UserType } from '../../../types/user';
import { withReceiverRouteWrapper } from '../ReceiverRouteWrapper';
import { BrandItemTag } from '../../../types/brand_catalogue';
import { SubBrand } from '../../../types/brand';
import { ExportParamsState } from '../../components/body/catalogue/CatalogueExportModal';
import { updateClientFilterBrandIds } from '../../actions/catalogue/catalogue/update';
import { CatalogueQueryParams } from '../../../types/catalogue';
import { startExport } from '../../actions/exporter';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import { intercomEvent, sendFilterEvent } from '../../utils/IntercomUtils';
import {
  fetchPendingDetailsRequests,
  fetchReceiverBrandsByFilterId,
} from '../../actions/receiver_data_stream/productsCatalogue/fetch';
import {
  approveBrandRequests,
  cancelRequestBrand,
  declineBrandRequests,
  requestBrands,
} from '../../actions/receiver_data_stream/productsCatalogue/update';
import { ReceiverColumns } from '../../constants/ReceiverConstants';
import { BrandCustomField } from '../../../types/custom_fields';
import { ReceiverDetails } from '../../../types/receiver';

type ReceiverCatalogueContainerProps = {
  dispatch: any;
  selectedFilter: FilterType;
  selectedFilterGo: FilterTypeGo;
  allProductsFilter: FilterType;
  brands: ReceiverCatalogueBrandType[];
  allAccessableBrands: ReceiverCatalogueBrandType[];
  brandFilterCount: number;
  user: UserType;
  fetching: boolean;
  filters: FilterType[];
  resources: Resources;
  brandItemTags: BrandItemTag[];
  catalogueQueryParams: CatalogueQueryParams;
  subBrands: SubBrand[];
  brandCustomFields: BrandCustomField[];
  fetchingCustomFields: boolean;
  companyInfo: ReceiverDetails;
};

type ReceiverCatalogueContainerState = {
  showFilterDrawer: boolean;
};

type Props = ReceiverCatalogueContainerProps;

class ReceiverCatalogueContainer extends React.Component<Props, ReceiverCatalogueContainerState> {
  readonly state = {
    showFilterDrawer: false,
    canExportFullTemplate: hasPermission(this.props.user, 'can_export_full_template'),
    goOnly: runningOnGoOnlyApi(this.props.user),
  };

  componentDidMount() {
    this.handleShowResults();

    intercomEvent('viewed-all-brands');
  }

  sendFilterSelectEvent = (filterId: number) => {
    intercomEvent('viewed-all-brands', {
      action: 'filter-selected',
      filter_name: this.props.filters.find(f => f.id === filterId)?.name || '',
    });
  };

  fetchFilter = (filterId: number) => {
    // const { goOnly } = this.state;
    // if (goOnly) return this.props.dispatch(fetchFilterGo(filterId));
    // this.props.dispatch(fetchFilterGo(filterId));
    return this.props.dispatch(filterActions.fetchAndSelectFilter(filterId));
  };

  handleFilterByKeyword = (keywords: string) => {
    // const { selectedFilterGo } = this.props;
    // const { goOnly } = this.state;

    typingDone(() =>
      // goOnly
      //   ? this.props.dispatch(updateFilterGo({ ...selectedFilterGo, keywords })).then(() => {
      //       this.handleShowResults();
      //     }) :
      this.props
        .dispatch(filterActions.updateFilter(this.props.selectedFilter.id, { keywords }))
        .then((res: { value: { data: { filter: FilterType } } }) => {
          // this.props
          //   .dispatch(updateFilterGo(convertToGoFilterStructure(res.value.data.filter)))
          //   .then(() => this.handleShowResults());
          this.handleShowResults();
        })
    );
  };

  updateClienFilterBrandIds = (brandIds: number[]) => {
    this.props.dispatch(updateClientFilterBrandIds(brandIds));
  };

  handleFilterByBrand = (
    brandIds: number[],
    statusIds: number[],
    sortOrder: string,
    sourceId?: number
  ) => {
    this.updateClienFilterBrandIds(brandIds);
    this.handleShowResults(brandIds, statusIds, ReceiverColumns.COMPANY, sortOrder, sourceId);
  };

  handleRequestBrands = (brandIds: number[]) => {
    this.props.dispatch(requestBrands(brandIds));

    intercomEvent('viewed-all-brands', {
      action: 'brand-requested',
      brand_names: brandIds.map(id => this.props.brands.find(b => b.id === id)?.name).join(', '),
      receiver: this.props.companyInfo.name,
    });
  };

  handleApproveBrandRequests = (brandIds: number[]) => {
    this.props.dispatch(approveBrandRequests(brandIds));

    intercomEvent('viewed-all-brands', {
      action: 'brand-approved',
      brand_names: brandIds.map(id => this.props.brands.find(b => b.id === id)?.name).join(', '),
      receiver: this.props.companyInfo.name,
    });
  };

  handleDeclineBrandRequests = (brandIds: number[]) => {
    this.props.dispatch(declineBrandRequests(brandIds));

    intercomEvent('viewed-all-brands', {
      action: 'brand-rejected',
      brand_names: brandIds.map(id => this.props.brands.find(b => b.id === id)?.name).join(', '),
      receiver: this.props.companyInfo.name,
    });
  };

  handleCancelRequestBrand = (brandId: number) => {
    this.props.dispatch(cancelRequestBrand(brandId));

    intercomEvent('viewed-all-brands', {
      action: 'brand-request-cancelled',
      brand_name: this.props.brands.find(b => b.id === brandId)?.name || '',
      receiver: this.props.companyInfo.name,
    });
  };

  handleOpenFilter = () => {
    this.setState(prevState => {
      const showFilterDrawer = !prevState.showFilterDrawer;
      if (!showFilterDrawer) {
        this.handleShowResults();
      }
      return { showFilterDrawer };
    });

    sendFilterEvent(this.props.selectedFilterGo, 'all-brands');
  };

  handleShowResults = (
    brandIds?: number[],
    statusIds?: number[],
    orderCondition?: string,
    sortOrder?: string,
    filterSourceId?: number
  ) => {
    const { catalogueQueryParams, selectedFilterGo } = this.props;
    const selectedBrandIds = brandIds || catalogueQueryParams.filterBrandIds;
    const condition = sortOrder ? orderCondition : undefined;

    this.props.dispatch(
      fetchReceiverBrandsByFilterId(
        selectedFilterGo.id,
        selectedBrandIds,
        filterSourceId,
        statusIds,
        condition,
        getOrderStringToInt(sortOrder)
      )
    );
    this.props.dispatch(fetchPendingDetailsRequests()).then((resp: any) => {
      const pendingReqs = resp.value.data.pending_requests;
      intercomEvent('profile-info-requested', {
        location: 'all-brands-view',
        account_name: pendingReqs.map((req: ReceiverPendingRequests) => req.account_name).join(','),
        parent_name: pendingReqs.map((req: ReceiverPendingRequests) => req.parent_name).join(','),
      });
    });
  };

  handleSaveNewFilter = (filterName: string, publicFilter: number) => {
    const { selectedFilterGo, allProductsFilter } = this.props;
    return this.props
      .dispatch(filterActions.copyFilter(filterName, selectedFilterGo.id, publicFilter))
      .then(() => {
        const allFilterId = allProductsFilter.id;
        this.resetFilter(allFilterId);
        return Promise.resolve();
      })
      .catch((e: ProgressEvent) => Promise.reject(e));
  };

  getTagParameterId = (customFilter: any) => {
    let parameterId;
    Object.keys(customFilter.parameters).forEach(id => {
      const parameter = customFilter.parameters[id];
      if (parameter.filter_custom_reference_id === 5) parameterId = id;
    });
    return parameterId;
  };

  createTagParameter = (
    customFilterId: string,
    customRefId: number,
    optionId: number,
    value: string
  ) => {
    // create new filter parameter including value
    this.props
      .dispatch(
        filterActions.createFilterCustomParameter({
          filterId: customFilterId,
          refId: null,
          optionId,
          value,
          customRefId,
        })
      )
      .then(() => this.handleShowResults());
  };

  handleAddTag = (tagName: string) => {
    const { selectedFilter, brandItemTags } = this.props;
    const { goOnly } = this.state;

    const customRefId = FilterCustomReference.TAGS;
    const optionId = 1; // is one of
    const tag = brandItemTags.find(tag => tag.name === tagName);

    if (!tag) return;

    if (goOnly) {
      // const updatedFilter = addFilterTag(customRefId, tag, optionId, selectedFilterGo);
      // this.props.dispatch(updateFilterGo(updatedFilter)).then(() => this.handleShowResults());
    } else if (selectedFilter.filter_custom) {
      const customFilterId = Object.keys(selectedFilter.filter_custom)[0];
      const parameterId = this.getTagParameterId(selectedFilter.filter_custom[customFilterId]);

      if (parameterId) {
        this.props
          .dispatch(filterActions.addFilterCustomParameterValue(parameterId, tag.id))
          .then((res: { value: { data: { filter: FilterType } } }) => {
            // this.props
            //   .dispatch(updateFilterGo(convertToGoFilterStructure(res.value.data.filter)))
            //   .then(() => this.handleShowResults());
            this.handleShowResults();
          });
      } else {
        this.createTagParameter(customFilterId, customRefId, optionId, String(tag.id));
      }
    } else {
      this.props
        .dispatch(filterActions.createCustomFilter(selectedFilter.id))
        .then((result: any) => {
          const filter = result.value.data.filter;
          const customFilterId = Object.keys(filter.filter_custom)[0];
          this.createTagParameter(customFilterId, customRefId, optionId, String(tag.id));
        });
    }

    intercomEvent('filter', { location: 'all-brands', filter_by: 'tags' });
  };

  handleRemoveTag = async (tagId: number) => {
    const { selectedFilter, selectedFilterGo } = this.props;
    const { goOnly } = this.state;

    const customRefId = FilterCustomReference.TAGS;

    if (goOnly) {
      // const updatedFilter = removeFilterTag(customRefId, tagId, selectedFilterGo);
      // await this.props.dispatch(updateFilterGo(updatedFilter));
    } else {
      const customFilterId = Object.keys(selectedFilter.filter_custom)[0];
      const customFilter = selectedFilter.filter_custom[customFilterId];
      const paramId = Object.keys(customFilter.parameters).find(
        id => customFilter.parameters[id].filter_custom_reference_id === FilterCustomReference.TAGS
      ) as string;
      const tagParameter = customFilter.parameters[paramId];
      const valueId = Object.keys(tagParameter.values).find(
        id => Number(tagParameter.values[id].value) === tagId
      );

      let fResponse;
      if (Object.keys(tagParameter.values).length > 1) {
        fResponse = await this.props.dispatch(
          filterActions.removeFilterCustomParameterValues(paramId, valueId)
        );
      } else {
        fResponse = await this.props.dispatch(filterActions.deleteFilterCustomParameter(paramId));
      }

      // await this.props.dispatch(
      //   updateFilterGo(convertToGoFilterStructure(fResponse.value.data.filter))
      // );
    }

    this.handleShowResults();

    intercomEvent('filter', { location: 'all-brands', filter_by: 'tags' });
  };

  getFilterParameterId = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => {
    const { selectedFilter } = this.props;
    let filterParameterId;
    // @ts-ignore
    Object.values(selectedFilter[sectionKey]).forEach((block: any) => {
      Object.keys(block.parameters || []).forEach(paramId => {
        const parameter = block.parameters[paramId];

        if (sectionKey === 'filter_category') {
          if (level === parameter.level) filterParameterId = paramId;
        } else if (sectionKey === 'filter_application') {
          if (resource === parameter.resource) filterParameterId = paramId;
        } else if (sectionKey === 'filter_analysis') {
          if (referenceId === parameter.reference_id) filterParameterId = paramId;
        } else if (sectionKey === 'filter_custom') {
          if (
            filterCustomReferenceId === parameter.filter_custom_reference_id &&
            referenceId === parameter.reference_id
          )
            filterParameterId = paramId;
        }
      });
    });

    return filterParameterId;
  };

  handleDeleteFilterParameter = async ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => {
    // const { selectedFilterGo } = this.props;
    // const { goOnly } = this.state;

    // if (goOnly) {
    //   const updatedFilter = removeFilterParameter({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     filterGo: selectedFilterGo,
    //   });

    //   await this.props.dispatch(updateFilterGo(updatedFilter));
    //   return this.handleShowResults();
    // }

    const paramId = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });

    let fResponse;

    if (sectionKey === 'filter_category') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterCategoryParameter(paramId));
    } else if (sectionKey === 'filter_application') {
      fResponse = await this.props.dispatch(
        filterActions.deleteFilterApplicationParameter(paramId)
      );
    } else if (sectionKey === 'filter_analysis') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterAnalysisParameter(paramId));
    } else if (sectionKey === 'filter_custom') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterCustomParameter(paramId));
    }

    if (fResponse) {
      // this.props
      //   .dispatch(updateFilterGo(convertToGoFilterStructure(fResponse.value.data.filter)))
      //   .then(() => this.handleShowResults());
      this.handleShowResults();
    }
  };

  resetFilter = (filterId: number) => this.props.dispatch(filterActions.resetFilter(filterId));

  handleStartExport = (exportState: ExportParamsState, brandId: number) => {
    const actionParams = {
      ...exportState,
      brandId,
      exportSchemaId: 2, // use exporting db
      filterId: this.props.selectedFilterGo.id,
    };
    // @ts-ignore
    this.props.dispatch(startExport(actionParams));
  };

  render() {
    const { catalogueQueryParams, selectedFilterGo } = this.props;
    const { canExportFullTemplate, goOnly } = this.state;

    return (
      <div className="catalogue-container receiver-catalogue">
        <div className="catalogue-bar">
          {this.props.selectedFilterGo && (
            <CatalogueBarFilter
              segment="receiver_catalogue"
              selectedFilterGo={this.props.selectedFilterGo}
              disableKeywordInput={catalogueQueryParams.filterBrandIds.length === 0}
              brandCustomFields={this.props.brandCustomFields}
              fetchingCustomFields={this.props.fetchingCustomFields}
              handleFilterByKeywords={this.handleFilterByKeyword}
              handleOpenFilter={this.handleOpenFilter}
              handleSaveNewFilter={this.handleSaveNewFilter}
              handleDeleteFilterParameter={this.handleDeleteFilterParameter}
              filters={this.props.filters}
              goOnly={goOnly}
              onFilterChange={({ filterId }) => {
                this.fetchFilter(filterId).then(() => this.handleShowResults());
                this.sendFilterSelectEvent(filterId);
              }}
              resources={this.props.resources}
              brandItemTags={this.props.brandItemTags}
              subBrands={this.props.subBrands}
              handleAddTag={this.handleAddTag}
              handleRemoveTag={this.handleRemoveTag}
            />
          )}
        </div>
        <div className="catalogue-menu-list-container">
          {catalogueQueryParams.filterBrandIds.length === 0 && !!selectedFilterGo.keywords ? (
            <div className="flex-1 p-4">
              <Alert
                className="h-20"
                message={filterTranslations.selectBrand}
                description={filterTranslations.selectedBrandRequired}
                type="info"
                showIcon
              />
              <CatalogueResetFilterButton
                className="no-products__button"
                selectedFilterGo={selectedFilterGo}
                goOnly={goOnly}
                showText
              />
            </div>
          ) : (
            <ReceiverCatalogueList
              fetching={this.props.fetching}
              brands={this.props.brands}
              selectedFilterGo={this.props.selectedFilterGo}
              allBrandsCount={this.props.allAccessableBrands.length}
              brandFilterCount={this.props.brandFilterCount}
              brandFilterIds={catalogueQueryParams.filterBrandIds}
              handleRequestBrands={this.handleRequestBrands}
              handleApproveBrandRequests={this.handleApproveBrandRequests}
              handleDeclineBrandRequests={this.handleDeclineBrandRequests}
              handleCancelRequestBrand={this.handleCancelRequestBrand}
              handleStartExport={this.handleStartExport}
              updateClienFilterBrandIds={this.updateClienFilterBrandIds}
              handleFilterByBrand={this.handleFilterByBrand}
              canExportFullTemplate={canExportFullTemplate}
            />
          )}
        </div>
        <Drawer
          open={this.state.showFilterDrawer}
          onClose={this.handleOpenFilter}
          title={fillInPlaceholders(catalogueTranslation.filterName, {
            filterName: mapFilterName(this.props.selectedFilterGo),
          })}
          width="80vw"
        >
          {this.state.showFilterDrawer && <FilterContainer />}
          <div className="catalogoue-extended-bottom">
            <Button onClick={this.handleOpenFilter} type="primary" size="small" htmlType="button">
              {catalogueTranslation.showResults}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const allProductsFilter =
    state.catalogue.filter && state.catalogue.filter.filters ? getAllProductsFilter(state) : [];
  return {
    brands: state.receiverDataStream.brands,
    fetching: state.receiverDataStream.fetching,
    catalogueQueryParams: state.catalogue.catalogue.catalogueQueryParams,
    allAccessableBrands: state.receiverDataStream.allAccessableBrands,
    subBrands: state.receiverDataStream.subBrands,
    brandFilterCount: state.receiverDataStream.brandFilterCount,
    brandItemTags: state.catalogue.catalogue.brandItemTags,
    selectedFilter: state.catalogue.filter.filter,
    selectedFilterGo: state.catalogue.filter.filterGo,
    filters: state.catalogue.filter.filters,
    brandCustomFields: state.brand.customFields.customFields,
    fetchingCustomFields: state.brand.customFields.fetchingCustomFields,
    companyInfo: state.settings.companyProfile.companyInfo,
    allProductsFilter,
    user: state.user.user,
    resources: state.resources.data,
  };
};

export default connect(mapStateToProps)(withReceiverRouteWrapper(ReceiverCatalogueContainer));
