import React from 'react';
import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Tooltip, App, Space, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { typingDone } from '../../../utils/Utils';
import {
  getFilterTags,
  isCustomFilter,
  isCustomPublicFilter,
  isFilterEmpty,
} from '../../../utils/FilterUtils';
import CatalogueFilterPreview from './CatalogueFilterPreview';
import CatalogueFilterTags from './CatalogueFilterTags';
import CatalogueSaveFilter from './CatalogueSaveFilter';
import catalogueTranslation from '../../../constants/CatalogueTranslations.json';
import { FilterType, FilterTypeGo } from '../../../../types/filter';
import AntPopover from '../../global/AntPopover';
import { BrandItemTag } from '../../../../types/brand_catalogue';
import { Resources } from '../../../../types/resources';
import { SubBrand } from '../../../../types/brand';
import HelpCenter from '../../../containers/HelpCenter';
import { CatalogueResetFilterButton } from './CatalogueResetFilterButton';
import CatalogueFilterSelect from './CatalogueFilterSelect';
import AntTooltip from '../../global/AntTooltip';
import { BrandCustomField } from '../../../../types/custom_fields';
import SearchInput from '../../global/Forms/SearchInput';

type CatalogueBarFilterProps = {
  segment: 'brand_catalogue' | 'receiver_catalogue';
  selectedFilterGo: FilterTypeGo;
  filters: FilterType[];
  disableKeywordInput?: boolean;
  brandCustomFields: BrandCustomField[];
  fetchingCustomFields: boolean;
  goOnly: boolean;
  handleFilterByKeywords: (keywords: string) => void;
  handleOpenFilter: () => void;
  handleSaveNewFilter: (
    filterName: string,
    publicFilter: number
  ) => Promise<undefined | ProgressEvent>;
  brandItemTags?: BrandItemTag[];
  handleAddTag?: (value: string) => void;
  handleRemoveTag?: (tagId: number) => void;
  handleDeleteFilterParameter: (params: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => void;
  resources: Resources;
  subBrands: SubBrand[];
  onFilterChange: ({ filterId }: { filterId: number }) => void;
  canManageGlobalFilter?: boolean;
  readOnlyFilter?: boolean;
};

const CatalogueBarFilter: React.FC<CatalogueBarFilterProps> = props => {
  const { modal } = App.useApp();
  const [keywordInput, setKeywordInput] = React.useState(props.selectedFilterGo.keywords || '');

  // Custom filters could be assigned to a channel, we therefore protect them from being changed by e.g. disabling the input fields by default.
  const selectedFilterIsCustomFilter =
    (isCustomFilter(props.selectedFilterGo) || isCustomPublicFilter(props.selectedFilterGo)) &&
    !props.readOnlyFilter;

  const [actionsDisabled, disableActions] = React.useState(selectedFilterIsCustomFilter);

  React.useEffect(() => {
    disableActions(selectedFilterIsCustomFilter);
  }, [selectedFilterIsCustomFilter, props.selectedFilterGo.id]);

  React.useEffect(() => {
    // after filter reset empy keyword
    if (!props.selectedFilterGo.keywords) {
      setKeywordInput('');
    }
  }, [props.selectedFilterGo.keywords]);

  React.useEffect(() => {
    setKeywordInput(props.selectedFilterGo.keywords || '');
  }, [props.selectedFilterGo.id]);

  const updateKeywordInput = (keyword: string) => {
    setKeywordInput(keyword);
    typingDone(() => {
      props.handleFilterByKeywords(keyword);
    });
  };

  const { selectedFilterGo } = props;
  const { t } = useTranslation();
  const filterIsEmpty = isFilterEmpty(selectedFilterGo);

  const confirmUpdate = () => {
    modal.confirm({
      title: t('catalogue:enableFilterUpdate.title'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        disableActions(false);
      },
    });
  };

  return (
    <div className="catalogue-filter-container">
      <AntTooltip title={t('catalogue:receiver.disabledSearch')} hide={!props.disableKeywordInput}>
        <div className="search">
          <SearchInput
            key={props.selectedFilterGo.id}
            value={keywordInput}
            disabled={actionsDisabled || props.disableKeywordInput}
            onChange={e => updateKeywordInput(e.target.value)}
            onPressEnter={() => props.handleFilterByKeywords(keywordInput)}
            placeholder={catalogueTranslation.catalogueBar.searchProducts}
            allowClear
            autoFocus
            size="small"
            suffix={
              <HelpCenter
                id="search_product"
                className="catalogue-search__helper"
                placement="bottom"
              />
            }
          />
        </div>
      </AntTooltip>
      <div className="catalogue-bar__tags">
        <CatalogueFilterTags
          brandItemTags={props.brandItemTags}
          filterId={props.selectedFilterGo.id}
          filterTags={getFilterTags(selectedFilterGo)}
          handleAddTag={props.handleAddTag}
          handleRemoveTag={props.handleRemoveTag}
          disabled={actionsDisabled || props.readOnlyFilter}
        />
      </div>
      {props.fetchingCustomFields ? (
        <Skeleton.Input active size="small" style={{ width: '40px' }} className="ml-1" />
      ) : (
        <CatalogueFilterPreview
          {...props}
          selectedFilter={selectedFilterGo}
          disabled={actionsDisabled}
          readOnlyFilter={props.readOnlyFilter}
        />
      )}
      <div className="filter-action-button-container flex-1 flex justify-between">
        <div className="flex">
          <Tooltip title={t('catalogue:addFilter.title')}>
            <Button
              icon={<FilterOutlined />}
              className="button-add-filter"
              onClick={props.handleOpenFilter}
              size="small"
              htmlType="button"
              disabled={actionsDisabled}
            />
          </Tooltip>
          {(props.selectedFilterGo.type_id === 1 || props.readOnlyFilter) && (
            <Tooltip
              title={
                props.readOnlyFilter
                  ? t('catalogue:saveFilter.copy')
                  : t('catalogue:saveFilter.title')
              }
            >
              <AntPopover
                trigger="click"
                disabled={filterIsEmpty}
                content={
                  <CatalogueSaveFilter
                    handleSaveNewFilter={props.handleSaveNewFilter}
                    canManageGlobalFilter={props.canManageGlobalFilter}
                  />
                }
                buttonProps={{
                  icon: props.readOnlyFilter ? <CopyOutlined /> : <SaveOutlined />,
                  className: 'button-save-filter ml-2',
                  size: 'small',
                  htmlType: 'button',
                }}
                overlayClassName="save-filter-popover"
              />
            </Tooltip>
          )}
          {actionsDisabled && (
            <Tooltip title={t('catalogue:enableFilterUpdate.action')}>
              <Button
                size="small"
                type="primary"
                onClick={() => confirmUpdate()}
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {!props.readOnlyFilter && (
            <CatalogueResetFilterButton
              className="px-2"
              selectedFilterGo={selectedFilterGo}
              disabled={actionsDisabled}
              goOnly={props.goOnly}
            />
          )}
        </div>
        <div className="ml-auto">
          <Space.Compact>
            <CatalogueFilterSelect
              filters={props.filters}
              selectedFilterGo={selectedFilterGo}
              selectedFilterId={props.selectedFilterGo.id}
              onFilterChange={props.onFilterChange}
            />
          </Space.Compact>
        </div>
      </div>
    </div>
  );
};

export default CatalogueBarFilter;
