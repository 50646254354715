import React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer, Table, Column } from 'react-virtualized';
import dayjs from 'dayjs';
import { ApplicationState } from '../../../reducers';
import PriceSheetDrawer from './PriceSheetDrawer';
import { PriceSheet } from '../../../../types/price_sheet';
import { ExtendedResources } from '../../../../types/resources';
import { hasPermission } from '../../../utils/Permissions';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { showUpgradePlanNotification } from '../../../actions/app/modal';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';

type PriceSheetsSettingsPageProps = {
  priceSheets: PriceSheet[];
};

const PriceSheetsSettingsPage: React.FC<PriceSheetsSettingsPageProps> = props => {
  const { priceSheets } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [priceSheet, setPriceSheet] = React.useState<PriceSheet | undefined>(undefined);

  const { resources, user, brandCode } = useSelector((state: ApplicationState) => {
    return {
      resources: state.resources.data.price,
      user: state.user.user,
      brandCode: getSelectedBrandCode(state),
    };
  });

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
    if (!canManagePriceSheets) dispatch(showUpgradePlanNotification());

    intercomEvent('viewed-brand-settings', {
      location: 'settings-price-sheets',
      brand_code: brandCode!,
      action: 'edit',
    });
  };

  const canManagePriceSheets = hasPermission(user, 'can_manage_price_sheets');

  const noData = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <React.Fragment>
      <AutoSizer>
        {({ height, width }) => (
          <div style={{ height, width }}>
            <Table
              height={height - 12}
              width={width - 2}
              headerHeight={40}
              rowCount={priceSheets.length}
              dataSource={priceSheets!}
              rowHeight={40}
              rowGetter={({ index }) => priceSheets[index]}
              noRowsRenderer={noData}
            >
              <Column label={t('pricing:number')} dataKey="number" width={250} />
              <Column
                label={t('pricing:supersededNumber')}
                dataKey="superseded_price_sheet_number"
                width={250}
              />
              <Column label={t('pricing:name')} dataKey="name" width={250} />
              <Column
                label={t('pricing:currency')}
                dataKey="currency_id"
                width={250}
                cellRenderer={({ rowData }) => {
                  const currency = resources.currencies.find(
                    (currency: ExtendedResources) => rowData.currency_id === currency.id
                  );
                  return currency?.name || null;
                }}
              />
              <Column
                label={t('pricing:effectiveDate')}
                dataKey="effective_date"
                cellRenderer={({ rowData }) =>
                  rowData.effective_date ? dayjs(rowData.effective_date).format('MM-DD-YYYY') : null
                }
                width={250}
              />
              <Column
                label={t('pricing:expirationDate')}
                dataKey="expiration_date"
                cellRenderer={({ rowData }) =>
                  rowData.expiration_date
                    ? dayjs(rowData.expiration_date).format('MM-DD-YYYY')
                    : null
                }
                width={250}
              />
              <Column
                label={t('pricing:brand')}
                dataKey="brand"
                cellRenderer={({ rowData }) => (rowData.brands ? rowData.brands.join(', ') : null)}
                width={150}
                flexGrow={1}
              />
              <Column
                dataKey="action"
                cellRenderer={({ rowData }) => (
                  <Button
                    onClick={() => {
                      handleOpenDrawer();
                      setPriceSheet(rowData);
                    }}
                    size="small"
                  >
                    {t('common:edit')}
                  </Button>
                )}
                width={70}
                flexGrow={1}
              />
            </Table>
          </div>
        )}
      </AutoSizer>
      {drawerOpen && (
        <PriceSheetDrawer
          canManagePriceSheet={canManagePriceSheets}
          visible={drawerOpen}
          onClose={() => {
            setPriceSheet(undefined);
            setDrawerOpen(false);
          }}
          priceSheet={priceSheet}
        />
      )}
    </React.Fragment>
  );
};

export default PriceSheetsSettingsPage;
