import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';

const getCustomFields = (state: ApplicationState) => state.brand.customFields.customFields;

export const customFieldsSortRecordNumber = createSelector([getCustomFields], brandCustomFields =>
  brandCustomFields.map(customField => ({
    ...customField,
    options: customField.options
      ? customField.options.sort((a, b) => a.record_number - b.record_number)
      : [],
  }))
);

const getAnalysisResources = (state: ApplicationState) => state.resources.analysis;
const getValidatedAnalysisTypeIds = (state: ApplicationState) =>
  state.settings.scorecards.validatedAnalysisTypeIds;
const getFilterAnalysesResorces = (state: ApplicationState) =>
  state.settings.scorecards.filterAnalysesResources;

export const allResourcesAndAddValidatingInfo = createSelector(
  [getAnalysisResources, getValidatedAnalysisTypeIds],
  (analysisResources, validatedAnalysisTypeIds) => {
    return {
      ...analysisResources,
      types: analysisResources.analysis_types,
      scorecards: analysisResources.scorecards.map(scorecard => ({
        ...scorecard,
        validating_against_scorecard: scorecard.type_ids
          .split(',')
          .every(typeId => validatedAnalysisTypeIds.includes(Number(typeId))),
      })),
    };
  }
);

export const filterResourcesAndAddValidatingInfo = createSelector(
  [getAnalysisResources, getValidatedAnalysisTypeIds, getFilterAnalysesResorces],
  (analysisResources, validatedAnalysisTypeIds, filterAnalysesResourcesById) => {
    const { selectedSegmentId, selectedAlertTypeId, selectedScorecardId } =
      filterAnalysesResourcesById;
    let analysisTypes = analysisResources.analysis_types;
    if (selectedSegmentId) {
      analysisTypes = analysisTypes.filter(analysis => analysis.segment_id === selectedSegmentId);
    }
    if (selectedAlertTypeId) {
      analysisTypes = analysisTypes.filter(
        analysis => analysis.alert_type_id === selectedAlertTypeId
      );
    }
    if (selectedScorecardId) {
      const selectedScoreCardTypeIds = analysisResources.scorecards
        .find(scorecard => scorecard.id === selectedScorecardId)
        ?.type_ids.split(',');
      analysisTypes = analysisTypes.filter(analysis =>
        selectedScoreCardTypeIds?.includes(analysis.id.toString())
      );
    }
    return {
      ...analysisResources,
      types: analysisTypes,
      scorecards: analysisResources.scorecards.map(scorecard => ({
        ...scorecard,
        validating_against_scorecard: scorecard.type_ids
          .split(',')
          .every(typeId => validatedAnalysisTypeIds.includes(Number(typeId))),
      })),
    };
  }
);

const accountLanguages = (state: ApplicationState) =>
  state.parent.languageSettings.accountLanguages;
const segmentTranslationValues = (state: ApplicationState) =>
  state.parent.languageTranslation.translations;

export const translationsInitValues = (languages: any, translations: any) => {
  const transInitValues = {};
  languages.forEach((accLanguage: any) => {
    translations.forEach((segTranslation: any) => {
      if (segTranslation.id !== -2) {
        const translationValue = segTranslation.translations?.find(
          (trans: any) => trans.language_id === accLanguage.language_id
        );
        if (translationValue) {
          // @ts-ignore
          transInitValues[`${segTranslation.id}_${accLanguage.language_id}`] = {
            parentId: segTranslation.id,
            value: translationValue.value,
            count: segTranslation.count,
            languageId: translationValue.language_id,
          };
        } else {
          // @ts-ignore
          transInitValues[`${segTranslation.id}_${accLanguage.language_id}`] = {
            parentId: segTranslation.id,
            value: '',
            count: null,
            languageId: accLanguage.language_id,
          };
        }
      }
    });
  });
  return transInitValues;
};

export const mapTranslationsToLanguages = createSelector(
  [accountLanguages, segmentTranslationValues],
  (languages, translations) => {
    const transInitValues = translationsInitValues(languages, translations);
    return transInitValues;
  }
);
