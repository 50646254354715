import React from 'react';
import { Button, Table, Tooltip, App } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HazardousMaterial } from '../../../../types/itemPackage';
import HazardousMaterialForm from './HazardousMaterialForm';

type HazardousMaterialTableProps = {
  hzRecords: HazardousMaterial[];
  pkgIndex: number;
  expandHzKeys: (string | number)[];
  showAllFields: boolean;
  handleHzDelete: (hzRecord: HazardousMaterial, pkgIndex: number) => void;
  updateHzMaterial: (updatedHzRecord: HazardousMaterial) => void;
};

const HazardousMaterialTable: React.FC<HazardousMaterialTableProps> = ({
  hzRecords,
  pkgIndex,
  expandHzKeys,
  handleHzDelete,
  showAllFields,
  updateHzMaterial,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const handleDelete = (record: HazardousMaterial) =>
    modal.confirm({
      title: t('packageSeg:deleteHz'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        handleHzDelete(record, pkgIndex);
      },
    });

  return (
    <Table
      className="hz-table"
      expandable={{
        defaultExpandedRowKeys: expandHzKeys,
        expandedRowRender: (record: HazardousMaterial, index: number) => (
          <div className="hz-sub-fields">
            <HazardousMaterialForm
              index={index}
              pkgIndex={pkgIndex}
              showAllFields={showAllFields}
              hazardousMaterial={record}
              updateHzMaterial={(fieldName, value) => {
                const updatedRecord = { ...record, [fieldName]: value };
                updateHzMaterial(updatedRecord);
              }}
            />
          </div>
        ),
        expandRowByClick: true,
      }}
      dataSource={hzRecords}
      showHeader={false}
      pagination={false}
      size="small"
      rowKey={(record: HazardousMaterial) => record.id}
    >
      <Column
        dataIndex="recordName"
        key="recordName"
        width={250}
        render={(record: string) => <div className="package-type">{record}</div>}
      />
      <Column
        key="summary"
        ellipsis
        render={(record: HazardousMaterial) => (
          <Tooltip
            title={record.detailedSummary?.map(s => (
              <div key={s}>{s}</div>
            ))}
            placement="topLeft"
          >
            <div className="truncate ...">{record.summary}</div>
          </Tooltip>
        )}
      />
      <Column
        key="action"
        width={80}
        render={(record: HazardousMaterial) => (
          <Button
            danger
            size="small"
            className="w-30 ml-2 mb-2"
            onClick={e => {
              handleDelete(record);
              e.stopPropagation();
            }}
            style={{ margin: 0 }}
          >
            {t('common:delete')}
          </Button>
        )}
      />
    </Table>
  );
};

export default HazardousMaterialTable;
