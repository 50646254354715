import React from 'react';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import constants from '../../../constants/SettingsTranslation.json';
import SingleDefaultValue from './SingleDefaultValue';
import DefaultValuesPrice from './DefaultValuesPrice';
import SimpleDivider from '../../global/SimpleDivider';
import { AntSelectEntries } from '../../global/AntSelect';
import { DefaultValue } from '../../../../types/brand_settings';
import { PriceSheet } from '../../../../types/price_sheet';
import { Segment } from '../../../../types/resources';

type DefaultValuesProps = {
  brandSettings: boolean;
  canManageDefaultValues: boolean;
  priceSheets: PriceSheet[];
  defaultValues: DefaultValue[];
  segments: Segment[];
  resources: { [key: string]: AntSelectEntries };
  updateDefaultValue: (id: number, selected: boolean | SelectValue | string | null) => void;
  createDefaultPriceTypeDescription: (
    priceSheetId: number,
    priceTypeId: number,
    description: string
  ) => void;
};

const DefaultValues: React.FC<DefaultValuesProps> = props => {
  const { t } = useTranslation();

  const updateDefaultValue = (id: number, value: boolean | SelectValue | string | null) => {
    return props.updateDefaultValue(id, value);
  };

  const settingsSegment = (id: number | null) => {
    const { segments, defaultValues } = props;
    const segment = id
      ? segments.find(segment => segment.id === id)
      : { id: 0, title: constants.default_values.global_settings };

    const isPriceSegment = id === 6;
    const values: DefaultValue[] = defaultValues.filter(value => value.segment_id === id);

    return (
      <div key={segment?.id} className="settings-card mb-12">
        <SimpleDivider title={segment?.title || ''} />

        <div key={segment?.id} className="title settings-title">
          <Form>
            {isPriceSegment ? (
              <DefaultValuesPrice
                brandSettings={props.brandSettings}
                values={values}
                createDefaultPriceTypeDescription={props.createDefaultPriceTypeDescription}
                updateDefaultValue={updateDefaultValue}
                priceSheets={props.priceSheets}
                resources={props.resources}
                canManageDefaultValues={props.canManageDefaultValues}
              />
            ) : (
              values.map(value => (
                <SingleDefaultValue
                  key={value.id}
                  canManageDefaultValues={props.canManageDefaultValues}
                  value={value}
                  resources={props.resources}
                  updateDefaultValue={updateDefaultValue}
                />
              ))
            )}
          </Form>
        </div>
      </div>
    );
  };

  const segmentIds = [...new Set(props.defaultValues.map(value => value.segment_id))];

  return (
    <div className="content settings-default">
      {!props.brandSettings && <h3 className="mb-5">{t('settings:accountDefaultValueNote')}</h3>}
      {segmentIds.map(id => settingsSegment(id))}
    </div>
  );
};

export default DefaultValues;
