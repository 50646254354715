import React from 'react';
import { connect } from 'react-redux';

import digitalAssetActions from '../../../../actions/items/digital_assets';
import applicationActions from '../../../../actions/items/application';
import marketingCopyActions from '../../../../actions/parent/marketing_copy/index.ts';
import { hideModal } from '../../../../actions/app/modal';
import { addUploadedDigitalAsset } from '../../../../actions/parent/marketing_copy/update';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';
import constants from '../../../../constants/DigitalAssetsConstants.json';

import Button from '../../../global/Button';
import DigitalAssetTypePreview from '../digital_asset_preview/DigitalAssetTypePreview';
import ModalContent from '../../../global/Modals/ModalContent';

const { create_error: text } = digitalAssetConstants;

class DigitalAssetExistingModal extends React.Component {
  constructor(props) {
    super(props);
    const { error } = props;
    const asset = error.digital_asset;

    this.state = {
      newFileName: asset.file_name || '',
      newAssetName: asset.asset_name || '',
    };
  }

  checkSaveButtonDisabled = () => {
    const { error } = this.props;
    const { newAssetName, newFileName } = this.state;
    const asset = error.digital_asset;
    const assetNameError =
      error.type === 'asset_name' ||
      error.type === 'file_and_asset_name' ||
      error.type === 'multi_file_and_asset_name';
    const fileNameError =
      error.type === 'file_name' ||
      error.type === 'file_and_asset_name' ||
      error.type === 'multi_file_and_asset_name';

    const assetNameInvalid = !newAssetName || newAssetName === asset.asset_name;
    const fileNameInvalid = !newFileName || newFileName === asset.file_name;

    if (assetNameError && fileNameError) return assetNameInvalid || fileNameInvalid;
    if (assetNameError) return assetNameInvalid;
    if (fileNameError) return fileNameInvalid;
  };

  showMappedItems = () => {
    const { assetArea } = this.props;
    if (assetArea === 'item') {
      const items = this.props.error.mapped_items;
      return (
        <React.Fragment>
          <div className="import-error__mapped-items-text">{text.title_mapped_to}</div>
          <div className="pdm-table import-error__mapped-items-table">
            {items.map(item => (
              <div className="row import-error__mapped-items-row" key={item.id}>
                {item.part_number}
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    }
  };

  disableUseExisting = () => {
    const { assetArea } = this.props;
    if (assetArea === 'item') {
      const items = this.props.error.mapped_items;
      return !!items.find(item => item.id === this.props.selectedItemId);
    }
    if (assetArea === 'application') {
      const applications = this.props.error.mapped_applications;
      return !!applications.find(
        application => application.id === this.props.selectedApplicationId
      );
    }
  };

  useExisting = () => {
    const {
      digitalAssetUpload,
      error,
      assetArea,
      selectedItemId,
      selectedApplicationId,
      selectedMarketingCopyId,
    } = this.props;
    const { assetInformation } = digitalAssetUpload;
    const asset = error.digital_asset;
    if (assetArea === 'item') {
      this.props.dispatch(
        digitalAssetActions.linkDigitalAsset(
          selectedItemId,
          asset.id,
          assetInformation.assetTypeId,
          assetInformation.representationTypeId
        )
      );
    }
    if (assetArea === 'application') {
      this.props.dispatch(
        applicationActions.linkApplicationDigitalAsset(
          selectedApplicationId,
          asset.id,
          assetInformation.assetTypeId,
          assetInformation.representationTypeId,
          assetInformation.assetName
        )
      );
    }
    if (assetArea === 'marketing_copy') {
      this.props.dispatch(addUploadedDigitalAsset({ ...asset, type_id: 19 }));
      this.props.dispatch(hideModal());
    }
  };

  saveWithNewName = () => {
    const { assetInformation, assetArea, newFileUploadType } = this.props.digitalAssetUpload;
    const { selectedItemId, selectedBrandId } = this.props;
    const { assetTypeId, file, overwrite, representationTypeId, fileTypeId, sourceUrl } =
      assetInformation;

    const downloadUri = newFileUploadType === constants.popupType.url ? sourceUrl : null;
    const uri = newFileUploadType === constants.popupType.external ? sourceUrl : null;
    if (assetArea === 'item') {
      const itemId = selectedItemId;
      this.props.dispatch(
        digitalAssetActions.createDigitalAsset(
          assetTypeId,
          file,
          this.state.newFileName,
          itemId,
          overwrite,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          null,
          selectedBrandId
        )
      );
    }
    if (assetArea === 'application') {
      const applicationId = this.props.selectedApplicationId;
      this.props.dispatch(
        applicationActions.createApplicationDigitalAssets(
          assetTypeId,
          file,
          this.state.newFileName,
          applicationId,
          overwrite,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          this.state.newAssetName,
          selectedBrandId
        )
      );
    }
    if (assetArea === 'marketing_copy') {
      this.props.dispatch(
        marketingCopyActions.createMarketingCopyDigitalAsset(
          file,
          this.state.newFileName,
          overwrite,
          downloadUri,
          uri
        )
      );
    }
  };

  overwriteExisting = () => {
    const { assetInformation, assetArea, newFileUploadType } = this.props.digitalAssetUpload;
    const { selectedItemId, selectedBrandId } = this.props;
    const { assetTypeId, file, fileName, representationTypeId, fileTypeId, sourceUrl, assetName } =
      assetInformation;

    const downloadUri = newFileUploadType === constants.popupType.url ? sourceUrl : null;
    const uri = newFileUploadType === constants.popupType.external ? sourceUrl : null;
    if (assetArea === 'item') {
      const itemId = selectedItemId;
      this.props.dispatch(
        digitalAssetActions.createDigitalAsset(
          assetTypeId,
          file,
          fileName,
          itemId,
          1,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          null,
          selectedBrandId
        )
      );
    }
    if (assetArea === 'application') {
      const applicationId = this.props.selectedApplicationId;
      this.props.dispatch(
        applicationActions.createApplicationDigitalAssets(
          assetTypeId,
          file,
          fileName,
          applicationId,
          1,
          representationTypeId,
          fileTypeId,
          downloadUri,
          uri,
          assetName,
          selectedBrandId
        )
      );
    }
    if (assetArea === 'marketing_copy') {
      this.props.dispatch(
        marketingCopyActions.createMarketingCopyDigitalAsset(file, fileName, 1, downloadUri, uri)
      );
    }
  };

  render() {
    const { error } = this.props;
    const { newAssetName, newFileName } = this.state;
    const asset = error.digital_asset;
    // multi_file_and_asset_name => filename and asset name already exist for two different assets
    const assetNameError =
      error.type === 'asset_name' ||
      error.type === 'file_and_asset_name' ||
      error.type === 'multi_file_and_asset_name';
    const fileNameError =
      error.type === 'file_name' ||
      error.type === 'file_and_asset_name' ||
      error.type === 'multi_file_and_asset_name';

    const { assetInformation } = this.props.digitalAssetUpload;

    return (
      <ModalContent clickOutside>
        <div className="digital-assets__add-container">
          <div className="digital-assets__import-error">
            <div className="title digital-assets__import-error-title">{text.title}</div>
            <div className="digital-assets__import-error-message">
              {assetNameError && (
                <p>
                  {`${text.message_first} ${text.asset_name} '${asset.asset_name}' ${text.message_last}`}
                </p>
              )}
              {fileNameError && (
                <p>
                  {`${text.message_first} ${text.file_name} '${asset.file_name}' ${text.message_last}`}
                </p>
              )}
              <p>{text.message_use_following}</p>
            </div>
            <div className="import-error__inner-container">
              <div className="import-error__inner-container-top flex">
                <span className="import-error__existing-asset">
                  <div className="import-error__existing-asset-text">{text.title_existing}</div>
                  <div className="import-error__existing-asset-image">
                    <DigitalAssetTypePreview
                      digitalAssetResources={this.props.digitalAssetResources}
                      asset={asset}
                    />
                  </div>
                </span>
                <div className="import-error__mapped-items">{this.showMappedItems()}</div>
              </div>
              <div className="import-error__inner-container-center">
                <div className="import-error__new-asset">
                  <div className="import-error__new-asset-text">{text.title_new}</div>
                  <div className="import-error__existing-asset-image">
                    <DigitalAssetTypePreview
                      digitalAssetResources={this.props.digitalAssetResources}
                      asset={{
                        file_type_id: assetInformation.fileTypeId,
                        uri: assetInformation.sourceUrl || assetInformation.localFilePath,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="import-error__footer">
              <div className="import-error__inner-container-bottom">
                <div className="digital-asset-exists__options">
                  <Button
                    className="digital-asset-exists__option"
                    name={text.button_use_existing}
                    disabled={this.disableUseExisting()}
                    onClick={() => this.useExisting(asset)}
                    color="blue"
                    hover
                  />

                  <Button
                    className="digital-asset-exists__option"
                    name={text.button_overwrite}
                    disabled={error.type === 'multi_file_and_asset_name'}
                    onClick={this.overwriteExisting}
                    color="blue"
                    hover
                  />

                  <div className="new-file-name">
                    <div className="new-file-name-input__container">
                      <span className="mr-1">{text.title_name_input}</span>
                      {assetNameError && (
                        <span>
                          {`${text.asset_name}:`}
                          <input
                            value={newAssetName}
                            maxLength="45"
                            onChange={e => this.setState({ newAssetName: e.target.value })}
                          />
                        </span>
                      )}
                      {fileNameError && (
                        <span>
                          {`${text.file_name}:`}
                          <input
                            value={newFileName}
                            maxLength="80"
                            onChange={e => this.setState({ newFileName: e.target.value })}
                          />
                        </span>
                      )}
                    </div>

                    <Button
                      className="digital-asset-exists__option"
                      name={text.button_save}
                      color="blue"
                      hover
                      disabled={this.checkSaveButtonDisabled()}
                      onClick={() => this.saveWithNewName()}
                    />
                  </div>
                </div>
                <Button
                  className="digital-asset-exists__cancel"
                  name="Cancel"
                  color="red"
                  hover
                  onClick={this.props.hideModal}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    );
  }
}

function mapStateToProps(state) {
  return {
    digitalAssetUpload: state.parent.digitalAssetUpload,
    assetArea: state.parent.digitalAssetUpload.assetArea,
    selectedItemId: state.catalogue.catalogue.selectedItemIds[0],
    selectedBrandId: state.parent.brands.selectedBrandId,
    selectedApplicationId: state.items.application.selectedApplicationId,
    selectedMarketingCopyId: state.parent.marketingCopy.selectedMarketingCopyId,
    digitalAssetResources: state.resources.data.digital_asset,
  };
}

export default connect(mapStateToProps)(DigitalAssetExistingModal);
