import React from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import APIRoutes from '../../routes/distribution/APIRoutes';
import { hasPermission } from '../../utils/Permissions';
import { ApplicationState } from '../../reducers';

const { Content, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const APISettingsMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { user } = useSelector((state: ApplicationState) => ({
    user: state.user.user,
  }));

  const hasScriptAccess = hasPermission(user, 'has_script_access');
  const apiSettingsItems: MenuItem[] = [
    {
      key: '/distribution/api/api-settings',
      label: <Link to={{ pathname: `api-settings` }}>{t('api:settingLogs')}</Link>,
    },
    ...(hasScriptAccess
      ? [
          {
            key: '/distribution/api/automation',
            label: <Link to={{ pathname: `automation` }}>{t('api:automation')}</Link>,
          },
          {
            key: '/distribution/api/automation-logs',
            label: <Link to={{ pathname: `automation-logs` }}>{t('api:automationLogs')}</Link>,
          },
        ]
      : []),
  ];

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          items={apiSettingsItems}
        />
      </Sider>
      <Content className="main-page-content">
        <APIRoutes />
      </Content>
    </Layout>
  );
};

export default APISettingsMenu;
