import React from 'react';
import { Popover, Button, Form, Tooltip } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalOutlined } from '@ant-design/icons';
import { PriceSheetType } from '../../../../types/price_sheet';
import { ExtendedResources, StandardResource } from '../../../../types/resources';
import PriceSheetSelect from '../../global/PriceSheetSelect';
import AntTooltip from '../../global/AntTooltip';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import { AsyncDispatch } from '../../../../types/global';
import { fetchFilters } from '../../../actions/catalogue/filter/fetch';
import { ApplicationState } from '../../../reducers';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';
import HelpCenter from '../../../containers/HelpCenter';
import { AccountFilter, FilterType } from '../../../../types/filter';
import FormCheckbox from '../../global/Forms/FormCheckbox';
import { BrandCustomField } from '../../../../types/custom_fields';
import { fetchSettingsCustomFields } from '../../../actions/brand/custom_fields';
import FormFilterMultiSelect from '../../global/Forms/FormFilterMultiSelect';

type ReceiverRequestPopoverProps = {
  visible: boolean;
  buttonName: string;
  title: string;
  tooltip?: string;
  hide: () => void;
  open: () => void;
  selectedFilterIds?: number[];
  brandId?: number;
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  defaultSelectedPriceSheetIds?: number[];
  defaultWithoutInterchanges?: boolean;
  defaultSelectedInterchangeQltyIds?: null | number[];
  defaultSelectedPriceTypeIds?: number[];
  defaultWithoutPrices?: boolean;
  defaultWithoutCustomfields?: boolean;
  defaultSelectedCustomFields?: null | number[];
  customFields?: BrandCustomField[];
  fetchingCustomFields?: boolean;
  requestReceiver: (params: {
    priceSheetIds: number[];
    priceTypeIds: number[];
    withoutPrices: boolean;
    filterIds: number[];
    withoutInterchanges: boolean;
    interchangeQualityIds: number[];
    customFieldIds: number[];
    withoutCustomFields: boolean;
  }) => Promise<any>;
  type?: 'primary' | 'text';
  interchangeQuality: StandardResource[];
};

const ReceiverRequestPopover = ({
  visible,
  buttonName,
  title,
  tooltip,
  defaultSelectedPriceSheetIds,
  defaultSelectedPriceTypeIds,
  defaultWithoutPrices,
  hide,
  open,
  selectedFilterIds,
  brandId,
  priceSheets,
  priceTypes,
  requestReceiver,
  type,
  interchangeQuality,
  defaultWithoutInterchanges,
  defaultSelectedInterchangeQltyIds,
  defaultWithoutCustomfields,
  defaultSelectedCustomFields,
  customFields,
  fetchingCustomFields,
}: ReceiverRequestPopoverProps) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  React.useEffect(() => {
    if (visible && brandId) {
      dispatch(fetchFilters(brandId));
      dispatch(fetchSettingsCustomFields(brandId));
    }
  }, [brandId, dispatch, visible]);

  const { filters, accountFilters, fetchingFilters, accountDefaultValues } = useSelector(
    (state: ApplicationState) => {
      return {
        filters: getAllCustomFilters(state),
        accountFilters: state.catalogue.filter.accountFilters,
        fetchingFilters: state.catalogue.filter.fetchingFilters,
        accountDefaultValues: state.parent.accountDefaultValues.accountDefaultValues,
      };
    }
  );

  const defaultInterchange = accountDefaultValues.find(
    def => def.name === 'Default Receiver Interchange Permission Type'
  );

  const defaultInterchangeIds =
    (defaultInterchange?.value && defaultInterchange.value.split(',').map(id => Number(id))) || [];

  const getValidationSchema = () => {
    return Yup.object().shape({
      withoutPrices: Yup.boolean(),
      priceSheetIds: Yup.array().when(
        // only validate if not withoutPrices
        ['withoutPrices'],
        ([withoutPrices], schema) =>
          !withoutPrices ? schema.min(1, t('validation:required')) : schema.min(0)
      ),
      priceTypeIds: Yup.array(),
      withoutInterchanges: Yup.boolean(),
      interchangeQualityIds: Yup.array().when(
        ['withoutInterchanges'],
        ([withoutInterchanges], schema) =>
          !withoutInterchanges ? schema.min(1, t('validation:required')) : schema.min(0)
      ),
      withoutCustomFields: Yup.boolean(),
      customFieldIds: Yup.array().when(['withoutCustomFields'], ([withoutCustomFields], schema) =>
        !withoutCustomFields && brandId ? schema.min(1, t('validation:required')) : schema.min(0)
      ),
    });
  };

  const handleRequestChange = (values: {
    priceSheetIds: number[];
    priceTypeIds: number[];
    withoutPrices: boolean;
    filterIds: number[];
    withoutInterchanges: boolean;
    interchangeQualityIds: number[];
    customFieldIds: number[];
    withoutCustomFields: boolean;
  }) => {
    const {
      priceSheetIds,
      priceTypeIds,
      withoutPrices,
      filterIds,
      withoutInterchanges,
      interchangeQualityIds,
      customFieldIds,
      withoutCustomFields,
    } = values;
    const selectedValues = {
      withoutPrices,
      priceSheetIds: !withoutPrices ? priceSheetIds : [],
      priceTypeIds: !withoutPrices ? priceTypeIds : [],
      filterIds,
      withoutInterchanges,
      interchangeQualityIds: !withoutInterchanges ? interchangeQualityIds : [],
      customFieldIds: !withoutCustomFields ? customFieldIds : [],
      withoutCustomFields,
    };

    return requestReceiver(selectedValues);
  };

  const filterIds = filters.map((f: FilterType) => f.id);
  let filterList = filters.map((f: FilterType) =>
    f.public === 1
      ? {
          ...f,
          icon: (
            <Tooltip title={f.userEmail}>
              <GlobalOutlined className="mr-1" />
            </Tooltip>
          ),
        }
      : f
  );
  if (selectedFilterIds && selectedFilterIds.find(id => !filterIds.includes(id))) {
    const missingFilterIds = selectedFilterIds.filter(id => !filterIds.includes(id));
    const otherUsersFilters = missingFilterIds.map(id => {
      const accFilter = accountFilters.find(f => f.id === id);
      return {
        ...accFilter,
        name: `${accFilter?.name || ''} (${t('common:otherUser')}: ${
          accFilter?.user_first_name.substring(0, 1).toUpperCase() || ''
        }${accFilter?.user_last_name.substring(0, 1).toUpperCase() || ''})`,
      };
    });
    filterList = [...otherUsersFilters, ...filters];
  }

  return (
    <Popover
      trigger="click"
      overlayClassName="channel-table-header__popover"
      placement="left"
      open={visible}
      onOpenChange={visible => {
        if (!visible) hide();
      }}
      content={
        visible && (
          <Formik
            initialValues={{
              withoutPrices: defaultWithoutPrices || false,
              priceSheetIds: defaultSelectedPriceSheetIds || [],
              priceTypeIds: defaultSelectedPriceTypeIds || [],
              filterIds: selectedFilterIds || [],
              withoutInterchanges: defaultWithoutInterchanges || false,
              interchangeQualityIds:
                defaultSelectedInterchangeQltyIds || defaultInterchangeIds || [],
              customFieldIds: defaultSelectedCustomFields || [],
              withoutCustomFields: defaultWithoutCustomfields || false,
            }}
            validationSchema={getValidationSchema()}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleRequestChange(values)
                .then(() => setSubmitting(false))
                .catch(() => setSubmitting(false));
            }}
            validateOnBlur
            validateOnChange
          >
            {({
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              errors,
              touched,
              dirty,
              values,
            }) => (
              <Form layout="vertical">
                <div className="w-full">
                  {brandId && (
                    <div className="flex">
                      <FormFilterMultiSelect
                        className="flex-1"
                        label={t('receiver:productFilter')}
                        name="filterIds"
                        values={filterList}
                        loading={fetchingFilters}
                        getTagTooltip={id => {
                          const filter = filterList.find((f: AccountFilter) => f.id === id);
                          return filter?.user_first_name
                            ? `${filter.user_first_name} ${filter.user_last_name} ${filter.user_email}`
                            : '';
                        }}
                        showTagTooltip
                        allowClear
                      />
                      <HelpCenter
                        className="pl-2 pt-5 self-center"
                        content={<div>{t('receiver:productFilterInfo')}</div>}
                      />
                    </div>
                  )}
                  <PriceSheetSelect
                    defaultSelectedPriceSheetIds={defaultSelectedPriceSheetIds}
                    defaultSelectedPriceTypeIds={defaultSelectedPriceTypeIds}
                    defaultWithoutPrices={defaultWithoutPrices}
                    priceSheets={priceSheets}
                    priceTypes={priceTypes}
                    updateSelection={async ({
                      selectedPriceSheetIds,
                      selectedPriceTypeIds,
                      withoutPrices,
                    }: {
                      selectedPriceSheetIds: number[];
                      selectedPriceTypeIds: number[];
                      withoutPrices: boolean;
                    }) => {
                      if (selectedPriceSheetIds) {
                        await setFieldValue('priceSheetIds', selectedPriceSheetIds);
                        setFieldTouched('priceSheetIds');
                      }
                      if (selectedPriceTypeIds) setFieldValue('priceTypeIds', selectedPriceTypeIds);
                      setFieldValue('withoutPrices', withoutPrices);
                    }}
                    unboundSheetAndType
                    multiSelectSheets
                    multiSelectTypes
                    validateStatus={
                      (errors.priceSheetIds && touched.priceSheetIds && 'error') || undefined
                    }
                    help={(touched.priceSheetIds && errors.priceSheetIds) || undefined}
                    checkboxLabel={t('common:excludeAll')}
                    title={t('pricesheetSelect:prices')}
                    className="receiver-request-popover"
                  />
                  <div className="channel-table-header__popover__multi-select-title">
                    <div className="first-row">
                      <span>{t('interchange:interchangeRecords')}</span>
                      <div className="receiver__popover-title">
                        <span style={{ paddingRight: '3px' }}>{t('common:excludeAll')}</span>
                        <FormCheckbox
                          name="withoutInterchanges"
                          handleChange={e => {
                            setFieldValue('withoutInterchanges', e.target.checked);
                            setFieldValue(
                              'interchangeQualityIds',
                              e.target.checked ? [] : values.interchangeQualityIds
                            );
                          }}
                        />
                      </div>
                    </div>
                    {!values.withoutInterchanges && (
                      <div className="second-row">
                        <FormMultiSelect
                          className="multi_selection"
                          name="interchangeQualityIds"
                          placeholder={t('interchange:selectInterchangeRecords')}
                          values={interchangeQuality}
                          handleChange={async values => {
                            await setFieldValue('interchangeQualityIds', values);
                            setFieldTouched('interchangeQualityIds');
                          }}
                        />
                        <HelpCenter
                          id="include_interchange_records"
                          className="channel-table-header__popover__inner__help"
                        />
                      </div>
                    )}
                  </div>
                  {brandId && (
                    <div className="channel-table-header__popover__multi-select-title">
                      <div className="first-row">
                        <span>{t('settings:titles.customFields')}</span>
                        <div className="receiver__popover-title">
                          <span style={{ paddingRight: '3px' }}>{t('common:excludeAll')}</span>
                          <FormCheckbox
                            name="withoutCustomFields"
                            handleChange={e => {
                              setFieldValue('withoutCustomFields', e.target.checked);
                              setFieldValue(
                                'customFieldIds',
                                e.target.checked ? [] : values.customFieldIds
                              );
                            }}
                          />
                        </div>
                      </div>
                      {!values.withoutCustomFields && (
                        <div className="second-row">
                          <FormMultiSelect
                            className="multi_selection"
                            name="customFieldIds"
                            placeholder={t('settings:customFields.selectCustomField')}
                            values={customFields || []}
                            handleChange={async values => {
                              await setFieldValue('customFieldIds', values);
                              setFieldTouched('customFieldIds');
                            }}
                            loading={fetchingCustomFields}
                          />
                          <HelpCenter
                            id="include_custom_fields"
                            className="channel-table-header__popover__inner__help"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col items-end">
                    <div>
                      <Button onClick={() => hide()} size="small">
                        {t('common:cancel')}
                      </Button>
                      <Button
                        className="ml-2"
                        type="primary"
                        size="small"
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:save')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )
      }
      title={title}
    >
      <AntTooltip title={tooltip || ''} hide={!tooltip}>
        <Button type={type || 'primary'} ghost size="small" onClick={() => open()}>
          {buttonName}
        </Button>
      </AntTooltip>
    </Popover>
  );
};

export default ReceiverRequestPopover;
