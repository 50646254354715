import React from 'react';
import { FieldProps, Field } from 'formik';
import { Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get as _get } from 'lodash';
import AntTooltip from '../../global/AntTooltip';

type EBPFilterFormTagSelectProps = {
  name: string;
  label?: React.ReactNode;
  className?: string;
  info?: string;
  handleChange: (values: string[]) => void;
};

const EBPFilterFormTagSelect: React.FC<EBPFilterFormTagSelectProps> = props => {
  const { name, label, className, info, handleChange } = props;

  const tooltipLabel = () => (
    <React.Fragment>
      {label}
      <AntTooltip title={info} className="ml-1">
        <InfoCircleOutlined className="ant-info-tooltip" />
      </AntTooltip>
    </React.Fragment>
  );

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched } = form;
    // use lodash because name can include dots eg. foo.bar
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));

    return (
      <Form.Item
        label={info ? tooltipLabel() : label}
        className={className}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <Select mode="tags" value={field.value.values} onChange={handleChange} allowClear />
      </Form.Item>
    );
  };

  return <Field name={name}>{fieldContent}</Field>;
};

export default EBPFilterFormTagSelect;
