import React from 'react';
import { Popover, Card, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PlusCircleOutlined } from '@ant-design/icons';
import FormInput from '../../global/Forms/FormInput';

type VcdbPopoverProps = {
  disabled?: boolean;
  handleSave: (name: string) => Promise<any>;
};

const VcdbPopover: React.FC<VcdbPopoverProps> = props => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation:required')),
  });

  return (
    <Popover
      open={!props.disabled && popoverVisible}
      onOpenChange={visible => {
        setPopoverVisible(visible);
      }}
      overlayClassName="help application_listing__popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="qualifier__value-popover" bordered={false}>
          <Formik
            initialValues={{
              name: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleSave(values.name);
              setSubmitting(false);
              setPopoverVisible(false);
            }}
          >
            {({ handleSubmit, dirty, isSubmitting }) => {
              return (
                <div>
                  <Form layout="vertical">
                    <FormInput name="name" label={t('application:vcdbName')} required />
                  </Form>

                  <div className="flex-col items-end">
                    <div>
                      <Button
                        onClick={() => setPopoverVisible(false)}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:save')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      <Button className="ml-1" icon={<PlusCircleOutlined />} disabled={props.disabled} />
    </Popover>
  );
};

export default VcdbPopover;
