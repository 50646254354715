import React from 'react';
import { useTranslation } from 'react-i18next';
import { App, Button, Tabs } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ApplicationVehicleBase from './ApplicationVehicleBase';
import { ApplicationState } from '../../../reducers';
import { Application, RankedValue } from '../../../../types/application';
import ApplicationEquipment from './ApplicationEquipment';
import { hasPermission } from '../../../utils/Permissions';
import { StandardResource } from '../../../../types/resources';
import AntTooltip from '../../global/AntTooltip';
import {
  isCustomValidBaseSelected,
  isFullVehicleSelected,
  isValidBaseSelected,
} from '../../../utils/ApplicationUtils';
import ChangeConfigInfo from './ChangeConfigInfo';

type ApplicationVehicleEquipmentProps = {
  getRecommendations: boolean;
  filterView: boolean;
  vcdbView?: boolean;
  createVehicleStatus?: boolean;
  changeConfigActive?: boolean;
  ranks: { [key: string]: RankedValue[] };
  selectedYears: number[];
  configKeysVehicle: string[];
  selectedMakes?: number[];
  selectedModels?: number[];
  selectedSubmodels?: number[];
  selectedRegions?: number[];
  selectedTypes?: number[];
  selectedGroups?: number[];
  vehicleEquipmentType?: string;
  selectedCustomVcdb?: number;
  selectMake: (makeId: number) => void;
  selectModel: (modelId: number, addValues?: boolean) => void;
  selectType: (id: number) => void;
  selectGroup: (id: number) => void;
  selectSubmodel: (ids: any, addValues: boolean, save?: boolean) => void;
  selectRegion: (ids: any, addValues: boolean, save?: boolean) => void;
  selectYears: (year: number | number[], addValues: boolean, save?: boolean) => void;
  mapConfigToApplication: (subconfigName: string, rowId: number) => void;
  resetEqipment?: () => void;
  resetVehicle?: () => void;
  allSubconfigValues: (config: string) => StandardResource[];
  addCustomValue?: (configName: string, value: string, group?: string) => Promise<any>;
  deleteCustomValue?: (configName: string, id: number) => Promise<any>;
  deleteCustomConfigById?: (configName: string, id: number) => Promise<any>;
  createVehicle?: (status: boolean) => void;
  changeConfiguration?: (status: boolean) => void;
  confirmChangeConfiguration?: (configName: string, id: number) => void;
  setRecommendations: (status: boolean) => void;
  setVehicleEquipment?: (type: string) => void;
  hideEquipment?: boolean;
};

const ApplicationVehicleEquipment: React.FC<ApplicationVehicleEquipmentProps> = ({
  getRecommendations,
  filterView,
  vcdbView,
  createVehicleStatus,
  changeConfigActive,
  ranks,
  selectedYears,
  selectedMakes,
  selectedModels,
  selectedSubmodels,
  selectedRegions,
  selectedTypes,
  selectedGroups,
  configKeysVehicle,
  vehicleEquipmentType,
  selectedCustomVcdb,
  selectMake,
  selectModel,
  selectSubmodel,
  selectRegion,
  selectType,
  selectGroup,
  selectYears,
  mapConfigToApplication,
  resetEqipment,
  resetVehicle,
  allSubconfigValues,
  addCustomValue,
  deleteCustomValue,
  deleteCustomConfigById,
  createVehicle,
  changeConfiguration,
  confirmChangeConfiguration,
  setRecommendations,
  setVehicleEquipment,
  hideEquipment,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const {
    fetchingYears,
    years,
    equipmentYears,
    applications,
    selectedApplicationId,
    validVcdbVersions,
    user,
  } = useSelector((state: ApplicationState) => {
    return {
      fetchingYears: state.items.application.fetchingYears,
      years: state.items.application.years,
      equipmentYears: state.items.application.equipmentYears,
      applications: state.items.application.applications,
      selectedApplicationId: state.items.application.selectedApplicationId,
      validVcdbVersions: state.items.application.validVcdbVersions,
      user: state.user.user,
    };
  }, shallowEqual);

  const [activeKey, setActiveKey] = React.useState(
    vehicleEquipmentType ||
      (Object.keys(ranks).find(key => ['mfrs', 'equipment_models'].includes(key))
        ? 'equipment'
        : 'vehicle')
  );

  const [currentSelectedApplicationId, setCurrentSelectedApplicationId] =
    React.useState(selectedApplicationId);

  const [prevChangeConfig, setPrevChangeConfig] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);

  React.useEffect(() => {
    if (selectedApplicationId !== currentSelectedApplicationId) {
      const selectedApplication = applications.find(
        (app: Application) => app.item_application_id === selectedApplicationId
      );

      if (selectedApplication)
        Object.keys(selectedApplication).find(key =>
          ['mfr_ids', 'equipment_model_ids'].includes(key)
        )
          ? setActiveKey('equipment')
          : setActiveKey('vehicle');

      setCurrentSelectedApplicationId(selectedApplicationId);
    }
  }, [applications, currentSelectedApplicationId, selectedApplicationId]);

  React.useEffect(() => {
    if (vehicleEquipmentType && vehicleEquipmentType !== activeKey) {
      setActiveKey(vehicleEquipmentType);
    }
  }, [activeKey, vehicleEquipmentType]);

  const canManageEquipment = hasPermission(user, 'can_manage_equipment');

  const deleteConfig = () => {
    if (deleteCustomConfigById)
      if (activeKey === 'equipment')
        deleteCustomConfigById('equipments', ranks.mfrs[0].vehicle_config_id!);
      else deleteCustomConfigById('vehicles', ranks.makes[0].vehicle_config_id!);
  };

  const handleChangeConfiguration = () => {
    if (changeConfiguration && !changeConfigActive) {
      changeConfiguration(true);
      setPrevChangeConfig(ranks);
    } else if (confirmChangeConfiguration) {
      if (activeKey === 'equipment')
        confirmChangeConfiguration('equipments', prevChangeConfig!.mfrs[0].vehicle_config_id!);
      else confirmChangeConfiguration('vehicles', prevChangeConfig!.makes[0].vehicle_config_id!);
    }
  };

  const getSelectedMakes = (rankedMakes: RankedValue[]) => {
    if (selectedMakes) return selectedMakes;
    return rankedMakes ? ranks.makes.filter(make => make.mapped).map(make => make.id) : [];
  };

  const getSelectedModels = (rankedModels: RankedValue[]) => {
    if (selectedModels) return selectedModels;
    return rankedModels ? ranks.models.filter(model => model.mapped).map(model => model.id) : [];
  };

  const getSelectedSubModels = (rankedSubModels: RankedValue[]) => {
    if (selectedSubmodels) return selectedSubmodels;
    return rankedSubModels
      ? ranks.sub_models.filter(subModel => subModel.mapped).map(subModel => subModel.id)
      : [];
  };

  const getSelectedRegions = (rankedRegions: RankedValue[]) => {
    if (selectedRegions) return selectedRegions;
    return rankedRegions
      ? ranks.regions.filter(region => region.mapped).map(region => region.id)
      : [];
  };

  const getSelectedTypes = (rankedTypes: RankedValue[]) => {
    if (selectedTypes) return selectedTypes;
    return rankedTypes ? ranks.vehicle_types.filter(type => type.mapped).map(type => type.id) : [];
  };

  const getSelectedGroups = (rankedGroups: RankedValue[]) => {
    if (selectedGroups) return selectedGroups;
    return rankedGroups ? ranks.vehicle_type_groups.filter(g => g.mapped).map(g => g.id) : [];
  };

  const handleActiveKeyChange = (key: string) => {
    const makeIds = getSelectedMakes(ranks.makes);
    const modelIds = getSelectedModels(ranks.models);
    const subModelIds = getSelectedModels(ranks.sub_models);
    const regionIds = getSelectedRegions(ranks.regions);

    const mfrIds = ranks.mfrs ? ranks.mfrs.filter(mfr => mfr.mapped).map(mfr => mfr.id) : [];
    const equipmentModelIds = ranks.equipment_models
      ? ranks.equipment_models.filter(model => model.mapped).map(model => model.id)
      : [];
    const vehicleTypeIds = ranks.vehicle_types
      ? ranks.vehicle_types.filter(type => type.mapped).map(type => type.id)
      : [];

    if (key === 'vehicle') {
      const equipmentApplication =
        mfrIds.length ||
        equipmentModelIds.length ||
        vehicleTypeIds.length ||
        regionIds.length ||
        selectedYears.length;

      if (equipmentApplication) {
        modal.confirm({
          title: t('application:vehicleEquipment.changeTitleVehicle'),
          icon: <ExclamationCircleOutlined />,
          content: t('application:vehicleEquipment.changeVehicle'),
          onOk() {
            if (resetEqipment) resetEqipment();
            setRecommendations(false);
            setActiveKey(key);
            if (setVehicleEquipment) setVehicleEquipment(key);
          },
        });
      } else {
        if (resetEqipment) resetEqipment();
        setActiveKey(key);
        if (setVehicleEquipment) setVehicleEquipment(key);
      }
    }
    if (key === 'equipment') {
      const vehicleApplication =
        makeIds.length ||
        modelIds.length ||
        subModelIds.length ||
        regionIds.length ||
        selectedYears.length;

      if (vehicleApplication) {
        modal.confirm({
          title: t('application:vehicleEquipment.changeTitleEquipment'),
          icon: <ExclamationCircleOutlined />,
          content: t('application:vehicleEquipment.changeEquipment'),
          onOk() {
            if (resetVehicle) resetVehicle();
            setRecommendations(false);
            setActiveKey(key);
            if (setVehicleEquipment) setVehicleEquipment(key);
          },
        });
      } else {
        setActiveKey(key);
        if (setVehicleEquipment) setVehicleEquipment(key);
      }
    }
  };

  selectedMakes = getSelectedMakes(ranks.makes);
  selectedModels = getSelectedModels(ranks.models);
  selectedSubmodels = getSelectedSubModels(ranks.sub_models);
  selectedRegions = getSelectedRegions(ranks.regions);
  selectedTypes = getSelectedTypes(ranks.vehicle_types);
  selectedGroups = getSelectedGroups(ranks.vehicle_type_groups);

  const validBaseSelected = isCustomValidBaseSelected(ranks);

  const tabItems = [
    {
      label: t(`application:vehicle`),
      key: 'vehicle',
      children: (
        <ApplicationVehicleBase
          ranks={ranks}
          getRecommendations={getRecommendations}
          configKeysVehicle={configKeysVehicle}
          selectedMakes={selectedMakes}
          selectMake={selectMake}
          selectedModels={selectedModels}
          selectModel={selectModel}
          years={vcdbView ? undefined : years}
          selectedYears={selectedYears}
          selectYears={selectYears}
          selectedSubmodels={selectedSubmodels}
          filterView={filterView}
          selectSubmodel={selectSubmodel}
          selectedRegions={selectedRegions}
          selectRegion={selectRegion}
          selectedTypes={selectedTypes}
          selectType={selectType}
          selectedGroups={selectedGroups}
          selectGroup={selectGroup}
          fetchingYears={fetchingYears}
          addCustomValue={addCustomValue}
          deleteCustomValue={deleteCustomValue}
          allSubconfigValues={allSubconfigValues}
        />
      ),
    },
    ...((!hideEquipment && [
      {
        label: t(`application:equipment`),
        key: 'equipment',
        disabled: !canManageEquipment,
        children: (
          <ApplicationEquipment
            ranks={ranks}
            vcdbView={vcdbView}
            filterView={filterView}
            getRecommendations={getRecommendations}
            years={vcdbView ? undefined : equipmentYears}
            fetchingYears={fetchingYears}
            allSubconfigValues={allSubconfigValues}
            mapConfigToApplication={mapConfigToApplication}
            addCustomValue={addCustomValue}
            deleteCustomValue={deleteCustomValue}
          />
        ),
      },
    ]) ||
      []),
  ];

  return (
    <div className="application__subconfig">
      <div className="application__subconfig-card-container">
        <Tabs
          type="card"
          activeKey={activeKey}
          onChange={activeKey => handleActiveKeyChange(activeKey)}
          tabBarExtraContent={
            vcdbView && (
              <React.Fragment>
                {changeConfigActive ? (
                  <ChangeConfigInfo ranks={prevChangeConfig || {}} activeKey={activeKey} />
                ) : (
                  <div className="flex-1" />
                )}

                {changeConfigActive ? (
                  <React.Fragment>
                    <Button
                      className="mr-1"
                      onClick={() => handleChangeConfiguration()}
                      disabled={isEqual(prevChangeConfig, ranks)}
                      size="small"
                    >
                      {t('application:confirmChange')}
                    </Button>
                    <Button
                      className="mr-1"
                      onClick={() => changeConfiguration && changeConfiguration(false)}
                      size="small"
                    >
                      {t('common:cancel')}
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    className="mr-1"
                    disabled={!(validBaseSelected && selectedCustomVcdb === validVcdbVersions[0])}
                    onClick={() => handleChangeConfiguration()}
                    size="small"
                  >
                    {t('application:changeConfiguration')}
                  </Button>
                )}

                {!changeConfigActive && (
                  <React.Fragment>
                    {validBaseSelected && selectedCustomVcdb === validVcdbVersions[0] ? (
                      // show delete button
                      <AntTooltip
                        title={t('application:deleteConfigIdTip')}
                        overlayClassName="whitespace-pre-line"
                      >
                        <Button onClick={() => deleteConfig()} size="small" danger>
                          {activeKey === 'vehicle'
                            ? t('application:vcdbDeleteVehicleId')
                            : t('application:vcdbDeleteEquipmentId')}
                        </Button>
                      </AntTooltip>
                    ) : (
                      // show create button
                      <AntTooltip
                        title={
                          activeKey === 'vehicle'
                            ? t('application:vcdbCreateVehicleIdTip')
                            : t('application:vcdbCreateEquipmentIdTip')
                        }
                        hide={isFullVehicleSelected(ranks)}
                        overlayClassName="whitespace-pre-line"
                      >
                        <Button
                          disabled={
                            !isFullVehicleSelected(ranks) ||
                            isValidBaseSelected(ranks) ||
                            changeConfigActive ||
                            createVehicleStatus
                          }
                          onClick={() => createVehicle && createVehicle(true)}
                          size="small"
                        >
                          {activeKey === 'vehicle'
                            ? t('application:vcdbCreateVehicleId')
                            : t('application:vcdbCreateEquipmentId')}
                        </Button>
                      </AntTooltip>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          }
          destroyInactiveTabPane
          items={tabItems}
        />
      </div>
    </div>
  );
};

export default ApplicationVehicleEquipment;
