import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemInfo from '../../components/global/ItemInfo';
import { intercomEvent } from '../../utils/IntercomUtils';
import * as navigationActions from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import { AsyncDispatch } from '../../../types/global';
import { fetchAttributes } from '../../actions/items/attributes/fetch';
import AttributesPage from '../../components/body/attributes/AttributesPage';
import { fetchAccountLanguages } from '../../actions/parent/language/fetch';
import { getPageLimit } from '../../utils/Utils';
import { SelectedListItem } from '../../../types/item';
import { fetchSelectedItemsLists } from '../../actions/catalogue/catalogue/fetch';

const AttributesContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const {
    accountLanguages,
    fetchingAccountLanguages,
    selectedItems,
    brandId,
    reverseSelected,
    reversedItemIds,
    allItemsSelected,
    filterId,
    selectedItemIds,
    filterBrandIds,
    fetchingSelectedItemsList,
  } = useSelector((state: ApplicationState) => {
    return {
      accountLanguages: state.parent.languageSettings.accountLanguages,
      fetchingAccountLanguages: state.parent.languageSettings.isFetching,
      selectedItems: getSelectedItems(state),
      brandId: state.parent.brands.selectedBrandId,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
      filterId: state.catalogue.filter.filterGo?.id,
      allItemsSelected: state.catalogue.catalogue.allItemsSelected,
      selectedItemIds: state.catalogue.catalogue.selectedItemIds,
      filterBrandIds: state.catalogue.catalogue.catalogueQueryParams.filterBrandIds,
      fetchingSelectedItemsList: state.catalogue.catalogue.fetchingSelectedItemsList,
    };
  });

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.attributes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!fetchingAccountLanguages && accountLanguages.length === 0)
      dispatch(fetchAccountLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'attributes',
      part_number: selectedItems.map(i => i.part_number).join(', '),
      brand_code: selectedItems[0]?.brand_code,
    });
  }, [selectedItems]);

  const defaultAttrFetchParams = React.useMemo(
    () => ({
      limit: getPageLimit(),
      page: 1,
      value_limit: 20,
      parent_owner_brand_id: brandId,
    }),
    [brandId]
  );

  React.useEffect(() => {
    if (reverseSelected) {
      dispatch(fetchSelectedItemsLists({ filterId })).then(response => {
        const allItemIds: number[] = response.action.payload.data.map(
          (item: SelectedListItem) => item.id
        );
        const itemIds = allItemIds.filter(id => !reversedItemIds.includes(id));
        dispatch(fetchSelectedItemsLists({ itemIds })).then(() =>
          dispatch(fetchAttributes({ ...defaultAttrFetchParams, item_ids: itemIds }))
        );
      });
    }
  }, [brandId, dispatch, filterId, reverseSelected, reversedItemIds, defaultAttrFetchParams]);

  React.useEffect(() => {
    if (selectedItemIds.length > 0) {
      if (allItemsSelected && selectedItemIds.length > 1) {
        // filterBrandIds is required for receiver users with access to multiple brands
        dispatch(fetchSelectedItemsLists({ filterId, filterBrandIds }));
        dispatch(fetchAttributes({ ...defaultAttrFetchParams, filter_id: filterId }));
      }
      if (
        (!allItemsSelected || (allItemsSelected && selectedItemIds.length === 1)) &&
        !reverseSelected
      ) {
        if (selectedItemIds.length > 1)
          dispatch(fetchSelectedItemsLists({ itemIds: selectedItemIds }));
        dispatch(fetchAttributes({ ...defaultAttrFetchParams, item_ids: selectedItemIds }));
      }
    }
  }, [
    allItemsSelected,
    brandId,
    defaultAttrFetchParams,
    dispatch,
    filterBrandIds,
    filterId,
    reverseSelected,
    selectedItemIds,
  ]);

  if (selectedItems.length === 0) return <ItemInfo items={selectedItemIds} />;

  if (fetchingSelectedItemsList)
    return (
      <div className="mt-10">
        <Spin className="spinner-center" />
      </div>
    );

  return <AttributesPage />;
};

export default AttributesContainer;
