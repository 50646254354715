import React from 'react';
import { Button, Drawer, Spin, Tooltip } from 'antd';
import classNames from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import translation from '../../../constants/SaveTranslation.json';

export type DrawerSaveProps = {
  visible: boolean;
  onClose: (force?: boolean) => void;
  onSave: () => void;
  onDelete?: () => void;
  onNew?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  className?: string;
  title?: React.ReactNode;
  width?: string | number;
  style?: React.CSSProperties;
  saveButtonEnabled?: boolean;
  deleteButtonEnabled?: boolean;
  newButtonEnabled?: boolean;
  additionalFooterData?: React.ReactNode;
  isSubmitting: boolean;
  isLoading?: boolean;
  destroyOnClose?: boolean;
  push?: boolean | { distance: number | string };
};

const DrawerSave: React.FC<DrawerSaveProps> = ({
  visible,
  onClose,
  onDelete,
  onSave,
  onNew,
  onNext,
  onPrev,
  className,
  title,
  width,
  style,
  saveButtonEnabled,
  deleteButtonEnabled,
  newButtonEnabled,
  additionalFooterData,
  children,
  isSubmitting,
  isLoading,
  destroyOnClose,
  push,
}) => {
  return (
    <Drawer
      className={`drawer-submit ${className}`}
      open={visible}
      onClose={() => onClose()}
      title={title}
      width={width || 800}
      destroyOnClose={destroyOnClose}
      style={{ height: '100%' }}
      push={push}
    >
      <div
        className={classNames('drawer-submit__content', {
          'margin-top': !title,
        })}
        style={style}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          children
        )}
      </div>
      <div className="drawer-submit__bottom">
        <div className="flex-1">
          {onDelete && (
            <Button
              onClick={() => onDelete()}
              disabled={!deleteButtonEnabled}
              danger
              ghost
              data-testid="drawer-delete"
            >
              {translation.delete}
            </Button>
          )}
          {onPrev && (
            <Tooltip title={translation.previous} mouseEnterDelay={0.5}>
              <Button onClick={onPrev} icon={<LeftOutlined />} />
            </Tooltip>
          )}
          {onNext && (
            <Tooltip title={translation.next} mouseEnterDelay={0.5}>
              <Button className="ml-1" onClick={onNext} icon={<RightOutlined />} />
            </Tooltip>
          )}
          {!!additionalFooterData && additionalFooterData}
        </div>
        <div>
          <Button onClick={() => onClose(true)} className="drawer-submit__bottom-cancel">
            {translation.cancel}
          </Button>
          {onNew && (
            <Button className="mr-2" onClick={() => onNew()} disabled={!newButtonEnabled}>
              {translation.new}
            </Button>
          )}
          <Button
            data-testid="drawer-submit"
            type="primary"
            onClick={() => onSave()}
            disabled={!saveButtonEnabled}
            loading={isSubmitting}
          >
            {translation.save}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerSave;
