import { WarningOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Attribute, AttributeRankedValue } from '../../../../types/attributes';
import AttributeValuePopover from './AttributeValuePopover';
import { attributesMappedWithUniqueId } from '../../../selectors/attributes/attributeSelector';
import { ApplicationState } from '../../../reducers';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType } from '../../../../types/resources';

const { CheckableTag } = Tag;

type AttributeValuesProps = {
  itemId: number;
  rowData: Attribute;
  attrErrors: (Analysis & AnalysisType)[];
  handleValueChange: (props: {
    valueId: number | null;
    uniqueId?: string;
    prevValueId?: number;
    uomId: number | null;
    uomlabel: string;
    valueName: string;
    itemId: number;
    rankedValue: AttributeRankedValue;
  }) => void;
};

const AttributeValues: React.FC<AttributeValuesProps> = ({
  itemId,
  rowData,
  attrErrors,
  handleValueChange,
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const { mappedAttributes } = useSelector((state: ApplicationState) => {
    return {
      mappedAttributes: attributesMappedWithUniqueId(state),
    };
  });

  const handleValueSelection = (selectedValueId: number | string, itemId: number) => {
    const updatedRankedValues = rowData.ranked_values.map(r => {
      const id = r.id ? r.id : r.uniqueId!;
      if (selectedValueId === id) {
        if (r.item_ids.includes(itemId))
          return { ...r, item_ids: r.item_ids.filter(id => id !== itemId) };
        return { ...r, item_ids: [...r.item_ids, itemId] };
      }
      return { ...r };
    });
    const temp =
      JSON.stringify(mappedAttributes.find(atrr => atrr.id === rowData.id)?.ranked_values) !==
      JSON.stringify(updatedRankedValues);
    const updatedAttribute = {
      ...rowData,
      ranked_values: updatedRankedValues,
      temp,
    };
    const updatedAttributes = values.attributes.map((a: Attribute) => {
      if (a.uniqueId && rowData.uniqueId && a.uniqueId === rowData.uniqueId)
        return updatedAttribute;
      if (a.id && rowData.id && a.id === rowData.id) return updatedAttribute;
      return a;
    });
    setFieldValue('attributes', updatedAttributes);
  };

  const renderWarnIcon = (error: Analysis & AnalysisType) => (
    <Tooltip title={error.dynamic_description || error.name} placement="top">
      <WarningOutlined
        className={classNames('attributes__analysis-message', {
          blue: error.alert_type_id === 1,
          yellow: error.alert_type_id === 2,
          red: error.alert_type_id === 3,
        })}
      />
    </Tooltip>
  );

  const renderCheckableTag = (rankedValue: AttributeRankedValue) => {
    const error = attrErrors.find(
      e =>
        e.item_part_attribute?.part_attribute_value_id === rankedValue.id &&
        e.item_part_attribute?.part_attribute_id === rowData.id
    );
    const itemIds = rankedValue.item_ids;
    const metaUOM = rankedValue.part_attribute_meta_uoms.find(uom => uom.item_ids.includes(itemId));

    return (
      <div
        onClick={e => e.stopPropagation()}
        key={`${itemId}_${rankedValue.id || rankedValue.uniqueId}`}
      >
        <CheckableTag
          className={classNames('attributes__value-tag', {
            'attributes__checkable-tag_green':
              rankedValue.rank === 1 && itemIds.includes(itemId!) && rowData.uniqueId,
            'attributes__checkable-tag_blue':
              (rankedValue.rank === 2 || rankedValue.rank === 3 || !rankedValue.rank) &&
              itemIds.includes(itemId!) &&
              rowData.uniqueId,
          })}
          checked={itemIds.includes(itemId)}
          onChange={() => handleValueSelection(rankedValue.id || rankedValue.uniqueId, itemId)}
        >
          <span className="mr-1">
            {`${rankedValue.name}${metaUOM?.label ? ` ${metaUOM.label}` : ''}`}
          </span>
          {itemIds.includes(itemId) &&
            error?.item_part_attribute?.part_attribute_value_id &&
            renderWarnIcon(error)}
          <AttributeValuePopover
            valueName={rankedValue.name}
            attrMetaUomLabel={metaUOM?.label}
            attributeIds={[rowData.id!]}
            type="editValue"
            valueId={rankedValue.id!}
            uniqueId={rankedValue.uniqueId}
            metaUomId={metaUOM?.id}
            handleChange={props => handleValueChange({ ...props, itemId, rankedValue })}
            translations={rankedValue?.translations || []}
            unitTranslations={metaUOM?.translations || []}
          />
        </CheckableTag>
      </div>
    );
  };

  return (
    <div className="flex content-start flex-wrap">
      {rowData?.ranked_values.length > 0 ? (
        rowData.ranked_values.map(rankedValue => renderCheckableTag(rankedValue))
      ) : (
        <div style={{ height: '29px' }} />
      )}
    </div>
  );
};

export default AttributeValues;
