import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChannelColumn, ChannelData, ChannelTemplate } from '../../../../types/channel';
import ShowChannelLogsButton from './ShowChannelLogsButton';
import EditChannelTable from './EditChannelTable';
import EditChannelProductDataDrawer from './EditChannelProductDataDrawer';
import { ApplicationState } from '../../../reducers';
import { updateChannelAdvisor } from '../../../actions/channel/update';
import { AsyncDispatch } from '../../../../types/global';
import { dayjsTimeStamp } from '../../../utils/Utils';
import { fetchChannelAdvisor, fetchChannelAdvisorTemplate } from '../../../actions/channel/fetch';
import { fetchExportBuilderAdvancedSegments } from '../../../actions/brand/export_builder_advanced/fetch';
import { segmentsMappedWithOptions } from '../../../selectors/export_builder_advanced/exportBuilderAdvancedSelector';
import { mapTempStatusToChannelAdvData } from '../../../selectors/channel/channelSelector';

type EditChannelAdvisorProps = {
  channelType: string;
};

const EditChannelAdvisor: React.FC<EditChannelAdvisorProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const [brandName, setBrandName] = React.useState<string | undefined>();
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [searchKey, setSearchkey] = React.useState('');
  const navigate = useNavigate();

  const {
    channelAdvData,
    fetchingChannelAdvisor,
    lastConnection,
    template,
    templateColumns,
    preview,
    segments,
    fetchingApplicationResources,
    fetchingSegments,
    fetchingTemplate,
    updatingChannelAdvisor,
  } = useSelector((state: ApplicationState) => ({
    channelAdvData: mapTempStatusToChannelAdvData(state) || [],
    lastConnection: state.channel.channels.channelAdvisorData?.last_connection || null,
    fetchingChannelAdvisor: state.channel.channels.fetchingChannelAdvisor,
    template: (state.channel.channels.channelAdvisorTemplate.template || {}) as ChannelTemplate,
    templateColumns: (state.channel.channels.channelAdvisorTemplate.columns ||
      []) as ChannelColumn[],
    preview: (state.channel.channels.channelAdvisorTemplate.preview || []) as any[],
    segments: segmentsMappedWithOptions(state) || [],
    fetchingApplicationResources: state.resources.fetchingApplicationResources,
    fetchingSegments: state.brand.exportBuilderAdvanced.fetchingSegments,
    fetchingTemplate: state.channel.channels.fetchingChannelAdvTemplate,
    updatingChannelAdvisor: state.channel.channels.updatingChannelAdvisor,
  }));

  const handleShowProductDataDrawer = (brandName: string, brandId: number) => {
    setBrandName(brandName);
    setBrandId(brandId);
    dispatch(fetchChannelAdvisorTemplate(brandId));
    dispatch(fetchExportBuilderAdvancedSegments(brandId));
  };

  const handleBrandSearch = (keywords: string | '') => {
    setSearchkey(keywords);
    dispatch(fetchChannelAdvisor(keywords));
  };

  const handleShowLogs = () => {
    navigate('/distribution/history', {
      state: { searchKeyword: 'ChannelAdvisor' },
    });
  };

  const updateChannelAdvisorValues = (updatedRecord: ChannelData) => {
    const updatedValues = channelAdvData.map((r: ChannelData) =>
      r.brand_id === updatedRecord.brand_id ? updatedRecord : r
    );

    dispatch(updateChannelAdvisor(updatedValues)); // direct save
  };

  return (
    <React.Fragment>
      <div className="h-full flex flex-col">
        <div className="mx-10 my-10">
          <ShowChannelLogsButton
            handleShowLogs={handleShowLogs}
            timeStamp={lastConnection ? dayjsTimeStamp(lastConnection) : undefined}
            confirmText={t('channel:switchChanneladvisorToHistory')}
          />
        </div>
        <div className="overflow-hidden flex-1 overflow-auto mx-10">
          <EditChannelTable
            data={channelAdvData}
            handleShowProductDataDrawer={handleShowProductDataDrawer}
            updateChannel={updateChannelAdvisorValues}
            handleBrandSearch={handleBrandSearch}
            searchKey={searchKey}
            fetchingChannel={fetchingChannelAdvisor}
            channelType={props.channelType}
            updatePending={updatingChannelAdvisor}
          />
        </div>
      </div>
      <EditChannelProductDataDrawer
        onClose={() => (brandName ? setBrandName(undefined) : '')}
        title={`${brandName || ''} - ${t('channel:productData')}`}
        visible={!!brandName}
        brandId={brandId}
        template={template}
        templateColumns={templateColumns}
        segments={segments}
        fetchingApplicationResources={fetchingApplicationResources}
        fetchingSegments={fetchingSegments}
        fetchingTemplate={fetchingTemplate}
        preview={preview}
        channelType={props.channelType}
      />
    </React.Fragment>
  );
};

export default EditChannelAdvisor;
