import React from 'react';
import DigitalAssetSingleDescription from './DigitalAssetSingleDescription';
import AddNewButton from '../../global/AddNewButton';
import digitalAssetConstants from '../../../constants/DigitalAssetsTranslation.json';

const { description: translation } = digitalAssetConstants;

export default class Description extends React.Component {
  state = {
    showTempDesc: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedDigitalAsset &&
      this.props.selectedDigitalAsset &&
      prevProps.selectedDigitalAsset.id !== this.props.selectedDigitalAsset.id
    ) {
      this.setState({ showTempDesc: false });
    }
  }

  handleAddDescription = () => {
    this.setState({ showTempDesc: true });
  };

  createDescription = (typeId, languageId, contents) => {
    this.setState({ showTempDesc: false });
    this.props.createDescription(typeId, languageId, contents);
  };

  deleteTempDescription = () => {
    this.setState({ showTempDesc: false });
  };

  render() {
    const { digitalAssetDescriptions } = this.props;
    return (
      <div className="digital-asset-information__description">
        <div className="digital-asset-information__description-title">
          {translation.description}
        </div>
        <AddNewButton
          size="small"
          name={translation.add_description}
          toggle={this.handleAddDescription}
        />
        {this.state.showTempDesc && (
          <DigitalAssetSingleDescription
            isTemp
            languages={this.props.languages}
            descriptionTypes={this.props.descriptionTypes}
            createDescription={this.createDescription}
            deleteDescription={this.deleteTempDescription}
            defaultLanguageId={this.props.defaultLanguageId}
          />
        )}
        {digitalAssetDescriptions.map(description => (
          <DigitalAssetSingleDescription
            key={description.id}
            description={description}
            languages={this.props.languages}
            descriptionTypes={this.props.descriptionTypes}
            updateDescriptionContents={this.props.updateDescriptionContents}
            deleteDescription={this.props.deleteDescription}
          />
        ))}
      </div>
    );
  }
}
