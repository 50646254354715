import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App, Button, Col, Drawer, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import { ApplicationState } from '../../../reducers';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import {
  fetchDefaultNotes,
  fetchDefaultQualifiers,
  fetchPartTypeCategories,
  fetchRankedPositions,
} from '../../../actions/items/application/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { Application, ApplicationCategory } from '../../../../types/application';
import ApplicationPosition from './ApplicationPosition';
import QualifierContainer from '../../../containers/application/QualifierContainer';
import CategoryDropdown from '../../global/CategoryDropdown';
import { nextPage } from '../../../utils/Utils';
import InfoTooltip from '../../global/InfoTooltip';

type ApplicationOptionsDrawerProps = {
  application: Application;
  visible: boolean;
  handleCategoryUpdate: (category?: ApplicationCategory) => void;
  updateItemApplicationAttributes: (param: { [x: string]: any }) => void;
  onClose: () => void;
};

const ApplicationOptionsDrawer: React.FC<ApplicationOptionsDrawerProps> = ({
  application,
  visible,
  handleCategoryUpdate,
  updateItemApplicationAttributes,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const { modal } = App.useApp();

  const {
    item,
    brandId,
    partTypeCategories,
    fetchingCategories,
    rankedPositions,
    positionResources,
  } = useSelector((state: ApplicationState) => ({
    item: getSelectedItems(state)[0],
    brandId: state.parent.brands.selectedBrandId,
    partTypeCategories: state.items.application.partTypeCategories,
    fetchingCategories: state.items.application.fetchingCategories,
    rankedPositions: state.items.application.rankedPositions,
    positionResources: state.resources.data.vehicle.positions,
  }));

  const [formChanged, setFormChanged] = useState(false);

  React.useEffect(() => {
    if (item.id) {
      dispatch(fetchDefaultQualifiers(item.id));
      dispatch(fetchDefaultNotes(item.id));
    }
  }, [dispatch, item.id]);

  React.useEffect(() => {
    // item.category is included in extended item data, so we have to check for it
    if (item.category?.id) dispatch(fetchRankedPositions(item.category.id));
  }, [dispatch, item.id, item.category]);

  React.useEffect(() => {
    if (visible) setFormChanged(false);
  }, [visible]);

  const handleClose = () => {
    if (formChanged) {
      modal.confirm({
        title: t('common:closeWarningTitle'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onClose();
        },
      });
    } else onClose();
  };

  const fetchCategories = (keyword?: string) => {
    dispatch(fetchPartTypeCategories(brandId, keyword));
  };

  const fetchNextPartTypeCategories = (event: any, keywords?: string) => {
    const page = nextPage(event);
    if (page && fetchingCategories) {
      dispatch(fetchPartTypeCategories(brandId, keywords, page));
    }
  };

  const updateCategory = (category?: ApplicationCategory) => {
    handleCategoryUpdate(
      category
        ? {
            id: category.id,
            level1_name: category.level1_name,
            level2_name: category.level2_name,
            level3_name: category.level3_name,
          }
        : undefined
    );
  };

  const form = () => {
    const defaultCategory = item.category.id ? item.category : undefined;

    return (
      <React.Fragment>
        <Form layout="vertical" className="application__defaults-form">
          <Row>
            <Col span={24}>
              <Form.Item label={t('application:partType')}>
                <CategoryDropdown
                  className="application__part-type-dropdown"
                  defaultKeyword={
                    application.category?.level3_name || defaultCategory?.level3_name || ''
                  }
                  displayedCategory={application.category}
                  fetchReviewCategories={fetchCategories}
                  fetchNextReviewCategories={fetchNextPartTypeCategories}
                  fetchingCategories={fetchingCategories}
                  categories={partTypeCategories}
                  updateCategory={updateCategory}
                  allowClear={!!application.category}
                  hasDefault={!application.category && !!defaultCategory}
                  defaultCategory={defaultCategory}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20} align="top">
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    {t('application:mfrLabel')}
                    <InfoTooltip className="ml-1" title={t('application:mfrLabelHint')} />
                  </span>
                }
              >
                <Input
                  placeholder={item.default_mfr_label || ''}
                  value={application.mfr_label}
                  onChange={e =>
                    updateItemApplicationAttributes({ mfr_label: e.target.value || undefined })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={t('application:qty')}>
                <Input
                  placeholder={item.default_application_qty?.toString() || ''}
                  value={application.qty}
                  onChange={e =>
                    updateItemApplicationAttributes({
                      qty: e.target.value ? Number(e.target.value) : undefined,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label={t('application:position')}>
                <ApplicationPosition
                  className="application__overview-position-qty"
                  value={(application.position_ids && application.position_ids[0]) || undefined}
                  rankedPositions={rankedPositions}
                  positionResources={positionResources}
                  defaultPosition={item.default_position}
                  onChange={(id: number | null) => {
                    updateItemApplicationAttributes({
                      position_ids: [id],
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="ant-form-item-label application__default-qualifiers-label">
            <label>{t('application:defaults.qualifier')}</label>
          </div>
          <div className="application__default-qualifiers-row">
            <QualifierContainer application={application} />
          </div>
        </Form>
        <div className="application__defaults-form-buttons">
          <Row gutter={20}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button className="application__defaults-cancel-button" onClick={() => handleClose()}>
                {t('common:back')}
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Drawer
      className="application__defaults-drawer"
      title={t('application:editValuesTitle')}
      width="60%"
      open={visible}
      onClose={handleClose}
      destroyOnClose
      style={{ height: '100%' }}
    >
      <div className="application__defaults">{form()}</div>
    </Drawer>
  );
};

export default withErrorBoundary(ApplicationOptionsDrawer);
