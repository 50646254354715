import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Select, Button, Popconfirm, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GroupType, Format } from '../../../../types/resources';
import {
  COUNTRY_CODES,
  Warranty,
  WARRANTY_OPTIONS,
  WARRANTY_OPTION_CODES,
} from '../../../constants/ExtendedInfoConstants';
import { ApplicationState } from '../../../reducers';
import { typingDone } from '../../../utils/Utils';

type ExpiDrawerSelectProps = {
  groupType?: GroupType;
  onChange: (value: any) => void;
  values: string[];
  displayOk?: boolean;
};

const ExpiDrawerSelect: React.FC<ExpiDrawerSelectProps> = props => {
  const { t } = useTranslation();
  const { groupType, values, onChange, displayOk } = props;

  const [keyWordLength, setKeyWordLength] = React.useState<number>(0);
  const [focus, setFocus] = React.useState<boolean>(false);
  const [overWriteValues, setOverWriteValues] = React.useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState(
    groupType?.code === Warranty.Code && WARRANTY_OPTIONS.includes(values[0])
      ? ''
      : (values && values[0]) || ''
  );

  const { countries } = useSelector((state: ApplicationState) => {
    return {
      countries: state.resources.data.global.countries,
    };
  });

  React.useEffect(() => {
    setInputValue(
      groupType?.code === Warranty.Code && WARRANTY_OPTIONS.includes(values[0])
        ? ''
        : (values && values[0]) || ''
    );
    if (values.length === 0) setKeyWordLength(0);
  }, [groupType, values]);

  const isTagValues = groupType?.format === Format.Text && !COUNTRY_CODES.includes(groupType.code);

  const isCountryDropDownList = groupType && COUNTRY_CODES.includes(groupType.code);

  const isCheckableTagDropDownList =
    groupType?.format === Format.Array || groupType?.format === Format.Bool;

  const addTextInputField = groupType?.id === Warranty.TypeId;

  const replaceAllValues = () => {
    onChange(overWriteValues);
    if (addTextInputField) setOverWriteValues([]);
  };

  const renderWarnIcon = (value: string) => (
    <Tooltip
      title={
        (value.length > groupType!.max_length && t('extendedInfo:tooLong')) ||
        (value.length < groupType!.min_length && t('extendedInfo:tooShort'))
      }
    >
      <span className="extended-info__drawer-warn-icon" key={value}>
        {value.length !== 0 &&
          (value.length > groupType!.max_length || value.length < groupType!.min_length) && (
            <WarningOutlined />
          )}
      </span>
    </Tooltip>
  );

  const renderDropDownList = () => {
    if (isCountryDropDownList) {
      return countries.map(country => ({
        key: country.code!,
        value: country.code!,
        label: country.name,
      }));
    }
    if (isCheckableTagDropDownList) {
      return groupType?.options
        .filter(option => option.code !== Warranty.Code)
        .map(option => ({
          key: option.code!,
          value: option.code!,
          label:
            groupType.options[0]?.code === groupType.options[0]?.name
              ? option.code!
              : `${option.code} - ${option.name}`,
        }));
    }
    if (
      overWriteValues.length > 0 &&
      !isCheckableTagDropDownList &&
      !isCountryDropDownList &&
      !addTextInputField
    ) {
      return overWriteValues.map((value: string) => ({
        key: value,
        value,
        label: (
          <React.Fragment>
            {renderWarnIcon(value)}
            <span>{value}</span>
          </React.Fragment>
        ),
      }));
    }
    return values.map((value: string) => ({
      key: value,
      value,
      label: (
        <React.Fragment>
          {renderWarnIcon(value)}
          <span>{value}</span>
        </React.Fragment>
      ),
    }));
  };

  const renderSelectStateValues = () => {
    if (addTextInputField) {
      if (
        inputValue === '' ||
        WARRANTY_OPTION_CODES.includes(overWriteValues!.length > 0 && overWriteValues[0]) ||
        (values && values!.length > 0 && WARRANTY_OPTIONS.includes(values[0]))
      ) {
        return displayOk ? overWriteValues : values;
      }
      return [];
    }
    return displayOk ? overWriteValues : values;
  };

  return (
    <div
      className={classNames('extended-info__drawer-select flex justify-between flex-1', {
        'extended-info__select-tag_wrapper': !addTextInputField,
        'extended-info__select-tag': isTagValues && !addTextInputField,
      })}
    >
      <Select
        showSearch
        size="small"
        mode={isTagValues ? 'tags' : undefined}
        popupClassName="extended-info__select-dropdown"
        placeholder={groupType!.application}
        value={renderSelectStateValues()}
        filterOption={(input, option: any) => {
          if (!isTagValues && option) {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(value: any) => {
          if (isTagValues) setKeyWordLength(value.length > 0 ? value[value.length - 1].length : 0);
          if (groupType!.code !== Warranty.Code) {
            setInputValue('');
            if (!displayOk) onChange(value);
            else setOverWriteValues(value);
          }
        }}
        allowClear={!isTagValues}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        dropdownRender={menu =>
          isTagValues && values!.length === 0 && !keyWordLength ? <div /> : <div>{menu}</div>
        }
        style={{ width: addTextInputField ? '65%' : '100%' }}
        onSearch={text => setKeyWordLength(text.length)}
        data-testid="expi-drawer-select"
        options={renderDropDownList() as any}
      />
      {isTagValues && (
        <div
          className={classNames('flex-grow', {
            'extended-info__select-tag_text-warning':
              keyWordLength !== 0 &&
              (keyWordLength > groupType!.max_length || keyWordLength < groupType!.min_length),
            'extended-info__input_highlight': focus && !addTextInputField,
          })}
        >
          <div className="extended-info__select-tag_length text-gray-600">
            {`${keyWordLength} / ${groupType!.max_length}`}
          </div>
        </div>
      )}
      {addTextInputField &&
        groupType!.options.map(
          type =>
            type.code === Warranty.Code && (
              <div className={classNames({ 'mx-1': addTextInputField })} key={type.code}>
                <Input
                  key={type.code}
                  size="small"
                  placeholder={type.name}
                  onChange={e => {
                    const value = e.target.value;
                    setInputValue(value);
                    if (!displayOk) typingDone(() => onChange(value));
                    else setOverWriteValues([value]);
                  }}
                  value={WARRANTY_OPTIONS.includes(inputValue) ? '' : inputValue}
                />
              </div>
            )
        )}
      {displayOk && (
        <Popconfirm
          placement="left"
          title={t('extendedInfo:warnReplaceText')}
          onConfirm={replaceAllValues}
          okText={t('common:yes')}
          cancelText={t('common:cancel')}
          onCancel={e => e?.stopPropagation()}
          disabled={overWriteValues?.length === 0}
        >
          <Button type="primary" size="small" disabled={overWriteValues?.length === 0}>
            {t('extendedInfo:ok')}
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default ExpiDrawerSelect;
