import React from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useBlockerConfirm } from '../../../hooks/useBlockerConfirm';

type FormRouterPropmtProps = {
  formDirty: boolean;
};

const FormRouterPrompt: React.FC<FormRouterPropmtProps> = props => {
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { formDirty } = props;

  const [nextRouteLocation, setNextRouteLocation] = React.useState<any>(null);
  const [navigateModalVisible, setNavigateModalVisible] = React.useState(false);

  const { blockerActive, next, onConfirm, onCancel } = useBlockerConfirm(
    formDirty && !nextRouteLocation
  );

  React.useEffect(() => {
    if (nextRouteLocation) navigate(nextRouteLocation);
  }, [navigate, nextRouteLocation]);

  React.useEffect(() => {
    return () => {
      if (blockerActive) onCancel();
    };
  }, []);

  React.useEffect(() => {
    const showModal = (next: string, onConfirm: () => void, onCancel: () => void) => {
      if (next === nextRouteLocation) {
        setNextRouteLocation('');
        onConfirm();
        return;
      }
      setNavigateModalVisible(true);
      modal.confirm({
        title: t('common:closeWarningTitle'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setNextRouteLocation(next);
          setNavigateModalVisible(false);
          onConfirm();
        },
        onCancel() {
          setNextRouteLocation('');
          setNavigateModalVisible(false);
          onCancel();
        },
      });
    };
    if (next && !navigateModalVisible) showModal(next, onConfirm, onCancel);
  }, [navigateModalVisible, next, nextRouteLocation, onCancel, onConfirm]);

  return null;
};

export default FormRouterPrompt;
