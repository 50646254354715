import { Button, Drawer } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from '../../global/page/HeaderPage';
import { FilterApplicationParameterValue, FilterType } from '../../../../types/filter';
import ApplicationDetails from '../application/ApplicationDetails';
import { ApplicationState } from '../../../reducers';
import { fetchShopifyRankedApplicationsByFilter } from '../../../actions/channel/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { RankedApplications, RankedValue } from '../../../../types/application';
import resourcesMap from '../../../constants/ApplicationResourcesMapping.json';
import { getVehicleResources } from '../../../selectors/applications/applicationsSelector';
import { StandardResource } from '../../../../types/resources';
import {
  addShopifyFilterApplicationParameterValue,
  createShopifyApplicationFilter,
  createShopifyFilterApplicationParameter,
  deleteShopifyFilterApplicationParameter,
  removeShopifyFilterApplicationParameterValues,
} from '../../../actions/channel/update';
import { fetchApplicationYears } from '../../../actions/items/application/fetch';

const resourcesMapping = resourcesMap as { [key: string]: string };

type ShopifyFilterDrawerProps = {
  editShopifyFilter: FilterType;
  visible: boolean;
  handleClose: (deleteFilterId?: number) => void;
  removeFilterParameterValue: (sectionKey: string, parameterKey: string, valueKey: string) => void;
  addFilterParameterValue: (
    parameterId: string | null,
    subconfigName: string,
    resourceId: number | number[],
    resourceName?: string
  ) => void;
  removeFilterValuesLocal: (parameterKey: string, ids: string[]) => void;
};

const ShopifyFilterDrawer: React.FC<ShopifyFilterDrawerProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [getRecommendations, setGetRecommendations] = React.useState<boolean>(false);
  const [rankedMappedApplications, setRankedMappedApplications] =
    React.useState<RankedApplications>({} as RankedApplications);
  const [selectedMakes, setSelectedMakes] = React.useState<StandardResource[]>([]);
  const [selectedModels, setSelectedModels] = React.useState<StandardResource[]>([]);
  const [selectedYears, setSelectedYears] = React.useState<number[]>([]);
  const [selectedSubconfig, setSelectedSubconfig] = React.useState<string | null>('vehicle_bases');
  const [selectedSubconfigGroup, setSelectedSubconfigGroup] = React.useState<string | null>(null);
  const [selectedSubmodels, setSelectedSubmodels] = React.useState<StandardResource[]>([]);
  const [selectedRegions, setSelectedRegions] = React.useState<StandardResource[]>([]);
  const [selectedGroups, setSelectedGroups] = React.useState<StandardResource[]>([]);
  const [selectedTypes, setSelectedTypes] = React.useState<StandardResource[]>([]);
  const [initialfetch, setInitialFetch] = React.useState<boolean>(true);
  const [fetchRanksTrigger, setFetchRanksTrigger] = React.useState<number>(0);

  const { fetchingShopifyRankedApplications, applicationStructure, rankedApplications, resources } =
    useSelector((state: ApplicationState) => ({
      applicationStructure: state.resources.data.vehicle_structure,
      rankedApplications: state.channel.channels.rankedApplications,
      resources: getVehicleResources(state),
      fetchingShopifyRankedApplications: state.channel.channels.fetchingShopifyRankedApplications,
    }));

  const getApplicationFilter: any = React.useCallback((filter: FilterType) => {
    if (filter) {
      if (filter.filter_application) {
        return Object.values(filter.filter_application)[0];
      }
    }
  }, []);

  const triggerRankFetch = () => {
    setFetchRanksTrigger(fetchRanksTrigger + 1);
  };

  const getFilterValues = React.useCallback(
    (filter: FilterType) => {
      const filterValues: { [key: string]: { [key: string]: FilterApplicationParameterValue } } =
        {};
      const applicationFilter: any = getApplicationFilter(filter);
      if (applicationFilter) {
        Object.keys(applicationFilter.parameters).forEach(parameterId => {
          const parameter = applicationFilter.parameters[parameterId];
          filterValues[parameter.resource] = parameter.values;
        });
      }
      return filterValues;
    },
    [getApplicationFilter]
  );

  const allSubconfigValues = React.useCallback(
    (configName: string) => {
      // get all values to a specific config name e.g. position
      if (resourcesMapping.hasOwnProperty(configName)) {
        configName = resourcesMapping[configName];
      }
      return resources[configName];
    },
    [resources]
  );

  const mergeFilterValuesWithRankedApplications = React.useCallback(() => {
    const allRankedApplications = getRecommendations
      ? rankedApplications || ({} as RankedApplications)
      : ({} as RankedApplications);

    const filterValues = getFilterValues(props.editShopifyFilter);
    const filterKeys = Object.keys(filterValues).filter(key => !['years'].includes(key));
    const configKeys = [...new Set([...Object.keys(allRankedApplications), ...filterKeys])];
    const mergedApplication: RankedApplications = {} as RankedApplications;

    configKeys.forEach(configName => {
      const rankedValues = allRankedApplications[configName] || [];
      const valueObj = filterValues[configName] || {};
      const mappedValueIds = Object.keys(valueObj).map(id => {
        const value = valueObj[id];
        return value.resource_id;
      });
      let values = rankedValues;
      if (mappedValueIds) {
        const keys = [
          ...new Set([...rankedValues.map((value: RankedValue) => value.id), ...mappedValueIds]),
        ];
        if (keys.length > rankedValues.length) {
          values = [...rankedValues];
          // add values which are not included in ranking (new mapped values)
          mappedValueIds.forEach(id => {
            if (!rankedValues.find((value: RankedValue) => value.id === id)) values.push({ id });
          });
        }
      }

      const resources = allSubconfigValues(configName);
      const mergedValues: RankedValue[] = [];
      values.forEach((value: RankedValue) => {
        const mapped = mappedValueIds && mappedValueIds.includes(value.id);
        const valueResources = resources.find(
          (resource: StandardResource) => resource.id === value.id
        );
        mergedValues.push({ ...value, ...valueResources, mapped });
      });
      mergedApplication[configName] = mergedValues;
    });
    setRankedMappedApplications(mergedApplication);
  }, [
    allSubconfigValues,
    getFilterValues,
    getRecommendations,
    props.editShopifyFilter,
    rankedApplications,
  ]);

  const fetchRankedApplications = React.useCallback(
    (keywords?: string) => {
      const filterValues = getFilterValues(props.editShopifyFilter);
      if (
        (filterValues.hasOwnProperty('makes') && !filterValues.makes.temp) ||
        (filterValues.hasOwnProperty('models') && !filterValues.models.temp) ||
        (filterValues.hasOwnProperty('years') && !filterValues.years.temp) ||
        (filterValues.hasOwnProperty('sub_models') && !filterValues.sub_models.temp) ||
        (filterValues.hasOwnProperty('regions') && !filterValues.regions.temp) ||
        (filterValues.hasOwnProperty('mfrs') &&
          !filterValues.mfrs.temp &&
          filterValues.hasOwnProperty('equipment_models') &&
          !filterValues.equipment_models.temp) ||
        keywords
      ) {
        dispatch(fetchShopifyRankedApplicationsByFilter(filterValues, [], keywords)).then(() => {
          if (initialfetch) setInitialFetch(false);
        });
      }
    },
    [dispatch, getFilterValues, props.editShopifyFilter]
  );

  const getApplicationFilterParameterId = (configName: string) => {
    let parameterId: string | undefined;
    const applicationFilter: any = getApplicationFilter(props.editShopifyFilter);
    if (applicationFilter) {
      Object.keys(applicationFilter.parameters).forEach(id => {
        if (applicationFilter.parameters[id].resource === configName) {
          parameterId = id;
        }
      });
    }

    return parameterId;
  };

  const getValueIdFromParameter = (parameterId: string, resourceId: string) => {
    const applicationFilter: any = getApplicationFilter(props.editShopifyFilter);
    const parameter = applicationFilter!.parameters[parameterId];
    let valueId: string | undefined;
    if (parameter)
      Object.keys(parameter.values).forEach(id => {
        if (parameter.values[id].resource_id === Number(resourceId)) valueId = id;
      });
    return valueId!;
  };

  const getApplicationFilterId = () => {
    const filter = props.editShopifyFilter;
    const id = Object.keys(filter.filter_application)[0];
    return id;
  };

  const mapConfigToFilter = (
    subconfigName: string,
    resourceId: number | number[],
    resourceName?: string
  ) => {
    if (!resourceName && subconfigName !== 'years' && subconfigName !== 'equipment_years')
      resourceName = allSubconfigValues(subconfigName).find(
        (res: StandardResource) => res.id === resourceId
      ).name;
    const mappedValues = getFilterValues(props.editShopifyFilter);
    // check if filter has already a parameter "configName"
    if (mappedValues.hasOwnProperty(subconfigName)) {
      const parameterId = getApplicationFilterParameterId(subconfigName);
      // check if value is already mapped to parameter
      const valueId =
        resourceId instanceof Array
          ? null
          : getValueIdFromParameter(parameterId!, resourceId.toString());
      if (valueId) {
        // remove value
        props.removeFilterParameterValue('filter_application', parameterId!, valueId);
        triggerRankFetch();
      } else if (parameterId !== 'temp') {
        // add value
        dispatch(addShopifyFilterApplicationParameterValue(parameterId, resourceId)).then(res => {
          getSelectedValuesFromFilter(res.value.data.filter);
          triggerRankFetch();
        });
        props.addFilterParameterValue(parameterId!, subconfigName, resourceId, resourceName);
      }
    } else {
      // create new filter parameter including value
      const appFilterId = getApplicationFilterId();
      dispatch(
        createShopifyFilterApplicationParameter(appFilterId, 1, subconfigName, resourceId)
      ).then(() => {
        triggerRankFetch();
      });
      props.addFilterParameterValue(null, subconfigName, resourceId, resourceName);
    }
  };

  const removeValues = (resourceName: string, resourceIds: number[]) => {
    const parameterId = getApplicationFilterParameterId(resourceName);
    const valueIds: string[] = [];
    resourceIds.forEach(resourceId =>
      valueIds.push(getValueIdFromParameter(parameterId!, resourceId.toString()))
    );

    if (valueIds) {
      dispatch(removeShopifyFilterApplicationParameterValues(valueIds));
      props.removeFilterValuesLocal(parameterId!, valueIds);
    }
  };

  const fetchAppYears = (makes: StandardResource[], models: StandardResource[]) => {
    if (models.length > 0 || makes.length > 0) {
      dispatch(fetchApplicationYears([], makes[0]?.id, models[0]?.id));
    }
  };

  const selectMake = (makeId: number, addValues?: boolean) => {
    const make = allSubconfigValues('makes').find((make: StandardResource) => make.id === makeId);

    let makes = selectedMakes.length === 1 && selectedMakes[0].id === make.id ? [] : [make];
    if (addValues) {
      makes = [...selectedMakes];
      if (selectedMakes.filter(element => element.id === make.id).length > 0) {
        makes = makes.filter(element => element.id !== make.id);
      } else {
        makes.push(make);
      }
    } else if (selectedMakes.length > 0) {
      // remove all previous selected values
      const ids = selectedMakes.map(make => make.id).filter(id => id !== make.id);
      if (ids.length > 0) removeValues('makes', ids);
    }

    mapConfigToFilter('makes', make.id, make.name);
    setSelectedMakes(makes);
    fetchAppYears(makes, selectedModels);
  };

  const selectModel = (modelId: number | null, addValues?: boolean) => {
    const model = allSubconfigValues('models').find(
      (model: StandardResource) => model.id === modelId
    );
    let models = selectedModels.length === 1 && selectedModels[0].id === model.id ? [] : [model];
    if (addValues) {
      models = [...selectedModels];
      if (selectedModels.filter(element => element.id === model.id).length > 0) {
        models = models.filter(element => element.id !== model.id);
      } else {
        models.push(model);
      }
    } else if (selectedModels.length > 0) {
      // remove all previous selected values
      const ids = selectedModels.map(model => model.id).filter(id => id !== model.id);
      if (ids.length > 0) removeValues('models', ids);
    }

    mapConfigToFilter('models', model.id, model.name);
    setSelectedModels(models);
    fetchAppYears(selectedMakes, models);
  };

  const selectType = (id: number | null) => {
    const type = allSubconfigValues('vehicle_types').find(
      (type: StandardResource) => type.id === id
    );
    const types = selectedTypes.length === 1 && selectedTypes[0].id === type.id ? [] : [type];
    if (selectedTypes.length > 0) {
      // remove all previous selected values
      const ids = selectedTypes.map(t => t.id).filter(id => id !== type.id);
      if (ids.length > 0) removeValues('vehicle_types', ids);
    }
    mapConfigToFilter('vehicle_types', type.id, type.name);
    setSelectedTypes(types);
  };

  const selectGroup = (id: number | null) => {
    const type = allSubconfigValues('vehicle_type_groups').find(
      (type: StandardResource) => type.id === id
    );
    const groups = selectedGroups.length === 1 && selectedGroups[0].id === type.id ? [] : [type];
    if (selectedGroups.length > 0) {
      // remove all previous selected values
      const ids = selectedGroups.map(g => g.id).filter(id => id !== type.id);
      if (ids.length > 0) removeValues('vehicle_type_groups', ids);
    }
    mapConfigToFilter('vehicle_type_groups', type.id, type.name);
    setSelectedGroups(groups);
  };

  let oldYears: number[] | null;

  const selectYears = (yearIds: number | number[] | null, addValues?: boolean, save = true) => {
    if (yearIds === null) {
      const parameterId = getApplicationFilterParameterId('years');
      if (parameterId) {
        dispatch(deleteShopifyFilterApplicationParameter(parameterId));
      }
      setSelectedYears([]);
      triggerRankFetch();
    } else {
      let years = yearIds instanceof Array ? Array.from(new Set(yearIds)) : [yearIds];
      if (years.length === 1 && selectedYears.length === 1) {
        years = years[0] === selectedYears[0] ? [] : years;
      }

      if (addValues) {
        years = [...selectedYears];
        if (yearIds instanceof Array) years = Array.from(new Set(years.concat(yearIds)));
        else if (years.includes(yearIds)) {
          years = years.filter(element => element !== yearIds);
          if (yearIds) removeValues('years', [yearIds]);
        } else years.push(yearIds);
      } else if (selectedYears.length > 0 || yearIds instanceof Array) {
        // remove all previous selected values
        if (!save && !oldYears) oldYears = selectedYears;
        else if (!oldYears && selectedYears.length > 0) removeValues('years', selectedYears);
        else if (save && oldYears) {
          if (oldYears.length > 0) removeValues('years', oldYears);
          oldYears = null;
        }
      }
      setSelectedYears(years);
      // save all years in one call
      if (save !== false) mapConfigToFilter('years', years);
    }
  };

  const selectSubmodel = (submodelId: number | null) => {
    if (submodelId === null) {
      const parameterId = getApplicationFilterParameterId('sub_models');
      if (parameterId) {
        dispatch(deleteShopifyFilterApplicationParameter(parameterId));
      }
      setSelectedSubmodels([]);
    } else {
      const submodel = allSubconfigValues('sub_models').find(
        (model: StandardResource) => model.id === submodelId
      );

      selectedSubmodels.length > 0 && submodel.id === selectedSubmodels[0].id
        ? setSelectedSubmodels([])
        : setSelectedSubmodels([submodel]);
      mapConfigToFilter('sub_models', submodel.id, submodel.name);
    }
  };

  const selectRegion = (regionId: number | null) => {
    if (regionId === null) {
      const parameterId = getApplicationFilterParameterId('regions');
      if (parameterId) {
        dispatch(deleteShopifyFilterApplicationParameter(parameterId));
      }
      setSelectedRegions([]);
    } else {
      const region = allSubconfigValues('regions').find(
        (region: StandardResource) => region.id === regionId
      );
      selectedRegions.length > 0 && region.id === selectedRegions[0].id
        ? setSelectedRegions([])
        : setSelectedRegions([region]);
      mapConfigToFilter('regions', region.id, region.name);
    }
  };

  const selectSubconfigGroup = (subConfigGroup: string | null) => {
    setSelectedSubconfigGroup(subConfigGroup);
  };

  const selectSubconfig = (subConfig: string | null) => {
    if (subConfig && selectedSubconfig !== subConfig) {
      setSelectedSubconfig(subConfig);

      subConfig === 'engines' ? selectSubconfigGroup('engine_bases') : selectSubconfigGroup(null);
    }
  };

  const getSelectedValuesFromFilter = React.useCallback(
    (filter?: FilterType) => {
      const filterValues = getFilterValues(filter || props.editShopifyFilter);
      let makes: StandardResource[] = [];
      let models: StandardResource[] = [];
      let types: StandardResource[] = [];
      let groups: StandardResource[] = [];
      let years;
      let submodels;
      let regions;
      if (filterValues.hasOwnProperty('makes')) {
        makes = Object.values(filterValues.makes).map(value => ({
          id: value.resource_id,
          name: value.resource_name,
        }));
      }
      if (filterValues.hasOwnProperty('models')) {
        models = Object.values(filterValues.models).map(value => ({
          id: value.resource_id,
          name: value.resource_name,
        }));
      }
      if (filterValues.hasOwnProperty('vehicle_types')) {
        types = Object.values(filterValues.vehicle_types).map(v => ({
          id: v.resource_id,
          name: v.resource_name,
        }));
      }
      if (filterValues.hasOwnProperty('vehicle_type_groups')) {
        groups = Object.values(filterValues.vehicle_type_groups).map(g => ({
          id: g.resource_id,
          name: g.resource_name,
        }));
      }
      if (filterValues.hasOwnProperty('years')) {
        years = Object.values(filterValues.years).map(value => value.resource_id);
      }
      if (filterValues.hasOwnProperty('sub_models')) {
        submodels = Object.values(filterValues.sub_models).map(value => ({
          id: value.resource_id,
          name: value.resource_name,
        }));
      }
      if (filterValues.hasOwnProperty('regions')) {
        regions = Object.values(filterValues.regions).map(value => ({
          id: value.resource_id,
          name: value.resource_name,
        }));
      }
      setSelectedMakes(makes || []);
      setSelectedModels(models || []);
      setSelectedTypes(types || []);
      setSelectedGroups(groups || []);
      setSelectedYears(years || []);
      setSelectedSubmodels(submodels || []);
      setSelectedRegions(regions || []);
    },
    [getFilterValues, props.editShopifyFilter]
  );

  const fetchMakeModelYearData = useCallback(() => {
    const filterValues = getFilterValues(props.editShopifyFilter);

    if (filterValues.hasOwnProperty('makes') && filterValues.hasOwnProperty('models')) {
      const makeIds = Object.values(filterValues.makes).map(value => value.resource_id);
      const modelIds = Object.values(filterValues.models).map(value => value.resource_id);
      if (makeIds.length === 1 || modelIds.length === 1) {
        dispatch(fetchApplicationYears([], makeIds[0], modelIds[0]));
      }
    }
  }, [dispatch, getFilterValues, props.editShopifyFilter]);

  React.useEffect(() => {
    if (props.visible) {
      setGetRecommendations(false);
      setRankedMappedApplications({} as RankedApplications);
      setSelectedMakes([]);
      setSelectedModels([]);
      setSelectedYears([]);
      setSelectedSubconfig('vehicle_bases');
      setSelectedSubconfigGroup(null);
      setSelectedSubmodels([]);
      setSelectedRegions([]);
      setSelectedGroups([]);
      setSelectedTypes([]);
      setInitialFetch(true);
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (props.visible && initialfetch) {
      fetchMakeModelYearData();
      fetchRankedApplications();
      getSelectedValuesFromFilter();
    }
  }, [
    props.visible,
    initialfetch,
    fetchRankedApplications,
    fetchMakeModelYearData,
    getSelectedValuesFromFilter,
  ]);

  React.useEffect(() => {
    if (!props.editShopifyFilter.filter_application && props.visible && props.editShopifyFilter.id)
      dispatch(createShopifyApplicationFilter(props.editShopifyFilter.id));
  }, [dispatch, props.editShopifyFilter, props.visible]);

  React.useEffect(() => {
    mergeFilterValuesWithRankedApplications();
  }, [mergeFilterValuesWithRankedApplications]);

  React.useEffect(() => {
    fetchRankedApplications();
  }, [fetchRanksTrigger]);

  React.useEffect(() => {
    if (!fetchingShopifyRankedApplications && !initialfetch) {
      const filterValues = getFilterValues(props.editShopifyFilter);
      if (
        filterValues.hasOwnProperty('makes') ||
        filterValues.hasOwnProperty('models') ||
        filterValues.hasOwnProperty('years') ||
        filterValues.hasOwnProperty('sub_models') ||
        filterValues.hasOwnProperty('regions') ||
        (filterValues.hasOwnProperty('mfrs') && filterValues.hasOwnProperty('equipment_models'))
      ) {
        setGetRecommendations(true);
      } else setGetRecommendations(false);
    }
  }, [fetchingShopifyRankedApplications, getFilterValues, initialfetch, props.editShopifyFilter]);

  const getAllValues = () => {
    const filterValues = getFilterValues(props.editShopifyFilter);
    const values = Object.values(filterValues);
    return values;
  };

  return (
    <Drawer
      className="shopify-filter__drawer"
      width="75%"
      placement="right"
      open={props.visible}
      style={{ height: '100%' }}
      closable={false}
    >
      <HeaderPage
        title={t('channel:filter')}
        actionButtons={
          <Button
            onClick={() =>
              getAllValues().length === 0
                ? props.handleClose(props.editShopifyFilter.id)
                : props.handleClose()
            }
            type="primary"
            className="shopify-filter__show-results"
            data-testid="show-results"
          >
            {t('common:close')}
          </Button>
        }
      >
        <ApplicationDetails
          hideSearch
          hideEquipment
          filterView
          canManageVcdb={false} // for channel no vcdb required
          applicationStructure={applicationStructure}
          getRankedApplications={fetchRankedApplications}
          getRecommendations={getRecommendations}
          rankedApplications={rankedMappedApplications}
          allSubconfigValues={allSubconfigValues}
          selectedMakes={selectedMakes.map(({ id }) => id) || []}
          selectedModels={selectedModels.map(({ id }) => id) || []}
          selectedYears={selectedYears || []}
          selectedSubmodels={selectedSubmodels.map(({ id }) => id) || []}
          selectedRegions={selectedRegions.map(({ id }) => id) || []}
          selectedSubconfig={selectedSubconfig}
          selectedSubconfigGroup={selectedSubconfigGroup}
          selectMake={selectMake}
          selectModel={selectModel}
          selectType={selectType}
          selectGroup={selectGroup}
          selectSubmodel={selectSubmodel}
          selectRegion={selectRegion}
          selectYears={selectYears}
          selectSubconfig={selectSubconfig}
          selectSubconfigGroup={selectSubconfigGroup}
          mapConfigToApplication={mapConfigToFilter}
        />
      </HeaderPage>
    </Drawer>
  );
};

export default ShopifyFilterDrawer;
