import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, Col, Form, Row, Spin, Switch, Upload } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

import { withContainerWrapper } from '../ContainerWrapper';
import { importExcelFile } from '../../actions/receiver_data_stream/import/create';
import { intercomEvent } from '../../utils/IntercomUtils';
import { fetchImportOptions } from '../../actions/brand/import_export/fetch';
import { ApplicationState } from '../../reducers';
import { ImportOption, ImportOptions } from '../../../types/import_export';
import helpCenterConstants from '../../constants/HelpCenterConstants.json';

const { url: helpCenterUrl, articles } = helpCenterConstants;

const ImportContainer: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [segmentIds, setSegmentIds] = React.useState([12, 13]);

  React.useEffect(() => {
    intercomEvent('viewed-import-export', { location: 'import-custom-fields' });
  }, []);

  React.useEffect(() => {
    dispatch(fetchImportOptions());
  }, [dispatch]);

  const { importOptions, fetchingImportOptions } = useSelector((state: ApplicationState) => {
    return {
      importOptions: state.brand.importExport.importOptions,
      fetchingImportOptions: state.brand.importExport.fetchingImportOptions,
    };
  });

  const readFile = (file: any) => {
    const option = importOptions
      .find((o: ImportOptions) => o.id === 10)
      ?.import_options.find((option: ImportOption) => option.code === 'only_selected_segments');

    dispatch(importExcelFile(file, { [option!.id]: segmentIds }));
  };

  const handleChange = (info: any) => {
    readFile(info.file);
  };

  const segments = importOptions
    ? importOptions
        .find((o: ImportOptions) => o.id === 10)
        ?.import_options.find((option: ImportOption) => option.code === 'only_selected_segments')
        ?.parameters.filter(
          segment => segment.code === 'custom_fields' || segment.code === 'ebay'
        ) || []
    : [];

  if (fetchingImportOptions)
    return (
      <div className="mt-10">
        <Spin className="spinner-center" />
      </div>
    );

  return (
    <div className="m-10">
      <h1>{t('importer:importFiles')}</h1>
      <Form labelCol={{ span: 5 }} labelAlign="left" className="import-format-options" labelWrap>
        <Form.Item label={t('importer:selectSegments')}>
          <Row>
            <Col span={16}>
              <Checkbox.Group
                className="w-full"
                onChange={checkedValues => setSegmentIds(checkedValues.map(v => Number(v)))}
                value={segmentIds}
              >
                <Row>
                  {segments.map(({ id, name: checkboxName }, i) => (
                    <Col span={12} key={i}>
                      <Checkbox value={id}>{checkboxName}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col span={8}>
              <div className="flex justify-end items-center ml-2" key="selectAll">
                <div className="mr-2">{t('common:selectAll')}</div>
                <Switch
                  size="small"
                  checked={segments.length === segmentIds.length}
                  onChange={checked => {
                    if (checked) setSegmentIds(segments.map(segment => segment.id));
                    if (!checked) setSegmentIds([]);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label={t('importer:uploadFiles')} className="import-receiver__upload">
          <Upload
            accept=".xlsx"
            listType="picture-card"
            showUploadList={false}
            customRequest={handleChange}
          >
            <div>
              <FileExcelOutlined className="background-icon-excel" />
              <div className="mt-4">
                <div>
                  {t('common:upload')} {t('common:excel')}
                </div>
                <div className="blue">{t('common:clickOrDrop')}</div>
              </div>
            </div>
          </Upload>

          <div className="mt-5">
            <Alert
              message={
                <React.Fragment>
                  {t('importer:receiverImportInfo')}
                  <a
                    className="pl-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={helpCenterUrl + articles.receiver_custom_fields}
                  >
                    {t('common:supportArticle')}
                  </a>
                </React.Fragment>
              }
              type="info"
              showIcon
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withContainerWrapper(ImportContainer);
