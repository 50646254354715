import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission } from '../../utils/Permissions';
import ReceiverExportRoutes from '../../routes/ReceiverExportRoutes';
import { ApplicationState } from '../../reducers';

type MenuItem = Required<MenuProps>['items'][number];
const { Content, Sider } = Layout;

const ReceiverExportMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector((state: ApplicationState) => {
    return {
      customFields: state.brand.customFields.customFields,
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-receiver-export');
  }, [dispatch]);

  const canManageCustomFields = hasPermission(user, 'can_manage_custom_fields');

  const exportItems: MenuItem[] = [
    {
      key: '/available-brands/export/export-data',
      label: <Link to={{ pathname: `export-data` }}>{t('common:export')}</Link>,
    },
    {
      key: '/available-brands/export/export-logs',
      label: <Link to={{ pathname: `export-logs` }}>{t('exporter:exportReport')}</Link>,
    },
    {
      key: '/available-brands/export/export-builder-advanced',
      label: (
        <Link to={{ pathname: `export-builder-advanced` }}>{t('exporter:exportBuilderPlus')}</Link>
      ),
    },
    ...(canManageCustomFields
      ? [
          {
            key: '/available-brands/export/import',
            label: <Link to={{ pathname: `import` }}>{t('common:import')}</Link>,
          },
        ]
      : []),
    {
      key: '/available-brands/export/import-reports',
      label: <Link to={{ pathname: `import-reports` }}>{t('importer:importReport')}</Link>,
    },
  ];

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          items={exportItems}
        />
      </Sider>
      <Content className="main-page-content">
        <ReceiverExportRoutes />
      </Content>
    </Layout>
  );
};

export default ReceiverExportMenu;
