import React from 'react';
import { Button, Form, Modal, Result, Spin, Typography, Upload } from 'antd';
import { DeleteOutlined, FileExcelOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Brand } from '../../../../types/brand';
import PageFormik from '../../global/page/PageFormik';
import FormInput from '../../global/Forms/FormInput';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import { downloadBulkReceiverExcel } from '../../../actions/receiver/fetch';

type ReceiverBulkInviteModalProps = {
  showModal: boolean;
  activeBrands: Brand[];
  handleBulkInvite: ({ values, file }: { values?: any[]; file?: any }) => Promise<any>;
  onClose: () => void;
};

const ReceiverBulkInviteModal: React.FC<ReceiverBulkInviteModalProps> = ({
  showModal,
  activeBrands,
  handleBulkInvite,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [uploadPending, setUploadPending] = React.useState(false);
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [inviteCount, setInviteCount] = React.useState(0);

  React.useEffect(() => {
    if (showModal) {
      setUploadSuccess(false);
      setInviteCount(0);
    }
  }, [showModal]);

  const readFile = async (info: any) => {
    setUploadPending(true);
    await handleBulkInvite({ file: info.file });
    setUploadPending(false);
    setUploadSuccess(true);
  };

  const validationSchema = Yup.object().shape({
    receiverList: Yup.array().of(
      Yup.object().shape({
        receiver_name: Yup.string().required(t('validation:required')),
        email: Yup.string().email(t('validation:invalidEmail')).required(t('validation:required')),
        first_name: Yup.string().required(t('validation:required')),
        last_name: Yup.string().required(t('validation:required')),
      })
    ),
  });

  const getInitialValues = () => {
    return {
      receiverList: [
        {
          receiver_name: '',
          email: '',
          first_name: '',
          last_name: '',
          phone_number: '',
          address: '',
          city: '',
          brand_ids: activeBrands.map(brand => brand.id),
        },
      ],
    };
  };

  return (
    <Modal
      className="receiver__invite-modal"
      title={t('receiver:addReceivers')}
      open={showModal}
      onCancel={() => onClose()}
      footer={null}
      width="90%"
    >
      {uploadSuccess || inviteCount ? (
        <Result
          status="success"
          title={
            inviteCount > 0
              ? t('receiver:inviteSuccess', { count: inviteCount })
              : t('receiver:uploadSuccess')
          }
          extra={[
            <Button onClick={() => onClose()} type="primary">
              {t('common:close')}
            </Button>,
          ]}
        />
      ) : (
        <PageFormik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitPending, setSubmitError, setSubmitSuccess }) => {
            setSubmitPending();

            handleBulkInvite({ values: values.receiverList })
              .then(() => {
                setSubmitSuccess();
                setInviteCount(values.receiverList.length);
              })
              .catch(() => setSubmitError());
          }}
          contentNoScroll
        >
          {({ values, touched, setFieldValue, setTouched }) => (
            <div className="h-full overflow-auto flex flex-col m-5">
              <div className="flex import-receiver__upload">
                <Upload
                  accept=".xlsx"
                  listType="picture-card"
                  showUploadList={false}
                  customRequest={readFile}
                  disabled={uploadPending}
                >
                  <div>
                    {uploadPending ? (
                      <Spin size="large" />
                    ) : (
                      <FileExcelOutlined className="background-icon-excel" />
                    )}
                    <div className="mt-4">
                      <div>
                        {t('common:upload')} {t('common:excel')}
                      </div>
                      <div className="blue">{t('common:clickOrDrop')}</div>
                    </div>
                  </div>
                </Upload>
              </div>

              <span>
                {t('receiver:downloadImportTemplate')}
                <Button
                  className="receiver__template-button"
                  onClick={() => dispatch(downloadBulkReceiverExcel())}
                  type="link"
                >
                  {t('common:here')}
                </Button>
              </span>

              <Typography.Title level={5} className="mt-7">
                {`...${t('receiver:addReceiverHint')}`}
              </Typography.Title>

              {values.receiverList.map((_receiver: any, index: number) => (
                <div key={index} className="receiver__bulk-form-wrapper flex mt-7">
                  <Form layout="vertical" className="flex flex-row">
                    <FormInput
                      label={index === 0 ? t('receiver:reseller') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].receiver_name`}
                      required
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:resellerEmail') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].email`}
                      required
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:resellerFirstName') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].first_name`}
                      required
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:resellerLastName') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].last_name`}
                      required
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:phoneNumber') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].phone_number`}
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:address') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].address`}
                    />
                    <FormInput
                      label={index === 0 ? t('receiver:city') : undefined}
                      className="flex-1"
                      name={`receiverList[${index}].city`}
                    />
                    <FormMultiSelect
                      className="receiver__invite-brand-select"
                      label={index === 0 ? t('receiver:brands') : undefined}
                      name={`receiverList[${index}].brand_ids`}
                      values={activeBrands}
                      allowClear
                    />
                    <Button
                      className={index === 0 ? 'receiver__bulk-action-buttons' : ''}
                      onClick={() => {
                        if (values.receiverList.length > 1)
                          setFieldValue(
                            'receiverList',
                            values.receiverList.filter((_code: string, i: number) => i !== index)
                          );
                        else setFieldValue('receiverList', getInitialValues().receiverList);
                      }}
                      icon={<DeleteOutlined />}
                    />
                  </Form>
                  {index === 0 && (
                    <Button
                      className="ml-3 receiver__bulk-action-buttons"
                      onClick={() => {
                        setFieldValue('receiverList', [
                          {
                            receiver_name: '',
                            email: '',
                            first_name: '',
                            last_name: '',
                            phone_number: '',
                            address: '',
                            city: '',
                            brand_ids: activeBrands.map(brand => brand.id),
                          },
                          ...values.receiverList,
                        ]);
                        if (Array.isArray(touched.receiverList))
                          setTouched({ receiverList: [{}, ...touched.receiverList] }, false);
                      }}
                      icon={<PlusCircleOutlined />}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </PageFormik>
      )}
    </Modal>
  );
};

export default ReceiverBulkInviteModal;
