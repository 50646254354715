import React from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { SortableHandle, SortableElement, SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Dropdown, Button, Spin, Tooltip, App } from 'antd';
import { deleteExportBuilderAdvancedColumn } from '../../../actions/brand/export_builder_advanced/delete';

const ROW_HEIGHT = 48;
const COL_WIDTH = 200;

const DragHandle = SortableHandle(({ className }: { className: string }) => (
  <span className={className}>::</span>
));

const SortableHeader = SortableElement(({ children, ...props }: any) => {
  return React.cloneElement(children, props);
});

const SortableHeaderRowRenderer = SortableContainer(({ className, columns, style }: any) => (
  <div className={className} role="row" style={style}>
    {React.Children.map(columns, (column, index) => (
      <SortableHeader index={index}>{column}</SortableHeader>
    ))}
  </div>
));

type PreviewTableProps = {
  setDrawerVisible: (bool: boolean, colId: number | null, newCol?: boolean) => void;
  templateColumns: any[];
  updateColumnsOrder: (cols: any[]) => void;
  fetching: boolean;
  fetchingPreview: boolean;
  preview: any[];
  segment?: string;
  lockColumnOrder?: boolean;
};

const PreviewTable: React.FC<PreviewTableProps> = ({
  setDrawerVisible,
  templateColumns,
  updateColumnsOrder,
  fetching,
  fetchingPreview,
  preview,
  segment,
  lockColumnOrder,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const [rows, setRows] = React.useState<any>([]);

  React.useEffect(() => {
    setRows(preview);
  }, [preview]);

  const handleDeleteCol = (colId: number) => {
    modal.confirm({
      title: t('exportBuilderAdvanced:deleteColumnWarning'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        dispatch(deleteExportBuilderAdvancedColumn(colId));
      },
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const increasingIndex = oldIndex > newIndex;
    const smallIndex = increasingIndex ? newIndex : oldIndex;
    const bigIndex = increasingIndex ? oldIndex : newIndex;

    // update record_numbers
    const updatedRecord = templateColumns.map((col: any) => {
      if (col.number === oldIndex + 1) {
        return { ...col, number: newIndex + 1 };
      }
      if (smallIndex + 1 <= col.number && bigIndex + 1 >= col.number) {
        return {
          ...col,
          number: increasingIndex ? col.number + 1 : col.number - 1,
        };
      }
      return col;
    });
    const sortedCols = arrayMoveImmutable(updatedRecord, oldIndex, newIndex);
    updateColumnsOrder(sortedCols);
  };

  const getRow = ({ index }: any) => {
    return rows[index];
  };

  const renderHeaderRow = (params: any) => {
    return (
      <SortableHeaderRowRenderer
        {...params}
        axis="x"
        lockAxis="x"
        useDragHandle
        onSortEnd={onSortEnd}
      >
        {params}
      </SortableHeaderRowRenderer>
    );
  };

  const columnItems = (colId: number) => [
    {
      label: t('exportBuilderAdvanced:editColumn'),
      key: 'ebp_edit-col',
      onClick: () => setDrawerVisible(true, colId),
    },
    ...(segment === 'exportBuilderAdvanced'
      ? [
          {
            label: t('exportBuilderAdvanced:addColumn'),
            key: 'ebp_add-col',
            onClick: () => setDrawerVisible(true, colId, true),
          },
          {
            label: t('exportBuilderAdvanced:deleteColumn'),
            key: 'ebp_delete-col',
            onClick: () => handleDeleteCol(colId),
          },
        ]
      : []),
  ];

  const headerRenderer = ({ label, columnData }: any) => {
    return (
      <div className="flex items-center">
        <div className="flex items-center mr-2 w-full overflow-hidden">
          {lockColumnOrder ? (
            ''
          ) : (
            <DragHandle className="mr-2 cursor-move text-gray-600 text-base" />
          )}
          <Tooltip title={label}>
            <span
              data-testid="preview-table-header-label"
              className="preview-table__header-title__truncate overflow-hidden overflow-ellipsis"
            >
              {label}
            </span>
          </Tooltip>
        </div>
        {columnData.locked ? (
          <LockOutlined />
        ) : (
          <Dropdown menu={{ items: columnItems(columnData.id) }} trigger={['click']}>
            <EllipsisOutlined />
          </Dropdown>
        )}
      </div>
    );
  };

  if (!templateColumns) {
    return null;
  }

  if (fetching)
    return (
      <div className="h-full flex justify-center pt-8">
        <Spin />
      </div>
    );

  return (
    <div className="flex flex-col h-full">
      {segment === 'exportBuilderAdvanced' && (
        <div className="flex flex-col mb-4">
          <div className="align-end">
            <Button
              type="primary"
              data-testid="add-new-column"
              ghost
              onClick={() => setDrawerVisible(true, null)}
            >
              {t('exportBuilderAdvanced:addNewColumn')}
            </Button>
          </div>
        </div>
      )}
      <div className="flex-1 border border-solid border-gray-400 rounded overflow-x-auto">
        {fetchingPreview ? (
          <div className="h-full flex justify-center pt-8">
            <Spin />
          </div>
        ) : (
          <AutoSizer>
            {({ width, height }) => {
              const allColWidth = templateColumns.length * COL_WIDTH;
              return (
                <Table
                  width={allColWidth < width ? width - 2 : allColWidth}
                  height={height - 20}
                  headerHeight={ROW_HEIGHT}
                  rowHeight={ROW_HEIGHT}
                  rowCount={rows.length}
                  rowGetter={getRow}
                  headerRowRenderer={renderHeaderRow}
                >
                  {templateColumns.map(col => (
                    <Column
                      key={col.id}
                      label={col.label}
                      dataKey={col.dataKey}
                      columnData={{ id: col.id, locked: col.locked }}
                      width={COL_WIDTH}
                      headerRenderer={headerRenderer}
                    />
                  ))}
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default PreviewTable;
