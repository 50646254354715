import React from 'react';
import { Allotment } from 'allotment';
import { Layout, Alert } from 'antd';
import { connect } from 'react-redux';
import 'allotment/dist/style.css';

import ProductsRoutes from '../../routes/brand/ProductsRoutes';
import CatalogueContainer from '../../containers/catalouge/CatalogueContainer';
import BrandProductsSideBar from '../../containers/sidebars/BrandProductsSideBar';
import UpdateManager from '../../containers/catalouge/UpdateManager';
import ConnectedContainerWrapper from '../../containers/BrandRouteWrapper';
import ConnectedReceiverRouteWrapper from '../../containers/ReceiverRouteWrapper';
import catalougeTranslation from '../../constants/CatalogueTranslations.json';

import { ApplicationState } from '../../reducers/index';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { Item } from '../../../types/item';
import { isManufacturer } from '../../utils/UserUtils';
import { Brand } from '../../../types/brand';

const { Content } = Layout;

type ProductsPageProps = {
  inAppArea: string;
  canSeeProductEditArea: boolean;
};

type ProductsPageState = {
  catalogueExtended: boolean;
  catalougeIsResizing: boolean;
};

class ProductsPage extends React.Component<ProductsPageProps, ProductsPageState> {
  allotmentRef = React.createRef<any>();

  state = {
    catalogueExtended: this.props.inAppArea === 'AVAILABLE_BRAND_AREA',
    catalougeIsResizing: false,
  };

  changeCatalougeHeight = async () => {
    await this.setState(prevState => ({
      catalogueExtended: !prevState.catalogueExtended,
    }));
    this.allotmentRef.current.reset();
  };

  render() {
    const { catalogueExtended } = this.state;
    let size: any = 210;
    if (catalogueExtended) size = '100%';
    return (
      <Layout className="products-page">
        <Allotment
          ref={this.allotmentRef}
          className="flex"
          vertical
          proportionalLayout={false}
          onDragStart={() => this.setState({ catalougeIsResizing: true })}
          onDragEnd={() => this.setState({ catalougeIsResizing: false })}
          separator={false}
        >
          <Allotment.Pane
            className="split-view__top-pane flex"
            minSize={125}
            maxSize={catalogueExtended ? 100000 : 500}
            preferredSize={size}
          >
            <CatalogueContainer
              changeCatalougeHeight={this.changeCatalougeHeight}
              catalogueExtended={this.state.catalogueExtended}
              catalougeIsResizing={this.state.catalougeIsResizing}
            />
          </Allotment.Pane>
          <Allotment.Pane className="split-view__bottom-pane" visible={!catalogueExtended}>
            <Layout className="products-edit-outer">
              <BrandProductsSideBar />
              <Content className="brand-products-content">
                {this.props.canSeeProductEditArea ? (
                  <ProductsRoutes />
                ) : (
                  <Alert
                    style={{ height: 85, width: '100%' }}
                    showIcon
                    type="warning"
                    message={catalougeTranslation.noAccess}
                    description={catalougeTranslation.detailedPreview}
                  />
                )}
              </Content>
            </Layout>
          </Allotment.Pane>
        </Allotment>
        <UpdateManager />
      </Layout>
    );
  }
}

const withProductsPageWrapper = (Component: any) => (props: any) => {
  if (props.inAppArea === 'AVAILABLE_BRAND_AREA') {
    return (
      <ConnectedReceiverRouteWrapper>
        <Component {...props} />
      </ConnectedReceiverRouteWrapper>
    );
  }
  return (
    <ConnectedContainerWrapper>
      <Component {...props} />
    </ConnectedContainerWrapper>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  let canSeeProductEditArea = false;
  const user = state.user.user;
  if (user && isManufacturer(user)) {
    canSeeProductEditArea = true;
  } else {
    const allBrands = state.receiverDataStream.authorizedBrands;
    const selectedItems: Item[] = getSelectedItems(state);
    if (selectedItems.length > 0) {
      canSeeProductEditArea = !!(
        selectedItems.length &&
        allBrands.find((brand: Brand) => brand.id === selectedItems[0].parent_owner_brand_id)
      );
    } else {
      canSeeProductEditArea = true;
    }
  }
  return {
    inAppArea: state.app.global.inAppArea,
    canSeeProductEditArea,
  };
};

export default connect(mapStateToProps)(withProductsPageWrapper(ProductsPage));
