import React from 'react';
import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Dropdown, App } from 'antd';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import { FormikValues, FormikHelpers, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import classNames from 'classnames';
import { ApplicationState } from '../../../../reducers';
import { AsyncDispatch } from '../../../../../types/global';
import DrawerFormik from '../../../global/drawer/DrawerFormik';
import FormInput from '../../../global/Forms/FormInput';
import { updateNamingConventions } from '../../../../actions/channel/update';
import { deleteNamingConventions } from '../../../../actions/channel/delete';
import { FileNameTemplate } from '../../../../../types/channel';
import { namingConventionPreview } from '../../../../utils/ChannelUtils';

type NamingConventionDrawerProps = {
  visible: boolean;
  onClose: () => void;
};

const NamingConventionDrawer: React.FC<NamingConventionDrawerProps> = ({ visible, onClose }) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const { fileNameTemplates, fileNameConfigOptions } = useSelector((state: ApplicationState) => {
    return {
      fileNameTemplates: state.channel.channels.fileNameTemplates as FileNameTemplate[],
      fileNameConfigOptions: state.resources.data.global.file_name_config_options,
    };
  });

  const updateTemplates = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;

    dispatch(updateNamingConventions(values.fileNameTemplates))
      .then(() => {
        setSubmitting(false);
        // resetForm({ values });
      })
      .catch(() => {
        setSubmitting(false);
      });

    setSubmitting(false);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    fileNameTemplates: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('validation:required'))
          // eslint-disable-next-line func-names
          .test(
            'check-unique-template-name',
            t('channel:duplicateNames'),
            function test(name?: string) {
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              const templateList = this.from[1].value.fileNameTemplates;
              if (templateList.length === new Set(templateList.map((a: any) => a.name)).size)
                return true;
              const filteredList = templateList.filter((template: any) => template.name === name);
              return filteredList.length === 1;
            }
          ),
        config: Yup.string().required(t('validation:required')),
      })
    ),
  });

  return (
    <DrawerFormik
      title={t('channel:namingConvention')}
      validationSchema={validationSchema}
      width={1000}
      onClose={() => onClose()}
      visible={visible}
      initialValues={{ fileNameTemplates }}
      onSubmit={(values, actions) => updateTemplates(values, actions)}
      destroyOnClose
    >
      {({ values, handleSubmit, setFieldValue, setValues, resetForm }) => {
        return (
          <FieldArray
            name="fileNameTemplates"
            render={({ unshift, remove }) => {
              const deleteTemplate = (index: number) => {
                const template = values.fileNameTemplates[index];

                if (template.id) {
                  modal.confirm({
                    title: t('channel:deleteNamingConvention'),
                    icon: <ExclamationCircleOutlined />,
                    content: `${t('channel:deleteNamingConventionContent1')} ${
                      template.channel_count
                    } ${t('channel:deleteNamingConventionContent2')}`,
                    okText: t('common:yes'),
                    cancelText: t('common:no'),
                    onOk() {
                      dispatch(deleteNamingConventions([template.id])).then(() => {
                        resetForm({
                          values: {
                            fileNameTemplates: fileNameTemplates.filter(t => template.id !== t.id),
                          },
                        });
                        setValues({
                          fileNameTemplates: values.fileNameTemplates.filter(
                            (t: FileNameTemplate) => template.id !== t.id
                          ),
                        });
                      });
                    },
                  });
                } else {
                  remove(index);
                }
              };

              const addPart = (code: string, index: number, name: string) => {
                const currentValues = values.fileNameTemplates[index].config;

                setFieldValue(name, `${currentValues}<${code}>`);
              };

              const rowRenderer = ({ key, index, style }: ListRowProps) => {
                const template = values.fileNameTemplates[index];

                return (
                  <div className="channel__naming-convention-row" key={key} style={style}>
                    <Row gutter={20} align="top" className="p-3">
                      <Col span={6}>
                        <FormInput
                          name={`fileNameTemplates[${index}].name`}
                          className={classNames({ borderVsible: !template.name })}
                          placeholder={t('channel:name')}
                        />
                      </Col>
                      <Col span={18}>
                        <div>
                          <div className="flex">
                            <FormInput
                              className={classNames('channel__naming-convention-add flex-1', {
                                borderVsible: !template.config,
                              })}
                              name={`fileNameTemplates[${index}].config`}
                              placeholder={t('channel:configuration')}
                              addonAfter={
                                <Dropdown
                                  menu={{
                                    items: fileNameConfigOptions.map(({ code, name }) => ({
                                      label: name,
                                      key: code,
                                      onClick: e =>
                                        addPart(
                                          e.key.toString(),
                                          index,
                                          `fileNameTemplates[${index}].config`
                                        ),
                                    })),
                                  }}
                                  trigger={['click']}
                                  getPopupContainer={trigger =>
                                    (trigger.parentNode as HTMLElement) || document.body
                                  }
                                >
                                  <Button type="text">
                                    {t('common:add')} <PlusCircleOutlined />
                                  </Button>
                                </Dropdown>
                              }
                            />
                            <Button
                              className="ml-3"
                              onClick={() => deleteTemplate(index)}
                              icon={<DeleteOutlined />}
                              ghost
                              danger
                            >
                              {t('common:delete')}
                            </Button>
                          </div>
                          {template.config && (
                            <div className="pl-3 text-gray-600 text-xs">
                              {namingConventionPreview(template.config, fileNameConfigOptions)}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              };

              return (
                <div className="h-full flex flex-col">
                  <div className="mb-3">
                    <Button
                      type="primary"
                      onClick={() => unshift({ name: '', config: '', default: 0 })}
                      ghost
                    >
                      {t('channel:addNamingConvention')}
                    </Button>
                  </div>

                  <Form
                    className="channel__naming-convention-form flex-1"
                    onFinish={() => handleSubmit()}
                    layout="vertical"
                  >
                    <div className="channel__naming-convention-list h-full flex flex-col">
                      <div className="channel__naming-convention-row bg-gray-200">
                        <Row className="py-2">
                          <Col span={6} className="pl-6">
                            {t('channel:name')}
                          </Col>
                          <Col span={18} className="pl-6">
                            {t('channel:configuration')}
                          </Col>
                        </Row>
                      </div>

                      <div className="flex-1">
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              width={width}
                              height={height}
                              rowCount={values.fileNameTemplates.length}
                              rowRenderer={rowRenderer}
                              rowHeight={85}
                            />
                          )}
                        </AutoSizer>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          />
        );
      }}
    </DrawerFormik>
  );
};

export default NamingConventionDrawer;
