import React from 'react';
import { List, Checkbox, Button, Popover } from 'antd';
import {
  StarTwoTone,
  StarFilled,
  StarOutlined,
  TagsOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import CatalogueAddTag from '../catalogue/CatalogueAddTag';
import QuickfixTitle from './QuickfixTitle';
import QuickfixAction from './QuickfixAction';
import QuickfixListItemRow from './QuickfixListItemRow';
import QuickfixVehicleRow from './QuickfixVehicleRow';
import { AnalysisType } from '../../../../types/resources';
import { BrandItemTag } from '../../../../types/brand_catalogue';
import {
  Analysis,
  AnalysisItem,
  AnalysisVehicleBasis,
  ConfirmedVehicleBase,
} from '../../../../types/analyses';
import {
  NEW_ENGINE_ANALYSIS_ID,
  NEW_TRANSMISSION_ANALYSIS_ID,
} from '../../../constants/InboxConstants';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

type AnalysisQuickFixProps = {
  fetchingReviewCategories: boolean;
  canManageQuickFixes: boolean;
  analysis: Analysis;
  brandItemTags: BrandItemTag[];
  categories: any;
  confirmedVehicleBases: ConfirmedVehicleBase[];
  getAnalysisResource: (typeId: number) => AnalysisType | undefined;
  fetchReviewCategories: (keywords?: string) => void;
  fetchNextReviewCategories: (event: any, keywords: string) => void;
  updateReviewCategory: (
    groupKey: string,
    referenceId: number,
    categoryId: number,
    typeId: number
  ) => void;
  confirmReviews: (Object: {
    typeId: number;
    analysisIds: number[];
    validValueId?: number;
    vehicleBaseIds?: number[];
  }) => void;
  declineReviews: (analysisIds: number[]) => void;
  declineAllReviews: (typeId: number, groupKey: string) => void;
  fetchReviewItems: (groupKey: string) => void;
  setDropdownState: (state: boolean) => void;
  addItemMarks: (itemIds: number[]) => void;
  removeItemMarks: (itemIds: number[]) => void;
  addToTempFilter: (itemIds: number[]) => void;
  handleAddTag: (itemIds: number[], tagName: string) => void;
  handleRemoveTag: (itemIds: number[], tagName: string) => void;
} & WithTranslation &
  WithAntdContextProps;

type AnalysisQuickFixState = {
  selectedIds: number[];
  acceptedIds: number[];
  declinedIds: number[];
  selectedSubConfigId: number;
};

class AnalysisQuickFix extends React.Component<AnalysisQuickFixProps, AnalysisQuickFixState> {
  constructor(props: AnalysisQuickFixProps) {
    super(props);
    const configKey =
      props.analysis.type_id === 209
        ? Object.keys(props.analysis.details_json!).find(key => key.includes('valid'))
        : undefined;
    const selectedSubConfigId =
      props.analysis.type_id === 209 ? props.analysis.details_json![configKey!][0].id : undefined;

    this.state = {
      selectedIds: [],
      acceptedIds: [],
      declinedIds: [],
      selectedSubConfigId,
    };
  }

  selectRow = (id: number) => {
    const { selectedIds } = this.state;
    if (!selectedIds.includes(id)) {
      this.setState({ selectedIds: [...selectedIds, id] });
    } else {
      this.setState({ selectedIds: selectedIds.filter(selectedId => selectedId !== id) });
    }
  };

  selectAllItems = () => {
    const { analysis } = this.props;
    const { selectedIds, acceptedIds, declinedIds } = this.state;
    const itemCount = analysis.items.length - acceptedIds.length - declinedIds.length;

    if (selectedIds.length === itemCount) {
      this.setState({ selectedIds: [] });
    } else {
      this.setState({
        selectedIds: analysis.items
          .filter(item => !acceptedIds.includes(item.id) && !declinedIds.includes(item.id))
          .map(item => item.id),
      });
    }
  };

  selectAllVehicles = () => {
    const { analysis } = this.props;
    const { selectedIds, acceptedIds, declinedIds } = this.state;
    const vehicleBases = analysis.details_json!.vehicle_bases;
    const vehicleCount = vehicleBases.length - acceptedIds.length - declinedIds.length;

    if (selectedIds.length === vehicleCount) {
      this.setState({ selectedIds: [] });
    } else {
      this.setState({
        selectedIds: vehicleBases
          .filter(vehicle => !acceptedIds.includes(vehicle.id) && !declinedIds.includes(vehicle.id))
          .map(vehicle => vehicle.id),
      });
    }
  };

  confirmSelected = () => {
    const { analysis } = this.props;
    const { selectedIds, acceptedIds, selectedSubConfigId } = this.state;
    const analysisIds = analysis.items
      .filter(item => selectedIds.includes(item.id))
      .map(item => item.analysis_ids)
      .reduce((a, b) => a.concat(b), []);

    this.props.confirmReviews({
      typeId: analysis.type_id,
      analysisIds,
      validValueId: selectedSubConfigId,
    });
    this.setState({
      acceptedIds: [...acceptedIds, ...selectedIds],
      selectedIds: [],
    });
  };

  confirmSelectedVehicles = () => {
    const { analysis } = this.props;
    const { selectedIds, acceptedIds } = this.state;
    const analysisIds = analysis.items
      .map(item => item.analysis_ids)
      .reduce((a, b) => a.concat(b), []);

    this.props.confirmReviews({
      typeId: analysis.type_id,
      analysisIds,
      vehicleBaseIds: selectedIds,
    });
    this.setState({
      acceptedIds: [...acceptedIds, ...selectedIds],
      selectedIds: [],
    });
  };

  declineSelected = () => {
    const { analysis } = this.props;
    const { selectedIds, declinedIds } = this.state;
    const analysisIds = analysis.items
      .filter(item => selectedIds.includes(item.id))
      .map(item => item.analysis_ids)
      .reduce((a, b) => a.concat(b), []);

    this.props.declineReviews(analysisIds);
    this.setState({
      declinedIds: [...declinedIds, ...selectedIds],
      selectedIds: [],
    });
  };

  declineAll = () => {
    const { analysis, t } = this.props;
    const { modal } = this.props.appContext;
    const { declinedIds, acceptedIds } = this.state;
    const vehicleBases = analysis.details_json!.vehicle_bases;
    const unhandledVehicleIds = vehicleBases
      .filter(vehicle => !acceptedIds.includes(vehicle.id) && !declinedIds.includes(vehicle.id))
      .map(vehicle => vehicle.id);
    this.setState({ selectedIds: unhandledVehicleIds });

    const handleConfirm = () => {
      this.props.declineAllReviews(analysis.type_id, analysis.group_key!);
      this.setState({
        declinedIds: unhandledVehicleIds,
        selectedIds: [],
      });
    };

    modal.confirm({
      title: t('quickfix:declineWarning'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okButtonProps: { ghost: true },
      okText: t('common:yes'),
      cancelText: t('common:cancel'),
      onOk() {
        handleConfirm();
      },
    });
  };

  handleMarkItem = (itemId: number, marked: boolean) => {
    if (marked) this.props.removeItemMarks([itemId]);
    else this.props.addItemMarks([itemId]);
  };

  handleMarkAll = () => {
    const { analysis } = this.props;
    const markedItemIds = analysis.items.filter(item => item.marked === 1).map(item => item.id);
    const markedItemCount = markedItemIds.length;

    if (analysis.items.length === markedItemCount) {
      this.props.removeItemMarks(markedItemIds);
    } else {
      const unMarkedItemIds = analysis.items.filter(item => item.marked === 0).map(item => item.id);
      this.props.addItemMarks(unMarkedItemIds);
    }
  };

  handleAddTag = (newTagName: string) => {
    const { selectedIds } = this.state;
    this.props.handleAddTag(selectedIds, newTagName);
  };

  handleRemoveTag = (tagName: string) => {
    const { selectedIds } = this.state;
    this.props.handleRemoveTag(selectedIds, tagName);
  };

  addToTempFilterButton = (itemIds = this.state.selectedIds) => {
    const { t, canManageQuickFixes } = this.props;

    return (
      <Button
        size="small"
        onClick={() => this.props.addToTempFilter(itemIds)}
        className="analyses__button-add-session"
        disabled={!canManageQuickFixes || itemIds.length === 0}
      >
        {t('quickfix:addToFilter')}
      </Button>
    );
  };

  showNoReferencePartType = (analysis: Analysis) => (
    <div key={analysis.type_id} className="analyses__content">
      <QuickfixTitle
        analysis={analysis}
        analysisTypeResource={this.props.getAnalysisResource(analysis.type_id)!}
        fetchReviewItems={this.props.fetchReviewItems}
      />
      <div className="analyses__content-inner">
        <div className="analyses__message">{this.props.t('quickfix:noPartTypeRecPossible')}</div>
        {this.addToTempFilterButton(analysis.items.map(item => item.id))}
      </div>
    </div>
  );

  header = (analysis: Analysis) => {
    const { t } = this.props;
    const { selectedIds, acceptedIds, declinedIds } = this.state;
    const items = analysis.items.map(item => ({ ...item, tag_ids: item.tags.map(tag => tag.id) }));
    const selectCount = selectedIds.length;
    const itemCount = analysis.items.length - acceptedIds.length - declinedIds.length;
    const markedCount = analysis.items.filter(item => item.marked === 1).length;
    const isMarked = markedCount === itemCount;
    const newEngineValidation = analysis.type_id === NEW_ENGINE_ANALYSIS_ID;
    const newTransmissionValidation = analysis.type_id === NEW_TRANSMISSION_ANALYSIS_ID;
    const baseVehicleValidation = newEngineValidation || newTransmissionValidation;

    const count = baseVehicleValidation
      ? analysis.details_json!.vehicle_bases.length
      : analysis.items.length;

    return (
      <div>
        <div className="analyses__item-list-header">
          <QuickfixTitle
            analysis={analysis}
            analysisTypeResource={this.props.getAnalysisResource(analysis.type_id)!}
            fetchReviewItems={this.props.fetchReviewItems}
          />
          <QuickfixAction
            fetchingReviewCategories={this.props.fetchingReviewCategories}
            analysis={analysis}
            selectedSubConfigId={this.state.selectedSubConfigId}
            categories={this.props.categories}
            selectSubConfigId={id => this.setState({ selectedSubConfigId: id })}
            updateReviewCategory={this.props.updateReviewCategory}
            setDropdownState={this.props.setDropdownState}
            fetchReviewCategories={this.props.fetchReviewCategories}
            fetchNextReviewCategories={this.props.fetchNextReviewCategories}
          />
        </div>
        <div className="analyses__item-list-header-checkbox">
          {baseVehicleValidation && (
            <Checkbox
              className="analyses__item-list-check"
              indeterminate={
                selectCount > 0 && selectCount < analysis.details_json!.vehicle_bases.length
              }
              checked={
                selectCount > 0 && selectCount === analysis.details_json!.vehicle_bases.length
              }
              onChange={() => this.selectAllVehicles()}
            />
          )}
          {!baseVehicleValidation && (
            <React.Fragment>
              <Checkbox
                className="analyses__item-list-check"
                indeterminate={selectCount > 0 && selectCount < itemCount}
                checked={selectCount > 0 && selectCount === itemCount}
                onChange={() => this.selectAllItems()}
              />
              {isMarked && (
                <StarFilled
                  className="analyses__item-list-star star marked"
                  onClick={() => this.handleMarkAll()}
                />
              )}
              {markedCount > 0 && !isMarked && (
                <StarTwoTone
                  className="analyses__item-list-star star"
                  twoToneColor="#fadb14" // SunriseYellow
                  onClick={() => this.handleMarkAll()}
                />
              )}
              {markedCount === 0 && (
                <StarOutlined
                  className="analyses__item-list-star star"
                  twoToneColor="#fadb14" // SunriseYellow
                  onClick={() => this.handleMarkAll()}
                />
              )}
              <Popover
                trigger="click"
                content={
                  <CatalogueAddTag
                    id={analysis.group_key}
                    brandItemTags={this.props.brandItemTags}
                    checkedItemIds={this.state.selectedIds}
                    checkedItems={items}
                    handleAddTag={this.handleAddTag}
                    handleRemoveTag={this.handleRemoveTag}
                  />
                }
              >
                <Button
                  className="anlayses__tag-button"
                  size="small"
                  icon={<TagsOutlined />}
                  disabled={!selectedIds.length}
                >
                  {t('quickfix:tag')}
                </Button>
              </Popover>
            </React.Fragment>
          )}
          <span className="text-gray-600">
            {baseVehicleValidation && `${count} (${t('quickfix:application', { count })})`}
            {!baseVehicleValidation && (
              <span className="ml-2">{`${count} (${t('quickfix:item', { count })})`}</span>
            )}
          </span>
        </div>
      </div>
    );
  };

  footer = () => {
    const { analysis, canManageQuickFixes, t } = this.props;
    const { selectedIds } = this.state;

    const isBaseVehicleValidation = [NEW_ENGINE_ANALYSIS_ID, NEW_TRANSMISSION_ANALYSIS_ID].includes(
      analysis.type_id
    );

    return (
      <div className="analyses__item-button-wrapper">
        <Button
          type="primary"
          ghost
          size="small"
          className="analyses__button-confirm"
          onClick={() =>
            isBaseVehicleValidation ? this.confirmSelectedVehicles() : this.confirmSelected()
          }
          disabled={!canManageQuickFixes || selectedIds.length === 0}
        >
          {t('common:confirm')}
        </Button>
        {!isBaseVehicleValidation && (
          <React.Fragment>
            <Button
              danger
              ghost
              size="small"
              className="analyses__button-decline"
              onClick={() => this.declineSelected()}
              disabled={!canManageQuickFixes || selectedIds.length === 0}
            >
              {t('common:decline')}
            </Button>
            {this.addToTempFilterButton()}
          </React.Fragment>
        )}
        {isBaseVehicleValidation && (
          <Button
            danger
            ghost
            size="small"
            className="analyses__button-decline"
            onClick={() => this.declineAll()}
            disabled={!canManageQuickFixes}
          >
            {t('quickfix:declineAll')}
          </Button>
        )}
      </div>
    );
  };

  item = (item: AnalysisItem) => {
    const { selectedIds, acceptedIds, declinedIds } = this.state;
    return (
      <QuickfixListItemRow
        item={item}
        isAccepted={acceptedIds.includes(item.id)}
        isDeclined={declinedIds.includes(item.id)}
        isMarked={item.marked === 1}
        isSelected={selectedIds.includes(item.id)}
        handleMarkItem={this.handleMarkItem}
        selectItemRow={this.selectRow}
      />
    );
  };

  vehicle = (vehicle: AnalysisVehicleBasis) => {
    const { confirmedVehicleBases, analysis } = this.props;
    const { selectedIds, acceptedIds, declinedIds } = this.state;
    const isAccepted = acceptedIds.includes(vehicle.id);
    const itemAnalysisId = analysis.items[0].analysis_ids[0];

    return (
      <QuickfixVehicleRow
        vehicle={vehicle}
        isAccepted={isAccepted}
        isDeclined={declinedIds.includes(vehicle.id)}
        isSelected={selectedIds.includes(vehicle.id)}
        confirmedBase={confirmedVehicleBases.find(
          confirmedBase =>
            isAccepted &&
            confirmedBase.vehicle_base_id === vehicle.id &&
            confirmedBase.itemAnalysisId === itemAnalysisId
        )}
        itemId={analysis.items[0].id}
        selectVehicleRow={this.selectRow}
      />
    );
  };

  render() {
    const { analysis } = this.props;
    if (!analysis.reference_id) return this.showNoReferencePartType(analysis);
    const baseVehicleValidation = [NEW_ENGINE_ANALYSIS_ID, NEW_TRANSMISSION_ANALYSIS_ID].includes(
      analysis.type_id
    );
    const dataSource = baseVehicleValidation
      ? analysis.details_json!.vehicle_bases
      : analysis.items;

    return (
      <List
        className="analyses__item-list"
        bordered
        size="small"
        header={this.header(analysis)}
        footer={this.footer()}
        // @ts-ignore
        dataSource={dataSource}
        // @ts-ignore
        renderItem={item => (baseVehicleValidation ? this.vehicle(item) : this.item(item))}
      />
    );
  }
}

export default withTranslation()(withAntdContext(AnalysisQuickFix));
