import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChannelData } from '../../../../types/channel';
import ShowChannelLogsButton from './ShowChannelLogsButton';
import EditChannelTable from './EditChannelTable';
import { ApplicationState } from '../../../reducers';
import { updateChannelWalmart } from '../../../actions/channel/update';
import { AsyncDispatch } from '../../../../types/global';
import { dayjsTimeStamp } from '../../../utils/Utils';
import { fetchWalmartBrands } from '../../../actions/channel/fetch';
import { mapTempStatusToWalmartData } from '../../../selectors/channel/channelSelector';

type EditChannelWalmartProps = {
  channelType: string;
};

const EditChannelWalmart: React.FC<EditChannelWalmartProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const [searchKey, setSearchkey] = React.useState('');
  const navigate = useNavigate();

  const { walmartData, fetchingChannelWalmart, lastConnection, updatingWalmart } = useSelector(
    (state: ApplicationState) => ({
      walmartData: mapTempStatusToWalmartData(state) || [],
      lastConnection: state.channel.channels.channelWalmartData?.last_connection || null,
      fetchingChannelWalmart: state.channel.channels.fetchingChannelWalmart,
      updatingWalmart: state.channel.channels.updatingWalmart,
    })
  );

  const handleBrandSearch = (keywords: string | '') => {
    setSearchkey(keywords);
    dispatch(fetchWalmartBrands(keywords));
  };

  const handleShowLogs = () => {
    navigate('/distribution/history', {
      state: { searchKeyword: 'walmart' },
    });
  };

  const updateChannelWalmartValues = (updatedRecord: ChannelData) => {
    const updatedValues = [updatedRecord];
    dispatch(updateChannelWalmart(updatedValues)); // direct save
  };

  return (
    <React.Fragment>
      <div className="h-full flex flex-col">
        <div className="mx-10 my-10">
          <ShowChannelLogsButton
            handleShowLogs={handleShowLogs}
            timeStamp={lastConnection ? dayjsTimeStamp(lastConnection) : undefined}
            confirmText={t('channel:switchWalmartToHistory')}
          />
        </div>
        <div className="overflow-hidden flex-1 overflow-auto mx-10">
          <EditChannelTable
            data={walmartData}
            handleShowProductDataDrawer={() => {}}
            updateChannel={updateChannelWalmartValues}
            handleBrandSearch={handleBrandSearch}
            searchKey={searchKey}
            fetchingChannel={fetchingChannelWalmart}
            channelType={props.channelType}
            updatePending={updatingWalmart}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditChannelWalmart;
