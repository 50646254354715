import React from 'react';
import { Select, Button } from 'antd';
import { AvailableReceiver } from '../../../types/receiver';
import { AvailableChannel } from '../../../types/channel';
import { FilterCustomReference, FilterCustomParameterGo } from '../../../types/filter';
import constants from '../../constants/FilterTranslation.json';
import headerConstants from '../../constants/HeaderTranslation.json';

const { Option, OptGroup } = Select;

type FilterChannelReceiverProps = {
  availableChannels: AvailableChannel[];
  availableReceivers: AvailableReceiver[];
  createParameter: (params: {
    customRefId: number;
    optionId: number | null;
    refId?: number;
    value?: number;
  }) => void;
  updateParameterOption: (params: {
    referenceId: number | null;
    filterCustomReferenceId: number;
    optionId: number;
  }) => void;
  deleteFilterCustomParameter: (
    filterCustomReferenceId: number,
    referenceId: number | null
  ) => Promise<any>;
  parametersSubmittedChannel: FilterCustomParameterGo[];
  parametersSubmittedReceiver: FilterCustomParameterGo[];
  parametersOnlineChannel: FilterCustomParameterGo[];
  parametersOnlineReceiver: FilterCustomParameterGo[];
};

type FilterChannelReceiverState = {
  selectedReceiverId: null | number;
  selectedChannelId: null | number;
  isSubmitted: null | boolean;
  isOnline: null | boolean;
};

const getInitialState = (props: FilterChannelReceiverProps) => {
  const submittedParameter =
    props.parametersSubmittedChannel[0] || props.parametersSubmittedReceiver[0];
  const onlineParameter = props.parametersOnlineChannel[0] || props.parametersOnlineReceiver[0];
  const referenceChannel = props.parametersOnlineChannel[0] || props.parametersSubmittedChannel[0];
  const referenceReceiver =
    props.parametersOnlineReceiver[0] || props.parametersSubmittedReceiver[0];

  const isSubmitted = submittedParameter ? submittedParameter.option_id === 3 : null;
  const isOnline = onlineParameter ? onlineParameter.option_id === 1 : null;
  const selectedChannelId = referenceChannel ? referenceChannel.reference_id : null;
  const selectedReceiverId = referenceReceiver ? referenceReceiver.reference_id : null;

  return {
    selectedReceiverId,
    selectedChannelId,
    isSubmitted,
    isOnline,
  };
};

const filterReducer = (state: FilterChannelReceiverState, action: any) => {
  switch (action.type) {
    case 'SELECT_RECEIVER_CHANNEL': {
      return {
        ...state,
        selectedReceiverId: action.payload.type === 'receiver' ? action.payload.id : null,
        selectedChannelId: action.payload.type === 'channel' ? action.payload.id : null,
      };
    }
    case 'SELECT_SUBMITTED_STATUS': {
      return {
        ...state,
        isSubmitted: action.payload === state.isSubmitted ? null : action.payload,
      };
    }
    case 'SELECT_ONLINE_STATUS': {
      return {
        ...state,
        isOnline: action.payload === state.isOnline ? null : action.payload,
      };
    }
    case 'RESET_RECEIVER_CHANNEL_FILTER': {
      return {
        isSubmitted: null,
        isOnline: null,
        selectedReceiverId: null,
        selectedChannelId: null,
      };
    }
    default:
      return state;
  }
};

const FilterChannelReceiver: React.FC<FilterChannelReceiverProps> = props => {
  const [state, dispatch] = React.useReducer(filterReducer, getInitialState(props));
  const { selectedChannelId, selectedReceiverId, isSubmitted, isOnline } = state;
  const filterDisabled = !selectedReceiverId && !selectedChannelId;

  React.useEffect(() => {
    const relevantParameter =
      props.parametersSubmittedChannel[0] || props.parametersSubmittedReceiver[0];
    const val = relevantParameter ? relevantParameter.option_id === 3 : null;
    if (val !== isSubmitted) {
      dispatch({ type: 'SELECT_SUBMITTED_STATUS', payload: val });
    }
  }, [isSubmitted, props.parametersSubmittedChannel, props.parametersSubmittedReceiver]);

  React.useEffect(() => {
    const relevantParameter = props.parametersOnlineChannel[0] || props.parametersOnlineReceiver[0];
    const val = relevantParameter ? relevantParameter.option_id === 1 : null;
    if (val !== isOnline) {
      dispatch({ type: 'SELECT_ONLINE_STATUS', payload: val });
    }
  }, [isOnline, props.parametersOnlineChannel, props.parametersOnlineReceiver]);

  const deleteAllParameters = async () => {
    if (props.parametersSubmittedChannel[0]) {
      const param = props.parametersSubmittedChannel[0];
      await props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    }
    if (props.parametersSubmittedReceiver[0]) {
      const param = props.parametersSubmittedReceiver[0];
      await props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    }
    if (props.parametersOnlineChannel[0]) {
      const param = props.parametersOnlineChannel[0];
      await props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    }
    if (props.parametersOnlineReceiver[0]) {
      const param = props.parametersOnlineReceiver[0];
      await props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    }
  };

  const handleChannelReceiverClick = (val: string) => {
    deleteAllParameters();
    dispatch({ type: 'RESET_RECEIVER_CHANNEL_FILTER' });
    if (val) {
      const [type, id] = val.split(':');
      const intId = parseInt(id, 10);
      dispatch({ type: 'SELECT_RECEIVER_CHANNEL', payload: { type, id: intId } });
    }
  };

  const handleSubmittedClick = (val: boolean) => {
    dispatch({ type: 'SELECT_SUBMITTED_STATUS', payload: val });

    const optionId = val ? 3 : 5;

    if (selectedChannelId && !props.parametersSubmittedChannel[0]) {
      props.createParameter({
        customRefId: FilterCustomReference.SUBMITTED_CHANNEL,
        refId: selectedChannelId,
        optionId,
      });
    } else if (selectedReceiverId && !props.parametersSubmittedReceiver[0]) {
      props.createParameter({
        customRefId: FilterCustomReference.SUBMITTED_RECEIVER,
        refId: selectedReceiverId,
        optionId,
      });
    } else if (val === isSubmitted) {
      const param = selectedChannelId
        ? props.parametersSubmittedChannel[0]
        : props.parametersSubmittedReceiver[0];
      props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    } else {
      const parameter = selectedChannelId
        ? props.parametersSubmittedChannel[0]
        : props.parametersSubmittedReceiver[0];
      props.updateParameterOption({
        referenceId: parameter.reference_id,
        filterCustomReferenceId: parameter.filter_custom_reference_id,
        optionId,
      });
    }
  };

  const handleOnlineClick = (val: boolean) => {
    dispatch({ type: 'SELECT_ONLINE_STATUS', payload: val });
    const optionId = val ? 1 : 2;

    if (selectedChannelId && !props.parametersOnlineChannel[0]) {
      props.createParameter({
        customRefId: FilterCustomReference.ONLINE_CHANNEL,
        refId: selectedChannelId,
        optionId,
        value: 1,
      });
    } else if (selectedReceiverId && !props.parametersOnlineReceiver[0]) {
      props.createParameter({
        customRefId: FilterCustomReference.ONLINE_RECEIVER,
        refId: selectedReceiverId,
        optionId,
        value: 1,
      });
    } else if (val === isOnline) {
      const param = selectedChannelId
        ? props.parametersOnlineChannel[0]
        : props.parametersOnlineReceiver[0];
      props.deleteFilterCustomParameter(param.filter_custom_reference_id, param.reference_id);
    } else {
      const customRefId = selectedChannelId
        ? FilterCustomReference.ONLINE_CHANNEL
        : FilterCustomReference.ONLINE_RECEIVER;
      const refId = selectedChannelId || selectedReceiverId;
      props.updateParameterOption({
        referenceId: refId,
        filterCustomReferenceId: customRefId,
        optionId,
      });
    }
  };

  const getSelectedChannelReceiver = () => {
    if (selectedChannelId) return `channel:${selectedChannelId}`;
    if (selectedReceiverId) return `receiver:${selectedReceiverId}`;
  };

  return (
    <React.Fragment>
      <div className="title">{constants.channelsReceivers}</div>
      <div style={{ padding: 20, marginBottom: 5 }} className="box box_background">
        <div className="flex justify-between">
          <Select
            placeholder={constants.channelsReceivers}
            onChange={(val: string) => handleChannelReceiverClick(val)}
            value={getSelectedChannelReceiver()}
            allowClear
            style={{ width: 260 }}
            options={[
              {
                key: 'channels',
                label: 'Channels',
                options: props.availableChannels.map(({ id, name }: AvailableChannel) => ({
                  key: id,
                  value: `channel:${id}`,
                  label: name,
                })),
              },
              {
                key: 'receivers',
                label: 'Receivers',
                options: props.availableReceivers.map(({ id, name }: AvailableReceiver) => ({
                  key: id,
                  value: `receiver:${id}`,
                  label: name,
                })),
              },
            ]}
          />
          <Button.Group>
            <Button
              type={isSubmitted !== null && isSubmitted === false ? 'primary' : 'default'}
              onClick={() => handleSubmittedClick(false)}
              disabled={filterDisabled}
            >
              {headerConstants.filter_custom.not_submitted}
            </Button>
            <Button
              type={isSubmitted ? 'primary' : 'default'}
              onClick={() => handleSubmittedClick(true)}
              disabled={filterDisabled}
            >
              {headerConstants.filter_custom.submitted}
            </Button>
          </Button.Group>
          <Button.Group>
            <Button
              type={isOnline !== null && isOnline === false ? 'primary' : 'default'}
              onClick={() => handleOnlineClick(false)}
              disabled={filterDisabled}
            >
              {headerConstants.filter_custom.offline}
            </Button>
            <Button
              type={isOnline ? 'primary' : 'default'}
              onClick={() => handleOnlineClick(true)}
              disabled={filterDisabled}
            >
              {headerConstants.filter_custom.online}
            </Button>
          </Button.Group>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterChannelReceiver;
