import React from 'react';
import { Drawer } from 'antd';
import Button from '../../../global/Button';
import DigitalAssetInformationAsset from './DigitalAssetInformationAsset';
import DigitalAssetInformationItem from './DigitalAssetInformationItem';
import Description from '../Description';
import DigitalAssetEdit from '../../media_and_marketing_copy/digital_asset/DigitalAssetEdit';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';

const { information_asset: translation } = digitalAssetConstants;

class DigitalAssetInformation extends React.Component {
  state = {
    showDrawer: false,
  };

  handleViewEditDetails = () => {
    this.setState({ showDrawer: true });
  };

  handleEditDetailsClose = () => {
    this.setState({ showDrawer: false });
  };

  render() {
    const {
      digitalAssetResources,
      selectedDigitalAsset,
      digitalAssetDescriptions,
      languages,
      countries,
    } = this.props;
    if (!selectedDigitalAsset) {
      return <div className="digital-asset-information" />;
    }
    return (
      <div className="digital-asset-information">
        <div className="digital-asset-information__top scrollable">
          <DigitalAssetInformationItem
            selectedDigitalAsset={selectedDigitalAsset}
            digitalAssetResources={digitalAssetResources}
            updateItemInformation={this.props.updateItemInformation}
            area={this.props.area}
          />
          {this.props.area === 'item' && (
            <Description
              digitalAssetDescriptions={digitalAssetDescriptions}
              languages={languages}
              descriptionTypes={digitalAssetResources.description_types}
              selectedDigitalAsset={selectedDigitalAsset}
              createDescription={this.props.createDescription}
              updateDescriptionContents={this.props.updateDescriptionContents}
              deleteDescription={this.props.deleteDescription}
              defaultLanguageId={this.props.defaultLanguageId}
            />
          )}
          <DigitalAssetInformationAsset
            area={this.props.area}
            selectedDigitalAsset={selectedDigitalAsset}
            digitalAssetResources={digitalAssetResources}
            updateAssetInformation={this.props.updateAssetInformation}
          />
        </div>
        <div className="digital-asset-information__bottom">
          <Button
            name={translation.view_edit}
            color="blue"
            hover
            onClick={this.handleViewEditDetails}
          />
        </div>
        <Drawer
          title="Digital Asset Detail"
          width="70vw"
          placement="right"
          onClose={this.handleEditDetailsClose}
          open={this.state.showDrawer}
        >
          <DigitalAssetEdit
            selectedDigitalAsset={selectedDigitalAsset}
            updateDigitalAssetParent={this.props.updateAssetInformation}
            digitalAssetResources={digitalAssetResources}
            languages={languages}
            countries={countries}
            canManageDigitalAssets
          />
        </Drawer>
      </div>
    );
  }
}

export default DigitalAssetInformation;
