import React from 'react';
import { Tag, Select } from 'antd';
import { ExtendedResources } from '../../../../types/resources';

const { CheckableTag } = Tag;

type ExpiCountryTagSelectProps = {
  label: string;
  typeValues: string[];
  options: ExtendedResources[];
  handleChange: (option: string[]) => void;
  countries: ExtendedResources[];
  placeholder?: string;
};

const ExpiCountryTagSelect: React.FC<ExpiCountryTagSelectProps> = props => {
  const { label, countries, placeholder, options, handleChange, typeValues } = props;

  return (
    <div className="extended-info__checkable-tags flex items-center flex-1">
      <div className="extended-info__label pl-3 self-start flex-1">{label}</div>
      <div className="extended-info__tags-wrapper flex flex-1 items-center">
        <div className="w-auto extended-info__checkable-tag_blue">
          {options.map(option => (
            <CheckableTag
              key={option.code}
              checked={typeValues.includes(option.code!)}
              onChange={() =>
                typeValues.includes(option.code!) ? handleChange([]) : handleChange([option.code!])
              }
            >
              {`${option.code} - ${option.name}`}
            </CheckableTag>
          ))}
        </div>
        <div className="flex-grow self-start">
          <Select
            size="small"
            mode="multiple"
            placeholder={placeholder}
            showSearch
            value={typeValues[0]}
            optionFilterProp="label"
            onChange={(value: any) => {
              value.length > 0 ? handleChange([value[value.length - 1]]) : handleChange([]);
            }}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            style={{ minWidth: '210px' }}
            options={countries.map(country => ({
              key: country.code,
              value: country.code!,
              label: country.name,
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpiCountryTagSelect;
