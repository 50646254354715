import React from 'react';
import { Checkbox, Alert, Form, Button, Input, Collapse, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import { get as _get } from 'lodash';
import PageFormik from '../../global/page/PageFormik';
import InputWithPreview from '../../global/InputWithPreview';
import SelectWithPreview from '../../global/SelectWithPreview';
import {
  MappedItemCustomFields,
  CustomFieldType,
  CustomFieldOption,
  ItemCustomFieldGroup,
  CustomFieldGroup,
} from '../../../../types/custom_fields';
import Page from '../../global/page/Page';
import { validUrlRegExp } from '../../../utils/Utils';
import externalLink from '../../../../images/icons/external-link.svg';
import JoditHtmlEditor from '../../../containers/settings/JoditHtmlEditor';

type CustomFieldsItemTableProps = {
  fetchingData: boolean;
  customFields: MappedItemCustomFields[];
  brandId: number;
  groups: ItemCustomFieldGroup[];
  updateCustomFields: (values: any) => Promise<any>;
  runScript: (scriptId: number) => Promise<any>;
};

const CustomFieldsItemTable: React.FC<CustomFieldsItemTableProps> = ({
  fetchingData,
  customFields,
  brandId,
  groups,
  updateCustomFields,
  runScript,
}) => {
  const { t } = useTranslation();

  const [runningScriptIds, setRunningScriptIds] = React.useState<number[]>([]);
  const [keywords, setKeywords] = React.useState('');

  const addRunningId = (id: number) => setRunningScriptIds([...runningScriptIds, id]);
  const removeRunningId = (id: number) =>
    setRunningScriptIds(runningScriptIds => runningScriptIds.filter(rId => rId !== id));

  const handleKeywordSearch = (filterKeywords: string) => {
    setKeywords(filterKeywords);
  };

  const getValidation = () => {
    return Yup.object().shape({
      customFields: Yup.array().of(
        Yup.object().shape({
          custom_field_type_id: Yup.number(),
          value: Yup.string().when('custom_field_type_id', {
            is: (value: number) => value === CustomFieldType.Link,
            then: schema => schema.matches(validUrlRegExp, t('validation:enterValidUrl')),
          }),
        })
      ),
    });
  };

  return customFields.length === 0 ? (
    <Page showExpandButton>
      <Alert
        message={t('segments:customFields.infoTitle')}
        description={
          <div>
            <Trans i18nKey="segments:customFields.goToSettings">
              Go to{' '}
              <Link
                to={{
                  pathname: brandId ? '/brand/settings/custom-fields' : '/settings/custom-fields',
                  search: brandId ? `brandId=${brandId}` : '',
                }}
              >
                Settings
              </Link>
              for adding and configuring your custom fields.
            </Trans>
          </div>
        }
        type="info"
      />
    </Page>
  ) : (
    <PageFormik
      initialValues={{ groups }}
      fetchingData={fetchingData}
      renderHeader={() => (
        <div className="flex flex-1 ml-1">
          <Input.Search
            className="cfs__item-top-bar-search"
            value={keywords}
            onChange={e => handleKeywordSearch(e.target.value)}
            placeholder={t('settings:customFields.searchCustomFields')}
            size="small"
            allowClear
          />
        </div>
      )}
      onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
        setSubmitPending();
        updateCustomFields(values)
          .then(() => setSubmitSuccess())
          .catch(() => setSubmitError());
      }}
      contentNoSpacing
      showExpandButton
      validationSchema={getValidation()}
      enableReinitialize
    >
      {({ setFieldValue, setFieldTouched, validateForm, values, dirty, errors, touched }) => {
        const filteredGroups = keywords
          ? values.groups.map((g: CustomFieldGroup) => {
              const filteredFields = g.fields.filter(({ name }) =>
                name.toLowerCase().includes(keywords.toLowerCase())
              );
              return { ...g, fields: filteredFields };
            })
          : values.groups;

        const nameCellRenderer = (value: any, record: MappedItemCustomFields) => {
          return (
            <>
              {value}
              {!!record.dynamic_configuration && (
                <Tooltip title={t('settings:customFields.dynamicFieldInfo')} className="align-top">
                  <InfoCircleOutlined className="ant-info-tooltip pl-2" />
                </Tooltip>
              )}
            </>
          );
        };

        const columnCellRenderer = (
          value: any,
          record: MappedItemCustomFields,
          index: number,
          groupIndex: number
        ) => {
          const fullDataRowIndex = values.groups[groupIndex].fields.findIndex(
            (f: MappedItemCustomFields) => f.id === record.id
          );
          const cutomFieldTypeId = record.custom_field_type_id;
          const formikIndex = `groups[${groupIndex}].fields[${fullDataRowIndex}].value`;

          return (
            <div>
              {cutomFieldTypeId === CustomFieldType.Input && (
                <InputWithPreview
                  value={value}
                  onChange={e => setFieldValue(formikIndex, e.target.value)}
                  showCount
                  disabled={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Number && (
                <InputWithPreview
                  type="number"
                  value={value}
                  onChange={e => setFieldValue(formikIndex, e.target.value)}
                  showCount
                  disabled={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Dropdown && (
                <SelectWithPreview
                  value={value}
                  onChange={(val: string | undefined) => setFieldValue(formikIndex, val || '')}
                  allowClear={record.read_only_custom_field === 1}
                  disabled={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                  options={record.options.map((option: CustomFieldOption) => ({
                    key: option.id,
                    value: option.name,
                    label: option.name,
                  }))}
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Checkbox && (
                <Checkbox
                  style={{ marginLeft: 12 }}
                  checked={value === '1'}
                  onChange={e => setFieldValue(formikIndex, e.target.checked ? '1' : '')}
                  disabled={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Text_Editor && (
                <JoditHtmlEditor
                  htmlText={value}
                  dirty={dirty}
                  handleOnChange={html => {
                    setFieldValue(formikIndex, html);
                  }}
                  readonly={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                />
              )}

              {cutomFieldTypeId === CustomFieldType.Link && (
                //  use lodash because name can include dots eg. foo.bar
                <div className="flex flex-row mt-px cf__https">
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      _get(errors, formikIndex) && _get(touched, formikIndex) ? 'error' : ''
                    }
                    help={(_get(touched, formikIndex) && _get(errors, formikIndex)) || undefined}
                    className="flex-1"
                  >
                    <InputWithPreview
                      placeholder="https://"
                      value={value}
                      onChange={e => {
                        setFieldValue(formikIndex, e.target.value);
                      }}
                      displayError={!!(_get(errors, formikIndex) && _get(touched, formikIndex))}
                      onBlur={() => {
                        setFieldTouched(formikIndex);
                        validateForm();
                      }}
                      showCount
                      disabled={
                        record.read_only_custom_field === 1 || !!record.dynamic_configuration
                      }
                    />
                  </Form.Item>
                  {validUrlRegExp.test(value) && (
                    <Button
                      className="cfs__external-link ml-1"
                      type="link"
                      href={value.startsWith('http') ? value : `//${value}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      icon={<img src={externalLink} alt="" />}
                      disabled={
                        record.read_only_custom_field === 1 || !!record.dynamic_configuration
                      }
                    />
                  )}
                </div>
              )}
              {cutomFieldTypeId === CustomFieldType.Button && (
                <Button
                  onClick={async () => {
                    addRunningId(Number(record.id));
                    await runScript(record.script_id!);
                    removeRunningId(Number(record.id));
                  }}
                  loading={runningScriptIds.includes(Number(record.id))}
                  disabled={record.read_only_custom_field === 1 || !!record.dynamic_configuration}
                >
                  {t('api:runScript')}
                </Button>
              )}
            </div>
          );
        };

        return (
          <Collapse
            className="cf__collapse"
            defaultActiveKey={groups.map(g => g.id)}
            onChange={() => {}}
          >
            {filteredGroups.map((group: ItemCustomFieldGroup, groupIndex: number) => (
              <Collapse.Panel header={group.name} key={group.id}>
                <Table
                  dataSource={group.fields}
                  rowKey="id"
                  pagination={false}
                  size="small"
                  locale={{ emptyText: t('common:noData') }}
                  className={group.id === 'sharedbyBrand' ? 'cf__sharedbyBrand' : undefined}
                >
                  <Table.Column
                    title={t('common:name')}
                    dataIndex="name"
                    render={(value: any, record: MappedItemCustomFields) =>
                      nameCellRenderer(value, record)
                    }
                    width={300}
                  />
                  <Table.Column
                    title={t('common:value')}
                    dataIndex="value"
                    render={(value: any, record: MappedItemCustomFields, index: number) =>
                      columnCellRenderer(value, record, index, groupIndex)
                    }
                  />
                </Table>
              </Collapse.Panel>
            ))}
          </Collapse>
        );
      }}
    </PageFormik>
  );
};

export default CustomFieldsItemTable;
