import React from 'react';
import { Checkbox } from 'antd';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';

const { representation } = digitalAssetConstants;

class Representations extends React.Component {
  render() {
    const { representationTypeId } = this.props;

    const isActual = representationTypeId === 1;
    const isRepresentative = representationTypeId === 2;

    return (
      <div className="digital-assets__representation">
        <span>{representation.representation}*</span>
        <div>
          <label htmlFor="true">
            <Checkbox
              checked={isActual}
              onChange={() => this.props.setFileUploadInformation('representationTypeId', 1)}
            />
            <span className="ml-1"> {representation.actual}</span>
          </label>
          <label htmlFor="true" className="ml-2">
            <Checkbox
              checked={isRepresentative}
              onChange={() => this.props.setFileUploadInformation('representationTypeId', 2)}
            />
            <span className="ml-1"> {representation.representative}</span>
          </label>
        </div>
      </div>
    );
  }
}

export default Representations;
