import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AsyncDispatch } from '../../../../types/global';
import { resetFilter as resetFilterAction } from '../../../actions/catalogue/filter/update';
import { FilterTypeGo } from '../../../../types/filter';
import { DefaultValue } from '../../../../types/brand_settings';
import { isFilterEmpty } from '../../../utils/FilterUtils';
import { updateClientFilterBrandIds } from '../../../actions/catalogue/catalogue/update';
import { fetchItemsByFilterId } from '../../../actions/catalogue/catalogue/fetch';
import AntTooltip from '../../global/AntTooltip';
import { ApplicationState } from '../../../reducers';
import {
  getAllProductsFilter,
  getTempProductsFilter,
} from '../../../selectors/catalogue/filterSelector';
import { isManufacturer } from '../../../utils/UserUtils';
import { fetchAndSelectFilter, fetchFilterGo } from '../../../actions/catalogue/filter/fetch';
import { brandAccDefaultValues } from '../../../selectors/default_values/defaultValuesSelector';

export type CatalogueResetAllFiltersButtonProps = {
  selectedFilterGo?: FilterTypeGo;
  className?: string;
  disabled?: boolean;
  showText?: boolean;
  goOnly: boolean;
};

export const CatalogueResetFilterButton: React.FC<CatalogueResetAllFiltersButtonProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tempProductsFilter, allProductsFilter, manufacturer, defaultValues } = useSelector(
    (state: ApplicationState) => ({
      tempProductsFilter: getTempProductsFilter(state),
      allProductsFilter: getAllProductsFilter(state),
      manufacturer: state.user.user && isManufacturer(state.user.user),
      defaultValues: brandAccDefaultValues(state),
    })
  );

  const [isResetting, setResetting] = useState(false);

  const resetFilter = () => {
    if (!props.selectedFilterGo) {
      return;
    }
    setResetting(true);
    const defLanguage = defaultValues.find((d: DefaultValue) => d.resource_table === 'languages');
    const languageId = defLanguage?.value;
    dispatch(updateClientFilterBrandIds([]));
    return dispatch(resetFilterAction(props.selectedFilterGo.id))
      .then(() => {
        if (props.selectedFilterGo?.id === tempProductsFilter.id) {
          if (!props.goOnly) dispatch(fetchAndSelectFilter(allProductsFilter.id));
          // dispatch(fetchFilterGo(allProductsFilter.id));
          dispatch(fetchItemsByFilterId({ filterId: allProductsFilter.id, languageId }));

          const urlParams = new URLSearchParams(location.search);
          urlParams.delete('filterId');

          navigate(
            manufacturer ? `?${urlParams.toString()}` : '/available-brands/all-products/item'
          );
        } else {
          // dispatch(fetchFilterGo(props.selectedFilterGo!.id));
          return dispatch(
            fetchItemsByFilterId({ filterId: props.selectedFilterGo!.id, languageId })
          );
        }
      })
      .finally(() => setResetting(false));
  };

  const selectedFilterIsEmpty = props.selectedFilterGo && isFilterEmpty(props.selectedFilterGo);

  return !!props.selectedFilterGo && !selectedFilterIsEmpty ? (
    <div className={classNames(props.className)}>
      <AntTooltip title={t('catalogue:resetFilter.title')}>
        <Button
          loading={isResetting}
          onClick={() => resetFilter()}
          disabled={!!props.disabled}
          size="small"
          icon={<CloseOutlined />}
        >
          {props.showText && 'Reset Filter'}
        </Button>
      </AntTooltip>
    </div>
  ) : null;
};
