import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';

const { placeholder } = digitalAssetConstants;

class SourceURL extends React.Component {
  render() {
    // const missingUrl = this.props.submitError.sourceUrl;
    const missingUrl = false;
    const classN = missingUrl
      ? 'digital-assets__add-source-input_error'
      : 'digital-assets__add-source-input';
    return (
      <div className="digital-assets__add-source">
        <p className="digital-assets__add-source-title">{placeholder.add_source}</p>
        <div className="digital-assets__add-source-input-wrapper">
          <input
            className={classN}
            type="url"
            value={this.props.sourceUrl}
            onChange={e => this.props.setSourceUrl(e.target.value)}
            placeholder={placeholder.url}
          />
          <CloseOutlined
            className="application__search_button"
            onClick={() => this.props.setSourceUrl('')}
          />
        </div>
      </div>
    );
  }
}

export default SourceURL;
