import React from 'react';
import { Button, Divider, Tabs } from 'antd';
import classNames from 'classnames';
import helpCenterConstants from '../../../constants/HelpCenterConstants.json';
import knowledgeBase from '../../../../images/icons/knowledgeBase.svg';

const { url: helpCenterUrl } = helpCenterConstants;

type HeaderPageProps = {
  title: string;
  description?: string;
  helpArticle?: {
    name: string;
    path: string;
  };
  actionButtons?: JSX.Element;
  tabs?: { id: number; name: string; content: JSX.Element }[];
  selectedTabKey?: number;
  handleSelectTab?: (key: number) => void;
};

const HeaderPage: React.FC<HeaderPageProps> = props => {
  const { title, description, helpArticle, actionButtons, tabs, selectedTabKey } = props;

  const [small, setSmall] = React.useState(false);

  const contentRef = React.useRef<HTMLDivElement>(null);
  const refs = React.useRef<any>([]);

  React.useEffect(() => {
    const node = contentRef.current || refs.current[(selectedTabKey || 1) - 1];

    const handleScroll = () => {
      if (node) {
        if (node.scrollTop > 200 && !small) setSmall(true);
        if (node.scrollTop < 100 && small) setSmall(false);
      }
    };

    if (node) {
      node.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (node) node.removeEventListener('scroll', handleScroll);
    };
  }, [selectedTabKey, small, refs]);

  const openHelpArticle = (path: string) => {
    const wnd = window.open(helpCenterUrl + path, '_blank', 'noopener,noreferrer');
    if (wnd) wnd.opener = null;
  };

  return (
    <div className="flex-col page-header">
      <div className="flex items-center">
        <div className="page-header__details flex flex-row justify-between mx-10 pt-3">
          <div>
            <div className="page-header__title">{title}</div>
            {description && !small && <div className="page-header__description">{description}</div>}
            {helpArticle && !small && (
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  openHelpArticle(helpArticle.path);
                }}
                className={classNames('page-header__article-link', { 'tabs-mode': tabs })}
              >
                <div className="flex flex-row items-center">
                  <img
                    src={knowledgeBase}
                    alt="knowledgeBase"
                    className="icon__knowledge-base mr-2"
                  />
                  {helpArticle.name}
                </div>
              </Button>
            )}
          </div>
          {actionButtons && actionButtons}
        </div>
      </div>

      {!tabs && <Divider />}

      {tabs ? (
        <Tabs
          activeKey={selectedTabKey?.toString()}
          onChange={activeKey => {
            if (props.handleSelectTab) props.handleSelectTab(Number(activeKey));
            setSmall(false);
          }}
          destroyInactiveTabPane
          items={tabs.map((t, i) => ({
            label: t.name,
            key: `${t.id}`,
            children: (
              <div ref={ref => (refs.current[i] = ref)} className="page-header__content flex-1">
                {t.content}
              </div>
            ),
          }))}
        />
      ) : (
        <div className="page-header__content flex-1" ref={contentRef}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default HeaderPage;
