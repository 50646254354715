import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountContainer from '../containers/account/AccountContainer';
import UserContainer from '../containers/user/UserContainer';
import TranslationSettingsPage from '../pages/parentSettings/TranslationSettingsPage';
import CustomVehicleTablePage from '../pages/parentSettings/CustomVehicleTablePage';
import BrandManagingContainer from '../containers/parentSettings/brand/BrandManagingContainer';
import AttributesSettingsPage from '../pages/parentSettings/AttributesSettingsPage ';
import SettingsCustomFieldsContainer from '../containers/settings/SettingsCustomFieldsContainer';
import CompanyProfileContainer from '../containers/settings/CompanyProfileContainer';
import RetoolContainer from '../containers/retool/RetoolContainer';
import SettingsDefaultValuesContainer from '../containers/settings/SettingsDefaultValuesContainer';

const UserSettingRoutes = () => (
  <Routes>
    <Route path="account" element={<AccountContainer />} />
    <Route path="brands" element={<BrandManagingContainer />} />
    <Route path="user" element={<UserContainer />} />
    <Route path="attributes/*" element={<AttributesSettingsPage />} />
    <Route path="translations/*" element={<TranslationSettingsPage />} />
    <Route path="custom-vcdb/*" element={<CustomVehicleTablePage />} />
    <Route path="custom-fields" element={<SettingsCustomFieldsContainer />} />
    <Route path="company-profile" element={<CompanyProfileContainer />} />
    <Route path="retool" element={<RetoolContainer />} />
    <Route path="default-values" element={<SettingsDefaultValuesContainer />} />
  </Routes>
);

export default UserSettingRoutes;
