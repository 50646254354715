import { Form, Col, Row, Alert, Button } from 'antd';
import React from 'react';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Ebay } from '../../../../types/resources';
import {
  EbaySettings,
  ItemEbaySettings,
  EbayCategory,
  Epid,
  EbayPolicies,
  EbayItemSettingsData,
  EbayOffer,
} from '../../../../types/ebay';
import channelConstants from '../../../constants/ChannelTranslation.json';
import FormInput from '../../global/Forms/FormInput';
import FormTextArea from '../../global/Forms/FormTextArea';
import FormSelect from '../../global/Forms/FormSelect';
import EbayEpidList from './EbayEpidList';
import EbayStatusRow from './EbayStatusRow';
import EbayFormDefaultLabel from './EbayFormDefaultLabel';
import validationMessages from '../../../constants/ValidationMessages.json';
import PageFormik from '../../global/page/PageFormik';

const { ebay } = channelConstants;

type EbayListingParameter = {
  title?: string;
  partNumber?: string;
  description?: string;
  price?: string;
  primaryCategoryId?: number;
  secondaryCategoryId?: number;
  fulfillmentProfileId?: number;
  paymentProfileId?: number;
  returnProfileId?: number;
  ebayListtype?: string;
  ebayListtime?: string;
};

type EbayListingDefaults = {
  defaultTitle: boolean;
  defaultPartNumber: boolean;
  defaultPrice: boolean;
  defaultDescription: boolean;
  defaultCategory: boolean;
  defaultListtype: boolean;
  defaultPayment: boolean;
  defaultReturns: boolean;
  defaultPaymentPolicy: boolean;
  defaultReturnPolicy: boolean;
  defaultFulfillmentPolicy: boolean;
};

type EbayItemSettingsProps = {
  ebayOffer: EbayOffer;
  itemSettings?: EbayItemSettingsData;
  itemUpdatedAt: string;
  epids: Epid[];
  itemEbaySettings: ItemEbaySettings;
  ebaySettings: EbaySettings;
  ebayResources: Ebay;
  ebayPolicies: EbayPolicies;
  ebayCategories: EbayCategory[];
  categoryResources: EbayCategory[];
  fetchingCategories: boolean;
  fetchingEpids: boolean;
  createEbayOffer: (params?: { updateOffer?: boolean }) => void;
  deleteEbayOffer: () => void;
  updateItemSettings: (values: EbayListingParameter) => Promise<any>;
  searchEbayCategories: (keywords?: string) => void;
  fetchNextCategories: (event: React.UIEvent<HTMLDivElement>, keywords?: string) => void;
} & WithTranslation;

type EbayItemSettingsState = {
  listOnEbayBySubmit: boolean;
  updateOnEbayBySubmit: boolean;
  defaultTitle: boolean;
  defaultPrice: boolean;
  defaultDescription: boolean;
  primaryCategoryOpen: boolean;
  secondaryCategoryOpen: boolean;
  missingDefaultSettings: boolean;
  categoryKeywords?: string;
};

class EbayItemSettings extends React.Component<EbayItemSettingsProps, EbayItemSettingsState> {
  constructor(props: EbayItemSettingsProps) {
    super(props);
    const { itemEbaySettings, ebaySettings } = this.props;
    const itemSettings = itemEbaySettings || ({} as ItemEbaySettings);
    this.state = {
      listOnEbayBySubmit: false,
      updateOnEbayBySubmit: false,
      primaryCategoryOpen: false,
      secondaryCategoryOpen: false,
      categoryKeywords: '',
      defaultTitle: !!(!itemSettings.title && itemSettings.default_title),
      defaultPrice: !!(!itemSettings.price && itemSettings.default_price),
      defaultDescription: !itemSettings.description,
      missingDefaultSettings:
        !ebaySettings.address_1 ||
        !ebaySettings.city ||
        !ebaySettings.country ||
        !ebaySettings.county ||
        !ebaySettings.postal_code ||
        !ebaySettings.state,
    };
  }

  getDefaults = () => {
    const { ebaySettings, itemEbaySettings, itemSettings } = this.props;

    return {
      defaultTitle: !!(!itemEbaySettings.title && itemEbaySettings.default_title),
      defaultPartNumber: !!(
        !itemEbaySettings.part_number && itemEbaySettings.manufacturer_part_number
      ),
      defaultPrice: !!(!itemEbaySettings.price && itemEbaySettings.default_price),
      defaultDescription: !itemEbaySettings.description,
      defaultCategory: !!(
        !itemEbaySettings.primary_category_id && itemEbaySettings.ebay_category_id
      ),
      defaultListtype:
        !itemSettings?.listing_type && ebaySettings.listing_type
          ? !!ebaySettings.listing_type
          : false,
      defaultPaymentPolicy:
        !itemSettings?.payment_id && ebaySettings.payment_policy
          ? !!ebaySettings.payment_policy.id
          : false,
      defaultReturnPolicy:
        !itemSettings?.return_id && ebaySettings.return_policy
          ? !!ebaySettings.return_policy.id
          : false,
      defaultFulfillmentPolicy:
        !itemSettings?.fulfillment_id && ebaySettings.fulfillment_policy
          ? !!ebaySettings.fulfillment_policy.id
          : false,
    };
  };

  handleListOnEbay = (
    submitForm: () => void,
    validateForm: () => Promise<any>,
    formDirty: boolean,
    update?: boolean
  ) => {
    validateForm().then(async errors => {
      if (Object.keys(errors).length === 0) {
        if (!formDirty) {
          this.props.createEbayOffer({ updateOffer: update });
        } else {
          await this.setState({ listOnEbayBySubmit: true, updateOnEbayBySubmit: !!update });
          submitForm();
        }
      } else {
        // call submit to show form errors
        submitForm();
      }
    });
  };

  handleSettingsChange = (values: EbayListingParameter & EbayListingDefaults): Promise<any> => {
    const {
      defaultTitle,
      defaultPartNumber,
      defaultPrice,
      defaultDescription,
      defaultCategory,
      defaultListtype,
      defaultPaymentPolicy,
      defaultReturnPolicy,
      defaultFulfillmentPolicy,
    } = values;

    // remove values which are marked as default
    const clearedValues = {
      ...(!defaultTitle && { title: values.title }),
      ...(!defaultPartNumber && { partNumber: values.partNumber }),
      ...(!defaultDescription && { description: values.description }),
      ...(!defaultPrice && { price: values.price }),
      ...(values.secondaryCategoryId && { secondaryCategoryId: values.secondaryCategoryId }),
      ...(!defaultCategory && { primaryCategoryId: values.primaryCategoryId }),
      ...(!defaultListtype && { ebayListtype: values.ebayListtype }),
      ...(!defaultListtype && { ebayListtime: values.ebayListtime }),
      ...(!defaultPaymentPolicy && { paymentProfileId: values.paymentProfileId }),
      ...(!defaultReturnPolicy && { returnProfileId: values.returnProfileId }),
      ...(!defaultFulfillmentPolicy && { fulfillmentProfileId: values.fulfillmentProfileId }),
    };

    return this.props.updateItemSettings(clearedValues);
  };

  getValidationSchema = () => {
    const { defaultTitle, defaultPrice, defaultDescription } = this.state;
    return Yup.object().shape({
      title: defaultTitle
        ? Yup.string().max(80)
        : Yup.string().max(80).required(validationMessages.required),
      price: defaultPrice ? Yup.string() : Yup.string().required(validationMessages.required),
      description: defaultDescription
        ? Yup.string()
        : Yup.string().required(validationMessages.required),
    });
  };

  handleSearch = (keywords?: string) => {
    this.props.searchEbayCategories(keywords);
    this.setState({ categoryKeywords: keywords });
  };

  handlePrimaryCategoryDropdownOpening = (open: boolean) => {
    this.setState({ primaryCategoryOpen: open });
  };

  handleSecondaryCategoryDropdownOpening = (open: boolean) => {
    this.setState({ secondaryCategoryOpen: open });
  };

  channelForm = () => {
    const {
      t,
      ebayOffer,
      ebaySettings,
      ebayResources,
      ebayPolicies,
      categoryResources,
      itemEbaySettings,
      itemSettings,
      itemUpdatedAt,
    } = this.props;
    const { primaryCategoryOpen, secondaryCategoryOpen, listOnEbayBySubmit, updateOnEbayBySubmit } =
      this.state;

    return (
      <PageFormik
        initialValues={{
          primaryCategoryId:
            itemEbaySettings.primary_category_id || itemEbaySettings.ebay_category_id || undefined,
          secondaryCategoryId: itemEbaySettings.secondary_category_id || undefined,
          title: itemEbaySettings.title || itemEbaySettings.default_title || '',
          partNumber:
            itemEbaySettings.part_number || itemEbaySettings.manufacturer_part_number || null,
          price: itemEbaySettings.price || itemEbaySettings.default_price || '',
          description:
            itemEbaySettings.description ||
            (itemEbaySettings.default_description
              ? itemEbaySettings.default_description.join('\n')
              : ''),
          ebayListtype: itemSettings?.listing_type || ebaySettings.listing_type || '', // todo: check when api is updated
          ebayListtime: itemSettings?.listing_length || ebaySettings.listing_length || '', // todo: check when api is updated
          // profiles
          paymentProfileId:
            itemSettings?.payment_id || ebaySettings.payment_policy?.id || undefined,
          returnProfileId: itemSettings?.return_id || ebaySettings.return_policy?.id || undefined,
          fulfillmentProfileId:
            itemSettings?.fulfillment_id || ebaySettings.fulfillment_policy?.id || undefined,
          // default switches
          ...this.getDefaults(),
        }}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
          setSubmitPending();
          this.handleSettingsChange(values as EbayListingParameter & EbayListingDefaults)
            .then(() => {
              if (listOnEbayBySubmit || updateOnEbayBySubmit) {
                this.props.createEbayOffer({ updateOffer: !!updateOnEbayBySubmit });
                this.setState({ listOnEbayBySubmit: false, updateOnEbayBySubmit: false });
              }
              setSubmitSuccess();
            })
            .catch(() => setSubmitError());
        }}
        renderHeader={({ isSubmitting, submitForm, validateForm, dirty }) => (
          <EbayStatusRow
            ebayOffer={ebayOffer}
            itemUpdatedAt={itemUpdatedAt}
            buttonDisabled={isSubmitting}
            formDirty={dirty}
            deleteEbayOffer={this.props.deleteEbayOffer}
            createEbayOffer={() => this.handleListOnEbay(submitForm, validateForm, dirty)}
            updateEbayOffer={() => this.handleListOnEbay(submitForm, validateForm, dirty, true)}
          />
        )}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form layout="vertical" className="ebay__item-form" onFinish={() => handleSubmit()}>
            <div>
              <Row gutter={20} align="top">
                <Col span={10}>
                  <FormSelect
                    name="primaryCategoryId"
                    values={
                      (primaryCategoryOpen && this.props.ebayCategories) ||
                      (values.primaryCategoryId && [
                        categoryResources.find(c => c.id === values.primaryCategoryId) || {
                          id: values.primaryCategoryId,
                          name: t('common:unknown'),
                          path: '',
                        },
                      ]) ||
                      []
                    }
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultCategory}
                        disabled={!itemEbaySettings.ebay_category_id}
                        handleChange={(checked: boolean) => {
                          if (checked)
                            setFieldValue('primaryCategoryId', itemEbaySettings.ebay_category_id);
                          setFieldValue('defaultCategory', checked);
                        }}
                        name="category"
                        title={t('ebay:form.category')}
                      />
                    }
                    showSearch
                    disabled={values.defaultCategory}
                    onSearch={this.handleSearch}
                    onDropdownVisibleChange={this.handlePrimaryCategoryDropdownOpening}
                    loading={this.state.primaryCategoryOpen && this.props.fetchingCategories}
                    onPopupScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) =>
                      this.props.fetchNextCategories(event, this.state.categoryKeywords)
                    }
                    addPathToOption
                  />
                  <FormSelect
                    name="secondaryCategoryId"
                    values={
                      (secondaryCategoryOpen && this.props.ebayCategories) ||
                      (values.secondaryCategoryId && [
                        categoryResources.find(c => c.id === values.secondaryCategoryId)!,
                      ]) ||
                      []
                    }
                    label={t('ebay:form.secondaryCategory')}
                    showSearch
                    onSearch={this.handleSearch}
                    onDropdownVisibleChange={this.handleSecondaryCategoryDropdownOpening}
                    loading={this.state.secondaryCategoryOpen && this.props.fetchingCategories}
                    onPopupScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) =>
                      this.props.fetchNextCategories(e, this.state.categoryKeywords)
                    }
                    allowClear
                    addPathToOption
                  />
                </Col>
                <Col span={10}>
                  <FormInput
                    name="title"
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultTitle}
                        disabled={!itemEbaySettings?.default_title}
                        handleChange={checked => {
                          if (checked) setFieldValue('title', itemEbaySettings.default_title);
                          setFieldValue('defaultTitle', checked);
                          this.setState({ defaultTitle: checked });
                        }}
                        name="title"
                        title={t('ebay:form.title')}
                      />
                    }
                    placeholder={itemEbaySettings.default_title || undefined}
                    required
                    disabled={values.defaultTitle}
                  />
                  <FormInput
                    name="partNumber"
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultPartNumber}
                        disabled={!itemEbaySettings.manufacturer_part_number}
                        handleChange={checked => {
                          if (checked)
                            setFieldValue('partNumber', itemEbaySettings.manufacturer_part_number);
                          setFieldValue('defaultPartNumber', checked);
                        }}
                        name="partNumber"
                        title={t('ebay:form.sku')}
                      />
                    }
                    placeholder={itemEbaySettings.manufacturer_part_number || undefined}
                    disabled={values.defaultPartNumber}
                  />
                </Col>
                <Col span={4}>
                  <FormInput
                    name="price"
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultPrice}
                        disabled={!itemEbaySettings.default_price}
                        handleChange={checked => {
                          if (checked) setFieldValue('price', itemEbaySettings.default_price);
                          setFieldValue('defaultPrice', checked);
                          this.setState({ defaultPrice: checked });
                        }}
                        name="price"
                        title={t('ebay:form.price')}
                      />
                    }
                    placeholder={itemEbaySettings.default_price || undefined}
                    required
                    disabled={values.defaultPrice}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24}>
                  <FormTextArea
                    name="description"
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultDescription}
                        handleChange={checked => {
                          if (checked)
                            setFieldValue(
                              'description',
                              itemEbaySettings.default_description?.join('\n') || ''
                            );
                          setFieldValue('defaultDescription', checked);
                          this.setState({ defaultDescription: checked });
                        }}
                        name="description"
                        title={t('ebay:form.description')}
                      />
                    }
                    placeholder={
                      itemEbaySettings.default_description
                        ? itemEbaySettings.default_description.join('\n')
                        : ''
                    }
                    required
                    disabled={values.defaultDescription}
                  />
                </Col>
              </Row>
              {values.primaryCategoryId &&
                categoryResources.find(c => c.id === values.primaryCategoryId)?.compatibility ===
                  0 && <Alert message={t('ebay:form.noApplication')} type="info" />}
              <EbayEpidList epids={this.props.epids} fetchingEpids={this.props.fetchingEpids} />

              <Row gutter={20} className="ebay__form-headline">
                <Col span={24}>
                  <span>{t('ebay:form.sellingDetails')}</span>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={12}>
                  <FormSelect
                    name="ebayListtype"
                    values={ebayResources.listtype}
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultListtype}
                        disabled={!ebaySettings.listing_type}
                        handleChange={checked => {
                          if (checked) setFieldValue('ebayListtype', ebaySettings.listing_type);
                          setFieldValue('defaultListtype', checked);
                        }}
                        name="listType"
                        title={t('ebay:form.listType')}
                      />
                    }
                    onChange={(value: any) => {
                      if (value === 'FIXED_PRICE') setFieldValue('ebayListtime', 'GTC');
                      setFieldValue('ebayListtype', value);
                    }}
                    placeholder={ebay.selectPlaceholder}
                    useCode
                    disabled={values.defaultListtype}
                  />
                </Col>
                <Col span={12}>
                  <FormSelect
                    name="ebayListtime"
                    values={ebayResources.listtime}
                    label={ebay.duration}
                    placeholder={ebay.selectPlaceholder}
                    useCode
                    disabled={values.defaultListtype || values.ebayListtype === 'FIXED_PRICE'}
                  />
                </Col>
              </Row>

              <Row gutter={20} className="ebay__form-headline">
                <Col span={24}>
                  <span>{t('ebay:form.businessPolicies')}</span>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <FormSelect
                    name="paymentProfileId"
                    values={ebayPolicies.payment}
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultPaymentPolicy}
                        disabled={!ebaySettings.payment_policy?.id}
                        handleChange={checked => {
                          if (checked)
                            setFieldValue('paymentProfileId', ebaySettings.payment_policy?.id);
                          setFieldValue('defaultPaymentPolicy', checked);
                        }}
                        name="paymentPolicy"
                        title={t('ebay:form.paymentPolicy')}
                      />
                    }
                    placeholder={ebay.selectPlaceholder}
                    disabled={values.defaultPaymentPolicy}
                  />
                </Col>
                <Col span={8}>
                  <FormSelect
                    name="returnProfileId"
                    values={ebayPolicies.return}
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultReturnPolicy}
                        disabled={!ebaySettings.return_policy?.id}
                        handleChange={checked => {
                          if (checked)
                            setFieldValue('returnProfileId', ebaySettings.return_policy?.id);
                          setFieldValue('defaultReturnPolicy', checked);
                        }}
                        name="returnPolicy"
                        title={t('ebay:form.returnPolicy')}
                      />
                    }
                    placeholder={ebay.selectPlaceholder}
                    disabled={values.defaultReturnPolicy}
                  />
                </Col>
                <Col span={8}>
                  <FormSelect
                    name="fulfillmentProfileId"
                    values={ebayPolicies.fulfillment}
                    label={
                      <EbayFormDefaultLabel
                        checked={values.defaultFulfillmentPolicy}
                        disabled={!ebaySettings.fulfillment_policy?.id}
                        handleChange={checked => {
                          if (checked)
                            setFieldValue(
                              'fulfillmentProfileId',
                              ebaySettings.fulfillment_policy?.id
                            );
                          setFieldValue('defaultFulfillmentPolicy', checked);
                        }}
                        name="fulfillmentPolicy"
                        title={t('ebay:form.shippingPolicy')}
                      />
                    }
                    placeholder={ebay.selectPlaceholder}
                    disabled={values.defaultFulfillmentPolicy}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </PageFormik>
    );
  };

  render() {
    const { t } = this.props;

    if (this.state.missingDefaultSettings)
      return (
        <Alert
          className="ebay__no-connection"
          message={t('ebay:defaultSettingsMissing')}
          description={
            <Button size="small" className="analyses__button-add-session">
              <Link to="/distribution/channels">{t('ebay:goToChannel')}</Link>
            </Button>
          }
          type="info"
        />
      );

    return this.channelForm();
  }
}

export default withTranslation()(EbayItemSettings);
