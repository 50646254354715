import { Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import {
  fetchReceiverFilters,
  fetchAllReceiverBrands,
} from '../../actions/receiver_data_stream/productsCatalogue/fetch.ts';
import { hasPermission } from '../../utils/Permissions';
import {
  mapResourcesToChannelOverview,
  mapSelectedChannelId,
} from '../../selectors/channel/channelOverviewSelector';
import { fetchAPIToken } from '../../actions/distribution_apisettings';
import { withContainerWrapper } from '../ContainerWrapper';
import ChannelDrawer from '../../components/body/channel/ChannelDrawer';
import ShopifyUrlModal from '../../components/body/channel/ShopifyUrlModal';
import ChannelNoPermission from '../../components/body/channel/ChannelNoPermission';
import ChannelTable from '../../components/body/channel/ChannelTable';
import actions from '../../actions/channel';
import translationConstants from '../../constants/ChannelTranslation.json';
import generalTranslation from '../../constants/GeneralTranslation.json';
import { isReceiver } from '../../utils/UserUtils.ts';
import { intercomEvent } from '../../utils/IntercomUtils';
import { exportChannel } from '../../actions/exporter';
import { pushChannelUpdate } from '../../actions/channelPusher';
import { fetchExportOptions } from '../../actions/brand/export/fetch';
import {
  fetchNPWBrands,
  fetchWalmartBrands,
  fetchAllShopifyChannels,
} from '../../actions/channel/fetch';
import { deleteShopifyStore } from '../../actions/channel/delete';
import ShopifyDeleteChannelModal from '../../components/body/channel/ShopifyDeleteChannelModal';
import withAntdContext from '../withAntdContext';

class ChannelContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateDrawer: false,
      showEditDrawer: false,
      createIntegratedId: null,
      selectedPlans: [],
      editPlan: undefined,
      filterKeywords: '',
      shopifyId: undefined,
      createShopify: false,
      showShopifyDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.fetchChannelOverview());
    this.props.dispatch(actions.fetchChannelItemsCount());
    this.props.dispatch(actions.fetchNamingConventions());
    if (this.props.fileExportOptionsSegment !== 'channel')
      this.props.dispatch(fetchExportOptions('channel'));
    this.props.dispatch(fetchAPIToken({}));

    // if (this.props.canManageChannelAdvisor) this.props.dispatch(fetchChannelAdvisor());
    if (this.props.canManageNpw) this.props.dispatch(fetchNPWBrands());
    if (this.props.canManageWalmart) this.props.dispatch(fetchWalmartBrands());
    if (this.props.canManageShopify) this.props.dispatch(fetchAllShopifyChannels());

    if (this.props.receiverUser) {
      this.props.dispatch(actions.fetchAutorizedBrands());
      this.props.dispatch(fetchReceiverFilters(this.props.user.receiver_id));
      this.props.dispatch(fetchAllReceiverBrands());
    }
    intercomEvent('viewed-channels');
  }

  showCreateDrawer = integratedId => {
    this.setState({ showCreateDrawer: true, createIntegratedId: integratedId });

    intercomEvent('viewed-channels', { action: 'add_new' });
  };

  showShopifyURLModal = () => {
    const { t } = this.props;
    const { modal } = this.props.appContext;

    if (this.props.canManageShopify) this.setState({ createShopify: true });
    else {
      modal.info({
        title: t('common:contactForUpgrade'),
        icon: <InfoCircleOutlined />,
        onOk() {},
      });
    }
  };

  showShopifyEditDrawer = shopifyId => {
    this.setState({ showEditDrawer: true, shopifyId });
  };

  showEditDrawer = channelId => {
    this.setState({ showEditDrawer: true });
    this.props.dispatch(actions.selectChannel(channelId));

    intercomEvent('viewed-channels', {
      action: 'edit',
      channel: this.props.channelOverview.find(({ id }) => id === channelId).name,
    });
  };

  closeDrawer = () => {
    this.setState({
      showCreateDrawer: false,
      showEditDrawer: false,
      createIntegratedId: null,
      editPlan: undefined,
      shopifyId: undefined,
    });
    this.props.dispatch(actions.selectChannel(null));
    this.props.dispatch(actions.fetchChannelOverview());
    this.props.dispatch(actions.fetchChannelItemsCount());
  };

  closeShopifyModal = () => {
    this.setState({
      shopifyId: undefined,
      createShopify: false,
    });
  };

  handleCreateShopify = shopifyId => {
    this.setState({
      showEditDrawer: true,
      shopifyId,
      createShopify: false,
    });
  };

  createChannel = values => this.props.dispatch(actions.createChannel(values));

  updateChannel = values => {
    const { selectedChannelId } = this.props;
    return this.props.dispatch(actions.updateChannel({ ...values, channelId: selectedChannelId }));
  };

  pushChannelUpdate = planId => {
    const { dispatch } = this.props;
    const { modal } = this.props.appContext;

    modal.confirm({
      title: translationConstants.confirmPush,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(pushChannelUpdate(planId));
      },
    });
  };

  downloadChannelUpdate = (planId, brandId, fileTypeId) =>
    this.props.dispatch(exportChannel(planId, brandId, fileTypeId));

  handleSelectAllChannels = () =>
    this.setState({
      selectedPlans: this.props.channelOverview
        .map(({ plans }) => plans)
        .reduce((a, b) => a.concat(b), [])
        .map(({ id }) => id),
    });

  handleUnselectAllChannels = () => this.setState({ selectedPlans: [] });

  handleChannelSelect = channelId => {
    const channel = this.props.channelOverview.find(({ id }) => id === channelId);
    const { plans } = channel;

    const currentlyselectedPlans = this.state.selectedPlans;
    this.setState({
      selectedPlans: [...plans.map(({ id }) => id), ...currentlyselectedPlans],
    });
  };

  handleChannelUnselect = channelId => {
    const channel = this.props.channelOverview.find(({ id }) => id === channelId);
    const planIds = channel.plans.map(({ id }) => id);
    this.setState(prevState => ({
      selectedPlans: prevState.selectedPlans.filter(p => !planIds.includes(p)),
    }));
  };

  handleEditPlan = (channelId, planId) => {
    this.setState({ editPlan: planId });
    this.showEditDrawer(channelId);
  };

  handlePlanSelect = planId => {
    const { selectedPlans } = this.state;
    this.setState({ selectedPlans: [planId, ...selectedPlans] });
  };

  handlePlanUnselect = planId => {
    const { selectedPlans } = this.state;
    this.setState({
      selectedPlans: selectedPlans.filter(id => id !== planId),
    });
  };

  handleChannelDelete = channelId => {
    const { dispatch, channelOverview } = this.props;
    const { modal } = this.props.appContext;

    intercomEvent('viewed-channels', {
      action: 'delete',
      channel: channelOverview.find(({ id }) => id === channelId).name,
    });

    modal.confirm({
      title: translationConstants.confirmDelete,
      icon: <ExclamationCircleOutlined />,
      okText: generalTranslation.delete,
      okButtonProps: { danger: true },
      onOk() {
        dispatch(actions.deleteChannel(channelId));
      },
    });
  };

  handlePlanDelete = (channelId, planId) =>
    this.props.dispatch(actions.deletePlans(channelId, [planId]));

  handleDeleteShopifyChannel = id => {
    this.setState({ showShopifyDeleteModal: true, shopifyId: id });
  };

  getShopifyChannel = shopifyId => this.props.shopifyChannels?.find(sc => sc.id === shopifyId);

  render() {
    const {
      channelOverview,
      brands,
      isLoading,
      selectedChannelId,
      selectedChannel = {},
      user,
      authorizedBrands,
      t,
    } = this.props;
    const { showCreateDrawer, showEditDrawer, createIntegratedId } = this.state;

    const canManageWalmart = this.props.receiverUser
      ? !!selectedChannel.integration_type_id
      : this.props.canManageWalmart;

    if (isLoading) {
      return <div className="loader" />;
    }

    if (!this.props.hasChannelAccess) {
      return <ChannelNoPermission />;
    }

    return (
      <div className="page-layout">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <Input.Search
              className="page-layout__top-bar__search"
              value={this.state.filterKeywords}
              onChange={e => this.setState({ filterKeywords: e.target.value || '' })}
              placeholder={translationConstants.searchPlaceholder}
              allowClear
            />
            <div className="page-layout__top-bar__actions">
              <Button type="primary" onClick={() => this.showCreateDrawer()}>
                {translationConstants.addChannel}
              </Button>
            </div>
          </div>
        </div>
        <div className="page-layout__content">
          <ChannelTable
            fetchingChannelShopify={this.props.fetchingChannelShopify}
            channels={
              channelOverview &&
              channelOverview.filter(({ name }) =>
                name.toLowerCase().includes(this.state.filterKeywords.toLowerCase())
              )
            }
            shopifyChannels={this.props.shopifyChannels}
            deletePending={this.props.deletePending}
            isReceiverUser={this.props.receiverUser}
            selectedPlans={this.state.selectedPlans}
            brands={user.receiver_id ? authorizedBrands : brands}
            allAccessableBrands={this.props.allAccessableBrands}
            pendingDownloads={this.props.pendingDownloads}
            channelResources={this.props.channelResources}
            pushChannelUpdate={this.pushChannelUpdate}
            handleChannelSelect={this.handleChannelSelect}
            handleChannelUnselect={this.handleChannelUnselect}
            handleSelectAllChannels={this.handleSelectAllChannels}
            handleUnselectAllChannels={this.handleUnselectAllChannels}
            handleEditPlan={this.handleEditPlan}
            handlePlanSelect={this.handlePlanSelect}
            handlePlanUnselect={this.handlePlanUnselect}
            handleEdit={this.showEditDrawer}
            handleDelete={this.handleChannelDelete}
            handlePlanDelete={this.handlePlanDelete}
            showCreateDrawer={this.showCreateDrawer}
            showShopifyURLModal={this.showShopifyURLModal}
            downloadChannelUpdate={this.downloadChannelUpdate}
            showShopifyEditDrawer={this.showShopifyEditDrawer}
            handleDeleteShopifyChannel={this.handleDeleteShopifyChannel}
          />
        </div>
        <ChannelDrawer
          create={showCreateDrawer}
          createIntegratedId={createIntegratedId}
          close={this.closeDrawer}
          drawerVisible={showCreateDrawer || showEditDrawer}
          brands={user.receiver_id ? authorizedBrands : brands}
          allAccessableBrands={this.props.allAccessableBrands}
          channel={selectedChannel.id === selectedChannelId && selectedChannel}
          selectedPlan={this.state.editPlan}
          createChannel={this.createChannel}
          updateChannel={this.updateChannel}
          isReceiverUser={this.props.receiverUser}
          // canManageChannelAdvisor={this.props.canManageChannelAdvisor}
          canManageNpw={this.props.canManageNpw}
          canManageWalmart={canManageWalmart}
          canManageShopify={this.props.canManageShopify}
          shopifyId={this.state.shopifyId}
        />
        <ShopifyUrlModal
          createChannel={this.state.createShopify}
          handleClose={this.closeShopifyModal}
          handleCreate={this.handleCreateShopify}
          fetchingChannelShopify={this.props.fetchingChannelShopify}
          token={this.props.token || null}
          rejectedName={this.props.shopifyChannelAlreadyExists}
          rejectedUsername={this.props.shopifyUsernameAlreadyExists}
        />
        <ShopifyDeleteChannelModal
          visible={this.state.showShopifyDeleteModal}
          handleDelete={() => {
            const shopifyChannel = this.getShopifyChannel(this.state.shopifyId);
            if (window && shopifyChannel?.shop_status?.status === 'connected')
              window.open('https://accounts.shopify.com/store-login');
            this.props.dispatch(deleteShopifyStore(this.state.shopifyId));
            this.setState({ showShopifyDeleteModal: false });
            message.success(t('channel:alertDelete', { shopifyName: shopifyChannel.name }), 5);
          }}
          handleCancel={() => this.setState({ showShopifyDeleteModal: false })}
          connected={
            this.getShopifyChannel(this.state.shopifyId)
              ? this.getShopifyChannel(this.state.shopifyId).shop_status?.status === 'connected'
              : false
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  receiverUser: isReceiver(state.user.user),
  user: state.user.user,
  isLoading: state.channel.channels.isLoading,
  selectedChannelId: state.channel.channels.selectedChannelId,
  selectedChannel: mapSelectedChannelId(state),
  deletePending: state.channel.channels.deletePending,
  authorizedBrands: state.channel.channels.authorizedBrands,
  channelOverview: mapResourcesToChannelOverview(state),
  channelResources: state.resources.data.channel,
  brandId: state.parent.brands.selectedBrandId,
  brands: state.parent.brands.brands,
  allAccessableBrands: state.receiverDataStream.allAccessableBrands,
  canManageChannelAdvisor: hasPermission(state.user.user, 'can_manage_channel_advisor'),
  canManageNpw: hasPermission(state.user.user, 'can_manage_npw'),
  canManageWalmart: hasPermission(state.user.user, 'can_manage_walmart'),
  canManageShopify: hasPermission(state.user.user, 'can_manage_shopify'),
  hasChannelAccess: hasPermission(state.user.user, 'has_channel_access'),
  shopifyChannels: state.channel.channels.channelShopifyData.channels,
  fetchingChannelShopify: state.channel.channels.fetchingChannelShopify,
  token: state.distribution.apiSettings?.token?.token,
  shopifyChannelAlreadyExists: state.channel.channels.shopifyChannelAlreadyExists,
  shopifyUsernameAlreadyExists: state.channel.channels.shopifyUsernameAlreadyExists,
  fileExportOptionsSegment: state.brand.brandExport.fileExportOptionsSegment,
});

export { ChannelContainer };
export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(withAntdContext(ChannelContainer)))
);
