import React from 'react';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { App, Button, Col, Divider, Form, Row, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormikValues, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AsyncDispatch, DrawerMode } from '../../../../types/global';
import { PermissionGroup, UserPermissionRole } from '../../../../types/user';
import { Segment, StandardResourceCode } from '../../../../types/resources';
import { deleteUserRole, updateUserRole } from '../../../actions/user/index';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormSelect from '../../global/Forms/FormSelect';
import FormSwitch from '../../global/Forms/FormSwitch';
import FormInput from '../../global/Forms/FormInput';
import AntSelect from '../../global/AntSelect';
import AntTooltip from '../../global/AntTooltip';
import RoleDrawerMenu from './RoleDrawerMenu';
import { ApplicationState } from '../../../reducers';
import { hasPermission } from '../../../utils/Permissions';

type UserRoleDrawerProps = {
  visible: boolean;
  onClose: () => void;
  permissionGroups: PermissionGroup[];
  roles: UserPermissionRole[];
  segments: Segment[];
  maintenanceTypes: StandardResourceCode[];
};

const UserRoleDrawer: React.FC<UserRoleDrawerProps> = ({
  visible,
  onClose,
  roles,
  segments,
  permissionGroups,
  maintenanceTypes,
}) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = App.useApp();
  // No value for maintenanceType means "hidden" in the backend. Hidden type gets added here for
  // select handling
  const extMaintenanceTypes = [...maintenanceTypes, { id: 3, code: '', name: 'Hide' }];

  const [mode, setMode] = React.useState(roles.length === 0 ? DrawerMode.CREATE : DrawerMode.EDIT);

  const { user } = useSelector((state: ApplicationState) => ({
    user: state.user.user,
  }));

  const canManageUsers = hasPermission(user, 'can_manage_users');

  const sectionSelectState = (values: { [key: number]: number }) => {
    const maintenanceTypeIds = Object.values(values);
    const uniq = [...new Set(maintenanceTypeIds)];
    return uniq.length === 1 ? uniq[0].toString() : undefined;
  };

  const handleSectionSelect = (
    maintenanceTypeId: number,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    segments.forEach(segment => setFieldValue(`segments.${segment.id}`, maintenanceTypeId));
  };

  const groupSwitchState = (name: string, values: { [key: number]: boolean }) => {
    const permissionGroup = permissionGroups.find(group => group.name === name);
    if (permissionGroup) {
      const disabled = permissionGroup.permissions.find(perm => values[perm.id] === false);
      return !disabled;
    }
  };

  const handleGroupSwitch = (
    name: string,
    checked: boolean,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    const permissionGroup = permissionGroups.find(group => group.name === name);
    if (permissionGroup)
      permissionGroup.permissions.forEach(permission => {
        setFieldValue(`permissions.${permission.id}`, checked);
      });
  };

  const updateRole = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;
    const { segments, permissions, name } = values;
    const role = roles.find(role => role.id === values.role) || { name: values.name };

    const permissionIds = Object.keys(permissions)
      .filter(key => permissions[Number(key)])
      .map(id => Number(id));

    const updatedSegments = Object.keys(segments)
      .filter(key => segments[Number(key)] !== 3)
      .map(key => ({
        segment_id: Number(key),
        maintenance_type_id: segments[Number(key)],
      }));

    await dispatch(
      updateUserRole({ ...role, name, permission_ids: permissionIds, segments: updatedSegments })
    );

    setSubmitting(false);
    onClose();
  };

  const deleteRole = async (roleId: number) => {
    modal.confirm({
      title: t('user:deleteRoleTitle'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        dispatch(deleteUserRole(roleId)).then(() => onClose());
      },
    });
  };

  const getInitialValues = (roleId?: number) => {
    const role = roleId ? roles.find(role => role.id === roleId) : undefined;

    const segmentPermissions = segments.map(segment => {
      const sPermission = role ? role.segments.find(s => s.segment_id === segment.id) : undefined;
      const maintenanceTypeId = sPermission ? sPermission.maintenance_type_id : 3;
      return {
        segmentId: segment.id,
        maintenanceTypeId: mode === DrawerMode.EDIT ? maintenanceTypeId : 1,
      };
    });

    const segmentObj = segmentPermissions.reduce(
      (obj, item) => ({ ...obj, [item.segmentId]: item.maintenanceTypeId }),
      {}
    );

    const allPermissions = permissionGroups.flatMap(group => group.permissions);

    const permissions = allPermissions.map(perm => ({
      permissionId: perm.id,
      status: role ? role.permission_ids.includes(perm.id) : false,
    }));

    const permissionsObj = permissions.reduce(
      (obj, item) => ({ ...obj, [item.permissionId]: item.status }),
      {}
    );

    return {
      role: roleId,
      name: (role && role.name) || '',
      segments: segmentObj,
      permissions: permissionsObj,
    };
  };

  const segmentSelect = (segment: Segment) => (
    <div key={segment.id} className="flex">
      <Col span={12}>
        <div className="ant-form-item-label">{segment.title}</div>
      </Col>
      <Col span={12}>
        <FormSelect name={`segments.${segment.id}`} values={extMaintenanceTypes} />
      </Col>
    </div>
  );

  return (
    <DrawerFormik
      title={t('user:userRoleManager')}
      className="user__role-menu"
      width={1000}
      onClose={() => onClose()}
      visible={visible}
      initialValues={getInitialValues(
        roles[0] && mode === DrawerMode.EDIT ? roles[0].id : undefined
      )}
      onSubmit={(values, actions) => updateRole(values, actions)}
      onDelete={
        mode === DrawerMode.EDIT && canManageUsers ? values => deleteRole(values!.role) : undefined
      }
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canManageUsers && formik.dirty
      }
    >
      {({ values, handleSubmit, setFieldValue, resetForm }) => (
        <div className="h-full flex">
          <Col span={5} className="user__role-menu-column flex h-full">
            <div className="user__role-menu-buttons">
              <Button
                className="user__add-role-button"
                type="primary"
                disabled={mode === DrawerMode.CREATE}
                onClick={() => {
                  if (roles.length) {
                    setMode(DrawerMode.CREATE);
                    resetForm({ values: getInitialValues(undefined) });
                  }
                }}
                size="small"
                ghost
                block
              >
                {t('user:addRole')}
              </Button>
            </div>
            <RoleDrawerMenu
              roles={roles}
              handleClick={id => {
                setFieldValue('role', id);
                resetForm({ values: getInitialValues(id) });
                setMode(DrawerMode.EDIT);
              }}
              noSelection={mode === DrawerMode.CREATE}
            />
          </Col>
          <Col span={19} className="user__role-form-column h-full overflow-auto">
            <Form className="user__role-form" onFinish={() => handleSubmit()} layout="vertical">
              <Row className="mt-3">
                <Col span={24}>
                  <FormInput
                    name="name"
                    label={t('user:userRole')}
                    placeholder={t('user:createRolePlaceholder')}
                    required
                  />
                </Col>
              </Row>

              <div className="user__divider_wrapper flex">
                <div className="flex-1">
                  <Divider className="user__divider" orientation="left">
                    {t('user:allProductsHeader')}
                    <span className="user__permission-info">
                      <AntTooltip title={t('user:segmentPermissionHint')}>
                        <InfoCircleOutlined />
                      </AntTooltip>
                    </span>
                  </Divider>
                </div>
                <div>
                  <AntSelect
                    onChange={(value: string) => handleSectionSelect(Number(value), setFieldValue)}
                    elements={extMaintenanceTypes.map(type => ({
                      ...type,
                      id: type.id.toString(),
                    }))}
                    value={sectionSelectState(values.segments)}
                    placeholder={t('user:select')}
                  />
                </div>
              </div>

              <Row className="user__role-form-segments" gutter={20} align="top">
                <Col span={12}>
                  {segments
                    .slice(0, Math.ceil(segments.length / 2))
                    .map(segment => segmentSelect(segment))}
                </Col>
                <Col span={12}>
                  {segments
                    .slice(Math.ceil(segments.length / 2), segments.length)
                    .map(segment => segmentSelect(segment))}
                </Col>
              </Row>

              {permissionGroups.map(group => (
                <React.Fragment key={group.id}>
                  <div className="user__divider_wrapper flex">
                    <div className="flex-1">
                      <Divider className="user__divider" orientation="left">
                        {group.name}
                      </Divider>
                    </div>
                    <div>
                      <Switch
                        checked={groupSwitchState(group.name, values.permissions)}
                        onChange={checked => handleGroupSwitch(group.name, checked, setFieldValue)}
                        checkedChildren={t('common:yes')}
                        unCheckedChildren={t('common:no')}
                      />
                    </div>
                  </div>

                  {group.permissions.map(permission => (
                    <div key={permission.id} className="user__permission-row flex">
                      <div className="user__permission-name">
                        {permission.name}
                        {permission.description && (
                          <span className="user__permission-info">
                            <AntTooltip title={permission.description}>
                              <InfoCircleOutlined />
                            </AntTooltip>
                          </span>
                        )}
                      </div>
                      <FormSwitch
                        name={`permissions.${permission.id}`}
                        checkedChildren={t('common:yes')}
                        unCheckedChildren={t('common:no')}
                      />
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </Form>
          </Col>
        </div>
      )}
    </DrawerFormik>
  );
};

export default UserRoleDrawer;
