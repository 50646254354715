import { createSelector } from 'reselect';

const getAnalysisResources = state => state.resources.analysis;
const getAnalysesAlertItemCount = state => state.catalogue.filter.analysesAlertItemCount;
const getValidatedAnalysisTypeIds = state => state.settings.scorecards.validatedAnalysisTypeIds;

export const addValidatingInfo = createSelector(
  [getAnalysisResources, getValidatedAnalysisTypeIds],
  (analysisResources, validatedAnalysisTypeIds) => ({
    ...analysisResources,
    types: analysisResources.analysis_types.map(type => {
      const typeIsValidating = validatedAnalysisTypeIds.includes(type.id);
      const newType = !typeIsValidating ? type : { ...type, isValidating: true };
      return newType;
    }),
    scorecards: analysisResources.scorecards.map(scorecard => ({
      ...scorecard,
      validating_against_scorecard: scorecard.type_ids
        .split(',')
        .every(typeId => validatedAnalysisTypeIds.includes(Number(typeId))),
    })),
  })
);

export const addItemCountAndValidatingInfoToResources = createSelector(
  [addValidatingInfo, getAnalysesAlertItemCount],
  (analysesResources, analysesAlertItemCount) => {
    if (!analysesResources) return false;
    return {
      ...analysesResources,
      analysis_types: analysesResources.types.map(analysisType => {
        const alertItemCount =
          analysesAlertItemCount &&
          analysesAlertItemCount.find(alertCount => alertCount.type_id === analysisType.id);
        return {
          ...analysisType,
          item_count: alertItemCount ? alertItemCount.item_count : 0,
        };
      }),
    };
  }
);
