import React from 'react';
import classNames from 'classnames';
import { Select, Tooltip } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { FilterType, FilterTypeGo } from '../../../../types/filter';
import {
  isAllFilter,
  isCustomFilter,
  isCustomPublicFilter,
  isFilterEmpty,
  isMarkedFilter,
  isTempFilter,
  mapFilterName,
} from '../../../utils/FilterUtils';
import catalogueTranslations from '../../../constants/CatalogueTranslations.json';

type CatalogueFilterSelectProps = {
  filters: FilterType[];
  selectedFilterGo: FilterTypeGo;
  selectedFilterId: number;
  onFilterChange: (params: { filterId: number }) => void;
};

const CatalogueFilterSelect = (props: CatalogueFilterSelectProps) => {
  const { filters, selectedFilterGo, selectedFilterId } = props;

  const tempFilter = filters.find(isTempFilter);
  const allFilter = filters.find(isAllFilter);
  const markedFilter = filters.find(isMarkedFilter);
  const customFilters = filters.filter(isCustomFilter);
  const customPublicFilter = filters.filter(isCustomPublicFilter);

  return (
    <Select
      value={selectedFilterId}
      onChange={(id: number) => props.onFilterChange({ filterId: id })}
      size="small"
      style={{ width: 200 }}
      className={classNames('catalogue-list-bar__filter-select', {
        'custom-filter': allFilter && selectedFilterId !== allFilter.id,
      })}
      options={[
        ...((tempFilter &&
          (tempFilter.item_ids || !isFilterEmpty(selectedFilterGo)) && [
            {
              key: tempFilter.id,
              value: tempFilter.id,
              label: (
                <span className="catalogue-list-bar__filter-temp">{mapFilterName(tempFilter)}</span>
              ),
            },
          ]) ||
          []),
        ...((allFilter && [
          {
            value: allFilter.id,
            key: allFilter.id,
            label: mapFilterName(allFilter),
          },
        ]) ||
          []),
        ...((markedFilter && [
          {
            value: markedFilter.id,
            key: markedFilter.id,
            label: mapFilterName(markedFilter),
          },
        ]) ||
          []),
        {
          label: <span>{catalogueTranslations.myFilters}</span>,
          key: catalogueTranslations.myFilters,
          options: [
            ...customFilters.map(filter => ({
              value: filter.id,
              key: filter.id,
              label: <span>{`${filter.name}`}</span>,
            })),
            ...customPublicFilter.map(filter => ({
              value: filter.id,
              key: filter.id,
              label: (
                <React.Fragment>
                  <Tooltip title={filter.userEmail}>
                    <GlobalOutlined className="mr-1" />
                  </Tooltip>
                  {`${filter.name}`}
                </React.Fragment>
              ),
            })),
          ],
        },
      ]}
    />
  );
};

export default CatalogueFilterSelect;
