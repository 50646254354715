import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { useLocation } from 'react-router-dom';
import {
  fetchDefaultValues,
  createDefaultPriceTypeDescription,
  updateDefaultValue,
} from '../../actions/settings/defaultValues';
import { fetchPriceSheets } from '../../actions/parent/price_sheets/fetch';
import { setItemFetchRequired } from '../../actions/catalogue/catalogue/update';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import DefaultValues from '../../components/body/settings/DefaultValues';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { VCDB_DEFAULT_VALUE_ID } from '../../constants/DefaultValueConstants';
import { selectVcdbs } from '../../actions/items/application/update';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import { updateAccountDefaultValues } from '../../actions/parent/accountDefaultValues';
import { getBrandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';
import { fetchVcdbVersions } from '../../actions/items/application/fetch';
import { withContainerWrapper } from '../ContainerWrapper';

const SettingsDefaultValuesContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const location = useLocation();

  const brandSettings = location.pathname === '/brand/settings/default-values';

  const {
    defaultValues,
    accDefaultValues,
    priceSheets,
    priceSheetsBrandId,
    brandCode,
    resources,
    brandId,
    user,
    vcdbVersions,
    selectedVcdbs,
  } = useSelector((state: ApplicationState) => {
    return {
      defaultValues: getBrandAccDefaultValues(state),
      accDefaultValues: state.parent.accountDefaultValues.accountDefaultValues,
      priceSheets: state.parent.priceSheets.priceSheets,
      priceSheetsBrandId: state.parent.priceSheets.priceSheetsBrandId,
      resources: state.resources.data,
      brandId: state.parent.brands.selectedBrandId,
      brandCode: getSelectedBrandCode(state),
      user: state.user.user,
      vcdbVersions: state.items.application.vcdbVersions,
      selectedVcdbs: state.items.application.selectVcdbs,
    };
  });

  React.useEffect(() => {
    if (brandSettings) {
      intercomEvent('viewed-brand-settings', {
        location: 'settings-default-values',
        brand_code: brandCode!,
      });
      dispatch(fetchDefaultValues(brandId));
      if (brandId !== priceSheetsBrandId) dispatch(fetchPriceSheets(brandId));
    } else {
      intercomEvent('viewed-account-settings', {
        location: 'account-settings-default-values',
      });
      dispatch(fetchVcdbVersions());
    }
  }, [brandCode, brandId, brandSettings, priceSheetsBrandId, dispatch]);

  const updateDefaultValues = (id: number, value?: boolean | SelectValue | string | null) => {
    if (brandSettings)
      dispatch(updateDefaultValue(id, value, brandId)).then(() => {
        if (id === VCDB_DEFAULT_VALUE_ID && (!selectedVcdbs || selectedVcdbs.length < 1))
          // @ts-ignore
          dispatch(selectVcdbs(value ? [value] : []));
        // on default language change the catalogue items need to be refreshed
        if (id === 1) {
          dispatch(setItemFetchRequired(true));
        }
      });
    else dispatch(updateAccountDefaultValues(id, value));
  };

  const createDefaultPriceTypeDescriptions = (
    priceSheetId: number,
    priceTypeId: number,
    description: string
  ) => {
    if (brandSettings)
      // @ts-ignore
      dispatch(createDefaultPriceTypeDescription(priceSheetId, priceTypeId, description));
  };

  const canManageDefaultValues = useMemo(
    () => hasPermission(user, 'can_manage_default_values'),
    [user]
  );

  const defaultResources = {
    languages: resources.global.languages,
    gtin_qualifiers: resources.global.gtin_qualifiers,
    price_currencies: resources.price.currencies,
    aces_versions: resources.channel.aces_versions,
    pies_versions: resources.channel.pies_versions,
    price_uoms: resources.price.uoms,
    price_types: resources.price.types,
    package_uoms: resources.package.uoms,
    package_dimension_uoms: resources.package.dimension_uoms,
    package_weight_uoms: resources.package.weight_uoms,
    vcdb_versions: vcdbVersions,
    interchange_information_qualitity_grade_levels:
      resources.interchange_information.qualitity_grade_levels,
    interchange_information_extended_qualitity_grade_levels:
      resources.interchange_information.extended_qualitity_grade_levels,
  };

  return (
    <DefaultValues
      brandSettings={brandSettings}
      canManageDefaultValues={canManageDefaultValues}
      priceSheets={priceSheets}
      defaultValues={brandSettings ? defaultValues : accDefaultValues}
      segments={resources.global.segments}
      resources={defaultResources}
      updateDefaultValue={updateDefaultValues}
      createDefaultPriceTypeDescription={createDefaultPriceTypeDescriptions}
    />
  );
};

export default withContainerWrapper(SettingsDefaultValuesContainer);
