import React from 'react';
import { Input, Button, Spin } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import SettingsCustomFieldEditDrawer from '../../components/body/settings/SettingsCustomFieldEditDrawer';
import { BrandCustomField, CustomFieldGroup } from '../../../types/custom_fields';
import { AsyncDispatch } from '../../../types/global';
import {
  deleteCustomFieldGroup,
  fetchReceiverCustomFieldGroups,
  fetchSettingsCustomFields,
  updateCustomFieldGroups,
  updateCustomFieldOrder,
} from '../../actions/brand/custom_fields';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { showUpgradePlanNotification } from '../../actions/app/modal';
import { fetchScripts } from '../../actions/distribution_apisettings';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import CustomFieldsTree from './CustomFieldsTree';
import SettingsCustomFieldsGroupDrawer from '../../components/body/settings/SettingsCustomFieldsGroupDrawer';
import PageFormik from '../../components/global/page/PageFormik';

const SettingsCustomFieldsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    customFields,
    customFieldGroups,
    fetchingCustomFields,
    fetchingCustomFieldGroups,
    user,
    brandId,
    brandCode,
    scripts,
    fetchingScripts,
  } = useSelector((state: ApplicationState) => {
    return {
      customFields: state.brand.customFields.customFields,
      customFieldGroups: state.brand.customFields.customFieldGroups,
      fetchingCustomFields: state.brand.customFields.fetchingCustomFields,
      fetchingCustomFieldGroups: state.brand.customFields.fetchingCustomFieldGroups,
      user: state.user.user,
      brandId: state.parent.brands.selectedBrandId,
      brandCode: getSelectedBrandCode(state),
      scripts: state.distribution.apiSettings.scripts,
      fetchingScripts: state.distribution.apiSettings.fetchingScripts,
    };
  });

  const [customFieldsData, setCustomFieldsData] = React.useState(customFields || []);
  const [keywords, setKeywords] = React.useState('');
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showGroupDrawer, setShowGroupDrawer] = React.useState(false);
  const [selectedCustomFieldId, setSelectedCustomFieldId] = React.useState<number | null>(null);
  const [selectedGroupId, setSelectedGroupId] = React.useState<number | string | null>(null);
  const [groups, setGroups] = React.useState<CustomFieldGroup[]>([]);

  React.useEffect(() => {
    intercomEvent('viewed-brand-settings', {
      location: 'settings-custom-fields',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    if (user?.receiver_id && hasPermission(user, 'can_manage_custom_fields')) {
      dispatch(fetchSettingsCustomFields());
      dispatch(fetchReceiverCustomFieldGroups());
    }
  }, [dispatch, user]);

  React.useEffect(() => {
    if (scripts.length === 0 && !fetchingScripts) dispatch(fetchScripts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setCustomFieldsData(customFields);
    if (!fetchingCustomFieldGroups && !fetchingCustomFields) {
      const groups = customFieldGroups.map(g => ({
        ...g,
        id: `g${g.id}`,
        fields: customFields.filter(c => c.group_id === g.id),
      }));
      const ungroupedCustomFields = customFields.filter(c => c.group_id === null);
      groups.push({
        id: 'other',
        name: 'other',
        fields: ungroupedCustomFields,
        record_number: customFieldGroups.length,
      } as any);

      setGroups(groups);
    }
  }, [customFieldGroups, customFields]);

  const handleKeywordSearch = (filterKeywords: string) => {
    setKeywords(filterKeywords);
    setCustomFieldsData(
      customFields.filter(({ name }) => name.toLowerCase().includes(filterKeywords.toLowerCase()))
    );
  };

  const handleShowDrawer = (customFieldId?: number) => {
    if (!canEditSettingsCustomFields) dispatch(showUpgradePlanNotification());
    setShowDrawer(true);
    if (customFieldId) {
      setSelectedCustomFieldId(customFieldId);
      const selectedGroupId = groups.find(g => g.fields.find(f => f.id === customFieldId))?.id;
      setSelectedGroupId(selectedGroupId || null);
    }

    intercomEvent('viewed-brand-settings', {
      location: 'settings-custom-fields',
      action: customFieldId ? 'edit' : 'add_new',
      brand_code: brandCode!,
    });
  };

  const handleShowGroupDrawer = (groupId?: number) => {
    if (!canEditSettingsCustomFields) dispatch(showUpgradePlanNotification());
    setShowGroupDrawer(true);
    setSelectedGroupId(groupId || null);
  };

  const canEditSettingsCustomFields = hasPermission(user, 'can_manage_custom_fields');

  if (
    fetchingCustomFields ||
    fetchingCustomFieldGroups ||
    (customFields.length > 0 && groups.length === 0) ||
    (customFieldGroups.length > 0 && groups.length === 1 && groups[0].name === 'other')
  )
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <PageFormik
      initialValues={{
        groups: groups.map(g => ({ id: g.id, fields: g.fields.map(f => ({ id: f.id })) })),
      }}
      onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
        setSubmitPending();

        const newOrder: {
          brand_custom_field_id: number;
          record_number: number;
          group_id: number | null;
        }[] = [];
        values.groups.forEach(
          (
            g: { fields: { id: number; groupId: number | string }[]; id: number | string },
            groupIndex: number
          ) => {
            let lastRecordNumber = 0;
            if (groupIndex > 0 && newOrder.length > 0)
              lastRecordNumber = newOrder[newOrder.length - 1].record_number;
            return g.fields.forEach((f: { id: number; groupId: number | string }, i: number) => {
              newOrder.push({
                brand_custom_field_id: f.id,
                record_number: lastRecordNumber + i + 1,
                group_id:
                  g.id === 'other' || typeof g.id === 'number' ? null : Number(g.id.substring(1)),
              });
            });
          }
        );
        const sortedCustomFields = newOrder.map(f => {
          const field = customFields.find(field => field.id === f.brand_custom_field_id)!;

          return { ...field, record_number: f.record_number, group_id: f.group_id };
        });

        const updatedGroups = values.groups
          .filter((g: CustomFieldGroup) => g.id !== 'other')
          .map((g: CustomFieldGroup, i: number) => ({
            ...groups.find(group => group.id === g.id),
            id: typeof g.id === 'string' ? Number(g.id.substring(1)) : g.id,
            record_number: i + 1,
            fields: undefined,
          }));
        dispatch(updateCustomFieldGroups(updatedGroups));

        dispatch(updateCustomFieldOrder(newOrder, sortedCustomFields))
          .then(() => setSubmitSuccess())
          .catch(() => setSubmitError());
      }}
      contentNoSpacing
    >
      {({ values, initialValues, dirty, setFieldValue, resetForm, setValues }) => {
        const handleAddGroup = (name: string) => {
          const updatedGroups = customFieldGroups.map(g => ({
            ...g,
            record_number: g.record_number! + 1,
          }));
          dispatch(
            updateCustomFieldGroups([
              { name, brand_id: brandId, record_number: 1 },
              ...updatedGroups,
            ])
          ).then(result => {
            const groupData = result.value.data;
            const groups = [{ id: `g${groupData[0].id}`, fields: [] }, ...initialValues.groups];
            const newValues = [{ id: `g${groupData[0].id}`, fields: [] }, ...values.groups];
            resetForm({ values: { groups } });
            setValues({ groups: newValues });
          });
        };

        const handleUpdateGroup = (id: number, name: string) => {
          const groups = customFieldGroups.map(g => (g.id === id ? { ...g, name } : g));
          dispatch(updateCustomFieldGroups(groups));
        };

        const handleDeleteGroup = (id: number) => {
          dispatch(deleteCustomFieldGroup(id));
          const groups = initialValues.groups.filter((g: any) => g.id !== `g${id}`);
          const newValues = values.groups.filter((g: any) => g.id !== `g${id}`);
          resetForm({ values: { groups } });
          setValues({ groups: newValues });
        };

        const handleAddField = (id: number, groupId: number | null) => {
          const groups = initialValues.groups.map((g: { id: string; fields: any }) =>
            g.id === `g${groupId}` ? { ...g, fields: [...g.fields, { id }] } : g
          );
          const newValues = values.groups.map((g: { id: string; fields: any }) =>
            g.id === `g${groupId}` ? { ...g, fields: [...g.fields, { id }] } : g
          );

          resetForm({ values: { groups } });
          setValues({ groups: newValues });
        };

        const handleDeleteField = (id: number, groupId: number | null) => {
          const groups = initialValues.groups.map((g: { id: string; fields: any[] }) =>
            g.id === `g${groupId}`
              ? { ...g, fields: g.fields.filter((f: { id: number }) => f.id !== id) }
              : g
          );
          const newValues = values.groups.map((g: { id: string; fields: any[] }) =>
            g.id === `g${groupId}`
              ? { ...g, fields: g.fields.filter((f: { id: number }) => f.id !== id) }
              : g
          );

          resetForm({ values: { groups } });
          setValues({ groups: newValues });
        };

        return (
          <div className="page-layout">
            <div className="page-layout__top-bar">
              <div className="page-layout__top-bar__container">
                <Input.Search
                  className="page-layout__top-bar__search"
                  value={keywords}
                  onChange={e => handleKeywordSearch(e.target.value)}
                  placeholder={t('settings:customFields.searchCustomFields')}
                  allowClear
                />
                <div className="page-layout__top-bar__actions">
                  <Button
                    className="mr-3"
                    onClick={() => {
                      handleShowGroupDrawer();
                    }}
                  >
                    {t('settings:customFields:addNewGroup')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleShowDrawer();
                    }}
                  >
                    {t('settings:customFields:addNewCustomField')}
                  </Button>
                </div>
              </div>
            </div>
            <div className="page-layout__content">
              <AutoSizer>
                {({ width, height }) => (
                  <div style={{ width, height, overflowX: 'hidden' }}>
                    <CustomFieldsTree
                      groups={groups}
                      keywords={keywords}
                      formDirty={dirty}
                      handleGroupChange={(groups: CustomFieldGroup[]) => {
                        setFieldValue(
                          'groups',
                          groups.map(g => ({
                            id: g.id,
                            fields: g.fields.map(f => ({ id: f.id })),
                          }))
                        );
                      }}
                      onEdit={(id: number, type: string) => {
                        if (type === 'group') handleShowGroupDrawer(id);
                        else if (type === 'field') handleShowDrawer(id);
                      }}
                      collapsible
                    />
                  </div>
                )}
              </AutoSizer>

              <SettingsCustomFieldEditDrawer
                visible={showDrawer}
                canEditSettingsCustomFields={canEditSettingsCustomFields}
                closeDrawer={() => {
                  setSelectedCustomFieldId(null);
                  setSelectedGroupId(null);
                  setShowDrawer(false);
                }}
                customField={groups
                  .find(g => g.id === selectedGroupId)
                  ?.fields.find(
                    (customField: BrandCustomField) => customField.id === selectedCustomFieldId
                  )}
                customFields={customFieldsData}
                customFieldsLength={customFields.length}
                groups={groups}
                handleAddField={(id: number, groupId: number | null) => handleAddField(id, groupId)}
                handleDeleteField={(id: number, groupId: number | null) =>
                  handleDeleteField(id, groupId)
                }
              />

              <SettingsCustomFieldsGroupDrawer
                visible={showGroupDrawer}
                canEditSettingsCustomFields={canEditSettingsCustomFields}
                groupNames={groups.map(g => g.name)}
                selectedGroup={groups.find(g => g.id === selectedGroupId)}
                closeDrawer={() => {
                  setShowGroupDrawer(false);
                  setSelectedGroupId(null);
                }}
                addGroup={(name: string) => handleAddGroup(name)}
                changeGroupName={(id: number, name: string) => handleUpdateGroup(id, name)}
                deleteGroup={(id: string | number) => handleDeleteGroup(Number(id))}
              />
            </div>
          </div>
        );
      }}
    </PageFormik>
  );
};

export default SettingsCustomFieldsContainer;
