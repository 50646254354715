import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { intercomEvent } from '../../utils/IntercomUtils';
import SettingsLanguageRoutes from '../../routes/parentSettings/SettingsLanguageRoutes';
import { setFileTypeId } from '../../actions/parent/exportImport/fetch';

const { Content, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
const TranslationSettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const translationId = 13;
    intercomEvent('viewed-translation-settings');
    dispatch(setFileTypeId(translationId));
  }, [dispatch]);

  const { t } = useTranslation();

  const layoutItems: MenuItem[] = [
    {
      key: '/settings/translations/language-settings',
      label: <Link to={{ pathname: `language-settings` }}>{t('language:languageSettings')}</Link>,
    },
    {
      key: '/settings/translations/translation-settings',
      label: <Link to={{ pathname: `translation-settings` }}>{t('language:translations')}</Link>,
    },
    //     {
    // key:"/settings/translations/import-export",
    // label: <Link to={{ pathname: `import-export` }}>
    // {t('sideBarLeft:import_export')}
    // </Link>
    //     },
    {
      key: '/settings/translations/import-logs',
      label: <Link to={{ pathname: `import-logs` }}>{t('importer:importReport')}</Link>,
    },
    {
      key: '/settings/translations/export-logs',
      label: <Link to={{ pathname: `export-logs` }}>{t('exporter:exportReport')}</Link>,
    },
  ];

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          items={layoutItems}
        />
      </Sider>
      <Content className="main-page-content">
        <SettingsLanguageRoutes />
      </Content>
    </Layout>
  );
};

export default TranslationSettingsPage;
