import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { Resources, Global, Vehicle, Analysis } from '../../../types/resources';
import { convertConfigIdsNameToName } from '../../utils/ApplicationUtils';
import resourcesMap from '../../constants/ApplicationResourcesMapping.json';

const resourcesMapping = resourcesMap as { [key: string]: string };

export type ResourcesState = {
  readonly fetching: boolean;
  readonly initialAccountResourcesFetching: boolean;
  readonly error: boolean;
  readonly data: Resources | { global: Global; [key: string]: any };
  readonly applicationVehicle: Vehicle | { [key: string]: any };
  readonly analysis: Analysis;
  readonly fetchingApplicationResources: boolean;
  readonly errorApplicationResources: boolean;
};

const initialState: ResourcesState = {
  fetching: true,
  initialAccountResourcesFetching: true,
  fetchingApplicationResources: false,
  errorApplicationResources: false,
  error: false,
  data: {
    global: {
      parent_owner_brands: [],
      languages: [],
      countries: [],
      measurement_uom_codes: [],
      gtin_qualifiers: [],
      export_schemas: [],
      format_types: [],
      view_product_options: [],
      segments: [],
      log_error_types: [],
      maintenance_types: [],
      file_name_config_options: [],
      file_name_templates: [],
      category_versions: '',
      part_attribute_padb_versions: '',
      qdb_versions: '',
      vehicle_versions: '',
      translation_types: [],
      vehicle_config_types: [],
      sources: [],
      vehicle_sub_config_types: [],
      import_sources: [],
    },
  },
  applicationVehicle: {},
  analysis: {} as Analysis,
};

const reducer: Reducer<ResourcesState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INITIAL_RESOURCES_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_INITIAL_RESOURCES_FULFILLED': {
      const applicationVehicle = state.initialAccountResourcesFetching
        ? state.applicationVehicle
        : {
            ...state.applicationVehicle,
            positions: action.payload.vehicle.positions,
            qualifier_types: action.payload.vehicle.qualifier_types,
          };
      return { ...state, fetching: false, data: action.payload, applicationVehicle };
    }
    case 'FETCH_INITIAL_RESOURCES_REJECTED': {
      return { ...state, fetching: false, error: true };
    }
    case 'FETCH_APPLICATION_RESOURCES_PENDING': {
      const initial = action.meta.initial;
      return {
        ...state,
        initialAccountResourcesFetching: !!initial,
        fetchingApplicationResources: true,
        errorApplicationResources: false,
      };
    }
    case 'FETCH_APPLICATION_RESOURCES_REJECTED': {
      return { ...state, initialAccountResourcesFetching: false, errorApplicationResources: true };
    }
    case 'FETCH_APPLICATION_RESOURCES_FULFILLED': {
      const positions = state.data.vehicle?.positions || [];
      const qualifier_types = state.data.vehicle?.qualifier_types || [];
      return {
        ...state,
        applicationVehicle: {
          ...action.payload.data.vehicles,
          positions,
          qualifier_types,
        },
        analysis: action.payload.data.analysis,
        initialAccountResourcesFetching: false,
        fetchingApplicationResources: false,
      };
    }
    case 'DELETE_CUSTOM_SUB_CONFIG_PENDING': {
      const { subConfigKey, subConfigId } = action.meta;
      const key = convertConfigIdsNameToName(`${subConfigKey}s`);
      const resourcesKey = resourcesMapping.hasOwnProperty(key) ? resourcesMapping[key] : key;
      const newResources = state.applicationVehicle[resourcesKey].filter(
        (r: { id: any }) => r.id !== subConfigId
      );
      return {
        ...state,
        applicationVehicle: { ...state.applicationVehicle, [resourcesKey]: newResources },
      };
    }
  }
  return state;
};

export default reducer;
