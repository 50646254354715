import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../components/global/ErrorBoundary';
import { ApplicationState } from '../reducers';

interface StateProps {
  fetchingResources: boolean;
  fetchingAccountResources: boolean;
}

interface OwnProps {
  children: React.ReactNode;
}

type Props = StateProps & OwnProps;

class ContainerWrapperAllResources extends React.Component<Props> {
  render() {
    const { fetchingResources, fetchingAccountResources, children } = this.props;
    return (
      <ErrorBoundary>
        {fetchingResources || fetchingAccountResources ? null : children}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  fetchingResources: state.resources.fetching,
  fetchingAccountResources: state.resources.fetchingApplicationResources,
});

const ConnectedContainerWrapper = connect(mapStateToProps)(ContainerWrapperAllResources);

export const withContainerWrapperAllResources =
  (Component: React.ComponentType<any>) => (props: any) =>
    (
      <ConnectedContainerWrapper>
        <Component {...props} />
      </ConnectedContainerWrapper>
    );

export default ConnectedContainerWrapper;
