import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { mapItemsToCustomFields } from '../../selectors/custom_fields/customFieldsSelector';
import { fetchCustomFields } from '../../actions/items/custom_fields/fetch';
import ItemInfo from '../../components/global/ItemInfo';
import CustomFieldsItemTable from '../../components/body/custom_fields/CustomFieldsItemTable';
import { AsyncDispatch } from '../../../types/global';
import { updateCustomFields } from '../../actions/items/custom_fields/update';
import { intercomEvent } from '../../utils/IntercomUtils';
import { runScripts } from '../../actions/distribution_apisettings';
import { ItemCustomField } from '../../../types/custom_fields';
import { isReceiver } from '../../utils/UserUtils';
import {
  fetchReceiverCustomFieldGroups,
  fetchSettingsCustomFields,
} from '../../actions/brand/custom_fields';

const CustomFieldsItemContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const selectedItemIds = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.selectedItemIds
  );
  const customFields = useSelector((state: ApplicationState) => mapItemsToCustomFields(state));
  const customFieldGroups = useSelector(
    (state: ApplicationState) => state.brand.customFields.customFieldGroups
  );
  const fetchingCustomFieldGroups = useSelector(
    (state: ApplicationState) => state.brand.customFields.fetchingCustomFieldGroups
  );
  const fetchingBrandCustomFields = useSelector(
    (state: ApplicationState) => state.brand.customFields.fetchingCustomFields
  );
  const fetchingCustomFields = useSelector(
    (state: ApplicationState) => state.items.customFields.isFetching
  );
  const brandId = useSelector((state: ApplicationState) => state.parent.brands.selectedBrandId);
  const selectedItem = useSelector((state: ApplicationState) => getSelectedItems(state)[0]);
  const user = useSelector((state: ApplicationState) => state.user.user);
  const receiver = useMemo(() => isReceiver(user!), [user]);
  const customFieldsBrandId = useSelector(
    (state: ApplicationState) => state.brand.customFields.customFieldsBrandId
  );

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'custom-fields-item',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItemIds.length) dispatch(fetchCustomFields(selectedItemIds));
  }, [dispatch, selectedItemIds]);

  React.useEffect(() => {
    if (
      receiver &&
      selectedItem &&
      !fetchingBrandCustomFields &&
      selectedItem.parent_owner_brand_id !== customFieldsBrandId
    ) {
      dispatch(fetchSettingsCustomFields(selectedItem.parent_owner_brand_id));
    }
  }, [customFieldsBrandId, dispatch, fetchingBrandCustomFields, receiver, selectedItem, user]);

  React.useEffect(() => {
    if (receiver) {
      dispatch(fetchReceiverCustomFieldGroups());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groups = React.useMemo(() => {
    const groupList = customFieldGroups.map(g => ({
      ...g,
      fields: customFields.filter(c => c.group_id === g.id && !c.read_only_custom_field),
    }));

    const ungroupedCustomFields = customFields.filter(
      c => c.group_id === null && !c.read_only_custom_field
    );
    groupList.push({
      id: 'other',
      name: 'other',
      fields: ungroupedCustomFields,
      record_number: customFieldGroups.length,
    });

    if (receiver) {
      // read_only_custom_field are fields that are shared by a brand and added to the top of the
      // list of receiver custom fields readonly
      const sharedBrandFields = customFields.filter(c => c.read_only_custom_field === 1);

      groupList.unshift({
        id: 'sharedbyBrand',
        name: t('segments:customFields.readOnlyCustomField'),
        fields: sharedBrandFields,
      });
    }
    return groupList;
  }, [customFieldGroups, customFields, receiver, t]);

  const handleRunScript = async (scriptId: number) => {
    const runPromise = dispatch(runScripts({ scriptId, itemIds: selectedItemIds }));
    message.info(t('api:runningScript'));
    // timeout to show spinner in button
    await new Promise(r => {
      setTimeout(r, 500);
    });
    return runPromise;
  };

  if (!selectedItemIds.length || selectedItemIds.length > 1) {
    return <ItemInfo items={selectedItemIds} />;
  }

  const props = {
    fetchingData: fetchingCustomFields || fetchingCustomFieldGroups,
    customFields,
    brandId,
    groups,
    updateCustomFields: (values: any) => {
      const itemValues: { id: number; values: { item_id: any; value: string }[] }[] = [];
      const fields: ItemCustomField[] = [];
      values.groups.forEach((group: any) => {
        group.fields.forEach((customField: ItemCustomField) => {
          itemValues.push({
            id: customField.id,
            values: [
              {
                item_id: selectedItem?.id,
                value: customField.value,
              },
            ],
          });
          fields.push({
            ...customField,
            item_id: selectedItem.id,
            brand_custom_field_id: customField.id,
          });
        });
      });
      return dispatch(updateCustomFields(itemValues, fields));
    },
  };

  return <CustomFieldsItemTable {...props} runScript={handleRunScript} />;
};

export default CustomFieldsItemContainer;
