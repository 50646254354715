import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAccountLanguages } from '../../../actions/parent/language/fetch';
import TranslationsPage from '../../../components/body/parentSettings/translations/TranslationsPage';
import NoDataInfo from '../../../components/global/NoDataInfo';
import { ApplicationState } from '../../../reducers';

const TranslationsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { accountLanguages, fetchingAccountLanguages } = useSelector((state: ApplicationState) => {
    return {
      accountLanguages: state.parent.languageSettings.accountLanguages,
      fetchingAccountLanguages: state.parent.languageSettings.isFetching,
    };
  });

  React.useEffect(() => {
    if (!fetchingAccountLanguages && accountLanguages.length === 0)
      dispatch(fetchAccountLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationState: { segmentId: number } = location.state as any;

  const handleAddLanguage = () => navigate(`/settings/translations/language-settings`);

  if (accountLanguages.length === 0)
    return <NoDataInfo handleAddNew={handleAddLanguage} buttonName={t('language:addLanguage')} />;

  return <TranslationsPage segmentId={location.state ? locationState.segmentId : 1} />;
};

export default TranslationsContainer;
