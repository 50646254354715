import React, { useMemo } from 'react';
import { Button, Table, Tooltip, App } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Column from 'antd/lib/table/Column';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HazardousMaterial, MultiValueFields, Package } from '../../../../types/itemPackage';
import PackageForm from './PackageForm';
import HazardousMaterialTable from './HazardousMaterialTable';
import { ApplicationState } from '../../../reducers';
import { DefaultValue } from '../../../../types/brand_settings';
import { AnalysisType, ExtendedResources, StandardResource } from '../../../../types/resources';
import { generateUUID } from '../../../utils/Utils';
import PackageDrawer from './PackageDrawer';
import { AllPackageInputStrings } from '../../../constants/PackageConstants';
import AnalysesAlertIcon from '../../global/AnalysesAlertIcon';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import { Analysis } from '../../../../types/analyses';
import { brandAccDefaultValues } from '../../../selectors/default_values/defaultValuesSelector';

type PackageTableProps = {
  extendedItemPackages: Package[];
  handlePkgDelete: (pkgRecordId: number | string) => void;
  allSelectedItemIds: number[];
  multipleValueFields?: MultiValueFields[];
  expandedKeys: (string | number)[];
  showAllFields: boolean;
  handleRowExpand: (id: string | number) => void;
  getFilteredPackageUoms: (uomId: number) => ExtendedResources[];
  handleMultiEdit: (field: string, uomId: number) => void;
  updatePackage: (updatedPkgRecord: Package) => void;
  handleUpdateHzMaterials: (updatedHzMaterials: HazardousMaterial[], pkgIndex: number) => void;
};

const PackageTable: React.FC<PackageTableProps> = ({
  extendedItemPackages,
  handlePkgDelete,
  allSelectedItemIds,
  multipleValueFields,
  expandedKeys,
  showAllFields,
  handleRowExpand,
  getFilteredPackageUoms,
  handleMultiEdit,
  updatePackage,
  handleUpdateHzMaterials,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { defaultLanguage, weightUoms, allItemPackages, analysesBySegment } = useSelector(
    (state: ApplicationState) => ({
      defaultLanguage: brandAccDefaultValues(state).find(
        (defValue: DefaultValue) => defValue.resource_table === 'languages'
      ),
      weightUoms: state.resources.data.package.weight_uoms as StandardResource[],
      allItemPackages: state.items.packageSeg.itemPackages,
      analysesBySegment: extendedAnalysesBySegment(state),
    })
  );

  const multipleValues = !!(allSelectedItemIds.length > 1);

  const [expandHzKeys, setExpandHzKeys] = React.useState<(number | string)[]>([]);
  const [pkgDrawerVisible, setPkgDrawerVisible] = React.useState<boolean>(false);
  const [pkgDrawerTitle, setPkgDrawerTitle] = React.useState<string>('');
  const [uomId, setUomId] = React.useState<number>();
  const [uomName, setUomName] = React.useState<string>('');
  const [showCreatePkg, setShowCreatePkg] = React.useState<boolean>(false);

  const extendedItemPackageAnalyses = useMemo(() => {
    return extendedItemPackages.map(expackage => {
      const analyses = analysesBySegment.filter((a: Analysis & AnalysisType) => {
        return a.reference_id === expackage.id;
      });
      return { ...expackage, analyses };
    });
  }, [analysesBySegment, extendedItemPackages]);

  const getDisplayText = (record: Package) => {
    if (allSelectedItemIds.length > 1) {
      const pkgCount = allItemPackages.filter(ip =>
        ip.packages.find(pkg => pkg.uom_id === record.uom_id)
      ).length;

      const deletePkg = {
        // when we try to delete unsaved package on multi edit we show all items count
        pkgCount: pkgCount === 0 ? allSelectedItemIds.length : pkgCount,
        uomName: record.uomName || '',
        selectedItems: allSelectedItemIds.length,
      };
      return t('packageSeg:deleteMultiPackages', { deletePkg });
    }
    return t('packageSeg:deletePackage', { uomName: record.uomName || '' });
  };

  const handleDelete = (record: Package) =>
    modal.confirm({
      title: getDisplayText(record),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        handlePkgDelete(record.id);
      },
    });

  const handleHzDelete = (hzRecord: HazardousMaterial, pkgIndex: number) => {
    const updatedHzMaterials = extendedItemPackages[pkgIndex].hazardous_materials.filter(
      (hz: HazardousMaterial) => hz.id !== hzRecord.id
    );
    handleUpdateHzMaterials(updatedHzMaterials, pkgIndex);
  };

  const getUomMultiValueFields = (recordUomId: number) =>
    multipleValues
      ? multipleValueFields?.find(uom => uom.uomId === recordUomId)?.multiValues
      : undefined;

  const getPackageTitle = (name: string) => {
    switch (name) {
      case 'quantity_of_eaches': {
        return `${t('packageSeg:package.quantityEach')}`;
      }
      case 'inner_quantity': {
        return `${t('packageSeg:package.innerQuantity')}`;
      }
      case 'width': {
        return `${t('packageSeg:package.shippingDimensions')}`;
      }
      case 'weight': {
        return `${t('packageSeg:package.weight')}`;
      }
      case 'weight_variance': {
        return `${t('packageSeg:package.weightVariance')}`;
      }
      case 'merchandising': {
        return `${t('packageSeg:package.merchandisingDimensions')}`;
      }
      case 'orderable_option_id': {
        return `${t('packageSeg:package.orderable')}`;
      }
      case 'stacking_factor': {
        return `${t('packageSeg:package.stackingFactor')}`;
      }
      case 'level_gtin': {
        return `${t('packageSeg:package.levelGtin')}`;
      }
      case 'electronic_product_code': {
        return `${t('packageSeg:package.electronicProductCode')}`;
      }
      case 'bar_code_characters': {
        return `${t('packageSeg:package.barcodeChar')}`;
      }
      default: {
        return ``;
      }
    }
  };

  const addNewHz = (count: number, pkgIndex: number) => {
    const uuid = generateUUID();
    const newHz = {
      id: uuid,
      language_id: defaultLanguage?.value ? Number(defaultLanguage.value) : null,
      shipping_scope_id: null,
      bulk_id: null,
      regulating_country_id: null,
      transport_method_id: null,
      regulated_option_id: null,
      label_id: null,
      class_id: null,
      qualifier_id: null,
      packing_group_id: null,
      whmis_code_id: null,
      whmis_free_text: null,
      description: null,
      hazardous_material_description: null,
      shipping_name: null,
      unnaid_code: null,
      regulations_exemption_code: null,
      text_message: null,
      outer_package_label: null,
      recordName: `${t('packageSeg:hazardous.hz')} ${count + 1}`,
      summary: '',
      temp: true,
    } as HazardousMaterial;
    const hzMaterials = [newHz, ...extendedItemPackages[pkgIndex].hazardous_materials];
    handleUpdateHzMaterials(hzMaterials, pkgIndex);
    setExpandHzKeys([newHz.id]);
  };

  const updateHzMaterial = (
    updatedHz: HazardousMaterial,
    allHzs: HazardousMaterial[],
    pkgIndex: number
  ) => {
    const hzMaterials = allHzs.map(hz => (hz.id === updatedHz.id ? updatedHz : hz));
    handleUpdateHzMaterials(hzMaterials, pkgIndex);
  };

  const getUpdatedRecords = (fieldName: string, value: any, record: Package) => {
    if (fieldName === 'weights_uom_id')
      return {
        ...record,
        weights_uom_id: value,
        weightName: weightUoms.find(uom => uom.id === value)?.name || '',
      };
    if (fieldName === 'uom_id')
      return {
        ...record,
        uom_id: value,
        uomName: getFilteredPackageUoms(record.uom_id).find(uom => uom.id === value)?.name,
        temp: allSelectedItemIds.length > 1 || false, // required to create or update package
      };
    return { ...record, [fieldName]: value };
  };

  const renderHZInfo = () => (
    <div className="bg-white flex items-center">
      <div className="hz-edit-info">{t('packageSeg:hazardous.hz')}</div>
      <div>{t('packageSeg:hzEditInfo')}</div>
    </div>
  );

  return (
    <React.Fragment>
      <Table
        className="package-table"
        onRow={(record: any) => ({
          onClick: () => {
            handleRowExpand(record.id);
          },
        })}
        expandable={{
          onExpand: (expanded, record) => {
            handleRowExpand(record.id);
          },
          expandedRowKeys: expandedKeys,
          expandedRowRender: (record: Package, index: number) => (
            <div className="package-sub-fields">
              <PackageForm
                itemPackage={record}
                index={index}
                uoms={getFilteredPackageUoms(record.uom_id)}
                multipleValueField={getUomMultiValueFields(record.uom_id)}
                showAllFields={showAllFields}
                showMultiEditIcon={allSelectedItemIds.length > 1}
                handleMultiEdit={(field: string) => {
                  handleMultiEdit(field, record.uom_id);
                }}
                handleDrawerVisibility={(pkgName, visibility) => {
                  setPkgDrawerVisible(visibility);
                  setPkgDrawerTitle(getPackageTitle(pkgName));
                  setUomId(record.uom_id);
                  setUomName(record.uomName || '');
                  setShowCreatePkg(
                    getUomMultiValueFields(record.uom_id)?.length ===
                      AllPackageInputStrings.length || (record.temp ? record.temp : false)
                  );
                }}
                updatePackage={(fieldName, value) => {
                  const updatedPackageRecord = getUpdatedRecords(fieldName, value, record);
                  updatePackage(updatedPackageRecord);
                }}
              />
              <Button
                size="small"
                className="package__add-hz"
                onClick={() => addNewHz(record.hazardous_materials.length, index)}
                disabled={!record.uom_id || !record.quantity_of_eaches || multipleValues}
              >
                {t('packageSeg:hazardous.addHZ')}
              </Button>
              {multipleValues
                ? renderHZInfo()
                : !!record.uom_id &&
                  !!record.quantity_of_eaches &&
                  record.hazardous_materials.length > 0 && (
                    <HazardousMaterialTable
                      hzRecords={record.hazardous_materials}
                      pkgIndex={index}
                      expandHzKeys={expandHzKeys}
                      handleHzDelete={handleHzDelete}
                      showAllFields={showAllFields}
                      updateHzMaterial={updatedHzRecord =>
                        updateHzMaterial(updatedHzRecord, record.hazardous_materials, index)
                      }
                    />
                  )}
            </div>
          ),
          expandRowByClick: true,
        }}
        dataSource={extendedItemPackageAnalyses}
        showHeader={false}
        pagination={false}
        size="small"
        rowKey={(record: Package) => record.id}
      >
        <Column
          width={15}
          key="analyses"
          dataIndex="analyses"
          render={analyses => <AnalysesAlertIcon analyses={analyses} />}
          align="left"
          className="package__analyses-alert-icon"
        />
        <Column
          width={250}
          key="packageName"
          dataIndex="uomName"
          render={(record: string) => <div className="package-type">{record}</div>}
        />
        <Column
          key="summary"
          ellipsis
          render={(record: Package) => (
            <Tooltip
              title={record.detailedSummary?.map(s => (
                <div key={s}>{s}</div>
              ))}
              placement="topLeft"
            >
              <div className="truncate ...">{record.summary}</div>
            </Tooltip>
          )}
        />
        <Column
          key="action"
          width={80}
          render={(record: Package) => (
            <Button
              danger
              size="small"
              className="w-30 ml-2 mb-2"
              onClick={e => {
                handleDelete(record);
                e.stopPropagation();
              }}
              style={{ margin: 0 }}
              data-testid="pkg-delete"
            >
              {t('common:delete')}
            </Button>
          )}
        />
      </Table>

      <PackageDrawer
        uomId={uomId}
        visible={pkgDrawerVisible}
        title={pkgDrawerTitle}
        onClose={() => setPkgDrawerVisible(false)}
        uomName={uomName}
        showCreatePkg={showCreatePkg}
      />
    </React.Fragment>
  );
};

export default PackageTable;
