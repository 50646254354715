import { Col, Input, Row, Select, Tooltip, Space } from 'antd';
import React from 'react';
import { ExtendedResources } from '../../../../types/resources';
import PackageDrawerReplaceButton from './PackageDrawerReplaceButton';

type PackageDrawerInputSelectProps = {
  infoText: string;
  disabled?: boolean;
  inputValue?: string | number;
  handleInputChange: (e: any) => void;
  selectValue?: number;
  testId?: string;
  handleSelectChange: (value: any) => void;
  options: ExtendedResources[];
  displayOk?: boolean;
  handleReplaceAll: () => void;
  handleOnBlur?: () => void;
  disableReplaceAll?: boolean;
  selectDisable?: boolean;
};

const PackageDrawerInputSelect: React.FC<PackageDrawerInputSelectProps> = ({
  infoText,
  disabled,
  inputValue,
  handleInputChange,
  testId,
  selectValue,
  handleSelectChange,
  options,
  displayOk,
  handleReplaceAll,
  handleOnBlur,
  disableReplaceAll,
  selectDisable,
}) => (
  <Tooltip title={infoText}>
    <Space.Compact className="package-drawer-field">
      <Input
        disabled={disabled}
        size="small"
        value={inputValue}
        onChange={e => handleInputChange(e)}
        onBlur={handleOnBlur}
        style={{ width: '25%' }}
        data-testid={testId}
      />
      <div className="w-9/12">
        <Row>
          <Col flex="auto">
            <Select
              size="small"
              disabled={disabled || selectDisable}
              allowClear
              value={selectValue}
              showSearch
              onChange={value => handleSelectChange(value)}
              style={{ width: '100%' }}
              options={options.map(uom => ({
                key: uom.id,
                value: uom.id,
                label: uom.name,
              }))}
            />
          </Col>
          {displayOk && (
            <Col>
              <PackageDrawerReplaceButton
                handleReplace={handleReplaceAll}
                disableReplaceAll={disableReplaceAll}
              />
            </Col>
          )}
        </Row>
      </div>
    </Space.Compact>
  </Tooltip>
);

export default PackageDrawerInputSelect;
