import { Drawer, Form, Button, Spin, Card } from 'antd';
import { Formik } from 'formik';
import { get as _get } from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LoadingState } from '../../../utils/ReduxUtils';
import headerConstants from '../../../constants/HeaderTranslation.json';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import FormCheckboxGroup, { getCheckable } from '../../global/Forms/FormCheckboxGroup';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import catalougeActions from '../../../actions/catalogue/catalogue';
import createItemActions from '../../../actions/catalogue/createItem';
import { ApplicationState } from '../../../reducers';
import { SubBrand } from '../../../../types/brand';
import { CatalogueQueryParams } from '../../../../types/catalogue';
import { Item } from '../../../../types/item';
import { FilterTypeGo } from '../../../../types/filter';
import { DefaultValue } from '../../../../types/brand_settings';
import { Indexable, ThunkAsyncDispatch } from '../../../../types/global';
import { brandAccDefaultValues } from '../../../selectors/default_values/defaultValuesSelector';

const cloneItemsConstants: Indexable = headerConstants.clone_item;

type CatalogueCopyProductDrawerProps = {
  dispatch: ThunkAsyncDispatch;
  brandId: number;
  subBrands: SubBrand[];
  fetchingMappedSegments: string;
  catalogueQueryParams: CatalogueQueryParams;
  mappedSegments: {
    id: string;
    disabled: boolean;
    name: any;
  }[];
  selectedItems: Item[];
  checkAbleSegments: string[];
  selectedFilterGo?: FilterTypeGo;
  defaultValues: DefaultValue[];
  visible: boolean;
  onClose: () => void;
} & WithTranslation;

class CatalogueCopyProductDrawer extends React.Component<CatalogueCopyProductDrawerProps> {
  componentDidMount() {
    this.props.dispatch(createItemActions.fetchMappedSegments(this.props.selectedItems[0].id));
  }

  handleCloneSubmit = (values: { partNumber: string; subBrand?: number; segments: string[] }) => {
    const { partNumber, subBrand, segments } = values;
    return this.props
      .dispatch(
        // @ts-ignore
        catalougeActions.cloneItem({
          itemId: this.props.selectedItems[0].id,
          partNumber,
          brandId: this.props.brandId,
          subBrandId: subBrand,
          segments,
          currentFilterId: this.props.selectedFilterGo!.id,
          selectedReceiverId: this.props.catalogueQueryParams.selectedReceiverId,
          selectedChannelId: this.props.catalogueQueryParams.selectedChannelId,
          languageId: this.props.defaultValues.find(d => d.resource_table === 'languages')?.value,
          order: this.props.catalogueQueryParams.order,
        })
      )
      .then(() => this.props.onClose());
  };

  render() {
    const {
      t,
      selectedItems,
      visible,
      onClose,
      subBrands,
      fetchingMappedSegments,
      mappedSegments,
      checkAbleSegments,
    } = this.props;
    const itemToCopy = selectedItems[0];
    return (
      <Drawer
        title={t('catalogue:copyDrawer.cloneProduct')}
        width={400}
        open={visible}
        onClose={onClose}
      >
        {itemToCopy && (
          <Card title={t('catalogue:copyDrawer.originalProduct')}>
            <div>
              <div>
                <span>{`${t('catalogue:copyDrawer.partNumber')}:`}</span>
                <br />
                <span>{itemToCopy.part_number}</span>
              </div>
              <br />
              <div>
                <span>{`${t('catalogue:copyDrawer.previewName')}:`}</span>
                <br />
                <span>{itemToCopy.short_name}</span>
              </div>
            </div>
          </Card>
        )}

        {fetchingMappedSegments === LoadingState.LOADING_DONE ? (
          <Formik
            initialValues={{
              partNumber: '',
              subBrand: undefined,
              segments: checkAbleSegments,
            }}
            validationSchema={Yup.object().shape({
              partNumber: Yup.string().required(t('validation:required')),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) =>
              this.handleCloneSubmit(values)
                .catch(e => {
                  const message = _get(e, 'response.data.message');
                  const status = _get(e, 'response.status');
                  if (message && status === 422) {
                    setFieldError('partNumber', message);
                  }
                })
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit, setFieldValue }) => (
              <Form className="copy-drawer-form" layout="vertical">
                <FormInput
                  name="partNumber"
                  label={t('catalogue:copyDrawer.partNumber')}
                  placeholder={t('catalogue:copyDrawer.placeholderPartNumber')}
                  required
                />
                <FormSelect
                  name="subBrand"
                  label={t('catalogue:copyDrawer.subBrand')}
                  placeholder={t('catalogue:copyDrawer.placeholderSubBrand')}
                  values={subBrands}
                  allowClear
                />
                <FormCheckboxGroup
                  name="segments"
                  setFieldValue={setFieldValue}
                  elements={mappedSegments}
                />
                <Button
                  className="copy-drawer-form__submit"
                  disabled={isSubmitting}
                  type="primary"
                  size="small"
                  onClick={() => handleSubmit()}
                >
                  {t('catalogue:copyDrawer.submit')}
                </Button>
              </Form>
            )}
          </Formik>
        ) : (
          <Spin />
        )}
      </Drawer>
    );
  }
}

const mapSegments = (segments: { [key: string]: boolean }) =>
  Object.entries(segments).map(([id, enabled]) => ({
    id,
    disabled: !enabled,
    name: cloneItemsConstants[id],
  }));

const mapStateToProps = (state: ApplicationState) => {
  const selectedItems = getSelectedItems(state);
  const mappedSegments = mapSegments(state.catalogue.createItem.mappedSegments || {});
  const checkAbleSegments = getCheckable(mappedSegments);
  return {
    brandId: state.parent.brands.selectedBrandId,
    subBrands: state.brand.currentBrand.subBrands,
    fetchingMappedSegments: state.catalogue.createItem.fetchingMappedSegments,
    catalogueQueryParams: state.catalogue.catalogue.catalogueQueryParams,
    mappedSegments,
    selectedItems,
    checkAbleSegments,
    selectedFilterGo: state.catalogue.filter.filterGo,
    defaultValues: brandAccDefaultValues(state),
  };
};

export default connect(mapStateToProps)(withTranslation()(CatalogueCopyProductDrawer));
