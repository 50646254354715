import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import VcdbEdit from './VcdbEdit';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

type VcdbDrawerProps = {
  visible: boolean;
  onClose: () => void;
} & WithTranslation &
  WithAntdContextProps;

class VcdbDrawer extends React.Component<VcdbDrawerProps> {
  closeDrawer = (dirty: boolean, force?: boolean) => {
    const { t, onClose } = this.props;
    const { modal } = this.props.appContext;

    if (!dirty || force) {
      onClose();
    } else {
      modal.confirm({
        title: t('common:closeWarningTitle'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onClose();
        },
      });
    }
  };

  render() {
    return <VcdbEdit visible={this.props.visible} onClose={this.closeDrawer} />;
  }
}

export { VcdbDrawer };
export default withContainerWrapper(withTranslation()(withAntdContext(VcdbDrawer)));
