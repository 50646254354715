import React from 'react';
import Dropdown from '../../../global/Dropdown';
import TextInput from '../../../global/TextInput';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';

const { information_asset: translation } = digitalAssetConstants;
class DigitalAssetInformationAsset extends React.Component {
  updateUri = (isUri, val) => {
    if (!isUri) return;
    const { selectedDigitalAsset } = this.props;
    this.props.updateAssetInformation(selectedDigitalAsset.digital_asset_id, 'uri', val);
  };

  showSource = () => {
    const { uri, id, asset_url: assetUrl } = this.props.selectedDigitalAsset;
    const isUri = !!uri;
    const link = uri || assetUrl;
    const label = isUri ? translation.linked_uri : translation.saved_server;

    return (
      <div className="form-field vertical">
        <div>{label}</div>
        <TextInput
          key={id}
          readOnly={!isUri}
          value={link}
          selectedDigitalAsset
          onChange={val => this.updateUri(isUri, val)}
        />
      </div>
    );
  };

  render() {
    const { selectedDigitalAsset, digitalAssetResources } = this.props;
    const fileTypeDropdownElements = digitalAssetResources.file_types.map(type => ({
      id: type.id,
      name: type.code,
    }));
    return (
      <div className="digital-asset-information__asset">
        <div className="title">{translation.asset_specific}</div>
        <div className="form-field vertical">
          <div className="form-field-name">{translation.file_name}</div>
          <TextInput
            id="file-name"
            key={selectedDigitalAsset.id}
            value={selectedDigitalAsset.file_name}
            readOnly
          />
        </div>
        <div className="form-field vertical">
          <div className="form-field-name">{translation.file_type}</div>
          <Dropdown
            key={selectedDigitalAsset.id}
            noSelection={!selectedDigitalAsset.file_type_id}
            className="digital-asset-information__dropdown"
            selectedElement={selectedDigitalAsset.file_type_id}
            elements={fileTypeDropdownElements}
            onChange={val =>
              this.props.updateAssetInformation(
                selectedDigitalAsset.digital_asset_id,
                'file_type_id',
                Number(val)
              )
            }
          />
        </div>
        {this.props.area === 'application' && (
          <div className="form-field vertical">
            <span>{translation.asset_name}</span>
            <TextInput
              key={selectedDigitalAsset.id}
              className="form-field-content"
              value={selectedDigitalAsset.asset_name}
              maxLength={40}
              onChange={value =>
                this.props.updateAssetInformation(
                  selectedDigitalAsset.digital_asset_id,
                  'asset_name',
                  value
                )
              }
            />
          </div>
        )}
        {this.showSource()}
      </div>
    );
  }
}

export default DigitalAssetInformationAsset;
