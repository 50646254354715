import { Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showModal } from '../../actions/app/modal';
import { showParentDigitalAssets } from '../../actions/parent/digital_asset';
import {
  resetUploadInformation,
  setFileUploadInformation,
  setUploadInformation,
} from '../../actions/parent/digital_asset_upload';
import { addUploadedDigitalAsset } from '../../actions/parent/marketing_copy/update';
import constants from '../../constants/DigitalAssetsConstants.json';
import { MODAL_TYPES } from '../../constants/ModalConstants';
import DigitalAssetsParentContainer from '../media_and_marketing_copy/DigitalAssetsParentContainer';

type ParentDigitalAssetsDrawerProps = {
  digitalAssets?: any;
  area: string;
};

const ParentDigitalAssetsDrawer: React.FC<ParentDigitalAssetsDrawerProps> = ({
  digitalAssets,
  area,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(resetUploadInformation());
  }, [dispatch]);

  return (
    <Drawer
      className="digital-assets__parent-drawer"
      title={t('digitalAssets:parentDigitalAssets')}
      open
      width="80%"
      onClose={() => {
        dispatch(showParentDigitalAssets(false));
      }}
      destroyOnClose
      style={{ height: '100%' }}
    >
      <DigitalAssetsParentContainer
        handleSelectAsset={(digitalAsset: any) => {
          dispatch(setUploadInformation(constants.popupType.parentDigitalAssets, area));
          dispatch(setFileUploadInformation('fileName', digitalAsset.file_name));
          dispatch(setFileUploadInformation('sourceUrl', digitalAsset.shop_preview));
          dispatch(setFileUploadInformation('fileTypeId', digitalAsset.file_type_id));
          dispatch(setFileUploadInformation('assetName', digitalAsset.file_name.split('.')[0]));

          if (area === 'marketing_copy') {
            dispatch(addUploadedDigitalAsset({ ...digitalAsset, type_id: 19 }));
            dispatch(showParentDigitalAssets(false));
          } else
            dispatch(
              showModal(MODAL_TYPES.DIGITAL_ASSET_UPLOAD, {
                parentAssetInfo: {
                  digitalAssetId: digitalAsset.id,
                  existingFile: !!digitalAssets?.find(
                    (dA: any) => dA.unique_key.localeCompare(digitalAsset.unique_key) === 0
                  ),
                },
              })
            );
        }}
      />
    </Drawer>
  );
};

export default ParentDigitalAssetsDrawer;
