import React from 'react';
import { connect } from 'react-redux';
import { Button, Tag } from 'antd';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { WithRouterProps, withRouter } from '../../../containers/withRouter';
import resourcesMap from '../../../constants/ApplicationResourcesMapping.json';
import {
  getVehicleResources,
  mapConfigIdToName,
  mergeApplicationRanks,
} from '../../../selectors/applications/applicationsSelector';
import ApplicationDetails from './ApplicationDetails';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { Application, RankedApplications, VcdbVersion } from '../../../../types/application';
import { Vehicle, VehicleStructureClass } from '../../../../types/resources';
import { UserType } from '../../../../types/user';
import {
  mapConfigToApplication,
  convertConfigNameToIds,
  getConfigGroupName,
  getSingularConfigGroupName,
  convertConfigNameToId,
} from '../../../utils/ApplicationUtils';
import {
  fetchRankedApplications,
  fetchApplicationResources,
} from '../../../actions/items/application/fetch';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import {
  addCustomSubConfig,
  changeCustomVehicle,
  updateCustomVehicleConfigs,
} from '../../../actions/items/application/update';
import {
  deleteCustomConfig,
  deleteCustomSubConfig,
} from '../../../actions/items/application/delete';
import DrawerSave from '../../global/drawer/DrawerSave';
import Page from '../../global/page/Page';
import { hasPermission } from '../../../utils/Permissions';
import { intercomEvent } from '../../../utils/IntercomUtils';
import FormRouterPrompt from '../../global/Forms/FormRouterPrompt';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

const resourcesMapping = resourcesMap as { [key: string]: string };

type VcdbEditProps = {
  dispatch: AsyncDispatch;
  visible: boolean;
  page: boolean;
  rankedApplications: RankedApplications;
  resources: Vehicle;
  applicationStructure: VehicleStructureClass;
  vcdbVersions: VcdbVersion[];
  user: UserType;
  onClose?: (dirty: boolean, force?: boolean) => void;
} & WithRouterProps &
  WithTranslation &
  WithAntdContextProps;

type VcdbEditState = {
  selectedVcdb?: number;
  getRecommendations: boolean;
  selectedSubconfig: string | null;
  pendingConfigCount?: number;
  vehicleEquipmentType: string;
  changeConfigActive: boolean;
};

class VcdbEdit extends React.Component<VcdbEditProps, VcdbEditState> {
  constructor(props: VcdbEditProps) {
    super(props);
    this.state = {
      getRecommendations: false,
      selectedSubconfig: 'vehicle_bases',
      selectedVcdb: props.vcdbVersions.filter(vcdb => vcdb.account_id !== null)[0]?.id,
      vehicleEquipmentType: 'vehicle',
      changeConfigActive: false,
    };
  }

  setRecommendations = (status: boolean) => this.setState({ getRecommendations: status });

  selectMake = (
    makeId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const makeIds = application.make_ids?.includes(makeId) ? [] : [makeId];
    const updatedApplication = mapConfigToApplication(application, 'make_ids', makeIds);

    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectModel = (
    modelId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const modelIds = application.model_ids?.includes(modelId) ? [] : [modelId];
    const updatedApplication = mapConfigToApplication(application, 'model_ids', modelIds);

    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectType = (
    id: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { resources } = this.props;
    const typeIds = application.vehicle_type_ids?.includes(id) ? [] : [id];
    let updatedApplication = mapConfigToApplication(application, 'vehicle_type_ids', typeIds);

    if (typeIds.length > 0) {
      const type = resources.model_types.find((t: any) => t.id === id);
      const group = resources.model_type_groups.find((g: any) => g.name === type.vehicle_group);

      updatedApplication = mapConfigToApplication(updatedApplication, 'vehicle_group_ids', [
        group.id,
      ]);
    }

    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectSubmodels = (
    ids: any,
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const submodelIds = ids instanceof Array ? ids : [ids];
    let selectedSubmodelIds = application.sub_model_ids || [];

    if (submodelIds.length === 1 && selectedSubmodelIds.length === 1) {
      // select or unselect single submodel
      selectedSubmodelIds = selectedSubmodelIds[0] === submodelIds[0] ? [] : submodelIds;
    } else {
      selectedSubmodelIds = submodelIds;
    }

    const updatedApplication = mapConfigToApplication(
      application,
      'sub_model_ids',
      selectedSubmodelIds
    );
    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectRegion = (
    ids: any,
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const regionIds = ids instanceof Array ? ids : [ids];
    let selectedRegionIds = application.region_ids || [];
    if (regionIds.length === 1 && selectedRegionIds.length === 1) {
      // select or unselect single region
      selectedRegionIds = selectedRegionIds[0] === regionIds[0] ? [] : regionIds;
    } else {
      selectedRegionIds = regionIds;
    }

    const updatedApplication = mapConfigToApplication(application, 'region_ids', selectedRegionIds);
    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectYears = (
    year: number | number[],
    application: Application,
    addValues: boolean,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    let years = year instanceof Array ? Array.from(new Set(year)) : [year];
    const selectedYears = application.years || [];

    if (years.length === 1 && selectedYears.length === 1) {
      years = years[0] === selectedYears[0] ? [] : years;
    }

    const updatedApplication = mapConfigToApplication(application, 'years', years);
    setFieldValue('application', updatedApplication);
    this.handleRankFetchForVehcile(updatedApplication);
  };

  selectSubconfig = (subConfig: string | null) => {
    if (subConfig && this.state.selectedSubconfig !== subConfig) {
      this.setState({ selectedSubconfig: subConfig, changeConfigActive: false });
    }
  };

  handleRankFetchForVehcile = async (application: Application) => {
    const { getRecommendations } = this.state;

    if (
      application.hasOwnProperty('make_ids') ||
      application.hasOwnProperty('model_ids') ||
      application.hasOwnProperty('sub_model_ids') ||
      application.hasOwnProperty('years') ||
      application.hasOwnProperty('region_ids')
    ) {
      await this.getRankedApplications(application);
      if (!getRecommendations) this.setRecommendations(true);
    } else if (getRecommendations) this.setRecommendations(false);
  };

  mapConfigToApplication = (
    subconfigName: string,
    subconfigId: number,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations } = this.state;

    const idName = convertConfigNameToIds(subconfigName);
    const mappedValueIds = application[idName] || [];
    const newValues = mappedValueIds.includes(subconfigId) ? [] : [subconfigId];

    const updatedApplication = mapConfigToApplication(application, idName, newValues);
    setFieldValue('application', updatedApplication);

    // handle recommendation fetch status for equipment
    if (
      idName === 'mfr_ids' ||
      idName === 'equipment_model_ids' ||
      idName === 'vehicle_type_ids' ||
      idName === 'region_ids' ||
      idName === 'production_start' ||
      idName === 'production_end'
    ) {
      const eMfr = updatedApplication.hasOwnProperty('mfr_ids');
      const eModel = updatedApplication.hasOwnProperty('equipment_model_ids');
      const vehicleType = updatedApplication.hasOwnProperty('vehicle_type_ids');
      const years =
        updatedApplication.hasOwnProperty('production_start') ||
        updatedApplication.hasOwnProperty('production_end');
      const regions = updatedApplication.hasOwnProperty('region_ids');

      if ((eMfr || eModel || vehicleType || years || regions) && !getRecommendations) {
        this.getRankedApplications(updatedApplication).then(() => {
          this.setRecommendations(true);
        });
      } else if (!(eMfr || eModel || vehicleType || years || regions) && getRecommendations)
        this.setRecommendations(false);
    }

    if (getRecommendations) this.getRankedApplications(updatedApplication);
  };

  getRankedApplications = (application: Application) => {
    const { selectedVcdb, vehicleEquipmentType } = this.state;

    const vcdbs = selectedVcdb ? [selectedVcdb] : [];
    const equipment = vehicleEquipmentType === 'equipment';

    return this.props.dispatch(fetchRankedApplications(application, vcdbs, undefined, equipment));
  };

  getValidationSchema = () => {
    const { t } = this.props;

    return Yup.object().shape({
      selectedVcdb: Yup.number().required(t('validation:required')),
    });
  };

  handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    const { resources, dispatch } = this.props;
    const { selectedVcdb, vehicleEquipmentType } = this.state;
    const { linkConfigs, createVehicle, application, changeConfigs } = values;

    const { setSubmitting } = actions;

    intercomEvent('viewed-company-cvt-settings', { location: 'settings', action: 'save' });

    let vehicleConfig: { [key: string]: number[] } = {};
    const {
      make_ids,
      model_ids,
      sub_model_ids,
      region_ids,
      years,
      production_start,
      production_end,
      mfr_ids,
      equipment_model_ids,
      vehicle_type_ids,
    } = application;
    if (make_ids && model_ids && sub_model_ids && region_ids && years) {
      vehicleConfig = { make_ids, model_ids, sub_model_ids, region_ids, years };
      const model = resources.models.find((model: any) => model.id === model_ids[0]);
      vehicleConfig.base_model_type_id = model.vehicle_type;
    } else if (mfr_ids && equipment_model_ids && vehicle_type_ids && region_ids) {
      vehicleConfig = {
        mfr_ids,
        equipment_model_ids,
        vehicle_type_ids,
        region_ids,
      };
      // year is optional
      if (production_start) vehicleConfig.production_start = production_start;
      if (production_end) vehicleConfig.production_end = production_end;
    }

    const configs: { [key: string]: { [key: string]: number[] }[] } = {};

    if (
      createVehicle ||
      changeConfigs.find((c: any) => c.configName === 'vehicles' || c.configName === 'equipments')
    ) {
      if (vehicleEquipmentType === 'vehicle') configs.vehicle_base = [vehicleConfig];
      else configs.equipment_engines = [vehicleConfig];
    }

    if (changeConfigs.length > 0) {
      await Promise.all(
        changeConfigs.map(async (config: { configName: string; id: number }) => {
          if (config.configName === 'vehicles' || config.configName === 'equipments') {
            const nameConfigs = mapConfigIdToName(configs, resources) as {
              [key: string]: { [key: string]: string }[];
            };
            await dispatch(
              changeCustomVehicle(nameConfigs, selectedVcdb!, config.id, vehicleEquipmentType)
            );
          } else {
            const configKey =
              vehicleEquipmentType === 'equipment' && config.configName === 'engines'
                ? 'equipment_engines'
                : config.configName;
            await dispatch(deleteCustomConfig(selectedVcdb!, configKey, config.id));
          }
        })
      );
    }

    const changeConfigKeys = changeConfigs
      .map((c: any) => c.configName)
      .filter((c: string) => !['vehicles', 'equipments'].includes(c));
    const configGroupKeys = [...new Set([...linkConfigs, ...changeConfigKeys])];

    const subconfigKeys = Object.keys(application);

    configGroupKeys.forEach((groupKey: string) => {
      const configPrefix = getSingularConfigGroupName(groupKey);
      const keys = subconfigKeys.filter(key => {
        if (configPrefix === 'body') return key.startsWith(configPrefix);
        return key.includes(configPrefix);
      });

      const configObj: { [key: string]: number[] } = {};

      keys.forEach(key => {
        configObj[key] = application[key];
      });

      const configName =
        vehicleEquipmentType === 'equipment' && groupKey === 'engines'
          ? 'equipment_engines'
          : groupKey;

      if (configGroupKeys.includes(groupKey))
        configs[configName] = [{ ...vehicleConfig, ...configObj }];
      else configs[configName] = [configObj];
    });

    // api expects names instead of ids
    const nameConfigs = mapConfigIdToName(configs, resources) as {
      [key: string]: { [key: string]: string }[];
    };

    this.props
      .dispatch(
        updateCustomVehicleConfigs(
          nameConfigs,
          selectedVcdb!,
          linkConfigs.length > 0 || createVehicle || changeConfigs.length > 0,
          createVehicle || linkConfigs.length > 0 || changeConfigs.length > 0
        )
      )
      .then(() => {
        setSubmitting(false);
        if (this.props.page) {
          actions.resetForm({ status: 'SUCCESS' });
          setTimeout(() => actions.setStatus('EDIT'), 2500);
          this.selectSubconfig('vehicle_bases');
          this.setRecommendations(false);
        }
        if (this.props.onClose) this.props.onClose(false);
      })
      .catch(() => {
        if (this.props.page) actions.setStatus('ERROR');
        setSubmitting(false);
      });
  };

  drawerFooter = () => {
    const { t } = this.props;
    const { pendingConfigCount } = this.state;

    return pendingConfigCount ? (
      <div className="flex-col text-right leading-8">
        {`(${t('application:pendingNewConfig', { count: pendingConfigCount })})`}
      </div>
    ) : null;
  };

  render() {
    const { t, rankedApplications, resources, user, onClose } = this.props;
    const { selectedVcdb, getRecommendations } = this.state;

    const canManageVcdb = hasPermission(user, 'can_manage_custom_vcdb');

    return (
      <Formik
        initialStatus="EDIT"
        initialValues={{
          application: {} as Application,
          linkConfigs: [],
          createVehicle: false,
          selectedVcdb,
          changeConfigs: [],
        }}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, actions) => this.handleSubmit(values, actions)}
      >
        {({
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          resetForm,
          setStatus,
          setValues,
          values,
          dirty,
          isSubmitting,
          status,
          isValid,
          submitCount,
          initialValues,
        }) => {
          const mergedRankedApplications = mergeApplicationRanks(
            values.application,
            getRecommendations ? rankedApplications : {},
            resources
          );

          const showSaveBar =
            values.createVehicle ||
            values.linkConfigs.length > 0 ||
            values.changeConfigs.length > 0;

          const saveButtonEnabled =
            canManageVcdb &&
            (values.createVehicle ||
              values.linkConfigs.length > 0 ||
              values.changeConfigs.length > 0);

          const getRankedApplications = () => this.getRankedApplications(values.application);

          const allSubconfigValues = (configName: string) => {
            // get all values to a specific config name e.g. position
            // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
            if (resourcesMapping.hasOwnProperty(configName)) {
              configName = resourcesMapping[configName];
            }

            return resources && resources[configName] ? resources[configName] : [];
          };

          const resetEqipment = () => {
            /*  eslint-disable @typescript-eslint/no-unused-vars */
            const {
              mfr_ids,
              equipment_model_ids,
              vehicle_type_ids,
              region_ids,
              production_end,
              production_start,
              ...rest
            } = values.application;
            /*  eslint-enable @typescript-eslint/no-unused-vars */
            setFieldValue('application', rest);
            setFieldValue('changeConfigs', []);
            setFieldValue('linkConfigs', []);
            setFieldValue('createVehicle', false);
            this.setState({ changeConfigActive: false });
          };

          const resetVehicle = () => {
            const { application } = values;
            const engineKeys = Object.keys(application).filter(key => key.includes('engine'));

            if (engineKeys.length > 0) {
              const newApplication: { [key: string]: string } = {};
              engineKeys.forEach(key => (newApplication[key] = application[key]));
              setFieldValue('application', newApplication);
            } else setFieldValue('application', {});
            setFieldValue('changeConfigs', []);
            setFieldValue('linkConfigs', []);
            setFieldValue('createVehicle', false);
            this.setState({ changeConfigActive: false });
          };

          const addCustomValue = (
            configName: string,
            value: string,
            type?: string,
            group?: string
          ) => {
            const { vehicleEquipmentType } = this.state;
            if (!selectedVcdb) {
              setFieldTouched('selectedVcdb');
              return Promise.resolve();
            }

            const configGroupKey = getConfigGroupName(configName);
            const configKey =
              vehicleEquipmentType === 'equipment' && configName === 'vehicle_types'
                ? 'vehicle_type_id'
                : convertConfigNameToId(configName);

            const configs: { [key: string]: { [key: string]: string }[] } = {
              [configGroupKey!]: [
                {
                  [configKey]: value,
                  ...(group && configName === 'vehicle_types'
                    ? { vehicle_type_id: type, vehicle_type_group_id: group }
                    : {}),
                  ...(group && configName === 'models'
                    ? { base_model_type_id: type, vehicle_type_group_id: group }
                    : {}),
                },
              ],
            };

            return this.props
              .dispatch(addCustomSubConfig(configs, selectedVcdb!))
              .then(() => this.props.dispatch(fetchApplicationResources()))
              .catch(() => {});
          };

          const deleteCustomValue = (configName: string, id: number) => {
            const { t, dispatch } = this.props;
            const { modal } = this.props.appContext;

            modal.confirm({
              title: t('application:deleteCustomSubConfig'),
              icon: <ExclamationCircleOutlined />,
              okType: 'danger',
              okText: t('common:delete'),
              onOk() {
                // TODO: remove special case for deleting equipment type when backend adjusted
                const configIdName =
                  configName === 'vehicle_types'
                    ? 'base_model_type_id'
                    : convertConfigNameToId(configName);
                dispatch(deleteCustomSubConfig(selectedVcdb!, configIdName, id));
              },
            });
          };

          const deleteCustomConfigById = (configName: string, id: number) => {
            const { t, dispatch } = this.props;
            const { modal } = this.props.appContext;

            modal.confirm({
              title: t('application:deleteCustomConfig'),
              icon: <ExclamationCircleOutlined />,
              okType: 'danger',
              okText: t('common:delete'),
              onOk() {
                dispatch(deleteCustomConfig(selectedVcdb!, configName, id)).then(() =>
                  getRankedApplications()
                );
              },
            });
          };

          const linkConfig = (name: string) => {
            const newValues = [name, ...values.linkConfigs];
            setFieldValue('linkConfigs', newValues);
            this.setState({ pendingConfigCount: newValues.length });
          };

          const createVehicle = (status: boolean) => {
            setFieldValue('createVehicle', status);
          };

          const changeConfiguration = (status: boolean) => {
            this.setState({ changeConfigActive: status });
          };

          const confirmChangeConfiguration = (configName: string, id: number) => {
            const { modal } = this.props.appContext;

            modal.confirm({
              title: t('application:confirmConfiguration'),
              icon: <ExclamationCircleOutlined />,
              content: t('application:confirmChangeInfo'),
              okText: t('common:confirm'),
              onOk() {
                setFieldValue('changeConfigs', [...values.changeConfigs, { configName, id }]);
                changeConfiguration(false);
              },
            });
          };

          const selectVcdb = (id: number | undefined) => {
            this.setState({ selectedVcdb: id }, () => getRankedApplications());
            const prevValues = { ...values };
            resetForm({ values: { ...initialValues, selectedVcdb: id } });
            setValues({ ...prevValues, selectedVcdb: id });
          };

          const details = (
            <ApplicationDetails
              singleApplicationEdit
              hideSearch
              vcdbView
              disableAddDelete={!canManageVcdb}
              addCustomValue={addCustomValue}
              deleteCustomValue={deleteCustomValue}
              deleteCustomConfigById={deleteCustomConfigById}
              application={values.application}
              applicationStructure={this.props.applicationStructure}
              getRankedApplications={getRankedApplications}
              getRecommendations={this.state.getRecommendations}
              rankedApplications={mergedRankedApplications}
              allSubconfigValues={allSubconfigValues}
              selectedSubconfig={this.state.selectedSubconfig}
              selectedVcdb={selectedVcdb}
              selectVcdb={selectVcdb}
              selectMake={(makeId: number) =>
                this.selectMake(makeId, values.application, setFieldValue)
              }
              selectModel={(modelId: number) =>
                this.selectModel(modelId, values.application, setFieldValue)
              }
              selectType={(typeIds: number) =>
                this.selectType(typeIds, values.application, setFieldValue)
              }
              selectSubmodel={(subModelIds: number | number[], addValues: boolean) =>
                this.selectSubmodels(subModelIds, values.application, addValues, setFieldValue)
              }
              selectRegion={(regionIds: number | number[], addValues: boolean) =>
                this.selectRegion(regionIds, values.application, addValues, setFieldValue)
              }
              selectYears={(years: number | number[], addValues: boolean) =>
                this.selectYears(years, values.application, addValues, setFieldValue)
              }
              selectSubconfig={this.selectSubconfig}
              mapConfigToApplication={(name: string, id: number) =>
                this.mapConfigToApplication(name, id, values.application, setFieldValue)
              }
              setRecommendations={this.setRecommendations}
              resetEqipment={resetEqipment}
              resetVehicle={resetVehicle}
              linkConfig={linkConfig}
              createVehicle={createVehicle}
              changeConfiguration={changeConfiguration}
              confirmChangeConfiguration={confirmChangeConfiguration}
              selectedCustomVcdb={selectedVcdb}
              linkConfigs={values.linkConfigs}
              createVehicleStatus={values.createVehicle}
              changeConfigActive={this.state.changeConfigActive}
              vehicleEquipmentType={this.state.vehicleEquipmentType}
              setVehicleEquipment={(type: string) => this.setState({ vehicleEquipmentType: type })}
            />
          );

          const show =
            (showSaveBar && status === 'EDIT') || status === 'SUCCESS' || status === 'ERROR';

          return (
            <React.Fragment>
              {this.props.page ? (
                <Page
                  contentNoScroll
                  renderBottom={
                    show ? (
                      <React.Fragment>
                        <div
                          className={classNames('status-bar flex items-center', {
                            success: status === 'SUCCESS',
                            error: status === 'ERROR',
                          })}
                        >
                          {status === 'ERROR' && <Tag color="red">{t('common:errorSaving')}</Tag>}
                        </div>
                        <div className="page__bottom-save flex items-center">
                          <div className="flex items-center flex-1">
                            {!isValid && submitCount > 0 && (
                              <Tag color="red">{t('common:fieldsMissing')}</Tag>
                            )}
                            <div className="flex-1 flex justify-end">
                              {this.drawerFooter()}
                              <Button
                                className="page__bottom-save-cancel"
                                onClick={() => {
                                  resetForm();
                                  setStatus('EDIT');
                                  this.selectSubconfig('vehicle_bases');
                                  this.setRecommendations(false);
                                }}
                              >
                                {t('common:cancel')}
                              </Button>

                              <Button
                                type="primary"
                                onClick={() => handleSubmit()}
                                disabled={!saveButtonEnabled}
                                loading={isSubmitting}
                              >
                                {t('common:save')}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <FormRouterPrompt formDirty={saveButtonEnabled} />
                      </React.Fragment>
                    ) : null
                  }
                >
                  {details}
                </Page>
              ) : (
                <DrawerSave
                  title={t('application:vcdbDrawerTitle')}
                  className="application__details-drawer"
                  onClose={force => onClose!(dirty, force)}
                  onSave={() => handleSubmit()}
                  visible={this.props.visible}
                  width="80%"
                  saveButtonEnabled={saveButtonEnabled}
                  additionalFooterData={this.drawerFooter()}
                  isSubmitting={isSubmitting}
                  destroyOnClose
                >
                  {details}
                </DrawerSave>
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    rankedApplications: state.items.application.rankedApplications,
    resources: getVehicleResources(state),
    applicationStructure: state.resources.data.vehicle_structure,
    vcdbVersions: state.items.application.vcdbVersions,
    user: state.user.user,
  };
}

export { VcdbEdit };
export default connect(mapStateToProps)(
  withContainerWrapper(withRouter(withTranslation()(withAntdContext(VcdbEdit))))
);
