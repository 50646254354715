import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

/*
  Possible props to pass:
  - className: string that is additionally added to default className
  - dataSource: array of objects (rows)
  - columns: array of column definitions => {key: int, dataIndex: int, title: string}
  - pagination: hide pagination by setting to false (boolean)
  - showHeader: hide header by setting to false (boolean)
  - expandedRowRender: callback for rendering nested rows
  => (record: obj, index: int, indent: int, expanded: boolean) =>  AntTable
*/

export default class AntTable extends React.Component {
  mapColumns = columns =>
    columns.map(({ name, width, hideHeader, align, title }) => ({
      title: hideHeader ? '' : title || name,
      dataIndex: name,
      key: name,
      width,
      ...({ align } || {}),
    }));

  render() {
    const {
      className,
      rowClassName,
      dataSource,
      columns,
      pagination,
      showHeader,
      expandedRowRender,
      rowExpandable,
      size,
      loading,
    } = this.props;

    const mappedColumns = this.mapColumns(columns);

    return (
      <Table
        {...{
          className,
          rowClassName,
          dataSource,
          pagination,
          showHeader,
          size,
          ...(expandedRowRender
            ? { expandedRowRender, ...{ rowClassName: 'table-row--expandable' } }
            : {}),
          ...(rowExpandable ? { rowExpandable } : {}),
        }}
        columns={mappedColumns}
        expandable={{
          expandRowByClick: true,
        }}
        loading={loading}
      />
    );
  }
}

AntTable.propTypes = {
  // string that is additionally added to default className
  className: PropTypes.string,
  // array of objects (rows)
  dataSource: PropTypes.array,
  // array of column definitions => {key: int, dataIndex: int, title: string}
  columns: PropTypes.array.isRequired,
  // hide pagination by setting to false
  pagination: PropTypes.bool,
  // hide header by setting to false
  showHeader: PropTypes.bool,
  // callback for rendering nested rows
  expandedRowRender: PropTypes.func,
};

AntTable.defaultProps = {
  className: '',
  dataSource: [],
  pagination: true,
  showHeader: true,
  expandedRowRender: null,
};
