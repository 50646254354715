import FileSaver from 'file-saver';
import { v1 as uuidv1 } from 'uuid';
import dayjs from 'dayjs';

export const validUrlRegExp =
  /^(?:(?:https?|ftps?|sftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

// return the filters as array
export function stringToArray(string = '') {
  // ensure that the value is a string
  string = string.toString();
  string = string.replace(/,/g, ' ');
  string = string.replace(/;/g, '');
  // delete the double tabs
  while (string.includes('  ')) {
    string = string.replace('  ', ' ');
  }
  // delete empty array elements
  const array = string.split(' ');
  for (let i = 0; i < array.length; i += 1) {
    while (array[i] === ' ' || array[i] === '') {
      array.splice([i], 1);
    }
  }
  return array;
}

export function createURL(params) {
  let url = '';
  let brackets = '';

  params.forEach((parameter, index) => {
    brackets = '';
    let metaTag;
    if (parameter.level) brackets = `[${parameter.level}]`;
    if (index === 0) metaTag = '?';
    else metaTag = '&';

    // handle empty value
    if (!parameter.values && parameter.values !== 0) {
      // return if allowEmpty is not set
      if (!parameter.allowEmpty) return;
      const validEmptyValues = ['', null];
      if (parameter.allowEmpty && !validEmptyValues.includes(parameter.values)) return;
      if (parameter.values === null) parameter.values = '';
    }
    if (!(parameter.values instanceof Array)) {
      parameter.values = [parameter.values];
    }

    // add empty parameter for resetting value (if values is empty array)
    if (parameter.allowEmpty && parameter.values.length === 0) {
      url += `${metaTag + parameter.name + brackets}=`;
    }

    parameter.values.forEach((value, index) => {
      const encodedValue = encodeURIComponent(value);
      if (url === '') {
        metaTag = '?';
      }
      if (index === 0) {
        url += `${metaTag + parameter.name + brackets}=${encodedValue}`;
      } else {
        url += `${encodeURIComponent(',')}${encodedValue}`;
      }
    });
  });
  return url;
}

export function getPageLimit() {
  // get the limit how many rows fit in the screen
  return Math.round(screen.height / 30 + 10);
}

export function nextPage(event, limit, numberOfElements) {
  const target = event.target || event; // makes it possible to pass table or scroll event
  const pageLimit = limit || getPageLimit();
  const hiddenHeight = target.scrollHeight - target.clientHeight;
  const elementCount = numberOfElements || target.childElementCount;
  const currentPage = elementCount / pageLimit;
  const hiddenHeightBottom = hiddenHeight - target.scrollTop;

  if (hiddenHeightBottom < target.clientHeight) {
    if (Number.isInteger(currentPage)) {
      return currentPage + 1;
    }
    return null;
  }
  return null;
}

export function hasPermissionAllParents(user) {
  if (!user) return false;
  let permissionAllParents = false;
  user.permissions.forEach(permission => {
    if (permission.id === 1) permissionAllParents = true;
  });

  return permissionAllParents;
}

let timer;
export function typingDone(task, timeout) {
  // set timeout to not call the function on every key press
  timeout = timeout || 500;
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (task && typeof task === 'function') {
      return task();
    }
  }, timeout);
}

export function checkKeywordLength(keywords) {
  const sanitizedKeywords = keywords
    .split(' ')
    .map(keyword => keyword.trim().replace(/[^0-9A-Za-z.-]/g, ''));

  return !!(sanitizedKeywords.find(k => k.length >= 3) || keywords.length === 0);
}

export function itemsChanged(oldItems, newItems) {
  return (
    oldItems
      .map(item => item.id)
      .sort()
      .join() !==
    newItems
      .map(item => item.id)
      .sort()
      .join()
  );
}

export function booleanToInt(bool) {
  if (bool !== undefined) {
    if (bool === true) return 1;
    if (bool === false) return 0;
  }
}

export function booleanToStringInt(bool) {
  if (bool) return '1';
  return '0';
}

export function intToBoolean(value) {
  if (value === '1' || value === 1) {
    return true;
  }
  if (value === '0' || value === 0) {
    return false;
  }
  return undefined;
}

export function checkIfStringIsBoolean(string) {
  if (string === 'true' || string === 'false') {
    return true;
  }
  return false;
}

export function convertStringBooleanToBoolean(string) {
  if (string === 'true') {
    return true;
  }
  if (string === 'false') {
    return false;
  }
  return undefined;
}

export function convertBooleanToStringBoolean(boolean) {
  if (boolean) {
    return 'true';
  }
  return 'false';
}

export function triggerHj(triggerEvent) {
  if (process.env.NODE_ENV === 'production' && typeof hj === 'function') {
    hj('trigger', triggerEvent);
  }
}

export const downloadArrayBuffer = (arrayBuffer, fileName) =>
  new Promise(resolve => {
    const blob = new Blob([arrayBuffer]);
    FileSaver.saveAs(blob, fileName);
    resolve();
  });

export const localDateString = date => {
  if (!date) return '';
  const newDate = new Date(date);
  const localDateString = newDate.toLocaleDateString();
  return localDateString;
};

export const localTimeString = date => {
  if (!date) return '';
  const newDate = new Date(date);
  const localTimeString = newDate.toLocaleTimeString();
  return localTimeString;
};

export const localDateTimeString = date => {
  if (!date) return '';
  const newDate = new Date(date);
  return newDate.toLocaleString();
};

export const localDateTimeTimeZoneString = date => {
  if (!date) return '';
  const newDate = new Date(date);
  return newDate.toLocaleString([], { timeZoneName: 'longOffset' });
};

export const isUploadUrlValid = (url, forceSecure = false) => {
  const regexp = forceSecure
    ? // eslint-disable-next-line
      /^(?:(?:https|ftps|sftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    : // eslint-disable-next-line
      /^(?:(?:https?|ftps?|sftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(url);
};

export const generateUUID = () => uuidv1();

export const isValidJSON = json => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

export const dayjsTimeStamp = timeStamp => {
  return dayjs(timeStamp).local().format('dddd MMMM Do YYYY, h:mm:ss a [UTC]Z');
};

export const getOrderStringToInt = sortOrder => {
  if (sortOrder === 'asc') return 0;
  if (sortOrder === 'desc') return 1;
  return undefined;
};

export const kilobytesToMegabytes = kilobytes => {
  return (kilobytes / 1024).toFixed(2);
};
