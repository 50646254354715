import React from 'react';
import TextInput from '../../../global/TextInput';
import Dropdown from '../../../global/Dropdown';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';

import DigitalAssetDates from './DigitalAssetDates';

const { parent: translation } = digitalAssetConstants;

class DigitalAssetEditBoxLeft extends React.Component {
  render() {
    const {
      digitalAssetResources,
      languages,
      countries,
      selectedDigitalAsset,
      updateDigitalAssetParent,
      canManageDigitalAssets,
    } = this.props;
    const key = selectedDigitalAsset.id;
    const digitalAssetId = selectedDigitalAsset.digital_asset_id || selectedDigitalAsset.id;
    const fileTypeDropdownElements = digitalAssetResources.file_types.map(type => ({
      id: type.id,
      name: type.code,
    }));
    return (
      <div className="digital-asset-parent__edit-column">
        <div className="digital-asset-parent__edit-box">
          <div className="title">{translation.asset_information}</div>
          <div className="edit-box__content box box_content scrollable">
            <div className="form-field">
              <div className="form-field-name">{translation.file_name}</div>
              <TextInput
                key={key}
                readOnly
                className="form-field-content"
                value={selectedDigitalAsset.file_name}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.file_type}</div>
              <Dropdown
                key={selectedDigitalAsset.id}
                dataCy="file-type"
                noSelection={!selectedDigitalAsset.file_type_id}
                className="form-field-content"
                selectedElement={selectedDigitalAsset.file_type_id}
                elements={fileTypeDropdownElements}
                onChange={val =>
                  this.props.updateDigitalAssetParent(digitalAssetId, 'file_type_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.file_size}</div>
              <TextInput
                key={key}
                dataCy="file-size"
                className="form-field-content"
                value={selectedDigitalAsset.file_size}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'file_size', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.resolution}</div>
              <Dropdown
                key={key}
                noSelection
                className="form-field-content"
                elements={digitalAssetResources.resolutions}
                selectedElement={selectedDigitalAsset.resolution_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'resolution_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.dimension_uom}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={digitalAssetResources.dimension_uoms}
                selectedElement={selectedDigitalAsset.dimension_uom_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'dimension_uom_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.asset_height}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.height}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'height', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.asset_width}</div>
              <TextInput
                key={key}
                className="form-field-content"
                value={selectedDigitalAsset.width}
                onChange={val => updateDigitalAssetParent(digitalAssetId, 'width', val)}
                readOnly={!canManageDigitalAssets}
              />
            </div>
            <br />
            <div className="form-field">
              <div className="form-field-name">{translation.color_mode}</div>
              <Dropdown
                key={key}
                noSelection
                className="form-field-content"
                elements={digitalAssetResources.color_modes}
                selectedElement={selectedDigitalAsset.color_mode_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'color_mode_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.background}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={digitalAssetResources.backgrounds}
                selectedElement={selectedDigitalAsset.background_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'background_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.orientation_view}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={digitalAssetResources.orientation_views}
                selectedElement={selectedDigitalAsset.orientation_view_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'orientation_view_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <br />
            <DigitalAssetDates
              updateDigitalAssetParent={updateDigitalAssetParent}
              digitalAssetResources={digitalAssetResources}
              selectedDigitalAsset={selectedDigitalAsset}
              canManageDigitalAssets={canManageDigitalAssets}
            />
            <br />
            <div className="form-field">
              <div className="form-field-name">{translation.country}</div>
              <Dropdown
                key={key}
                dataCy="country"
                className="form-field-content"
                noSelection
                elements={countries}
                selectedElement={selectedDigitalAsset.country_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'country_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
            <div className="form-field">
              <div className="form-field-name">{translation.language}</div>
              <Dropdown
                key={key}
                className="form-field-content"
                noSelection
                elements={languages}
                selectedElement={selectedDigitalAsset.language_id}
                onChange={val =>
                  updateDigitalAssetParent(digitalAssetId, 'language_id', Number(val))
                }
                disabled={!canManageDigitalAssets}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DigitalAssetEditBoxLeft;
