import React from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { intercomEvent } from '../../utils/IntercomUtils';
import AttributeSettingsRoutes from '../../routes/parentSettings/AttributeSettingsRoutes';
import { setFileTypeId } from '../../actions/parent/exportImport/fetch';

const { Content, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const AttributesSettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const attributeSettingsId = 20;
    dispatch(setFileTypeId(attributeSettingsId));
    intercomEvent('viewed-attribute-settings');
  }, [dispatch]);

  const { t } = useTranslation();
  const attributeItems: MenuItem[] = [
    {
      key: '/settings/attributes',
      label: <Link to={{ pathname: `.` }}>{t('attributes:attributeSettings')}</Link>,
    },
    {
      key: '/settings/attributes/import-logs',
      label: <Link to={{ pathname: `import-logs` }}>{t('importer:importReport')}</Link>,
    },
    {
      key: '/settings/attributes/export-logs',
      label: <Link to={{ pathname: `export-logs` }}>{t('exporter:exportReport')}</Link>,
    },
  ];

  return (
    <Layout className="layout">
      <Sider theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          items={attributeItems}
        />
      </Sider>
      <Content className="main-page-content">
        <AttributeSettingsRoutes />
      </Content>
    </Layout>
  );
};

export default AttributesSettingsPage;
