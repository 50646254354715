import { Button, Card, Col, Input, Row, Skeleton, Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';

import { NO_VALUE_OPTIONS } from '../../constants/FilterConstants';
import AddNewButton from '../global/AddNewButton';
import AntMultiSelect from '../global/AntMultiSelect';
import AntSelect from '../global/AntSelect';
import AntTags from '../global/AntTags';
import constants from '../../constants/FilterTranslation.json';
import headerConstants from '../../constants/HeaderTranslation.json';
import { BrandCustomField } from '../../../types/custom_fields';
import { FilterCustomParameterGo } from '../../../types/filter';

const number = 1;
const text = 2;
const dropdown = 3;
const checkbox = 4;

type FilterCustomFieldsProps = {
  customFields: BrandCustomField[];
  fetchingCustomFields: boolean;
  parameters: FilterCustomParameterGo[];
  createParameter: (params: {
    customRefId: number;
    optionId: number | null;
    refId?: number;
    value?: string;
  }) => void;
  updateParameterOption: (params: {
    filterCustomReferenceId: number;
    referenceId: number | null;
    optionId: number;
  }) => void;
  addValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
  removeValue: (referenceId: number | null, filterCustomReferenceId: number, value: any) => void;
};

type FilterCustomFieldsState = {
  showAddFilterParameter: boolean;
  selectedCustomField?: number;
  selectedOption: number;
  parameterValue?: any;
};

export default class FilterCustomFields extends React.Component<
  FilterCustomFieldsProps,
  FilterCustomFieldsState
> {
  labels: { [key: number]: any } = {};

  constructor(props: FilterCustomFieldsProps) {
    super(props);
    this.state = {
      showAddFilterParameter: false,
      selectedCustomField: undefined,
      selectedOption: 1,
      parameterValue: undefined,
    };
  }

  getDropdownValue = (customField: BrandCustomField, valueId: number) => {
    const option = customField.options.find(option => option.id === valueId);
    return option!.name;
  };

  getCustomFieldOptionId = (customField: BrandCustomField, value: any) => {
    const option = customField.options.find(opt => opt.name === value);
    return option!.id;
  };

  createParameter = (value?: string | number) => {
    const { customFields } = this.props;
    const { selectedCustomField, selectedOption } = this.state;
    const customRefId = 2;
    const customField = customFields.find(customField => customField.id === selectedCustomField);
    const valueName =
      customField!.custom_field_type_id === dropdown && value
        ? this.getDropdownValue(customField!, Number(value))
        : value;

    this.props.createParameter({
      customRefId,
      refId: selectedCustomField,
      optionId: selectedOption,
      value: valueName?.toString(),
    });

    // reset state
    this.setState({
      showAddFilterParameter: false,
      selectedCustomField: undefined,
      selectedOption: 1,
      parameterValue: undefined,
    });
  };

  handleCreateValueSelection = (selectedValue: string | number) => {
    this.setState({ parameterValue: selectedValue });
    this.createParameter(selectedValue.toString());
  };

  handleCheckboxChange = (
    referenceId: number | null,
    filterCustomReferenceId: number,
    optionId: number
  ) => {
    this.props.updateParameterOption({ referenceId, filterCustomReferenceId, optionId });
  };

  parameterValue = (customFieldId: number) => {
    const { customFields } = this.props;
    const { parameterValue, selectedOption } = this.state;
    const customField = customFields.find(customField => customField.id === customFieldId);
    const type = customField!.custom_field_type_id;

    return (
      <Col span={10}>
        {(type === text || type === number) && (
          <Input
            type={type === number ? 'number' : 'text'}
            value={this.state.parameterValue}
            // @ts-ignore
            onPressEnter={e => this.handleCreateValueSelection(e.target.value)}
            onBlur={e => this.handleCreateValueSelection(e.target.value)}
            onChange={e => this.setState({ parameterValue: e.target.value })}
            disabled={NO_VALUE_OPTIONS.includes(selectedOption)} // any value, no value
          />
        )}
        {type === dropdown && (
          <AntSelect
            className="filter-custom-dropdown"
            elements={customField!.options}
            onChange={selectedValue => this.handleCreateValueSelection(selectedValue)}
            value={parameterValue}
            placeholder={constants.dropdown_placeholder}
            showSearch
            disabled={NO_VALUE_OPTIONS.includes(selectedOption)} // any value, no value
          />
        )}
        {type === checkbox && (
          <Button.Group>
            <Button
              className={classNames({ selected: this.state.parameterValue })}
              onClick={() => this.handleCreateValueSelection(1)}
            >
              {constants.true}
            </Button>
            <Button
              className={classNames({ selected: this.state.parameterValue === 0 })}
              onClick={() => {
                this.setState({ selectedOption: 2 }, () => this.handleCreateValueSelection(1));
              }} // selectedOption 2 (is not)
            >
              {constants.false}
            </Button>
          </Button.Group>
        )}
      </Col>
    );
  };

  parameterOption = (parameter?: FilterCustomParameterGo) => {
    // @ts-ignore
    const options = [1, 2, 3, 4, 5].map(id => ({ id, name: headerConstants.filterDropdown[id] }));
    const onChange = parameter
      ? (selectedValue: any) =>
          this.props.updateParameterOption({
            referenceId: parameter.reference_id,
            filterCustomReferenceId: parameter.filter_custom_reference_id,
            optionId: selectedValue,
          })
      : (selectedValue: number) =>
          this.setState({ selectedOption: selectedValue }, () => {
            if (NO_VALUE_OPTIONS.includes(selectedValue)) this.createParameter();
          });
    const selectedElement = parameter ? parameter.option_id : this.state.selectedOption;

    return (
      <Col className="filter-custom-label" span={6}>
        <AntSelect
          className="filter-custom-dropdown"
          popupClassName="filter-custom-dropdown_small"
          elements={options}
          onChange={onChange}
          value={selectedElement}
        />
      </Col>
    );
  };

  showCreateParameter = () => {
    const { customFields, parameters, fetchingCustomFields } = this.props;
    const { selectedCustomField } = this.state;
    const customField = customFields.find(customField => customField.id === selectedCustomField);
    const typeId = customField ? customField.custom_field_type_id : undefined;
    // filter used parameters
    const paramReferenceIds = parameters.map(param => param.reference_id);
    const unusedElements = customFields.filter(field => !paramReferenceIds.includes(field.id));

    return (
      <Card className="filter__create-custom-card">
        <Row align="middle" gutter={10}>
          <Col className="filter-custom-label" span={8}>
            <AntSelect
              className="filter-custom-dropdown"
              elements={unusedElements}
              onChange={selectedValue => this.setState({ selectedCustomField: selectedValue })}
              value={selectedCustomField}
              placeholder={constants.filter_custom.select}
              showSearch
              loading={fetchingCustomFields}
            />
          </Col>
          {selectedCustomField && typeId !== checkbox && this.parameterOption()}
          {selectedCustomField && this.parameterValue(selectedCustomField)}
        </Row>
      </Card>
    );
  };

  showCreateButton = () => (
    <Row>
      <AddNewButton
        size="sub"
        name={constants.add_new}
        toggle={() =>
          this.setState(prevState => ({
            showAddFilterParameter: !prevState.showAddFilterParameter,
          }))
        }
      />
    </Row>
  );

  parameterLabel = (customField: BrandCustomField) => {
    const { id } = customField;
    const longTitle =
      this.labels[id] &&
      this.labels[id].offsetWidth > this.labels[id].offsetParent.offsetWidth - 10;

    return (
      <Card className="filter-custom-label_card">
        <Tooltip title={longTitle ? customField.name : ''}>
          <span ref={el => (this.labels[customField.id] = el)}>{customField.name}</span>
        </Tooltip>
      </Card>
    );
  };

  singleParameter = (parameter: FilterCustomParameterGo) => {
    const { customFields } = this.props;
    const customField = customFields.find(customField => customField.id === parameter.reference_id);
    // custom field can be deleted but stays in filter
    const typeId = customField ? customField.custom_field_type_id : undefined;
    const values = parameter.values.map(v => {
      return {
        ...v,
        optionId:
          typeId === dropdown ? this.getCustomFieldOptionId(customField!, v.value) : undefined,
      };
    });
    const selectedOptionIds = values.map(value => value.optionId);

    const handleDropdownChange = (selectedValues: any[]) => {
      if (selectedValues.length < values.length) return;
      const newId = selectedValues.filter(id => selectedOptionIds.indexOf(id) === -1)[0];
      const value = customField?.options.find(option => option.id === newId)?.name;
      this.props.addValue(parameter.reference_id, parameter.filter_custom_reference_id, value);
    };

    const handleDropdownRemove = (selectedOptionId: number) => {
      const value = values.find(value => value.optionId === selectedOptionId)?.value;
      this.props.removeValue(parameter.reference_id, parameter.filter_custom_reference_id, value);
    };

    return (
      <Card key={parameter.reference_id} className="filter__custom-card" bordered={false}>
        <Row align="middle" gutter={10}>
          <Col className="filter-custom-label" span={7}>
            {customField && this.parameterLabel(customField)}
          </Col>
          {typeId !== checkbox && this.parameterOption(parameter)}
          <Col span={11}>
            {(typeId === number || typeId === text) && (
              <AntTags
                closable
                elements={values}
                onClose={(value: any) =>
                  this.props.removeValue(
                    parameter.reference_id,
                    parameter.filter_custom_reference_id,
                    value
                  )
                }
                create={(value: any) =>
                  this.props.addValue(
                    parameter.reference_id,
                    parameter.filter_custom_reference_id,
                    value
                  )
                }
                addButtonClassName="filter__custom-add-tag"
                disabled={NO_VALUE_OPTIONS.includes(parameter.option_id)} // any value, no value
              />
            )}
            {typeId === dropdown && (
              <AntMultiSelect
                className="filter-custom-dropdown"
                elements={customField!.options}
                onChange={handleDropdownChange}
                onDeselect={handleDropdownRemove}
                selectedElements={selectedOptionIds}
                showSearch
                disabled={NO_VALUE_OPTIONS.includes(parameter.option_id)} // any value, no value
              />
            )}
            {typeId === checkbox && (
              <Button.Group>
                <Button
                  className={classNames({ selected: parameter.option_id === 1 })}
                  onClick={() =>
                    this.handleCheckboxChange(
                      parameter.reference_id,
                      parameter.filter_custom_reference_id,
                      1
                    )
                  }
                >
                  {constants.true}
                </Button>
                <Button
                  className={classNames({ selected: parameter.option_id === 2 })}
                  onClick={() =>
                    this.handleCheckboxChange(
                      parameter.reference_id,
                      parameter.filter_custom_reference_id,
                      2
                    )
                  }
                >
                  {constants.false}
                </Button>
              </Button.Group>
            )}
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { customFields, parameters, fetchingCustomFields } = this.props;
    const { showAddFilterParameter } = this.state;

    return (
      <React.Fragment>
        <div className="title">{constants.filter_custom.custom_fields}</div>
        <div className="filter__custom-fields box box_background">
          {!showAddFilterParameter && this.showCreateButton()}
          {showAddFilterParameter && this.showCreateParameter()}
          {fetchingCustomFields ? (
            <Skeleton.Input active size="small" style={{ width: '350px' }} className="mt-2" />
          ) : (
            customFields.length > 0 && parameters.map(parameter => this.singleParameter(parameter))
          )}
        </div>
      </React.Fragment>
    );
  }
}
