import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChannelColumn, ChannelData, ChannelTemplate } from '../../../../types/channel';
import ShowChannelLogsButton from './ShowChannelLogsButton';
import EditChannelTable from './EditChannelTable';
import EditChannelProductDataDrawer from './EditChannelProductDataDrawer';
import { ApplicationState } from '../../../reducers';
import { updateChannelNPW } from '../../../actions/channel/update';
import { AsyncDispatch } from '../../../../types/global';
import { dayjsTimeStamp } from '../../../utils/Utils';
import { fetchNPWBrands, fetchChannelNPWTemplate } from '../../../actions/channel/fetch';
import { fetchExportBuilderAdvancedSegments } from '../../../actions/brand/export_builder_advanced/fetch';
import { segmentsMappedWithOptions } from '../../../selectors/export_builder_advanced/exportBuilderAdvancedSelector';
import { mapTempStatusToNPWData } from '../../../selectors/channel/channelSelector';

type EditChannelNPWProps = {
  channelType: string;
};

const EditChannelNPW: React.FC<EditChannelNPWProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const [brandName, setBrandName] = React.useState<string | undefined>();
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [searchKey, setSearchkey] = React.useState('');
  const navigate = useNavigate();

  const {
    npwData,
    fetchingChannelNPW,
    lastConnection,
    template,
    templateColumns,
    preview,
    segments,
    fetchingApplicationResources,
    fetchingSegments,
    fetchingTemplate,
    updatingNPW,
  } = useSelector((state: ApplicationState) => ({
    npwData: mapTempStatusToNPWData(state) || [],
    lastConnection: state.channel.channels.channelNPWData?.last_connection || null,
    fetchingChannelNPW: state.channel.channels.fetchingChannelNPW,
    template: (state.channel.channels.channelNPWTemplate?.template || {}) as ChannelTemplate,
    templateColumns: (state.channel.channels.channelNPWTemplate?.columns || []) as ChannelColumn[],
    preview: (state.channel.channels.channelNPWTemplate.preview || []) as any[],
    segments: segmentsMappedWithOptions(state) || [],
    fetchingApplicationResources: state.resources.fetchingApplicationResources,
    fetchingSegments: state.brand.exportBuilderAdvanced.fetchingSegments,
    fetchingTemplate: state.channel.channels.fetchingChannelNPWTemplate,
    updatingNPW: state.channel.channels.updatingNPW,
  }));

  const handleShowProductDataDrawer = (brandName: string, brandId: number) => {
    setBrandName(brandName);
    setBrandId(brandId);
    dispatch(fetchChannelNPWTemplate(brandId));
    dispatch(fetchExportBuilderAdvancedSegments(brandId));
  };

  const handleBrandSearch = (keywords: string | '') => {
    setSearchkey(keywords);
    dispatch(fetchNPWBrands(keywords));
  };

  const handleShowLogs = () => {
    navigate('/distribution/history', {
      state: { searchKeyword: 'npw' },
    });
  };

  const updateChannelNPWValues = (updatedRecord: ChannelData) => {
    const updatedValues = [updatedRecord];
    dispatch(updateChannelNPW(updatedValues)); // direct save
  };

  return (
    <React.Fragment>
      <div className="h-full flex flex-col">
        <div className="mx-10 my-10">
          <ShowChannelLogsButton
            handleShowLogs={handleShowLogs}
            timeStamp={lastConnection ? dayjsTimeStamp(lastConnection) : undefined}
            confirmText={t('channel:switchNPWToHistory')}
          />
        </div>
        <div className="overflow-hidden flex-1 overflow-auto mx-10">
          <EditChannelTable
            data={npwData}
            handleShowProductDataDrawer={handleShowProductDataDrawer}
            updateChannel={updateChannelNPWValues}
            handleBrandSearch={handleBrandSearch}
            searchKey={searchKey}
            fetchingChannel={fetchingChannelNPW}
            channelType={props.channelType}
            updatePending={updatingNPW}
          />
        </div>
      </div>
      <EditChannelProductDataDrawer
        onClose={() => (brandName ? setBrandName(undefined) : '')}
        title={`${brandName || ''} - ${t('channel:productData')}`}
        visible={!!brandName}
        brandId={brandId}
        template={template}
        templateColumns={templateColumns}
        segments={segments}
        fetchingApplicationResources={fetchingApplicationResources}
        fetchingSegments={fetchingSegments}
        fetchingTemplate={fetchingTemplate}
        preview={preview}
        channelType={props.channelType}
      />
    </React.Fragment>
  );
};

export default EditChannelNPW;
