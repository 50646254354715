import React from 'react';
import { Form, Select, Tag } from 'antd';
import { Field, FieldProps, FastField } from 'formik';
import { get as _get } from 'lodash';
import { SelectProps } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import AntTooltip from '../AntTooltip';

type FormMultiSelectProps = {
  name: string;
  className?: string;
  values: { id: number; name: string }[];
  label?: string | React.ReactNode;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  fastField?: boolean;
  handleChange?: (value: string[]) => void;
  addFilterSort?: boolean;
  showTagTooltip?: boolean;
  getTagTooltip?: (id: number) => string;
  info?: string;
} & SelectProps<any>;

const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name,
  className,
  values,
  label,
  placeholder,
  required,
  disabled,
  loading,
  fastField,
  handleChange,
  addFilterSort,
  getTagTooltip,
  showTagTooltip,
  info,
  ...selectProps
}) => {
  const tagRender = (props: CustomTagProps) => {
    const { label, closable, value, onClose } = props;
    const tooltip = getTagTooltip && getTagTooltip(value);

    if (loading) return <span />;

    return (
      <Tag closable={closable} onClose={onClose}>
        <AntTooltip title={tooltip} hide={!tooltip}>
          {label}
        </AntTooltip>
      </Tag>
    );
  };

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched, setFieldValue, setFieldTouched } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        className={className}
        label={label}
        required={required}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
        extra={info}
      >
        <Select
          mode="multiple"
          placeholder={placeholder}
          value={field.value}
          onChange={(value: string[]) => {
            if (handleChange) handleChange(value);
            else {
              setFieldValue(name, value);
              setFieldTouched(name);
            }
          }}
          disabled={disabled}
          loading={loading}
          optionFilterProp="label"
          filterSort={
            addFilterSort
              ? (a, b) =>
                  (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase())
              : undefined
          }
          tagRender={showTagTooltip ? tagRender : undefined}
          {...selectProps}
          options={values.map((value: { id: number; name: string }) => ({
            key: value.id,
            value: value.id,
            // We use the value of the innerHTML for the filterOptions - if we provide
            // anything else than a string here, the search would break.
            label: value.name,
          }))}
        />
      </Form.Item>
    );
  };

  return fastField ? (
    <FastField name={name}>{fieldContent}</FastField>
  ) : (
    <Field name={name}>{fieldContent}</Field>
  );
};

export default FormMultiSelect;
