import React from 'react';
import { CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';

type SelectWithPreviewProps = {
  value: string | number | null;
  onChange: (e: any) => void;
  showSelect?: boolean;
  allowClear?: boolean;
  size?: any;
  showSearch?: boolean;
  options: { key: number | string; label: string; value: string | number }[];
  disabled?: boolean;
};

const SelectWithPreview: React.FC<SelectWithPreviewProps> = ({
  onChange,
  value,
  showSelect,
  allowClear,
  size,
  showSearch,
  options,
  disabled,
}) => {
  const [isEditAble, setIsEditAble] = React.useState(showSelect || !value || false);
  React.useEffect(() => {
    if (!value) {
      setIsEditAble(true);
    }
  }, [value]);
  return isEditAble ? (
    <Select
      size={size || 'default'}
      autoFocus={!!value}
      defaultOpen={!!value}
      allowClear={allowClear}
      value={value || undefined}
      showSearch={showSearch}
      optionFilterProp="label"
      onChange={(val: string | number | undefined) => {
        if (val) {
          setIsEditAble(false);
        }
        onChange(val ? val.toString() : undefined);
      }}
      onBlur={(e: React.FocusEvent<HTMLElement>) => {
        if (e.target.nodeValue || value) {
          setIsEditAble(false);
        }
      }}
      style={{ width: '100%' }}
      options={options}
      disabled={disabled}
    />
  ) : (
    <span
      onClick={disabled ? undefined : () => setIsEditAble(true)}
      className={classNames('select-with-preview', {
        'select-with-preview__disabled': disabled,
      })}
    >
      <span>{value}</span>
      {!disabled && <DownOutlined className="icon-down" />}
      {!disabled && (
        <CloseCircleFilled className="icon-close-circle" onClick={() => onChange(undefined)} />
      )}
    </span>
  );
};

export default SelectWithPreview;
