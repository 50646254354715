import React from 'react';
import { connect } from 'react-redux';
import { Modal, Radio, Select } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import SegmentCheckboxes from '../../global/SegmentCheckboxes';
import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { Segment, Price, ChannelResources, ExtendedResources } from '../../../../types/resources';
import translationConstants from '../../../constants/ImportExportTranslation.json';
import {
  HIDDEN_RECEIVER_SEGMENTS,
  REMOVE_DEFAULT_SELECTION_SEGMENTS,
} from '../../../constants/ImportExportConstants';
import PriceSheetSelect from '../../global/PriceSheetSelect';
import { PriceSheetType } from '../../../../types/price_sheet';
import { AsyncDispatch } from '../../../../types/global';
import { fetchPriceSheets } from '../../../actions/parent/price_sheets/fetch';
import ExportAces from '../../global/ExportAces';
import ExportPDF from '../../global/ExportPDF';
import {
  getDefaultValues,
  DefaultValuesSelectorState,
} from '../../../selectors/default_values/defaultValuesSelector';
import ExportPies from '../../global/ExportPies';
import { ExportTypes } from '../../../../types/import_export';
import { isReceiver, isManufacturer } from '../../../utils/UserUtils';
import { hasPermission } from '../../../utils/Permissions';
import { ExportBuilderAdvancedTemplate } from '../../../../types/export_builder_advanced';
import { fetchExportBuilderAdvancedTemplates } from '../../../actions/brand/export_builder_advanced/fetch';

type CatalogueExportModalProps = {
  segments: Segment[];
  priceSheets: PriceSheetType[];
  priceTypes: Price[];
  pdfTemplates: ExtendedResources[];
  exportTemplatesAdvanced: ExportBuilderAdvancedTemplate[];
  channelResources: ChannelResources;
  dispatch: AsyncDispatch;
  brandId?: number;
  defaultValues: DefaultValuesSelectorState;
  isManufacturer: boolean;
  isReceiver: boolean;
  canManageBundle: boolean;
  canManageEquipment: boolean;
  canManageExportFlatApplication: boolean;
} & WithTranslation;

type OwnProps = {
  handleStartExport: (obj: any) => void;
  handleExportModalState: () => void;
  showModal: boolean;
  exportType: ExportTypes;
};

type Props = CatalogueExportModalProps & OwnProps;

export type PriceTypes = {
  selectedPriceSheetIds: number[];
  selectedPriceTypeIds: number[];
  withoutPrices: boolean;
};

type ExcelFullParameters = {
  selectedSegmentIds: number[];
  individualYears: boolean;
};

export type ExcelPriceSheetParameters = {
  priceSheetIds: number[];
  priceTypeIds: number[];
  withoutPrices: boolean;
};

type ExcelTemplateAdvancedParameters = {
  exportTemplateAdvancedId: number;
};

type PdfParameters = {
  pdfTemplateId: number | null;
  priceSheetId: number | null;
};

type AcesParameters = {
  acesVersion: number;
  baseVehicle: string;
};

type PiesParameters = {
  piesVersion: number;
  priceSheetIds: number[];
  priceTypeIds: number[];
  withoutPrices: boolean;
};

export type ExportParamsState = {
  fileType: ExportTypes;
  excelFullState?: ExcelFullParameters;
  excelPriceSheetState?: ExcelPriceSheetParameters;
  excelTemplateAdvancedState?: ExcelTemplateAdvancedParameters;
  pdfState?: PdfParameters;
  acesState?: AcesParameters;
  piesState?: PiesParameters;
};

type ExportStatusState = {
  excelFullState: ExcelFullParameters;
  excelPriceSheetState: ExcelPriceSheetParameters;
  excelTemplateAdvancedState: ExcelTemplateAdvancedParameters;
  pdfState: PdfParameters;
  acesState: AcesParameters;
  piesState: PiesParameters;
};

const CatalogueExportModal = (props: Props) => {
  const { exportType, t } = props;

  const excelSegments = () => {
    if (props.isReceiver)
      return props.segments.filter(segment => !HIDDEN_RECEIVER_SEGMENTS.includes(segment.name));

    let filterdSegments = props.segments;
    if (!props.canManageBundle)
      filterdSegments = filterdSegments.filter(segment => segment.name !== 'app_inheriting');

    if (!props.canManageEquipment)
      filterdSegments = filterdSegments.filter(segment => segment.name !== 'equipments');

    if (!props.canManageExportFlatApplication)
      filterdSegments = filterdSegments.filter(segment => segment.name !== 'flat_applications');
    return filterdSegments;
  };

  const [exportState, setExportState] = React.useState<ExportStatusState>({
    excelFullState: {
      individualYears: false,
      selectedSegmentIds: excelSegments
        ? excelSegments()
            .filter(s => !REMOVE_DEFAULT_SELECTION_SEGMENTS.includes(s.name))
            .map(s => s.id)
        : [],
    },
    excelPriceSheetState: {
      priceSheetIds: [],
      priceTypeIds: [],
      withoutPrices: false,
    },
    excelTemplateAdvancedState: {
      exportTemplateAdvancedId:
        props.exportTemplatesAdvanced.length && props.exportTemplatesAdvanced[0].id
          ? props.exportTemplatesAdvanced[0].id
          : 0,
    },
    pdfState: {
      pdfTemplateId: null,
      priceSheetId: null,
    },
    acesState: {
      acesVersion: props.defaultValues?.acesVersion
        ? Number(props.defaultValues.acesVersion)
        : props.channelResources.aces_versions[0].id,
      baseVehicle: props.defaultValues ? props.defaultValues.individualByBase : '0',
    },
    piesState: {
      piesVersion: props.defaultValues?.piesVersion
        ? Number(props.defaultValues.piesVersion)
        : props.channelResources.pies_versions[0].id,
      withoutPrices: false,
      priceSheetIds: [],
      priceTypeIds: [],
    },
  });

  React.useEffect(() => {
    if (!props.exportTemplatesAdvanced.length) {
      props.dispatch(fetchExportBuilderAdvancedTemplates(props.brandId));
    }
    if (!props.priceSheets.length) {
      props.dispatch(fetchPriceSheets(props.brandId));
    }
  }, []);

  const getExportName = () => {
    switch (exportType) {
      case ExportTypes.EXCEL: {
        return translationConstants.excel_full;
      }
      case ExportTypes.EXCEL_PRICE_SHEETS: {
        return translationConstants.excel_price_sheets;
      }
      case ExportTypes.EXCEL_TEMPLATE_ADVANCED: {
        return translationConstants.full_export_template;
      }
      case ExportTypes.PDF: {
        return translationConstants.pdf;
      }
      case ExportTypes.ACES: {
        return translationConstants.aces;
      }
      case ExportTypes.PIES: {
        return translationConstants.pies;
      }
      default:
        return '';
    }
  };

  const getStateToPass = () => {
    switch (exportType) {
      case ExportTypes.EXCEL: {
        return { fileType: exportType, ...exportState.excelFullState };
      }
      case ExportTypes.EXCEL_PRICE_SHEETS: {
        return { fileType: exportType, ...exportState.excelPriceSheetState };
      }
      case ExportTypes.EXCEL_TEMPLATE_ADVANCED: {
        return { fileType: exportType, ...exportState.excelTemplateAdvancedState };
      }
      case ExportTypes.ACES: {
        return { fileType: exportType, ...exportState.acesState };
      }
      case ExportTypes.PIES: {
        return { fileType: exportType, ...exportState.piesState };
      }
      case ExportTypes.PDF: {
        return { fileType: exportType, ...exportState.pdfState };
      }
      default:
        throw Error('Export type not given');
    }
  };

  const handleStartExport = () => {
    const exportState = getStateToPass();
    props.handleStartExport(exportState);
    props.handleExportModalState();
  };

  const exportName = getExportName();

  return (
    <Modal
      title={`Export - ${exportName}`}
      open={props.showModal}
      onCancel={props.handleExportModalState}
      onOk={handleStartExport}
      okText={translationConstants.export.export}
    >
      {exportType === ExportTypes.EXCEL && (
        <div>
          <SegmentCheckboxes
            selectedSegmentIds={exportState.excelFullState.selectedSegmentIds}
            segments={excelSegments()}
            onChange={(selectedSegmentIds: number[]) => {
              setExportState(values => ({
                ...values,
                excelFullState: { ...values.excelFullState, selectedSegmentIds },
              }));
            }}
          />
          <br />
          <Radio.Group
            onChange={() =>
              setExportState(values => ({
                ...values,
                excelFullState: {
                  ...values.excelFullState,
                  individualYears: !values.excelFullState.individualYears,
                },
              }))
            }
            value={exportState.excelFullState.individualYears.toString()}
          >
            <Radio value="false">{translationConstants.export.grouped_by_range}</Radio>
            <Radio value="true">{translationConstants.export.individual_years}</Radio>
          </Radio.Group>
        </div>
      )}
      {exportType === ExportTypes.EXCEL_PRICE_SHEETS && (
        <PriceSheetSelect
          priceSheets={props.priceSheets}
          priceTypes={props.priceTypes}
          updateSelection={(selection: PriceTypes) =>
            setExportState(values => ({
              ...values,
              excelPriceSheetState: {
                priceSheetIds: selection.selectedPriceSheetIds,
                priceTypeIds: selection.selectedPriceTypeIds,
                withoutPrices: selection.withoutPrices,
              },
            }))
          }
          multiSelectTypes
        />
      )}
      {exportType === ExportTypes.EXCEL_TEMPLATE_ADVANCED && (
        <Select
          className="import-export__export-template-select"
          placeholder={t('exportBuilderAdvanced:searchByTemplateNameId')}
          onChange={exportTemplateId =>
            setExportState(values => ({
              ...values,
              excelTemplateAdvancedState: { exportTemplateAdvancedId: Number(exportTemplateId) },
            }))
          }
          options={props.exportTemplatesAdvanced.map(template => ({
            key: template.id,
            value: template.id!,
            label: template.name,
          }))}
          showSearch
          optionFilterProp="label"
        />
      )}
      {exportType === ExportTypes.PDF && (
        <ExportPDF
          pdfTemplates={props.pdfTemplates}
          priceSheets={props.priceSheets}
          onChange={(pdfState: any) =>
            setExportState(values => ({
              ...values,
              pdfState,
            }))
          }
        />
      )}
      {exportType === ExportTypes.ACES && (
        <ExportAces
          acesVersions={props.channelResources.aces_versions}
          defaultVersion={exportState.acesState.acesVersion}
          defaultBaseVehicle={exportState.acesState.baseVehicle}
          onChange={(acesState: any) =>
            setExportState(values => ({
              ...values,
              acesState,
            }))
          }
        />
      )}
      {exportType === ExportTypes.PIES && (
        <ExportPies
          piesVersions={props.channelResources.pies_versions}
          defaultVersion={exportState.piesState.piesVersion}
          priceSheets={props.priceSheets}
          priceTypes={props.priceTypes}
          showPriceOption={props.isManufacturer}
          onChange={(piesState: any) =>
            setExportState(values => ({
              ...values,
              piesState,
            }))
          }
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  const receiver = state.user.user && isReceiver(state.user.user);
  return {
    brandId: state.parent.brands.selectedBrandId,
    segments: state.resources.data.global && state.resources.data.global.segments,
    priceSheets: state.parent.priceSheets.priceSheets || [],
    priceTypes: (state.resources.data && state.resources.data.price.types) || [],
    exportTemplatesAdvanced: state.brand.exportBuilderAdvanced.templates,
    pdfTemplates: (state.resources.data && state.resources.data.export.pdf_templates) || [],
    channelResources: state.resources.data.channel,
    defaultValues: receiver ? null : getDefaultValues(state),
    isManufacturer: state.user.user && isManufacturer(state.user.user),
    isReceiver: state.user.user && isReceiver(state.user.user),
    canManageBundle: hasPermission(state.user.user, 'can_manage_bundle'),
    canManageEquipment: hasPermission(state.user.user, 'can_manage_equipment'),
    canManageExportFlatApplication: hasPermission(state.user.user, 'can_export_flat_applications'),
  };
};

export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(CatalogueExportModal))
);
