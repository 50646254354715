import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
import { fetchItemCategories, fetchSubBrands } from '../../actions/items/item/fetch';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemPage from '../../components/body/item/ItemPage';
import { deleteItems } from '../../actions/catalogue/catalogue/delete';
import { updateItem } from '../../actions/catalogue/catalogue/update';
import { Item } from '../../../types/item';
import ItemInfo from '../../components/global/ItemInfo';
import { setActiveArea } from '../../actions/app/navigationBar';
import { Brand, SubBrand } from '../../../types/brand';
import { isReceiver } from '../../utils/UserUtils';
import { createBrandTitle } from '../../utils/String';
import { StandardResourceCode } from '../../../types/resources';
import { AsyncDispatch } from '../../../types/global';

const ItemContainer = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const {
    itemId,
    isFetchingFullItem,
    userNameList,
    selectedItems,
    listBrands,
    selectedBrandId,
    subBrandParendId,
    listSubBrands,
    listQualifier,
    listUOM,
    listGtin,
    itemResources,
    receiver,
    itemBrandId,
  } = useSelector((state: ApplicationState) => {
    const receiverUser = isReceiver(state.user.user!);
    const selectedItems = getSelectedItems(state);
    const itemBrandId = selectedItems[0]?.parent_owner_brand_id;
    const brands = receiverUser
      ? state.receiverDataStream.authorizedBrands.filter(b => b.id === itemBrandId)
      : state.parent.brands.brands;

    return {
      itemId: state.catalogue.catalogue.selectedItemIds[0],
      isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
      userNameList: state.user.userNameList,
      selectedItems,
      itemBrandId,
      listBrands: brands.map((brand: Brand) => ({
        ...brand,
        name: createBrandTitle(
          brand.name,
          brand.code,
          brand.default_whitelabel_brand_code,
          receiverUser
        ),
      })),
      selectedBrandId: state.parent.brands.selectedBrandId,
      subBrandParendId: state.items.item.subBrandParentId,
      listSubBrands: state.items.item.subBrands.map((brand: SubBrand) => ({
        ...brand,
        name: `${brand.code} | ${brand.name}`,
      })),
      listQualifier: state.resources.data.item.quantity_per_application_qualifiers.map(
        (qualifier: StandardResourceCode) => ({
          ...qualifier,
          name: `${qualifier.name} (${qualifier.code})`,
        })
      ),
      listUOM: state.resources.data.global.measurement_uom_codes,
      listGtin: state.resources.data.global.gtin_qualifiers,
      itemResources: state.resources.data.item,
      receiver: receiverUser,
    };
  }, shallowEqual);
  const populationStatusList = itemResources.population_statuses;

  React.useEffect(() => {
    const brandId = receiver ? itemBrandId : selectedBrandId;
    if (itemId) dispatch(fetchItemCategories({ itemId, brandId }));
  }, [dispatch, itemBrandId, itemId, receiver, selectedBrandId]);

  React.useEffect(() => {
    const brandId = receiver ? itemBrandId : selectedBrandId;
    if (brandId && subBrandParendId !== brandId) dispatch(fetchSubBrands(brandId));
  }, [dispatch, itemBrandId, receiver, selectedBrandId, subBrandParendId]);

  React.useEffect(() => {
    dispatch(setActiveArea('item'));
  }, [dispatch]);

  const handleDeleteItem = (itemId: number) => {
    dispatch(deleteItems({ itemIds: [itemId] }));
  };
  const updateItems = (itemId: number, item: Item) => {
    return dispatch(updateItem(itemId, item));
  };

  if (selectedItems.length > 1 || selectedItems.length === 0) {
    return <ItemInfo items={selectedItems} />;
  }

  if (isFetchingFullItem) {
    return <Spin className="spinner-center" />;
  }

  return (
    <ItemPage
      listBrands={listBrands}
      listSubBrands={listSubBrands}
      listQualifier={listQualifier}
      listUOM={listUOM}
      listGtin={listGtin}
      onSubmit={(itemId: number, editedItem: Item) => updateItems(itemId, editedItem)}
      deleteItem={handleDeleteItem}
      populationStatusList={populationStatusList}
      userNameList={userNameList}
    />
  );
};

export default ItemContainer;
