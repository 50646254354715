import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Button, Col, Row, Tag, Popover, Spin } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { ebayValidationSchema } from '../../../utils/ChannelValidationSchemas';
import { PriceSheetType } from '../../../../types/price_sheet';
import { ExtendedResources, Ebay, DescriptionTypeElement } from '../../../../types/resources';
import { EbaySettings, EbayCountry, EbayPolicies } from '../../../../types/ebay';
import { Channel } from '../../../../types/channel';
import { AsyncDispatch } from '../../../../types/global';
import channelConstants from '../../../constants/ChannelTranslation.json';
import commonTranslation from '../../../constants/SaveTranslation.json';
import { pricingUrl } from '../../../constants/ParamountReactConstants';
import { disconnectEbayAccount } from '../../../actions/items/ebay/delete';
import SimpleDivider from '../../global/SimpleDivider';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import FormErrorFocus from '../../global/Forms/FormErrorFocus';
import ChannelFormDirtyHandler from './ChannelFormDirtyHandler';
import EbaySettingsFormPart from './EbaySettingsFormPart';
import EbayScheduleFormPart from './EbayScheduleFormPart';
import validationMessages from '../../../constants/ValidationMessages.json';
import EbayConnect from './EbayConnect';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

const { drawer, ebay } = channelConstants;

type EbayChannelFormValues = {
  country: string;
  businessZip: string;
  descriptionTypeIds: number[];
  ebayListtime: string;
  ebayListtype: string;
  ebaySiteid: number;
  priceSheetId: number | null;
  priceTypeId: number | null;
  schedule: {
    deliveryFrequencyId: number;
  };
  titleDescriptionTypeId: number | null;
  withoutPrices: number | null;
};

type EditEbayChannelProps = {
  dispatch: AsyncDispatch;
  ebaySettings: EbaySettings;
  channel: Channel;
  ebayResources: Ebay;
  ebayPolicies: EbayPolicies;
  countries: EbayCountry[];
  descriptionTypes: DescriptionTypeElement[];
  deliveryFrequencies: ExtendedResources[];
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  fetchingEbaySettings: boolean;
  fetchingConnectionState: boolean;
  isReceiverUser: boolean;
  handleEbayUpdate: (values: { [key: string]: any }) => Promise<any>;
  createEbayConnection: () => Promise<any>;
  onClose: (force?: boolean) => void;
} & WithTranslation &
  WithAntdContextProps;

type EditEbayChannelState = {
  fetchingConnectionState: boolean;
  fetchingRegisterData: boolean;
  connectionAuthUrl: string | undefined;
};

export class EditEbayChannel extends React.Component<EditEbayChannelProps, EditEbayChannelState> {
  validationSchema = Yup.object().shape({
    ebaySiteid: Yup.number().required(validationMessages.required),
    address1: Yup.string().required(validationMessages.required),
    city: Yup.string().required(validationMessages.required),
    businessZip: Yup.string().required(validationMessages.required),
    country: Yup.string().required(validationMessages.required),
    state: Yup.string().required(validationMessages.required),
    county: Yup.string().required(validationMessages.required),
    ...ebayValidationSchema(this.props.isReceiverUser), // only for receiver user in ebay channel ignore pricesheet validation
  });

  handleChannelChange = (values: EbayChannelFormValues) => {
    if (values.withoutPrices) {
      values.priceSheetId = null;
      values.priceTypeId = null;
    }

    return this.props.handleEbayUpdate(values);
  };

  handleDisconnect = () => {
    const { t } = this.props;
    const disconnectEbay = () => this.props.dispatch(disconnectEbayAccount());
    const { modal } = this.props.appContext;

    modal.confirm({
      title: t('ebay:disconnectYourEbayAccount'),
      icon: <ExclamationCircleOutlined />,
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        disconnectEbay();
      },
    });
  };

  connectAccount = () => {
    const { t, ebaySettings } = this.props;
    const ebayRegistered = ebaySettings.ebay_registered;

    return (
      <React.Fragment>
        <SimpleDivider title={ebay.account} />
        {!ebayRegistered && (
          <React.Fragment>
            <EbayConnect createEbayConnection={this.props.createEbayConnection} />
            <Popover content={<span>{ebay.connectionHint}</span>} trigger="click">
              <QuestionCircleOutlined className="help__icon" />
            </Popover>
          </React.Fragment>
        )}
        {!!ebayRegistered && (
          <React.Fragment>
            <Tag color="green">{ebay.connected}</Tag>

            <Button onClick={() => this.handleDisconnect()} size="small">
              {t('ebay:disconnect')}
            </Button>
          </React.Fragment>
        )}

        <p className="channel__ebay-additional-fees-hint mt-1">
          {ebay.additionalFees1}
          <a href={pricingUrl} target="_blank" rel="noopener noreferrer">
            {pricingUrl}
          </a>
          {ebay.additionalFees2}
        </p>
      </React.Fragment>
    );
  };

  channelForm = () => {
    const { channel, ebaySettings } = this.props;
    const defaultSettings = channel.default_integration_settings;
    const defaultSchedule = channel.default_schedule;
    const disabled = !ebaySettings.ebay_registered;

    return (
      <Formik
        initialValues={{
          ebaySiteid: 100,
          address1: ebaySettings ? ebaySettings.address_1 || '' : '',
          address2: ebaySettings ? ebaySettings.address_2 || '' : '',
          city: ebaySettings ? ebaySettings.city || '' : '',
          country: ebaySettings ? ebaySettings.country || '' : '',
          county: ebaySettings ? ebaySettings.county || '' : '',
          businessZip: ebaySettings ? ebaySettings.postal_code || '' : '',
          state: ebaySettings ? ebaySettings.state || '' : '',
          ebayListtype: ebaySettings.listing_type || '',
          ebayListtime: ebaySettings.listing_length || '',
          // profiles
          paymentProfileId: ebaySettings.payment_policy ? ebaySettings.payment_policy.id || '' : '',
          returnProfileId: ebaySettings.return_policy ? ebaySettings.return_policy.id || '' : '',
          shippingProfileId: ebaySettings.fulfillment_policy
            ? ebaySettings.fulfillment_policy.id || ''
            : '',
          // defaults
          priceSheetId: defaultSettings ? defaultSettings.price_sheet_id : null,
          priceTypeId: defaultSettings ? defaultSettings.price_type_id : null,
          titleDescriptionTypeId: defaultSettings
            ? defaultSettings.title_description_type_id
            : 9065, // description long
          descriptionTypeIds: defaultSettings ? defaultSettings.description_type_ids || [] : [],
          withoutPrices: defaultSettings ? defaultSettings.manage_prices_manually : 0,
          schedule: {
            deliveryFrequencyId: defaultSchedule ? defaultSchedule.delivery_frequency_id : 4,
          },
        }}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.handleChannelChange(values)
            .then(() => {
              resetForm({ values });
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          values,
          dirty,
        }) => (
          <React.Fragment>
            <Form layout="vertical" className="h-full flex flex-col">
              <ChannelFormDirtyHandler />
              <FormErrorFocus />
              <div className="flex-1 overflow-auto pr-5 pl-5">
                {this.connectAccount()}

                <Row gutter={20} align="bottom">
                  <Col span={12}>
                    <FormInput
                      name="ebaySite"
                      label={ebay.site}
                      placeholder={ebay.sitePlaceholder}
                      required
                      disabled
                    />
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={6}>
                    <FormInput
                      name="address1"
                      label={ebay.address_1}
                      required
                      disabled={disabled}
                    />
                  </Col>
                  <Col span={6}>
                    <FormInput name="address2" label={ebay.address_2} disabled={disabled} />
                  </Col>
                  <Col span={6}>
                    <FormInput name="city" label={ebay.city} required disabled={disabled} />
                  </Col>
                  <Col span={6}>
                    <FormInput name="businessZip" label={ebay.zip} required disabled={disabled} />
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={8}>
                    <FormSelect
                      name="country"
                      values={this.props.countries || []}
                      label={ebay.country}
                      placeholder={drawer.select_filter}
                      useCode
                      required
                      showSearch
                      disabled={disabled}
                    />
                  </Col>
                  <Col span={8}>
                    <FormInput name="state" label={ebay.state} required disabled={disabled} />
                  </Col>
                  <Col span={8}>
                    <FormInput name="county" label={ebay.county} required disabled={disabled} />
                  </Col>
                </Row>

                <SimpleDivider title={ebay.defaultSchedule} />

                <EbayScheduleFormPart
                  values={values}
                  deliveryFrequencies={this.props.deliveryFrequencies}
                  disabled={disabled}
                />

                <SimpleDivider title={ebay.defaultSettings} />

                <EbaySettingsFormPart
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  ebayResources={this.props.ebayResources}
                  descriptionTypes={this.props.descriptionTypes}
                  ebayPolicies={this.props.ebayPolicies}
                  priceSheets={this.props.priceSheets}
                  priceTypes={this.props.priceTypes}
                  disabled={disabled}
                  isReceiverUser={this.props.isReceiverUser}
                />
              </div>

              <div className="drawer-submit__bottom flex-col items-end">
                <div>
                  <Button
                    onClick={() => this.props.onClose(true)}
                    className="drawer-submit__bottom-cancel"
                  >
                    {commonTranslation.cancel}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                  >
                    {commonTranslation.save}
                  </Button>
                </div>
              </div>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  };

  render() {
    const { fetchingEbaySettings, fetchingConnectionState } = this.props;

    if (fetchingEbaySettings && !fetchingConnectionState) {
      return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;
    }

    return <div className="channel__ebay-settings h-full">{this.channelForm()}</div>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  fetchingEbaySettings: state.channel.channels.fetchingEbaySettings,
  fetchingConnectionState: state.channel.channels.fetchingConnectionState,
  ebayPolicies: state.channel.channels.ebayPolicies,
  ebaySettings: state.channel.channels.ebaySettings,
  countries: state.channel.channels.ebayResources.ebay_countries,
  deliveryFrequencies: state.resources.data.channel.delivery_frequencies,
  ebayResources: state.resources.data.ebay,
  descriptionTypes: state.resources.data.description.types,
  priceSheets: state.parent.priceSheets.priceSheets || [],
  priceTypes: (state.resources.data && state.resources.data.price.types) || [],
});

export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(withAntdContext(EditEbayChannel)))
);
