import React, { useState } from 'react';
import { connect } from 'react-redux';
import { App, Button, Col, Drawer, Form, Input, Row, Spin } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import { ApplicationState } from '../../../reducers';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import {
  fetchDefaultNotes,
  fetchDefaultQualifiers,
  fetchRankedPositions,
} from '../../../actions/items/application/fetch';
import { updateItem } from '../../../actions/catalogue/catalogue/update';
import { AsyncDispatch } from '../../../../types/global';
import { Item } from '../../../../types/item';
import { ApplicationNote, Qualifier, RankedPositions } from '../../../../types/application';
import { StandardResource } from '../../../../types/resources';
import { DefaultValue } from '../../../../types/brand_settings';
import FormInput from '../../global/Forms/FormInput';
import ApplicationPosition from './ApplicationPosition';
import DefaultQualifierContainer from '../../../containers/application/DefaultQualifierContainer';
import {
  updateDefaultNotes,
  updateDefaultQualifiers,
} from '../../../actions/items/application/update';
import { intercomEvent } from '../../../utils/IntercomUtils';
import IntercomFormEventHandler from '../../global/page/IntercomFormEventHandler';
import { brandAccDefaultValues } from '../../../selectors/default_values/defaultValuesSelector';

type ApplicationDefaultsFormProps = {
  mfrLabel: string;
  qty: number | null;
  position: number | null;
  overwrite: number;
  defaultQualifiers: (Qualifier & { changed?: boolean })[];
  defaultNotes: ApplicationNote[];
};

type ApplicationDefaultsDrawerProps = {
  dispatch: AsyncDispatch;
  item: Item;
  visible: boolean;
  rankedPositions: RankedPositions[];
  positionResources: StandardResource[];
  fetchingDefaultQualifiers: boolean;
  defaultQualifiers: Qualifier[];
  defaultNotes: ApplicationNote[];
  defaultValues: DefaultValue[];
  onClose: () => void;
};

const ApplicationDefaultsDrawer: React.FC<ApplicationDefaultsDrawerProps> = ({
  dispatch,
  item,
  rankedPositions,
  positionResources,
  fetchingDefaultQualifiers,
  defaultQualifiers,
  defaultNotes,
  defaultValues,
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const [formChanged, setFormChanged] = useState(false);

  React.useEffect(() => {
    if (item.id) {
      dispatch(fetchDefaultQualifiers(item.id));
      dispatch(fetchDefaultNotes(item.id));
    }
  }, [dispatch, item.id]);

  React.useEffect(() => {
    // item.category is included in extended item data, so we have to check for it
    if (item.category?.id) dispatch(fetchRankedPositions(item.category.id));
  }, [dispatch, item.id, item.category]);

  React.useEffect(() => {
    if (visible) setFormChanged(false);
  }, [visible]);

  const showPartTypeId = defaultValues.find(d => d.id === 11)?.value === '1';

  const handleClose = () => {
    if (formChanged) {
      modal.confirm({
        title: t('common:closeWarningTitle'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onClose();
        },
      });
    } else onClose();
  };

  const validationSchema = Yup.object().shape({
    mfrLabel: Yup.string(),
    qty: Yup.number()
      .typeError(t('validation:invalidNumber'))
      .positive(t('validation:positiveNumber')),
  });

  const updateDefaultValues = async (
    values: ApplicationDefaultsFormProps,
    formikActions: FormikHelpers<ApplicationDefaultsFormProps>
  ) => {
    const { setSubmitting } = formikActions;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'application_default_values',
      part_number: item?.part_number,
      brand_code: item?.brand_code,
    });

    const {
      mfrLabel,
      qty,
      position,
      overwrite,
      defaultQualifiers: updateQualifiers,
      defaultNotes,
    } = values;

    if (
      mfrLabel !== item.default_mfr_label ||
      qty !== item.default_application_qty ||
      position !== item.default_position_id ||
      overwrite !== item.default_overwrite
    ) {
      let updatedItem = { ...item };

      if (mfrLabel !== item.default_mfr_label) {
        updatedItem = { ...updatedItem, default_mfr_label: mfrLabel };
      }
      if (qty !== item.default_application_qty) {
        updatedItem = { ...updatedItem, default_application_qty: Number(qty) };
      }
      if (position !== item.default_position_id) {
        updatedItem = { ...updatedItem, default_position_id: position };
      }
      if (overwrite !== item.default_overwrite) {
        updatedItem = { ...updatedItem, default_overwrite: overwrite };
      }

      dispatch(updateItem(item.id, updatedItem));
    }

    const update = updateQualifiers.map((qualifier: Qualifier) => ({
      id: qualifier.qualifier_id,
      values: qualifier.values,
      record_number: qualifier.record_number,
    }));
    await Promise.all([
      dispatch(updateDefaultQualifiers(item.id, update)),
      dispatch(updateDefaultNotes(item.id, defaultNotes)),
    ]);

    setSubmitting(false);
    onClose();
  };

  const form = () => {
    return (
      <Formik
        initialValues={{
          mfrLabel: item.default_mfr_label || '',
          qty: item.default_application_qty,
          position: item.default_position_id,
          overwrite: item.default_overwrite,
          defaultQualifiers,
          defaultNotes,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => updateDefaultValues(values, actions)}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          setFieldTouched,
          setFieldValue,
          values,
          dirty,
        }) => (
          <React.Fragment>
            <Form layout="vertical" className="application__defaults-form">
              <Row>
                <Col span={24}>
                  <Form.Item label={t('application:partType')}>
                    <Input
                      placeholder={
                        item.category && item.category.id
                          ? `${item.category.level1_name} | ${item.category.level2_name} | ${
                              item.category.level3_name
                            }${
                              showPartTypeId && item.part_type_id ? ` (${item.part_type_id})` : ''
                            }`
                          : t('application:noPartType')
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20} align="top">
                <Col span={12}>
                  <FormInput
                    name="mfrLabel"
                    label={t('application:mfrLabel')}
                    handleChange={(e: React.ChangeEvent<any>) => {
                      setFormChanged(true);
                      handleChange(e);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <FormInput
                    name="qty"
                    label={t('application:qty')}
                    handleChange={(e: React.ChangeEvent<any>) => {
                      setFormChanged(true);
                      handleChange(e);
                    }}
                  />
                </Col>
                <Col span={9}>
                  <Form.Item label={t('application:position')}>
                    <ApplicationPosition
                      className="application__overview-position-qty"
                      value={values.position || undefined}
                      rankedPositions={rankedPositions}
                      positionResources={positionResources}
                      onChange={(id: number | null) => {
                        setFieldValue('position', id);
                        setFieldTouched('position');
                        setFormChanged(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* 
              <FormRadio
                name="overwrite"
                values={[
                  { id: 0, name: t('application:addToExisting').toString() },
                  { id: 1, name: t('application:overwriteExisting').toString() },
                ]}
                label={t('application:overwriteLabel')}
                buttonOptions
              /> */}

              <div className="ant-form-item-label application__default-qualifiers-label">
                <label>{t('application:defaults.qualifier')}</label>
              </div>
              <div className="application__default-qualifiers-row">
                <DefaultQualifierContainer
                  itemId={item.id}
                  defaultQualifiers={values.defaultQualifiers}
                  deleteDefaultQualifier={(id: number, recordNumber: number) => {
                    const qualifiers = values.defaultQualifiers.filter(
                      q => !(q.qualifier_id === id && q.record_number === recordNumber)
                    );
                    setFieldValue('defaultQualifiers', qualifiers);
                    setFieldTouched('defaultQualifiers');
                    setFormChanged(true);
                  }}
                  updateDefaultQualifier={(qualifier: Qualifier) => {
                    const { defaultQualifiers } = values;
                    const updated = { ...qualifier, changed: true };
                    const qualifiers = defaultQualifiers.find(
                      ({ qualifier_id }) => updated.qualifier_id === qualifier_id
                    )
                      ? defaultQualifiers.map(q =>
                          q.qualifier_id === updated.qualifier_id &&
                          q.record_number === updated.record_number
                            ? updated
                            : q
                        )
                      : [...values.defaultQualifiers, updated];

                    setFieldValue('defaultQualifiers', qualifiers);
                    setFieldTouched('defaultQualifiers');
                    setFormChanged(true);
                  }}
                  duplicateDefaultQualifier={(qualifier: Qualifier) => {
                    const { defaultQualifiers } = values;

                    let index = 0;
                    defaultQualifiers.forEach((q, i) => {
                      if (q.qualifier_id === qualifier.qualifier_id) index = i;
                    });

                    const updatetQualifiers = [...defaultQualifiers];
                    updatetQualifiers.splice(index + 1, 0, qualifier);

                    setFieldValue('defaultQualifiers', updatetQualifiers);
                    setFieldTouched('defaultQualifiers');
                    setFormChanged(true);
                  }}
                  clearTempState={() => {
                    const updated = values.defaultQualifiers.map(q => ({ ...q, temp: false }));
                    setFieldValue('defaultQualifiers', updated);
                  }}
                  notes={values.defaultNotes}
                  addNote={(noteValue?: string) => {
                    const recordNumber = values.defaultNotes.length
                      ? Math.max(...values.defaultNotes.map(n => n.record_number))
                      : 1;
                    const notes = [
                      ...values.defaultNotes,
                      { record_number: (recordNumber || 0) + 1, note: noteValue || '' },
                    ];
                    setFieldValue('defaultNotes', notes);
                  }}
                  removeNote={(recordNumber: number) => {
                    const notes = values.defaultNotes.filter(n => n.record_number !== recordNumber);
                    setFieldValue('defaultNotes', notes);
                  }}
                  updateNote={(recordNumber: number, noteValue: string) => {
                    const note = values.defaultNotes.find(n => n.record_number === recordNumber);
                    const notes = note
                      ? values.defaultNotes.map(n =>
                          n.record_number === recordNumber ? { ...n, note: noteValue } : n
                        )
                      : [...values.defaultNotes, { record_number: recordNumber, note: noteValue }];
                    setFieldValue('defaultNotes', notes);
                  }}
                />
              </div>
            </Form>
            <div className="application__defaults-form-buttons">
              <Row gutter={20}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button
                    className="application__defaults-cancel-button"
                    onClick={() => handleClose()}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    className="application__defaults-submit-button"
                    disabled={!dirty || isSubmitting}
                    type="primary"
                    htmlType="submit"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                  >
                    {t('common:save')}
                  </Button>
                </Col>
              </Row>
            </div>

            <IntercomFormEventHandler
              segment="application_default_values"
              partNr={item.part_number}
              brandCode={item.brand_code}
            />
          </React.Fragment>
        )}
      </Formik>
    );
  };

  return (
    <Drawer
      className="application__defaults-drawer"
      title={t('application:defaults.title')}
      width="60%"
      open={visible}
      onClose={handleClose}
      destroyOnClose
      style={{ height: '100%' }}
    >
      {fetchingDefaultQualifiers && <Spin />}
      {!fetchingDefaultQualifiers && <div className="application__defaults">{form()}</div>}
    </Drawer>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedItems = getSelectedItems(state);
  return {
    item: selectedItems[0],
    fetchingDefaultQualifiers: state.items.application.fetchingDefaultQualifiers,
    defaultQualifiers: state.items.application.defaultQualifiers,
    defaultNotes: state.items.application.defaultNotes,
    rankedPositions: state.items.application.rankedPositions,
    positionResources: state.resources.data.vehicle.positions,
    defaultValues: brandAccDefaultValues(state),
  };
};

export default connect(mapStateToProps)(withErrorBoundary(ApplicationDefaultsDrawer));
