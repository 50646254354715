import { FluxStandardAction } from 'redux-promise-middleware';
import { Reducer } from 'redux';
import { Category, SubCategory, PartType } from '../../../types/item';
import { SubBrand } from '../../../types/brand';

export type ItemState = {
  fetchingCategory: boolean;
  fetchingSubCategory: boolean;
  fetchingPartType: boolean;
  categories: Category[];
  subCategories: SubCategory[];
  partTypes: PartType[];
  subBrands: SubBrand[];
  subBrandParentId?: number;
};

const initialState = {
  fetchingCategory: false,
  fetchingSubCategory: false,
  fetchingPartType: false,
  categories: [],
  subCategories: [],
  partTypes: [],
  subBrands: [],
  subBrandParentId: undefined,
};

const reducer: Reducer<ItemState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ITEM_CATEGORIES_PENDING': {
      const activeLevel = action.meta.activeLevel;

      const fetchingCategory = activeLevel === 'category';
      const fetchingSubCategory = activeLevel === 'subCategory';
      const fetchingPartType = activeLevel === 'partType';
      return {
        ...state,
        fetchingCategory,
        fetchingSubCategory: fetchingCategory || fetchingSubCategory,
        fetchingPartType: fetchingCategory || fetchingSubCategory || fetchingPartType,
      };
    }
    case 'FETCH_ITEM_CATEGORIES_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_ITEM_CATEGORIES_FULFILLED': {
      const response = action.payload.data;
      const page = action.meta.page;
      return {
        ...state,
        fetchingCategory: false,
        fetchingSubCategory: false,
        fetchingPartType: false,
        categories: page > 1 ? [...state.categories, ...response[1]] : response[1] || [],
        subCategories: page > 1 ? [...state.subCategories, ...response[2]] : response[2] || [],
        partTypes: page > 1 ? [...state.partTypes, ...response[3]] : response[3] || [],
      };
    }
    case 'FETCH_CATEGORY_ITEMS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_CATEGORY_ITEMS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_ITEM_SUB_BRANDS_FULFILLED': {
      const brandList = action.payload.data.brands;
      const subBrands = brandList[0]?.sub_brands || [];
      return { ...state, subBrands, subBrandParentId: brandList[0]?.id };
    }
    case 'SELECT_ITEM_CATEGORY': {
      return {
        ...state,
        selectedCategory: action.payload,
        selectedSubCategory: null,
        selectedPartType: null,
      };
    }
  }
  return state;
};

export default reducer;
