import React from 'react';
import { connect } from 'react-redux';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/catalogue/filter';
import FilterCategories from '../../components/header/FilterCategories';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

class PartTypeFilterContainer extends React.Component {
  componentDidMount() {
    const { filterOld } = this.props;
    this.fetchCategories();
    if (filterOld && filterOld.id && !filterOld.filter_category) {
      this.createCategoryFilter();
    }
  }

  componentDidUpdate(prevProps) {
    const { filterOld } = this.props;
    if (
      filterOld &&
      prevProps.filterOld !== filterOld &&
      !filterOld.filter_category &&
      !filterOld.delete
    ) {
      this.createCategoryFilter();
    }
  }

  fetchCategories = (params = {}, page) => {
    this.props.dispatch(actions.fetchFilterItemCategories(this.props.brandId, params, page));
  };

  createCategoryFilter = () => {
    this.props.dispatch(actions.createCategoryFilter(this.props.filter.id));
  };

  updateCategoryFilterOption = params => {
    const { filter } = this.props;

    if (
      filter.filter_category &&
      filter.filter_category[0] &&
      filter.filter_category[0].parameters &&
      filter.filter_category[0].parameters.find(p => p.level === params.level)
    )
      this.props.updateFilterOption({
        sectionKey: 'filter_category',
        level: params.level,
        optionId: params.optionId,
      });
    else {
      // create new filter parameter including value
      this.props.addFilterParameterValue({
        sectionKey: 'filter_category',
        optionId: params.optionId,
        level: params.level,
      });
    }
  };

  updateCategoryFilter = category => {
    let level;
    let name;
    if (category && category.part_type_name) {
      level = 3;
      name = category.part_type_name;
    } else if (category && category.sub_category_name) {
      level = 2;
      name = category.sub_category_name;
    } else if (category && category.category_name) {
      level = 1;
      name = category.category_name;
    }
    this.props.addFilterParameterValue({
      sectionKey: 'filter_category',
      resourceName: name,
      resourceId: category.id,
      level,
    });
  };

  render() {
    const { defaultValues } = this.props;

    const showPartTypeId = defaultValues.find(d => d.id === 11)?.value === '1';

    return (
      <FilterCategories
        filter={this.props.filter}
        categories={this.props.categories}
        subCategories={this.props.subCategories}
        partTypes={this.props.partTypes}
        fetchingCategory={this.props.fetchingCategory}
        fetchingSubCategory={this.props.fetchingSubCategory}
        fetchingPartType={this.props.fetchingPartType}
        showPartTypeId={showPartTypeId}
        updateCategoryFilter={this.updateCategoryFilter}
        updateCategoryFilterOption={this.updateCategoryFilterOption}
        fetchCategories={this.fetchCategories}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.catalogue.filter.categories,
    subCategories: state.catalogue.filter.subCategories,
    partTypes: state.catalogue.filter.partTypes,
    fetchingCategory: state.catalogue.filter.fetchingCategory,
    fetchingSubCategory: state.catalogue.filter.fetchingSubCategory,
    fetchingPartType: state.catalogue.filter.fetchingPartType,
    brandId: state.parent.brands.selectedBrandId,
    defaultValues: brandAccDefaultValues(state),
  };
}

export default connect(mapStateToProps)(withContainerWrapper(PartTypeFilterContainer));
