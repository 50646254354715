import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { BrandCustomField, CustomFieldGroup } from '../../../types/custom_fields';

export type CustomFieldsBrandState = {
  customFields: BrandCustomField[];
  customFieldGroups: CustomFieldGroup[];
  fetchingCustomFields: boolean;
  fetchingCustomFieldGroups: boolean;
  customFieldsBrandId?: number;
};

const initalState: CustomFieldsBrandState = {
  customFields: [],
  customFieldGroups: [],
  fetchingCustomFields: false,
  fetchingCustomFieldGroups: false,
  customFieldsBrandId: undefined,
};

const reducer: Reducer<CustomFieldsBrandState, FluxStandardAction> = (
  state = initalState,
  action
) => {
  switch (action.type) {
    case 'FETCH_SETTINGS_CUSTOM_FIELDS_PENDING': {
      return {
        ...state,
        customFieldsBrandId: undefined,
        fetchingCustomFields: true,
      };
    }
    case 'FETCH_SETTINGS_CUSTOM_FIELDS_FULFILLED': {
      return {
        ...state,
        customFields: action.payload.data,
        customFieldsBrandId: action.meta.brandId,
        fetchingCustomFields: false,
      };
    }
    case 'FETCH_CUSTOM_FIELD_GROUPS_PENDING': {
      return {
        ...state,
        fetchingCustomFieldGroups: true,
      };
    }
    case 'FETCH_CUSTOM_FIELD_GROUPS_FULFILLED': {
      return {
        ...state,
        customFieldGroups: action.payload.data,
        fetchingCustomFieldGroups: false,
      };
    }
    case 'UPDATE_CUSTOM_FIELD_GROUPS_FULFILLED': {
      const newGroups = action.payload.data;
      const groups =
        newGroups.length === 1
          ? action.meta.groups.map((g: CustomFieldGroup) =>
              g.name === newGroups[0].name ? newGroups[0] : g
            )
          : action.meta.groups;
      return {
        ...state,
        customFieldGroups: groups,
      };
    }
    case 'UPDATE_BRAND_CUSTOM_FIELD_FULFILLED': {
      const {
        customFieldId,
        name,
        customFieldTypeId,
        recordNumber,
        options,
        scriptId,
        ignoreOnSync,
        groupId,
      } = action.meta;
      const customFieldIds = state.customFields.map(({ id }) => id);
      const newCustomField = !customFieldIds.includes(customFieldId);
      return {
        ...state,
        customFields: newCustomField
          ? [
              ...state.customFields,
              {
                id: action.payload.data.custom_field_id,
                name,
                custom_field_type_id: customFieldTypeId,
                record_number: recordNumber,
                ignore_on_sync: ignoreOnSync,
                options,
                script_id: scriptId,
                group_id: groupId,
                read_only_custom_field: 0,
                dynamic_configuration: null,
              },
            ]
          : state.customFields.map(customField => {
              if (customField.id === customFieldId) {
                return {
                  ...customField,
                  name,
                  custom_field_type_id: customFieldTypeId,
                  record_number: recordNumber,
                  ignore_on_sync: ignoreOnSync,
                  options,
                  script_id: scriptId,
                  group_id: groupId,
                };
              }
              return customField;
            }),
      };
    }
    case 'DELETE_BRAND_CUSTOM_FIELD_FULFILLED': {
      return {
        ...state,
        customFields: state.customFields.filter(field => field.id !== action.meta.customFieldId),
      };
    }
    case 'DELETE_CUSTOM_FIELD_GROUP_FULFILLED': {
      return {
        ...state,
        customFieldGroups: state.customFieldGroups.filter(g => g.id !== action.meta.groupId),
      };
    }
    case 'UPDATE_BRAND_CUSTOM_FIELD_ORDER_FULFILLED': {
      return {
        ...state,
        customFields: action.meta.sortedCustomFields,
      };
    }
  }
  return state;
};

export default reducer;
