import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchAccountDefaultValues() {
  return {
    type: 'FETCH_ACCOUNT_DEFAULT_VALUES',
    payload: axios.get(`${getApiRoot()}/accounts/default_values`),
  };
}

export function updateAccountDefaultValues(defaultValueId: number, value: any) {
  value = typeof value === 'boolean' ? utils.booleanToStringInt(value) : value;
  const params = [
    {
      default_value_id: defaultValueId,
      value: value ? value.toString() : '',
    },
  ];

  return {
    type: 'UPDATE_ACCOUNT_DEFAULT_VALUE',
    meta: { defaultValueId, value },
    payload: axios.patch(`${getApiRoot()}/accounts/default_values`, {
      default_values: params,
    }),
  };
}
