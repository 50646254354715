import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

export type AntSelectEntries = { id: string | number; name: string }[];

export type AntSelectProps = SelectProps<any> & {
  popupClassName?: string;
  elements?: AntSelectEntries;
  clearContainer?: boolean;
};

export default class AntSelect extends React.Component<AntSelectProps> {
  render() {
    const {
      className,
      popupClassName = '',
      elements = [],
      onChange,
      onSelect,
      onDeselect,
      onBlur,
      value,
      placeholder,
      size,
      disabled,
      showSearch,
      allowClear,
      variant,
      suffixIcon,
      clearContainer,
      style,
    } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onBlur={onBlur}
        placeholder={placeholder}
        size={size}
        allowClear={allowClear}
        showSearch={showSearch}
        optionFilterProp="label"
        disabled={disabled}
        className={className}
        popupClassName={popupClassName}
        getPopupContainer={
          clearContainer
            ? undefined
            : trigger => (trigger && (trigger.parentNode as HTMLElement)) || document.body
        }
        variant={variant}
        suffixIcon={suffixIcon}
        style={style}
        options={elements.map(element => ({
          key: element.id,
          value: element.id,
          label: element.name,
        }))}
      />
    );
  }
}
