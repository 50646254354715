import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Card, Button, Form, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { AttributeTranslation } from '../../../../types/attributes';
import { typingDone } from '../../../utils/Utils';
import { fetchTranslations } from '../../../actions/parent/language/fetch';
import { LanguageTranslation } from '../../../../types/language';
import FormInput from '../../global/Forms/FormInput';
import { hasPermission } from '../../../utils/Permissions';
import AttributeTranslationTable from './AttributeTranslationTable';

type AttributeNamePopoverProps = {
  attributeName?: string;
  translations?: AttributeTranslation[];
  handleChange: (attributeName: string) => void;
  warnText?: string;
  detailedConfirmWarnText?: string;
  isSearchKeyword?: boolean;
};

const AttributeNamePopover: React.FC<AttributeNamePopoverProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const navigate = useNavigate();

  const [valueTranslations, setValueTranslations] = React.useState(props.translations);
  const [popoverVisible, setPopoverVisible] = React.useState(false);

  const user = useSelector((state: ApplicationState) => state.user.user);
  const canManageTranslations = hasPermission(user, 'can_manage_translation');

  const validationSchema = () => {
    return Yup.object().shape({
      attributeName: Yup.string().required(t('validation:required')),
    });
  };

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => {
        setPopoverVisible(visible);
      }}
      overlayClassName="help attribute_popover"
      destroyTooltipOnHide
      content={
        <Card className="attribute__form" onClick={e => e.stopPropagation()} bordered={false}>
          <Formik
            enableReinitialize
            initialValues={{
              attributeName: props.attributeName || '',
            }}
            validationSchema={validationSchema()}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleChange(values.attributeName);
              setSubmitting(false);
              setPopoverVisible(false);
            }}
          >
            {({ handleSubmit, setFieldValue, dirty, values }) => {
              const handleChangedAttributeName = (name: string) => {
                setFieldValue('attributeName', name);
                typingDone(() =>
                  dispatch(fetchTranslations({ translationTypeId: 1, keywords: name })).then(
                    response => {
                      const translation = response.value.data.find((data: LanguageTranslation) =>
                        data.translations?.find(t => t.language_id === 1 && t.value === name)
                      );
                      setValueTranslations(translation?.translations || []);
                    }
                  )
                );
              };

              const okButton = (
                <Button
                  data-testid="ok"
                  type="primary"
                  onClick={e => {
                    if (!props.warnText) handleSubmit();
                    e.stopPropagation();
                  }}
                  disabled={props.isSearchKeyword ? values.attributeName.length === 0 : !dirty}
                >
                  {t('common:ok')}
                </Button>
              );

              return (
                <React.Fragment>
                  <Form layout="vertical">
                    <div className="flex flex-col attribute-name">
                      <FormInput
                        id="attributeValueInput"
                        name="attributeName"
                        label={t('attributes:attribute')}
                        handleChange={e => handleChangedAttributeName(e.target.value || '')}
                        required
                      />
                      {dirty && <div className="attribute__warn-text">{props.warnText}</div>}
                    </div>
                    {canManageTranslations && (
                      <React.Fragment>
                        <div className="flex justify-between">
                          <div>{t('attributes:translations')}</div>
                          <Popconfirm
                            title={t('attributes:confirmSwitchToTanslations')}
                            okText={t('common:yes')}
                            cancelText={t('common:cancel')}
                            onConfirm={() =>
                              navigate('/settings/translations/translation-settings', {
                                state: { segmentId: 1 },
                              })
                            }
                          >
                            <Button size="small" className="truncate">
                              {t('attributes:editTranslations')}
                            </Button>
                          </Popconfirm>
                        </div>
                        <div className="attribute-translation-table">
                          <AttributeTranslationTable translations={valueTranslations || []} />
                        </div>
                      </React.Fragment>
                    )}
                  </Form>

                  <div className="flex-col items-end mt-2">
                    <div>
                      <Button
                        onClick={e => {
                          setPopoverVisible(false);
                          e.stopPropagation();
                        }}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      {props.warnText && (
                        <Popconfirm
                          title={props.detailedConfirmWarnText}
                          okText={t('common:confirm')}
                          cancelText={t('common:cancel')}
                          onConfirm={() => handleSubmit()}
                        >
                          {okButton}
                        </Popconfirm>
                      )}
                      {!props.warnText && okButton}
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      <div
        className={classNames('pr-1', { attribute__popover_visible: popoverVisible })}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </Popover>
  );
};

export default AttributeNamePopover;
