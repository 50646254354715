import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button, message, Alert } from 'antd';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

import { withTracker } from '../containers/withTracker';
import FormInput from '../components/global/Forms/FormInput';
import { AsyncDispatch } from '../../types/global';
import { forgotPassword } from '../actions/user';
import LoginFooter from './LoginFooter';
import emailImage from '../../images/icons/register-email.svg';

type ForgotPasswordProps = {
  dispatch: AsyncDispatch;
} & WithTranslation;

type ForgotPasswordState = {
  submitted: boolean;
  error: string;
};

export class ForgotPasswordPage extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
  state = {
    submitted: false,
    error: '',
  };

  getValidationSchema = () => {
    const { t } = this.props;

    return Yup.object().shape({
      email: Yup.string().email().required(t('validation:required')),
    });
  };

  render() {
    const { t } = this.props;
    const { submitted, error } = this.state;

    return (
      <div className="login__background">
        <div className="login__box">
          <div className="login__box-header">
            <div className="login-logo" />
          </div>
          <div className="login__box-content">
            {submitted && (
              <div className="login--header-content">
                <img src={emailImage} alt={t('common:email')} />

                <div className="login__title">{t('login:resetPassword')}</div>
                <div className="mt-2">{t('login:emailSent')}</div>
              </div>
            )}

            {!submitted && (
              <React.Fragment>
                <div className="login--header-content">
                  <div className="login__title">{t('login:resetPassword')}</div>
                  <div className="mt-2">{t('login:resetPasswordInfo')}</div>
                </div>

                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={this.getValidationSchema()}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    this.setState({ error: '' });

                    this.props
                      .dispatch(forgotPassword(values.email))
                      .then(() => {
                        message.success(t('login:passwordEmailSuccess'));
                        setSubmitting(false);
                        this.setState({ submitted: true });
                      })
                      .catch(() => {
                        this.setState({ error: t('login:passwordEmailFailed') });
                        setSubmitting(false);
                      });
                  }}
                >
                  {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                    <Form layout="vertical">
                      <FormInput
                        name="email"
                        label={t('common:email')}
                        placeholder={t('common:email')}
                        disabled={this.state.submitted}
                        size="large"
                        required
                      />

                      {error && (
                        <Alert type="error" showIcon message={error} style={{ marginBottom: 20 }} />
                      )}

                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!(isValid && dirty) || isSubmitting || this.state.submitted}
                        loading={isSubmitting}
                        icon={this.state.submitted ? <CheckOutlined /> : undefined}
                        style={{ width: '100%', marginTop: 15, marginBottom: 10 }}
                        size="large"
                      >
                        {t('login:confirmResetPassword')}
                      </Button>
                    </Form>
                  )}
                </Formik>
                <div className="text-center mt-5">
                  <Link className="register__login-text" to="/">
                    {t('login:backToLogin')}
                  </Link>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        <LoginFooter />
      </div>
    );
  }
}

export default withTracker(connect()(withTranslation()(ForgotPasswordPage) as any));
