import React from 'react';
import { App, Button } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type TranslationDeleteCellProps = {
  disabled?: boolean;
  handleDelete: () => void;
};

const TranslationDeleteCell: React.FC<TranslationDeleteCellProps> = ({
  handleDelete,
  disabled,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const handleDeleteLanguage = () => {
    modal.confirm({
      title: t('language:deleteTranslationInfo'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        handleDelete();
      },
    });
  };

  return (
    <Button
      size="small"
      ghost
      icon={<DeleteOutlined />}
      onClick={handleDeleteLanguage}
      disabled={disabled}
      danger
      data-testid="delete"
    >
      {t('common:delete')}
    </Button>
  );
};

export default TranslationDeleteCell;
