import React from 'react';
import { connect } from 'react-redux';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/catalogue/filter';
import { fetchSettingsCustomFields } from '../../actions/brand/custom_fields';
import extendedInfoActions from '../../actions/items/extended_info';
import FilterCustomItem from '../../components/header/FilterCustomItem';
import FilterExtendedInfo from '../../components/header/FilterExtendedInfo';
import FilterCustomFields from '../../components/header/FilterCustomFields';
import { BRAND_AREA } from '../../constants/InAppAreaConst';
import FilterChannelReceiver from '../../components/header/FilterChannelReceiver';
import { isReceiver } from '../../utils/UserUtils';
import { AsyncDispatch } from '../../../types/global';
import { BrandCustomField } from '../../../types/custom_fields';
import { GroupedSubBrand, SubBrand } from '../../../types/brand';
import { TypeOptions } from '../../../types/extendedInfo';
import { Resources } from '../../../types/resources';
import { AvailableChannel } from '../../../types/channel';
import { AvailableReceiver } from '../../../types/receiver';
import { FilterCustomParameterGo, FilterType, FilterTypeGo } from '../../../types/filter';
import { ApplicationState } from '../../reducers';

type CustomFilterContainerProps = {
  dispatch: AsyncDispatch;
  filter: FilterTypeGo;
  filterOld: FilterType;
  inAppArea: string;
  brandId: number;
  brandCustomFields: BrandCustomField[];
  fetchingCustomFields: boolean;
  subBrands: SubBrand[];
  receiverGroupedSubBrands: GroupedSubBrand[];
  extendedInfoCountryOptions: TypeOptions[];
  resources: Resources;
  availableChannels: AvailableChannel[];
  availableReceivers: AvailableReceiver[];
  isReceiver: boolean;
  addFilterParameterValue: (params: {
    sectionKey: string;
    filterCustomReferenceId: number;
    referenceId: number | null;
    value: string;
    optionId?: number;
  }) => Promise<any>;
  removeFilterParameter: (params: {
    sectionKey: string;
    filterCustomReferenceId: number;
    referenceId: number | null;
  }) => Promise<any>;
  removeFilterParameterValue: (params: {
    sectionKey: string;
    referenceId: number | null;
    filterCustomReferenceId: number;
    value: any;
  }) => void;
  updateFilterOption: (params: {
    sectionKey: string;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
    optionId: number;
  }) => void;
};

class CustomFilterContainer extends React.Component<CustomFilterContainerProps> {
  componentDidMount() {
    const { filterOld } = this.props;

    if (filterOld && filterOld.id && !filterOld.filter_custom) {
      this.createCustomFilter(filterOld.id);
    }
    if (this.props.inAppArea === BRAND_AREA) {
      if (this.props.brandCustomFields.length === 0) {
        this.props.dispatch(fetchSettingsCustomFields(this.props.brandId));
      }
      this.props.dispatch(
        extendedInfoActions.fetchExtendedInfoTypeOptionsOrder(this.props.brandId)
      );
    }
  }

  componentDidUpdate(prevProps: CustomFilterContainerProps) {
    const { filterOld } = this.props;
    if (
      filterOld &&
      prevProps.filterOld !== filterOld &&
      !filterOld.filter_custom &&
      // @ts-ignore
      !filterOld.delete
    ) {
      this.createCustomFilter(filterOld.id);
    }
  }

  createCustomFilter = (filterId: number) => {
    this.props.dispatch(actions.createCustomFilter(filterId));
  };

  updateParameterOption = ({
    referenceId,
    filterCustomReferenceId,
    optionId,
  }: {
    referenceId: number | null;
    filterCustomReferenceId: number;
    optionId: number;
  }) => {
    this.props.updateFilterOption({
      sectionKey: 'filter_custom',
      referenceId,
      filterCustomReferenceId,
      optionId,
    });
  };

  getParametersByReferenceId = (referenceId: number) => {
    const { filter } = this.props;
    const customFilter = filter.filter_custom ? filter.filter_custom[0] : undefined;
    const parameters: FilterCustomParameterGo[] = [];
    if (customFilter && customFilter.parameters.length > 0) {
      customFilter.parameters.forEach(parameter => {
        if (parameter.filter_custom_reference_id === referenceId) {
          parameters.push(parameter);
        }
      });
    }
    return parameters;
  };

  createParameter = ({ customRefId, optionId, value, refId }: any) => {
    // create new filter parameter including value
    this.props.addFilterParameterValue({
      sectionKey: 'filter_custom',
      referenceId: refId,
      filterCustomReferenceId: customRefId,
      optionId,
      value,
    });
  };

  addValue = (referenceId: number | null, filterCustomReferenceId: number, value: any) => {
    return this.props.addFilterParameterValue({
      sectionKey: 'filter_custom',
      referenceId,
      filterCustomReferenceId,
      value,
    });
  };

  removeValue = (referenceId: number | null, filterCustomReferenceId: number, value: any) => {
    this.props.removeFilterParameterValue({
      sectionKey: 'filter_custom',
      referenceId,
      filterCustomReferenceId,
      value,
    });
  };

  changeValue = (
    referenceId: number | null,
    filterCustomReferenceId: number,
    oldValue: any,
    value: any
  ) => {
    this.addValue(referenceId, filterCustomReferenceId, value).then(() =>
      this.removeValue(referenceId, filterCustomReferenceId, oldValue)
    );
  };

  deleteFilterCustomParameter = (filterCustomReferenceId: number, referenceId: number | null) => {
    return this.props.removeFilterParameter({
      sectionKey: 'filter_custom',
      filterCustomReferenceId,
      referenceId,
    });
  };

  render() {
    const { resources, isReceiver } = this.props;
    const allExpi = resources.extended_information.groups
      .map(group => group.types)
      .reduce((a, b) => a.concat(b), []);

    return (
      <React.Fragment>
        <div className="filter-custom-container">
          <div className="filter-custom__item">
            <FilterCustomItem
              populationParameter={this.getParametersByReferenceId(4)[0]}
              universalParameter={this.getParametersByReferenceId(1)[0]}
              subBrandParameter={this.getParametersByReferenceId(10)[0]}
              createParameter={this.createParameter}
              updateParameterOption={this.updateParameterOption}
              addValue={this.addValue}
              removeValue={this.removeValue}
              changeValue={this.changeValue}
              subBrands={this.props.subBrands}
              groupedSubBrands={isReceiver ? this.props.receiverGroupedSubBrands : undefined}
              populationStatuses={resources.item.population_statuses}
              customReferences={resources.filter.custom_references}
            />
          </div>
          <div className="filter-custom__extended flex">
            <FilterExtendedInfo
              inAppArea={this.props.inAppArea}
              groups={resources.extended_information.groups}
              allExpi={allExpi}
              countryOptions={this.props.extendedInfoCountryOptions}
              countries={resources.global.countries}
              createParameter={this.createParameter}
              updateParameterOption={this.updateParameterOption}
              addValue={this.addValue}
              removeValue={this.removeValue}
              changeValue={this.changeValue}
              parameters={this.getParametersByReferenceId(3)}
            />
          </div>
        </div>
        <div className="filter-custom-field-container flex">
          {this.props.inAppArea === BRAND_AREA && (
            <FilterChannelReceiver
              availableChannels={this.props.availableChannels}
              availableReceivers={this.props.availableReceivers}
              createParameter={this.createParameter}
              updateParameterOption={this.updateParameterOption}
              deleteFilterCustomParameter={this.deleteFilterCustomParameter}
              parametersSubmittedChannel={this.getParametersByReferenceId(6)}
              parametersSubmittedReceiver={this.getParametersByReferenceId(7)}
              parametersOnlineChannel={this.getParametersByReferenceId(8)}
              parametersOnlineReceiver={this.getParametersByReferenceId(9)}
            />
          )}
          <FilterCustomFields
            customFields={this.props.brandCustomFields}
            fetchingCustomFields={this.props.fetchingCustomFields}
            createParameter={this.createParameter}
            updateParameterOption={this.updateParameterOption}
            addValue={this.addValue}
            removeValue={this.removeValue}
            parameters={this.getParametersByReferenceId(2)}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    inAppArea: state.app.global.inAppArea,
    brandId: state.parent.brands.selectedBrandId,
    brandCustomFields: state.brand.customFields.customFields,
    fetchingCustomFields: state.brand.customFields.fetchingCustomFields,
    subBrands: state.brand.currentBrand.subBrands,
    receiverGroupedSubBrands: state.receiverDataStream.groupedSubBrands,
    extendedInfoCountryOptions: state.items.extendedInfo.typesOptions,
    resources: state.resources.data,
    availableChannels: state.brand.currentBrand.availableChannels,
    availableReceivers: state.brand.currentBrand.availableReceivers,
    isReceiver: state.user.user && isReceiver(state.user.user),
  };
}

export { CustomFilterContainer };
export default connect(mapStateToProps)(withContainerWrapper(CustomFilterContainer));
