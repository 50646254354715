import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import queryString from 'query-string';

import { initializeBrand } from '../actions/brand/currentBrand/fetch';
import {
  fetchAndSelectFilter,
  fetchFilterGo,
  fetchFilters,
} from '../actions/catalogue/filter/fetch';
import { fetchItemsByFilterId } from '../actions/catalogue/catalogue/fetch';
import { fetchDefaultValues } from '../actions/settings/defaultValues';
import { selectVcdbs } from '../actions/items/application/update';
import AllApplicationsContainer from '../containers/all_applications_validation/AllApplicationsContainer';
import ProductsPage from '../pages/brand/ProductsPage';
import SettingsPage from '../pages/brand/SettingsPage';
import ImportExportPage from '../pages/brand/ImportExportPage';
import ValidationPage from '../pages/brand/ValidationPage';
import SyncPage from '../pages/brand/SyncPage';
import RetoolContainer from '../containers/retool/RetoolContainer';
import WelcomeContainer from '../containers/home/WelcomeContainer';
import { withRouter, WithRouterProps } from '../containers/withRouter';
import { ApplicationState } from '../reducers';
import { Brand } from '../../types/brand';
import { ThunkAsyncDispatch, ThunkAsyncResult } from '../../types/global';
import { FilterType } from '../../types/filter';
import { UserType } from '../../types/user';
import { ALL_FILTER_TYPE_ID } from '../constants/FilterConstants';
import { VCDB_DEFAULT_VALUE_ID } from '../constants/DefaultValueConstants';
import { selectBrand, setBrandFilterInitializeStatus } from '../actions/brand/currentBrand/update';
import { runningOnGoOnlyApi } from '../utils/Permissions';

type BrandRouteProps = {
  brands: Brand[];
  selectedBrandId: number;
  user?: UserType;
  dispatch: ThunkAsyncDispatch;
} & WithRouterProps;

type BrandRouteState = {
  goOnly: boolean;
};

class BrandRoutes extends React.Component<BrandRouteProps, BrandRouteState> {
  state = {
    goOnly: runningOnGoOnlyApi(this.props.user),
  };

  componentDidMount() {
    const { brands } = this.props;
    const values = queryString.parse(this.props.location.search);
    const { brandId } = values;

    if (brandId && brands.find(b => b.id === Number(brandId) && b.active))
      this.selectBrand(Number(brandId));
  }

  componentDidUpdate(prevProps: BrandRouteProps) {
    const { brands, selectedBrandId } = this.props;

    const prevValues = queryString.parse(prevProps.location.search, { arrayFormat: 'comma' });
    const values = queryString.parse(this.props.location.search, { arrayFormat: 'comma' });
    const { brandId: prevBrandId } = prevValues;
    const { brandId, brandCode } = values;
    if (prevBrandId !== brandId) {
      this.selectBrand(Number(brandId));
    }
    if (!selectedBrandId && brandId && !brands.find(b => b.id === Number(brandId))) {
      this.props.navigate(`/brand/home?brandId=${brands.find(b => b.active)?.id}`);
    }
    if (!brandId && brands.length) {
      if (brandCode && brands.find(b => b.active && b.code === brandCode)?.id) {
        const { brandCode, ...rest } = values;
        this.props.navigate({
          ...location,
          search: `?${queryString.stringify(
            {
              ...rest,
              brandId: brands.find(b => b.active && b.code === brandCode)?.id,
            },
            { arrayFormat: 'comma' }
          )}`,
        });
      } else
        this.props.navigate({
          pathname: '/brand/home',
          search: `?${queryString.stringify(
            {
              ...values,
              brandId: brands.find(b => b.active)?.id,
            },
            { arrayFormat: 'comma' }
          )}`,
        });
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    this.props.dispatch(selectBrand(undefined));
  }

  selectBrand = (brandId: number) => {
    this.props.dispatch(initializeBrand(brandId));
    // handle filter and items fetch out of initializeBrand to speed up items response
    this.initializeFilter(brandId);
  };

  initializeFilter = (brandId: number) => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma' });

    // @ts-ignore
    this.props.dispatch(setBrandFilterInitializeStatus(false));

    Promise.all([
      this.props.dispatch(fetchDefaultValues(brandId)),
      this.props.dispatch(fetchFilters(brandId) as unknown as ThunkAsyncResult),
    ]).then(async (results: any[]) => {
      const defaultValues = results.find(r => r.action.type === 'FETCH_DEFAULT_VALUES_FULFILLED')
        .value.data;
      const defaultVcdb = defaultValues.find((d: any) => d.id === VCDB_DEFAULT_VALUE_ID)?.value;
      const defaultLangId = defaultValues.find((d: any) => d.resource_table === 'languages')?.value;

      // @ts-ignore
      if (defaultVcdb) this.props.dispatch(selectVcdbs([Number(defaultVcdb)]));

      const filters = results.find(r => r.action.type === 'FETCH_FILTERS_FULFILLED').value.data;
      const filterId =
        (params.filterId &&
          filters.find((f: FilterType) => f.id === Number(params.filterId))?.id) ||
        filters.find((f: FilterType) => f.type_id === ALL_FILTER_TYPE_ID)?.id;

      // multiselect temp filter handled by CatalogueContainer
      if (!Array.isArray(params.partNumbers))
        this.props.dispatch(fetchItemsByFilterId({ filterId, languageId: defaultLangId }));
      if (this.state.goOnly) {
        // await this.props.dispatch(fetchFilterGo(filterId));
      } else {
        // this.props.dispatch(fetchFilterGo(filterId));
        await this.props.dispatch(fetchAndSelectFilter(filterId));
      }

      // @ts-ignore
      this.props.dispatch(setBrandFilterInitializeStatus(true));
    });
  };

  render() {
    return (
      <Routes>
        <Route path="home" element={<WelcomeContainer />} />
        <Route path="products/*" element={<ProductsPage />} />
        <Route path="applications" element={<AllApplicationsContainer />} />
        <Route path="validation/*" element={<ValidationPage />} />
        <Route path="import-export/*" element={<ImportExportPage />} />
        <Route path="publish-data/*" element={<SyncPage />} />
        <Route path="settings/*" element={<SettingsPage />} />
        <Route path="retool" element={<RetoolContainer />} />
      </Routes>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  brands: state.parent.brands.brands,
  selectedBrandId: state.parent.brands.selectedBrandId,
  user: state.user.user,
});

export default withRouter(connect(mapStateToProps)(BrandRoutes));
