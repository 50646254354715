// eslint-disable-next-line
import { FilterType } from 'ParamountTypes';
import receiverDataStreamProductsActions from '../productsCatalogue';
import filterActions from '../../catalogue/filter';
import catalougeActions from '../../catalogue/catalogue';
import { AsyncDispatch } from '../../../../types/global';
import { Brand } from '../../../../types/brand';
import {
  fetchAuthorizedBrands,
  fetchAvailableChannelsReceivers,
  fetchReceiverSubBrands,
} from '../productsCatalogue/fetch';
import { fetchSettingsCustomFields } from '../../brand/custom_fields';
import { fetchCompanyProfile } from '../../settings/companyProfile';

export function setReceiverInitializeStatus(status: boolean) {
  return {
    type: 'SET_RECEIVER_DATA_STREAM_INITIALIZE_STATUS',
    payload: { status },
  };
}

export function initializeReceiverDataStream(receiverId: number, goOnly: boolean) {
  return (dispatch: AsyncDispatch) => {
    dispatch(setReceiverInitializeStatus(false));
    dispatch(receiverDataStreamProductsActions.fetchReceiverFilters(receiverId)).then(res => {
      const { data } = res.value;
      // get all filter id
      const allProductsFilter = data.find((filter: FilterType) => filter.type_id === 1);
      // might not be reset after page reload (reseting is only happening if we switch to a different filter)
      dispatch(filterActions.resetFilter(allProductsFilter.id)).then(() => {
        dispatch(fetchSettingsCustomFields());
        Promise.all([
          dispatch(fetchAuthorizedBrands()),
          dispatch(fetchAvailableChannelsReceivers()),
          !goOnly && dispatch(filterActions.fetchAndSelectFilter(allProductsFilter.id)),
          // dispatch(filterActions.fetchFilterGo(allProductsFilter.id)),
          dispatch(catalougeActions.fetchReceiverItemsSummary(receiverId)),
          dispatch(catalougeActions.fetchReceiverItemTags(receiverId)),
          dispatch(receiverDataStreamProductsActions.fetchAllReceiverBrands()),
          dispatch(fetchCompanyProfile()),
        ])
          .then(result => {
            const brands = result[0].value.data;
            const brandIds = brands.map((brand: Brand) => brand.id);
            dispatch(fetchReceiverSubBrands(brandIds));
          })
          .finally(() => dispatch(setReceiverInitializeStatus(true)));
      });
    });
  };
}
