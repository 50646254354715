import React from 'react';
import {
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { App, Button, Dropdown, Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { MarketingCopyGroup } from '../../../../types/marketing_copy';
import InputWithPreview from '../../global/InputWithPreview';
import marketingCopyActions from '../../../actions/parent/marketing_copy';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import DrawerFormik from '../../global/drawer/DrawerFormik';

type MarketingCopyEditGroupDrawerProps = {
  isOpen: boolean;
  canManageMarketingCopy: boolean;
  closeDrawer: () => void;
};

type FormMarketingCopyGroup = {
  id: number;
  name: string;
  sub_groups?: FormMarketingCopyGroup[] | undefined;
  type_code_id: number;
  isNew: boolean;
};

const MarketingCopyEditGroupDrawer: React.FC<MarketingCopyEditGroupDrawerProps> = ({
  isOpen,
  canManageMarketingCopy,
  closeDrawer,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const { modal } = App.useApp();
  const groups = useSelector((state: ApplicationState) => state.parent.marketingCopy.groups);

  const formatGroups = (groups: FormMarketingCopyGroup[]) => {
    return groups
      .map(group => {
        if (group.isNew) {
          return {
            name: group.name,
            sub_groups: group.sub_groups,
          };
        }
        return group;
      })
      .map(group => {
        return {
          ...group,
          sub_groups: group.sub_groups
            ? group.sub_groups.map(subGroup => {
                if (subGroup.isNew) {
                  return {
                    name: subGroup.name,
                  };
                }
                return subGroup;
              })
            : [],
        };
      });
  };

  return (
    <DrawerFormik
      title={t('marketing:actions.manageGroups')}
      width={420}
      onClose={() => closeDrawer()}
      visible={isOpen}
      initialValues={{ groups: groups as FormMarketingCopyGroup[] }}
      onSubmit={values => {
        const formattedGroups = formatGroups(values.groups);
        dispatch(marketingCopyActions.updateGroups(formattedGroups as MarketingCopyGroup[])).then(
          () => closeDrawer()
        );
      }}
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canManageMarketingCopy && formik.dirty
      }
    >
      {({ values, handleSubmit }) => (
        <Form onFinish={() => handleSubmit()}>
          <FieldArray
            name="groups"
            render={arrayHelpers => {
              const handleAddGroup = () => {
                arrayHelpers.insert(0, {
                  id: values.groups.length,
                  name: '',
                  sub_groups: [],
                  type_code_id: 6,
                  isNew: true,
                });
              };

              const handleAddSubGroup = (group: FormMarketingCopyGroup, index: number) => {
                arrayHelpers.replace(index, {
                  ...group,
                  sub_groups: [
                    ...(group.sub_groups ? group.sub_groups : []),
                    {
                      id: Math.floor(Math.random() * 100) + 1,
                      name: '',
                      type_code_id: 7,
                      isNew: true,
                    },
                  ],
                });
              };

              const handleCreateSubGroup = (
                group: FormMarketingCopyGroup,
                subGroupId: number,
                index: number,
                name: string
              ) => {
                arrayHelpers.replace(index, {
                  ...group,
                  sub_groups: group.sub_groups
                    ? group.sub_groups.map(sg => {
                        if (sg.id === subGroupId) {
                          return {
                            ...sg,
                            name,
                          };
                        }
                        return sg;
                      })
                    : [],
                });
              };

              const handleDeleteGroup = (index: number) => {
                arrayHelpers.remove(index);
              };

              const handleDeleteSubGroup = (
                group: FormMarketingCopyGroup,
                subGroupId: number,
                index: number
              ) => {
                arrayHelpers.replace(index, {
                  ...group,
                  sub_groups: group.sub_groups
                    ? group.sub_groups.filter(sg => sg.id !== subGroupId)
                    : [],
                });
              };

              return (
                <div className="group-drawer">
                  <Button type="primary" ghost onClick={() => handleAddGroup()}>
                    {t('marketing:actions.addGroup')}
                  </Button>
                  <div className="group-drawer-list flex-col">
                    {values.groups.map((group: FormMarketingCopyGroup, index: number) => {
                      return (
                        <div key={group.id}>
                          <div className="group-drawer-list__group">
                            <div className="flex justify-between item-center group-drawer-list__item">
                              <InputWithPreview
                                value={group.name}
                                submit={value => {
                                  if (!value && group.isNew) {
                                    handleDeleteGroup(index);
                                  }
                                }}
                                onChange={e => {
                                  const value = e.target.value;
                                  arrayHelpers.replace(index, {
                                    ...group,
                                    name: value,
                                  });
                                }}
                                showInput={group.isNew}
                                maxLength={10}
                              />
                              <Dropdown
                                menu={{
                                  items: [
                                    {
                                      label: (
                                        <React.Fragment>
                                          <PlusCircleOutlined />{' '}
                                          {t('marketing:actions.addSubGroup')}
                                        </React.Fragment>
                                      ),
                                      key: 'add-sub-group',
                                      onClick: () => handleAddSubGroup(group, index),
                                    },
                                    {
                                      label: (
                                        <React.Fragment>
                                          <DeleteOutlined /> {t('marketing:actions.deleteGroup')}
                                        </React.Fragment>
                                      ),
                                      key: 'delete-group',
                                      onClick: () => {
                                        if (!group.isNew) {
                                          modal.confirm({
                                            title: t('marketing:placeholder.promptDeleteGroup', {
                                              name: group.name,
                                            }),
                                            icon: <ExclamationCircleOutlined />,
                                            okText: t('common:delete'),
                                            okButtonProps: { danger: true },
                                            onOk() {
                                              dispatch(marketingCopyActions.deleteGroup(group.id))
                                                .then(() => {
                                                  handleDeleteGroup(index);
                                                })
                                                .catch((err: any) => {
                                                  message.error(err.response.data.message);
                                                });
                                            },
                                          });
                                        } else {
                                          handleDeleteGroup(index);
                                        }
                                      },
                                    },
                                  ],
                                }}
                                trigger={['click']}
                              >
                                <EllipsisOutlined className="group-drawer-list-edit-icon" />
                              </Dropdown>
                            </div>
                          </div>
                          <div>
                            {group.sub_groups &&
                              group.sub_groups.map(subGroup => {
                                return (
                                  <div key={subGroup.id} className="group-drawer-list__sub-group">
                                    <div className="flex justify-between group-drawer-list__item-nested">
                                      <InputWithPreview
                                        value={subGroup.name}
                                        showInput={subGroup.isNew}
                                        submit={value => {
                                          if (!value && subGroup.isNew) {
                                            handleDeleteSubGroup(group, subGroup.id, index);
                                          }
                                        }}
                                        onChange={e => {
                                          handleCreateSubGroup(
                                            group,
                                            subGroup.id,
                                            index,
                                            e.target.value
                                          );
                                        }}
                                        maxLength={10}
                                      />
                                      <Dropdown
                                        menu={{
                                          items: [
                                            {
                                              label: (
                                                <React.Fragment>
                                                  <DeleteOutlined />
                                                  {t('marketing:actions.deleteSubGroup')}
                                                </React.Fragment>
                                              ),
                                              key: 'delete-sub-group',
                                              onClick: () => {
                                                if (!subGroup.isNew) {
                                                  modal.confirm({
                                                    title: t(
                                                      'marketing:placeholder.promptDeleteSubgroup',
                                                      {
                                                        name: subGroup.name,
                                                      }
                                                    ),
                                                    icon: <ExclamationCircleOutlined />,
                                                    okText: t('common:delete'),
                                                    okButtonProps: { danger: true },
                                                    onOk() {
                                                      dispatch(
                                                        marketingCopyActions.deleteSubGroup(
                                                          group.id,
                                                          subGroup.id
                                                        )
                                                      )
                                                        .then(() => {
                                                          handleDeleteSubGroup(
                                                            group,
                                                            subGroup.id,
                                                            index
                                                          );
                                                        })
                                                        .catch((err: any) => {
                                                          message.error(err.response.data.message);
                                                        });
                                                    },
                                                  });
                                                } else {
                                                  handleDeleteSubGroup(group, subGroup.id, index);
                                                }
                                              },
                                            },
                                          ],
                                        }}
                                        trigger={['click']}
                                      >
                                        <EllipsisOutlined className="group-drawer-list-edit-icon" />
                                      </Dropdown>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
        </Form>
      )}
    </DrawerFormik>
  );
};

export default MarketingCopyEditGroupDrawer;
