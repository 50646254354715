import React from 'react';
import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Dropdown, Button, MenuProps, App } from 'antd';
import { useTranslation } from 'react-i18next';

type TranslationActionsProps = {
  handleDelete: () => void;
  selectedTranslations: number[];
  handleExportTranslations: () => void;
  disabled?: boolean;
};

const TranslationActions = (props: TranslationActionsProps) => {
  const { t } = useTranslation();
  const { handleDelete, selectedTranslations, handleExportTranslations } = props;
  const { modal } = App.useApp();

  const handleDeleteTranslations = () => {
    modal.confirm({
      title: t('language:deleteTranslationInfo'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        handleDelete();
      },
    });
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <React.Fragment>
          <DeleteOutlined className="mr-1" />
          {t('common:delete')}
        </React.Fragment>
      ),
      key: 'delete',
      onClick: handleDeleteTranslations,
    },
    {
      label: (
        <span>
          <ExportOutlined className="mr-1" />
          {t('common:export')}
        </span>
      ),
      key: 'export',
      onClick: handleExportTranslations,
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      className="actions"
      disabled={props.disabled || !selectedTranslations.length}
      trigger={['click']}
    >
      <Button size="small">
        {t('common:actions')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default TranslationActions;
