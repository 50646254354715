import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { FileExportOption } from '../../../types/import_export';

export type ExportBrandState = {
  fileExportOptions: FileExportOption[];
  fileExportOptionsBrandIds?: number[];
  fileExportOptionsSegment?: string;
  fetchingExportOptions: boolean;
};

const initialState = {
  fileExportOptions: [],
  fileExportOptionsBrandIds: undefined,
  fileExportOptionsSegment: undefined,
  fetchingExportOptions: false,
};

const reducer: Reducer<ExportBrandState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EXPORT_OPTIONS_PENDING': {
      return {
        ...state,
        fileExportOptions: [],
        fileExportOptionsBrandIds: undefined,
        fileExportOptionsSegment: undefined,
        fetchingExportOptions: true,
      };
    }
    case 'FETCH_EXPORT_OPTIONS_FULFILLED': {
      return {
        ...state,
        fileExportOptions: action.payload.data,
        fileExportOptionsBrandIds: action.meta.brandIds,
        fileExportOptionsSegment: action.meta.exportSegment,
        fetchingExportOptions: false,
      };
    }
  }
  return state;
};

export default reducer;
