import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CheckOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { AsyncDispatch } from '../../../../types/global';
import { testFTPConnection } from '../../../actions/channel/other';
import AntTooltip from '../../global/AntTooltip';

type TestFTPButtonProps = {
  server: string;
  userName: string;
  password: string;
  port: number;
  customDirectory: string;
  protocolTypeId: number;
};

const TestFTPButton: React.FC<TestFTPButtonProps> = ({
  server,
  userName,
  password,
  port,
  customDirectory,
  protocolTypeId,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [testFTPPending, setTestConnectionPending] = useState(false);
  const [testFTPError, setTestFTPError] = useState<boolean>(false);
  const [testFTPSuccess, setTestFTPSuccess] = useState<boolean>(false);
  const [testFTPMessage, setTestFTPMessage] = useState<string>('');

  const testConnection = () => {
    setTestConnectionPending(true);
    setTestFTPError(false);
    setTestFTPSuccess(false);
    setTestFTPMessage('');

    dispatch(
      testFTPConnection({
        server,
        user_name: userName,
        password,
        port,
        custom_directory: customDirectory,
        protocol_type_id: protocolTypeId,
      })
    )
      .then(response => {
        const message = response.value.data.message;
        const success = response.value.data.success;
        if (success) {
          setTestFTPSuccess(true);
        } else {
          setTestFTPError(true);
        }
        setTestFTPMessage(message);
      })
      .catch(error => {
        const errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : error.response.data?.message;
        setTestFTPMessage(errorMessage);
      })
      .finally(() => {
        setTestConnectionPending(false);
      });
  };

  return (
    <div className="mt-7 mb-5">
      <Button
        loading={testFTPPending}
        icon={
          <AntTooltip title={t('channel:testFTPInfo')}>
            <InfoCircleOutlined />
          </AntTooltip>
        }
        onClick={() => {
          testConnection();
        }}
        disabled={testFTPPending}
      >
        {t('channel:testFTPConnection')}
      </Button>
      <span>
        {testFTPError && (
          <span className="text-red-500 ml-4">
            <WarningOutlined />
            <span className="ml-2">{testFTPMessage}</span>
          </span>
        )}
        {testFTPSuccess && (
          <span className="text-green-500 ml-4">
            <CheckOutlined />
            <span className="ml-2">{testFTPMessage}</span>
          </span>
        )}
      </span>
    </div>
  );
};

export default TestFTPButton;
