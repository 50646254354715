import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { AsyncDispatch } from '../../../types/global';
import { fetchExportOptions } from '../../actions/brand/export/fetch';
import {
  fetchExportLogs,
  fetchExport,
  fetchExportLogDetailsJSON,
  fetchExportLogsDetailsExcel,
} from '../../actions/brand/import_export/fetch';
import ImportExportLogs from '../../components/body/import_export/ImportExportLogs';
import { ApplicationState } from '../../reducers';
import { exportLogMapper } from '../../selectors/importExportLogsSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { isManufacturer, isReceiver } from '../../utils/UserUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { openExportNotification } from '../../actions/exporter';
import { FileExportOption } from '../../../types/import_export';
import { getPageLimit } from '../../utils/Utils';

const ExportLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const {
    user,
    brandId,
    brandCode,
    exportLogs,
    fetchingExportLogs,
    exportErrorLogDetail,
    fetchingExportErrors,
    fetchingExportOptions,
    fileExportOptions,
    fileExportOptionsBrandIds,
    fileExportOptionsSegment,
  } = useSelector((state: ApplicationState) => ({
    brandId: state.parent.brands.selectedBrandId,
    brandCode: getSelectedBrandCode(state),
    exportLogs: exportLogMapper(state),
    fetchingExportLogs: state.brand.importExport.fetchingExportLogs,
    exportErrorLogDetail: state.brand.importExport.exportErrorLogDetail,
    fetchingExportErrors: state.brand.importExport.fetchingExportErrors,
    user: state.user.user,
    fetchingExportOptions: state.brand.brandExport.fetchingExportOptions,
    fileExportOptions: state.brand.brandExport.fileExportOptions as FileExportOption[],
    fileExportOptionsBrandIds: state.brand.brandExport.fileExportOptionsBrandIds,
    fileExportOptionsSegment: state.brand.brandExport.fileExportOptionsSegment,
  }));

  const manufacturer = isManufacturer(user!);
  const receiver = isReceiver(user!);

  const [pageSize] = React.useState<number>(getPageLimit());
  const [selectedTypeFilterIds, setSelectedTypeFilterIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    intercomEvent('viewed-import-export', {
      location: 'viewed-export-reports',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    if (manufacturer && (!fileExportOptionsBrandIds || fileExportOptionsBrandIds[0] !== brandId))
      dispatch(fetchExportOptions('export', brandId));
  }, [brandId, dispatch, fileExportOptionsBrandIds, manufacturer]);

  React.useEffect(() => {
    if (receiver && (!fileExportOptionsSegment || fileExportOptionsSegment !== 'export'))
      dispatch(fetchExportOptions('export'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (manufacturer) dispatch(fetchExportLogs({ brandId, limit: pageSize }));
    if (receiver) dispatch(fetchExportLogs({ limit: pageSize }));
  }, [brandId, dispatch, manufacturer, pageSize, receiver]);

  const saveExportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'paramount_export_errors.xlsx');
  };

  const handleErrorDownload = (logId: number) => {
    dispatch(fetchExportLogsDetailsExcel(logId)).then(response =>
      saveExportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );
  };

  const handleExportFileDownload = (logId: number) =>
    // @ts-ignore
    dispatch(fetchExport(logId));

  const handleFetchExportLogDetailsJSON = (logId: number) =>
    dispatch(fetchExportLogDetailsJSON(logId));

  const handleOpenNotification = (logId: number) => dispatch(openExportNotification(logId));

  const handleFilterFileTypes = (fileTypeIds: number[]) => {
    if (JSON.stringify(fileTypeIds) !== JSON.stringify(selectedTypeFilterIds))
      dispatch(
        fetchExportLogs({
          brandId,
          fileTypeIds,
          limit: pageSize,
        })
      );
    setSelectedTypeFilterIds(fileTypeIds);
  };

  const getExportLogs = (page: number) => {
    return dispatch(
      fetchExportLogs({
        brandId,
        fileTypeIds: selectedTypeFilterIds,
        limit: pageSize,
        page,
      })
    );
  };

  return (
    <ImportExportLogs
      logs={exportLogs}
      fetchingLogs={fetchingExportLogs}
      fetchingFileTypeValues={fetchingExportOptions}
      fetchingErrors={fetchingExportErrors}
      errorLogDetails={exportErrorLogDetail}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleExportFileDownload}
      handleFetchLogDetailsJSON={handleFetchExportLogDetailsJSON}
      handleOpenNotification={handleOpenNotification}
      fileTypeValues={fileExportOptions}
      selectedTypeFilterIds={selectedTypeFilterIds}
      handleFilterByType={handleFilterFileTypes}
      fetchLogs={getExportLogs}
      pageSize={pageSize}
      exportLogs
    />
  );
};

export default ExportLogsContainer;
