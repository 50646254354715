import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import helpCenterConstants from '../../../../constants/HelpCenterConstants.json';
import { AsyncDispatch } from '../../../../../types/global';
import { getSelectedItems } from '../../../../selectors/catalogue/catalogueSelector';
import { ApplicationState } from '../../../../reducers';
import { deleteEbayOffers } from '../../../../actions/items/ebay/delete';

const { url: helpCenterUrl, articles } = helpCenterConstants;

type EbayEndListModalProps = {
  showModal: boolean;
  onClose: () => void;
};

const EbayEndListModal: React.FC<EbayEndListModalProps> = ({ showModal, onClose }) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItems,
    filterId,
    selectedItemIds,
    itemsSummary,
    allItemsSelected,
    reverseSelected,
    reversedItemIds,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      filterId: state.catalogue.filter.filterGo?.id,
      selectedItemIds: state.catalogue.catalogue.selectedItemIds,
      itemsSummary: state.catalogue.catalogue.itemsSummary,
      allItemsSelected: state.catalogue.catalogue.allItemsSelected,
      reverseSelected: state.catalogue.catalogue.reverseSelected,
      reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    };
  });

  const [endListPending, setEndListPending] = React.useState(false);
  const [selectedItemCount, setSelectedItemCount] = React.useState<number>();

  React.useEffect(() => {
    if (showModal) {
      if (!allItemsSelected && !reverseSelected) {
        setSelectedItemCount(selectedItems.length);
      } else {
        const itemCount = reverseSelected ? itemsSummary - reversedItemIds.length : itemsSummary;
        setSelectedItemCount(itemCount);
      }
    }
  }, [allItemsSelected, itemsSummary, reverseSelected, reversedItemIds, selectedItems, showModal]);

  const handleCancel = () => {
    onClose();
  };

  const handleEndListOnEbay = async () => {
    setEndListPending(true);
    if (!allItemsSelected && !reverseSelected) {
      // @ts-ignore
      await dispatch(deleteEbayOffers({ itemIds: selectedItems.map(item => item.id) }));
    } else {
      await dispatch(
        // @ts-ignore
        deleteEbayOffers({
          filterId:
            (allItemsSelected && selectedItems.length >= 100) || reverseSelected
              ? filterId
              : undefined,
          withoutItemdIds: reversedItemIds,
          itemIds:
            (!allItemsSelected || (allItemsSelected && selectedItemIds.length < 100)) &&
            !reverseSelected
              ? selectedItemIds
              : undefined,
        })
      );
    }

    setEndListPending(false);
    onClose();
  };

  return (
    <Modal
      open={showModal}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="cancel" onClick={() => handleCancel()}>
          {t('common:cancel')}
        </Button>,
        <Button
          key="publish"
          type="primary"
          loading={endListPending}
          onClick={() => handleEndListOnEbay()}
        >
          {t('exporter:endListing')}
        </Button>,
      ]}
      closable={false}
    >
      <div className="text-base font-medium">
        <QuestionCircleOutlined className="text-xl blue mr-3" />
        {t('exporter:endListingSelectedItems')}
      </div>
      <div className="mt-2 ml-8 italic text-gray-700">
        {`(${selectedItemCount} ${t('exporter:itemsSelected')})`}
      </div>
      <div className="mt-2 ml-8">{t('exporter:ebayEndListInfo')}</div>
      <div className="mt-2 ml-8 italic text-xs">
        {`${t('common:learnMoreAbout')} `}
        <a target="_blank" rel="noopener noreferrer" href={helpCenterUrl + articles.using_tags}>
          {t('exporter:usingAndRemovingTags')}
        </a>
      </div>
    </Modal>
  );
};

export default EbayEndListModal;
