import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AsyncDispatch, Indexable } from '../../../../types/global';
import { updateExternalSources } from '../../../actions/receiver_data_stream/externalSources/update';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import PageFormik from '../../global/page/PageFormik';
import ExternalSourceInfo from './ExternalSourceInfo';
import { ExternalSource } from '../../../../types/external_sources';
import FTPCredentials from './FTPCredentials';
import { intercomEvent } from '../../../utils/IntercomUtils';

const ExternalSourcePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const location = useLocation();

  const { externalSources, user } = useSelector((state: ApplicationState) => {
    return {
      externalSources: state.externalSources.externalSources,
      user: state.user.user,
    };
  });

  const { url } = queryString.parseUrl(location.pathname);
  const sourceName = url.split('/')[3];
  const sources: Indexable = externalSources || {};
  const externalSource: ExternalSource = externalSources && sources[sourceName];

  React.useEffect(() => {
    intercomEvent('viewed-external-sources', { location: sourceName });
  }, [sourceName]);

  const sendAddAuthDetailsEvent = () => {
    intercomEvent('viewed-external-sources', {
      location: sourceName,
      action: 'add-auth-details',
    });
  };

  const sendDeleteAuthDetailsEvent = () => {
    intercomEvent('viewed-external-sources', {
      location: sourceName,
      action: 'delete-auth-details',
    });
  };

  const getInitialValues = () => {
    if (externalSource.authorization_type === 'user_password')
      return {
        authDetails: externalSource?.authorization_details || [{ user_name: '', password: '' }],
      };
    return {
      authDetails: externalSource?.authorization_details || [{ token: '' }],
    };
  };

  return (
    <PageFormik
      initialValues={getInitialValues()}
      onSubmit={(values, { setSubmitPending, setSubmitError, setSubmitSuccess }) => {
        setSubmitPending();
        const authDetails = [
          ...values.authDetails.map((auth: any) => ({
            external_data_provider_id: externalSource.id,
            ...auth,
          })),
          ...((externalSource.external_code !== 'sdc' &&
            externalSources?.sdc.authorization_details?.map((auth: any) => ({
              external_data_provider_id: externalSources?.sdc.id,
              ...auth,
            }))) ||
            []),
          ...((externalSource.external_code !== 'dci' &&
            externalSources?.dci.authorization_details?.map((auth: any) => ({
              external_data_provider_id: externalSources?.dci.id,
              ...auth,
            }))) ||
            []),
          ...((externalSource.external_code !== 'asap' &&
            externalSources?.asap.authorization_details?.map((auth: any) => ({
              external_data_provider_id: externalSources?.asap.id,
              ...auth,
            }))) ||
            []),
          ...((externalSource.external_code !== 'opticat' &&
            externalSources?.opticat.authorization_details?.map((auth: any) => ({
              external_data_provider_id: externalSources?.opticat.id,
              ...auth,
            }))) ||
            []),
        ];

        dispatch(updateExternalSources(authDetails))
          .then(() => {
            setSubmitSuccess();
          })
          .catch(() => setSubmitError());
      }}
      contentNoScroll
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <div className="h-full overflow-auto flex flex-col ">
          <div>
            {values.authDetails.map((credencial: any, index: number) => (
              <div key={index} className="sources__auth-wrapper flex mt-2">
                <Form layout="vertical" className="flex flex-row w-1/2 sources__auth-token">
                  {externalSource.authorization_type === 'user_password' && (
                    <React.Fragment>
                      <FormInput
                        label={index === 0 ? t('externalSources:login') : undefined}
                        className="flex-1"
                        name={`authDetails[${index}].user_name`}
                      />
                      <Field name={`authDetails[${index}].password`}>
                        {({ field }: FieldProps) => {
                          const { onChange, onBlur } = field;
                          return (
                            <Form.Item
                              label={index === 0 ? t('externalSources:password') : undefined}
                              className="flex-1 source__password"
                            >
                              <Input.Password
                                name={`authDetails[${index}].password`}
                                value={field.value}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            </Form.Item>
                          );
                        }}
                      </Field>
                    </React.Fragment>
                  )}
                  {externalSource.authorization_type === 'token' && (
                    <FormInput
                      label={index === 0 ? t('externalSources:apiCode') : undefined}
                      className="flex-1"
                      name={`authDetails[${index}].token`}
                    />
                  )}
                  <Button
                    className={index === 0 ? 'source__action-buttons' : ''}
                    onClick={() => {
                      setFieldValue(
                        'authDetails',
                        values.authDetails.filter((_code: string, i: number) => i !== index)
                      );
                      sendDeleteAuthDetailsEvent();
                    }}
                    icon={<DeleteOutlined />}
                  />
                </Form>
                {index === 0 && (
                  <Button
                    className="ml-3 source__action-buttons"
                    onClick={() => {
                      setFieldValue('authDetails', [
                        ...(externalSource.authorization_type === 'user_password'
                          ? [{ user_name: '', password: '' }]
                          : [{ token: '' }]),
                        ...values.authDetails,
                      ]);
                      sendAddAuthDetailsEvent();
                    }}
                    icon={<PlusCircleOutlined />}
                  />
                )}
              </div>
            ))}
          </div>

          <ExternalSourceInfo
            authorizedBrandCount={externalSource.authorized_brand_count}
            availableBrandCount={externalSource.available_brand_count}
            lastConnection={externalSource.last_connection}
            showSupplier={externalSource.external_code === 'opticat'}
            showBrandReportDownload={
              externalSource.external_code === 'sdc' && user?.email.endsWith('pdm.ai')
            }
          />
          {externalSource.display_ftp === 1 && (
            <FTPCredentials
              server={externalSource.ftp_server}
              user={externalSource.ftp_user}
              password={externalSource.ftp_pass}
              port={externalSource.ftp_port}
              showIntercom={() => {
                if ((window as any).Intercom) (window as any).Intercom('show');
              }}
              subText={t('externalSources:manageInfo', { name: externalSource.name })}
            />
          )}
          <div className="sources__info-text">
            {(sourceName !== 'opticat' && (
              <Alert
                message={t('externalSources:externalSourceCredits')}
                description={
                  <React.Fragment>
                    <ul className="ml-5">
                      <li>{t('externalSources:externalSourceCreditsInfo')}</li>
                    </ul>
                    <div className="mt-2">{t('externalSources:externalSourceSecurity')}</div>
                  </React.Fragment>
                }
                type="info"
                showIcon
              />
            )) || (
              <Alert
                message={t('externalSources:opticatCredits')}
                description={
                  <React.Fragment>
                    <ul className="ml-5">
                      <li>{t('externalSources:opticatSubDetails.0')}</li>
                      <li>{t('externalSources:opticatSubDetails.1')}</li>
                      <li className="ml-5">{t('externalSources:opticatSubDetails.2')}</li>
                      <li>{t('externalSources:opticatSubDetails.3')}</li>
                    </ul>
                    <div className="mt-2">{t('externalSources:opticatSecurity')}</div>
                  </React.Fragment>
                }
                type="info"
                showIcon
              />
            )}
          </div>
        </div>
      )}
    </PageFormik>
  );
};

export default ExternalSourcePage;
