import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DeleteOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import { CustomFieldOption } from '../../../../types/custom_fields';
import InputWithPreview from '../../global/InputWithPreview';

type SettingsCustomFieldOptionProps = {
  options: CustomFieldOption[];
  disabled?: boolean;
  updateOptions: (options: CustomFieldOption[]) => void;
};

const SortableItem = SortableElement(
  ({
    value,
    disabledOptions,
    onChange,
    handleDelete,
  }: {
    value: string;
    disabledOptions: boolean;
    onChange: (e: any) => void;
    handleDelete: (e: any) => void;
  }) => (
    <div
      className={classNames('flex items-center cfs-options__table__row', {
        disabled: disabledOptions,
      })}
    >
      <DragHandle />
      <InputWithPreview value={value} onChange={onChange} />
      <DeleteOutlined onClick={handleDelete} style={{ marginLeft: '3px' }} />
    </div>
  )
);
const SortableCon = SortableContainer(({ children }: { children: any }) => (
  <div className="cfs-options__table">{children}</div>
));
const DragHandle = SortableHandle(() => <span className="cfs-options__table__drag">::</span>);

const SettingsCustomFieldOptions: React.FC<SettingsCustomFieldOptionProps> = ({
  options,
  disabled,
  updateOptions,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    // update record_numbers
    // change array position
    updateOptions(
      arrayMoveImmutable(options, oldIndex, newIndex).map((o, i) => ({
        ...o,
        record_number: i + 1,
      }))
    );
  };

  return (
    <SortableCon onSortEnd={onSortEnd} lockAxis="y" useDragHandle helperClass="stylizedHelper">
      {options.map((option, index) => (
        <SortableItem
          key={`dropdown-${index}`}
          index={index}
          value={option.name}
          disabledOptions={!!disabled}
          onChange={e =>
            updateOptions(options.map((o, i) => (i === index ? { ...o, name: e.target.value } : o)))
          }
          handleDelete={() => {
            updateOptions(options.filter((_, i) => i !== index));
          }}
        />
      ))}
    </SortableCon>
  );
};

export default SettingsCustomFieldOptions;
