import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducers';
import { DefaultValue } from '../../../types/brand_settings';
import { VCDB_DEFAULT_VALUE_ID } from '../../constants/DefaultValueConstants';

const getDefaultValuesFromState = (state: ApplicationState) => state.settings.defaultValues;
const getAccountDefaultValues = (state: ApplicationState) =>
  state.parent.accountDefaultValues.accountDefaultValues;
const getResources = (state: ApplicationState) => state.resources.data;
const getVcdbVersions = (state: ApplicationState) => state.items.application.vcdbVersions;

export type DefaultValuesSelectorState = {
  language: string;
  acesVersion: string;
  individualByBase: string;
  piesVersion: string;
  gtinQualifier: string;
  currency: string;
  priceUom: string;
  packageUom: string;
  packageDimensionUom: string;
};

export const getDefaultValues = createSelector(
  [getDefaultValuesFromState],
  defaultValuesFromState => {
    const defaultValues = defaultValuesFromState;

    const language = defaultValues.find((def: any) => def.resource_table === 'languages');
    const gtinQualifier = defaultValues.find(
      (def: any) => def.resource_table === 'gtin_qualifiers'
    );
    const currency = defaultValues.find((def: any) => def.resource_table === 'price_currencies');
    const priceUom = defaultValues.find((def: any) => def.resource_table === 'price_uoms');
    const packageUom = defaultValues.find((def: any) => def.resource_table === 'package_uoms');
    const packageDimensionUom = defaultValues.find(
      (def: any) => def.resource_table === 'package_dimension_uoms'
    );
    const acesVersion = defaultValues.find((def: any) => def.resource_table === 'aces_versions');
    const individualByBase = defaultValues.find((def: any) => def.id === 5);
    const piesVersion = defaultValues.find((def: any) => def.resource_table === 'pies_versions');
    return {
      language: language.value,
      acesVersion: acesVersion.value,
      individualByBase: individualByBase.value,
      piesVersion: piesVersion.value,
      gtinQualifier: gtinQualifier.value,
      currency: currency.value,
      priceUom: priceUom.value,
      packageUom: packageUom.value,
      packageDimensionUom: packageDimensionUom.value,
    };
  }
);

export const getBrandAccDefaultValues = createSelector(
  [getDefaultValuesFromState, getAccountDefaultValues, getResources, getVcdbVersions],
  (defaultValues, accountDefaultValues, resources, vcdbVersions) => {
    const defaultResources: any = {
      languages: resources.global.languages,
      gtin_qualifiers: resources.global.gtin_qualifiers,
      aces_versions: resources.channel.aces_versions,
      pies_versions: resources.channel.pies_versions,
      price_currencies: resources.price.currencies,
      price_uoms: resources.price.uoms,
      price_types: resources.price.types,
      package_uoms: resources.package.uoms,
      package_dimension_uoms: resources.package.dimension_uoms,
      package_weight_uoms: resources.package.weight_uoms,
      vcdb_versions: vcdbVersions,
      interchange_information_qualitity_grade_levels:
        resources.interchange_information.qualitity_grade_levels,
      interchange_information_extended_qualitity_grade_levels:
        resources.interchange_information.extended_qualitity_grade_levels,
    };

    const getAccName = (val: string | null, resource: string) => {
      const res = defaultResources[resource]?.find((res: any) => Number(val) === res.id);
      return res ? res.name : '';
    };

    return defaultValues.map((value: DefaultValue) => {
      const accDefaultValue = accountDefaultValues.find(acc => acc.id === value.id);
      return {
        ...value,
        accountDefaultValue: accDefaultValue?.value || null,
        accountDefaultName:
          accDefaultValue?.resource_table || accDefaultValue?.id === VCDB_DEFAULT_VALUE_ID
            ? getAccName(
                accDefaultValue.value,
                accDefaultValue?.id === VCDB_DEFAULT_VALUE_ID
                  ? 'vcdb_versions'
                  : accDefaultValue.resource_table!
              )
            : '',
      };
    });
  }
);

export const brandAccDefaultValues = createSelector(
  [getDefaultValuesFromState, getAccountDefaultValues],
  (defaultValues, accountDefaultValues) => {
    return defaultValues.map((value: DefaultValue) => {
      const accDefaultValue = accountDefaultValues.find(acc => acc.id === value.id);
      return {
        ...value,
        value: value?.value || accDefaultValue?.value || null,
      };
    });
  }
);
