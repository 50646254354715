import React from 'react';
import { Form, Card, Input, Button } from 'antd';

import { SelectValue } from 'antd/lib/select';
import constants from '../../../constants/SettingsTranslation.json';
import SingleDefaultValue from './SingleDefaultValue';
import { DefaultValue } from '../../../../types/brand_settings';
import { PriceSheet } from '../../../../types/price_sheet';
import { AntSelectEntries } from '../../global/AntSelect';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { xl: { span: 24 }, xxl: { span: 4 } },
  wrapperCol: { xl: { span: 24 }, xxl: { span: 20 } },
};

const formItemCardLayout = {
  labelCol: { xl: { span: 24 }, xxl: { span: 8 } },
  wrapperCol: { xl: { span: 24 }, xxl: { span: 14 } },
};

type DefaultValuesPriceProps = {
  brandSettings: boolean;
  values: DefaultValue[];
  createDefaultPriceTypeDescription: (
    priceSheetId: number,
    priceTypeId: number,
    description: string
  ) => void;
  updateDefaultValue: (id: number, selected: boolean | SelectValue | string | null) => void;
  priceSheets: PriceSheet[];
  resources: { [key: string]: AntSelectEntries };
  canManageDefaultValues: boolean;
};

const DefaultValuesPrice: React.FC<DefaultValuesPriceProps> = props => {
  const { values, priceSheets } = props;

  const [showTypeDescription, setShowTypeDescription] = React.useState<boolean>(false);

  const handleShowTypeDescription = () => {
    setShowTypeDescription(!showTypeDescription);
  };

  const createDefaultPriceTypeDescription = (
    priceSheetId: number,
    priceTypeId: number,
    description: string
  ) => {
    props.createDefaultPriceTypeDescription(priceSheetId, priceTypeId, description);
  };

  return (
    <React.Fragment>
      {values.map(value => (
        <SingleDefaultValue
          key={value.id}
          value={value}
          resources={props.resources}
          canManageDefaultValues={props.canManageDefaultValues}
          updateDefaultValue={props.updateDefaultValue}
        />
      ))}
      {props.brandSettings && (
        <FormItem
          key={1}
          className="setting-price-type-description"
          {...formItemLayout}
          label={constants.default_values.price_sheets_type_desc}
        >
          <Button type="primary" onClick={handleShowTypeDescription}>
            {constants.default_values.show_price_type_desc}
          </Button>
          {showTypeDescription && (
            <div className="setting-price-type-description__sheets">
              {priceSheets.map(priceSheet => {
                const title =
                  priceSheet.name && priceSheet.name !== priceSheet.number
                    ? `${priceSheet.number} (${priceSheet.name})`
                    : priceSheet.number;
                return (
                  priceSheet.parent_price_types.length > 0 && (
                    <Card
                      key={priceSheet.id}
                      type="inner"
                      title={title}
                      style={{ width: '32%', marginRight: '10px', marginBottom: '10px' }}
                    >
                      {priceSheet.parent_price_types.map(priceType => {
                        const label = props.resources.price_types.find(
                          type => type.id === priceType.type_id
                        );
                        return (
                          <FormItem
                            key={priceType.type_id}
                            className="setting-price-type-description__row"
                            {...formItemCardLayout}
                            label={label?.name}
                          >
                            <Input
                              defaultValue={priceType.type_description || ''}
                              maxLength={80}
                              onBlur={e =>
                                createDefaultPriceTypeDescription(
                                  priceSheet.id,
                                  priceType.type_id,
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        );
                      })}
                    </Card>
                  )
                );
              })}
            </div>
          )}
        </FormItem>
      )}
    </React.Fragment>
  );
};

export default DefaultValuesPrice;
