import React from 'react';
import { App } from 'antd';

export type WithAntdContextProps = {
  appContext: ReturnType<typeof App.useApp>;
};

export const withAntdContext = <Props extends WithAntdContextProps>(
  Component: React.ComponentType<Props>
) => {
  return (props: Omit<Props, keyof WithAntdContextProps>) => {
    const appContext = App.useApp();
    return <Component {...(props as Props)} appContext={appContext} />;
  };
};

export default withAntdContext;
