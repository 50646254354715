import {
  AppstoreOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Input, Card, Spin, Dropdown } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';

import ApplicationLinkedChilds from './ApplicationLinkedChilds';
import ApplicationActions from './ApplicationActions';
import ApplicationLinkDrawer from './ApplicationLinkDrawer';
import ApplicationDefaultsDrawer from './ApplicationDefaultsDrawer';
import ApplicationList from './ApplicationList';
import EnterprisePromotionDrawer from '../../global/EnterprisePromotion/EnterprisePromotionDrawer';
import { hasPermission } from '../../../utils/Permissions';
import { getPageLimit } from '../../../utils/Utils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { Item } from '../../../../types/item';
import {
  StructuredApplication,
  ApplicationGroups,
  LinkedApplications,
  BundleItem,
  Qualifier,
  ApplicationNote,
  AppOrder,
  ApplicationType,
} from '../../../../types/application';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType, Vehicle } from '../../../../types/resources';
import { UserType } from '../../../../types/user';
import { withErrorBoundary } from '../../global/ErrorBoundary';
import PageFormik from '../../global/page/PageFormik';
import ApplicationBundleTable from './ApplicationBundleTable';
import ApplicationCardGroups from './ApplicationCardGroups';
import Page from '../../global/page/Page';
import ApplicationGridList from './ApplicationGridList';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';
import SearchInput from '../../global/Forms/SearchInput';

type ApplicationOverviewProps = {
  selectedItem: Item;
  keywords: string;
  user: UserType;
  isReceiver: boolean;
  isManufacturer: boolean;
  applications: StructuredApplication[];
  analyses: (Analysis & AnalysisType)[];
  analysisGroups: ApplicationGroups;
  fetching: boolean;
  fetchingFullItem: boolean;
  baseItemApplications: LinkedApplications[];
  oeItemApplications: LinkedApplications[];
  applicationOrder: AppOrder[];
  applicationBundleItems: BundleItem[];
  resources: Vehicle;
  showApplicationListView: boolean;
  showApplicationGridView: boolean;
  defaultQualifiers: Qualifier[];
  defaultNotes: ApplicationNote[];
  parentApplicationItemBases: BundleItem[];
  selectApplication: (id: number, category?: string) => void;
  selectListApplication: (id: number) => void;
  cloneApplication: (id: number) => void;
  createApplication: (type?: ApplicationType) => void;
  deleteApplication: (id: number) => Promise<any>;
  deleteApplications: (props: { applicationIds?: number[]; itemId?: number }) => Promise<any>;
  updateUniversalPart: (checked: number) => void;
  updateApplicationBundle: (bundleItems: BundleItem[]) => Promise<any>;
  unlinkItem: (itemId: number) => void;
  removeBundleItems: (bundleIds: number[]) => void;
  filterApplications: () => void;
  fetchNextApplications: (event: React.UIEvent<HTMLDivElement>) => void;
  showListView: (show: boolean) => void;
  showGridView: (show: boolean) => void;
  handleKeywordsChange: (keywords: string) => void;
} & WithTranslation &
  WithAntdContextProps;

type ApplicationOverviewState = {
  universalPart: number;
  showApplicationLinkComponent: boolean;
  showApplicationLinkDrawer: boolean;
  showApplicationDefaultsDrawer: boolean;
  canManageBundle: boolean;
  bundle: boolean;
  pageSize: number;
};

class ApplicationOverview extends React.Component<
  ApplicationOverviewProps,
  ApplicationOverviewState
> {
  constructor(props: ApplicationOverviewProps) {
    super(props);
    const canManageBundle = hasPermission(props.user, 'can_manage_bundle');

    this.state = {
      canManageBundle,
      universalPart: this.props.selectedItem.universal_part ? 1 : 0,
      bundle: canManageBundle && !!this.props.applicationBundleItems.length,
      showApplicationLinkComponent: false,
      showApplicationLinkDrawer: false,
      showApplicationDefaultsDrawer: false,
      pageSize: getPageLimit(),
    };
  }

  componentDidUpdate(prevProps: ApplicationOverviewProps) {
    const { canManageBundle } = this.state;
    const { applicationBundleItems } = this.props;
    if (this.props.selectedItem.id !== prevProps.selectedItem.id) {
      const showBundleTable = canManageBundle && this.props.applicationBundleItems.length > 0;

      this.setState({
        universalPart: this.props.selectedItem.universal_part ? 1 : 0,
        showApplicationLinkComponent: false,
        bundle: showBundleTable,
      });
    }
    if (
      canManageBundle &&
      applicationBundleItems.length &&
      prevProps.applicationBundleItems.length === 0
    ) {
      this.setState({ bundle: true });
    }
  }

  selectConfig = (id: number, config?: string, defaultValue?: boolean) => {
    defaultValue && config !== 'category'
      ? this.setState({ showApplicationDefaultsDrawer: true })
      : this.props.selectApplication(id, config);
  };

  createApplication = (type?: ApplicationType) => this.props.createApplication(type);

  deleteApplicationNoConfirm = (id: number) => {
    const { deleteApplication, selectedItem } = this.props;

    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_application',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    return deleteApplication(id);
  };

  deleteApplication = (id: number, noConfirm?: boolean) => {
    const { t, deleteApplication, selectedItem } = this.props;
    const { modal } = this.props.appContext;

    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_application',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    if (noConfirm) return deleteApplication(id);

    modal.confirm({
      title: t('application:deleteMessage'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        return deleteApplication(id);
      },
    });
  };

  deleteApplications = (props: {
    applicationIds?: number[];
    itemId?: number;
    noConfirm?: boolean;
  }) => {
    const { t, deleteApplications, selectedItem } = this.props;
    const { modal } = this.props.appContext;

    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_application',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    if (props.noConfirm) return deleteApplications(props);

    modal.confirm({
      title: props.applicationIds
        ? t('application:deleteSelectedMessage')
        : t('application:deleteAllMessage'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:delete'),
      cancelText: t('common:cancel'),
      okButtonProps: { danger: true },
      onOk() {
        return deleteApplications(props);
      },
    });
  };

  updateUniversalPart = (checked: boolean) => {
    this.setState({ universalPart: checked ? 1 : 0 });
    this.props.updateUniversalPart(checked ? 1 : 0);
  };

  updateBundle = (checked: boolean) => {
    const { t, removeBundleItems, showApplicationListView, applicationBundleItems } = this.props;
    const { modal } = this.props.appContext;
    const updateBundleState = () => this.setState({ bundle: checked });

    if (checked) {
      this.setState({ bundle: checked });
      if (showApplicationListView) this.props.showListView(false);
    } else if (applicationBundleItems.length) {
      const bundleItemIds = applicationBundleItems.map(bItem => bItem.id);
      modal.confirm({
        title: t('application:unlinkAllItemsConfirm'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          removeBundleItems(bundleItemIds);
          updateBundleState();
        },
      });
    } else {
      this.setState({ bundle: checked });
    }
  };

  unlinkItem = (itemId: number) => {
    const { t, unlinkItem } = this.props;
    const { modal } = this.props.appContext;
    modal.confirm({
      title: t('application:unlinkItemConfirm'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        unlinkItem(itemId);
      },
    });
  };

  handleSearch = (value: string) => {
    this.props.handleKeywordsChange(value);
  };

  fetchApplications = () => {
    this.props.filterApplications();
  };

  universalPartCardView = () => {
    const { t } = this.props;

    return (
      <Card className="application__card" style={{ width: 400 }}>
        <div className="application__card-info-title">{t('application:universalPart')}</div>
        <div className="application__card-content">{t('application:universalPartInfo')}</div>
        <Button onClick={() => this.updateUniversalPart(false)} danger ghost block>
          {t('application:unsetUniversalPart')}
        </Button>
      </Card>
    );
  };

  cardView = () => {
    const {
      t,
      isManufacturer,
      fetching,
      fetchingFullItem,
      applications,
      applicationOrder,
      applicationBundleItems,
    } = this.props;
    const { universalPart, pageSize, bundle, canManageBundle } = this.state;

    const validationSchema = Yup.object().shape({
      linkedItems: Yup.array().of(
        Yup.object().shape({
          asset_item_order: Yup.number().nullable(),
          item_id: Yup.number().required(t('validation:required')),
        })
      ),
    });

    if (fetchingFullItem)
      return (
        <div className="mt-10">
          <Spin className="spinner-center" />
        </div>
      );

    return (
      <PageFormik
        initialValues={{
          linkedItems: applicationBundleItems,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitPending, setSubmitError, setStatus }) => {
          setSubmitPending();
          const itemBases = values.linkedItems.map((i: BundleItem) => {
            const qualifiers = i.qualifiers.map(q => ({
              id: q.qualifier_id,
              text: q.qualifier_text,
              record_number: q.record_number,
              values: q.values,
            }));

            const applicationIds = i.application_ids?.map((id: number) => {
              const application = applicationOrder[id - 1];
              return application.item_application_id;
            });
            return { ...i, qualifiers, application_ids: applicationIds };
          });
          this.props
            .updateApplicationBundle(itemBases)
            .then(() => {
              setStatus('SUCCESS');
              setTimeout(() => setStatus('EDIT'), 2500);
            })
            .catch(() => setSubmitError());
        }}
        contentNoScroll
        enableReinitialize
      >
        {() => (
          <div className="h-full flex flex-col">
            {this.topRow()}
            {isManufacturer && (
              <div
                className="overflow-auto mb-4"
                style={{ maxHeight: canManageBundle ? '50%' : '33%' }}
              >
                {!canManageBundle && <ApplicationLinkedChilds />}
                {canManageBundle && bundle && !fetching && <ApplicationBundleTable />}
              </div>
            )}
            {!!universalPart && this.universalPartCardView()}
            {!universalPart && (
              <div
                className="application__overview-table flex-1"
                onScroll={e => this.props.fetchNextApplications(e)}
              >
                <ApplicationCardGroups
                  selectedItem={this.props.selectedItem}
                  bundle={bundle}
                  isReceiver={this.props.isReceiver}
                  isManufacturer={this.props.isManufacturer}
                  applications={this.props.applications}
                  applicationOrder={this.props.applicationOrder}
                  analyses={this.props.analyses}
                  analysisGroups={this.props.analysisGroups}
                  baseItemApplications={this.props.baseItemApplications}
                  oeItemApplications={this.props.oeItemApplications}
                  resources={this.props.resources}
                  defaultQualifiers={this.props.defaultQualifiers}
                  defaultNotes={this.props.defaultNotes}
                  selectConfig={this.selectConfig}
                  deleteApplication={this.deleteApplication}
                  unlinkItem={this.unlinkItem}
                />
                {fetching && applications.length % pageSize === 0 && (
                  <Spin className="spinner-center" />
                )}
              </div>
            )}
          </div>
        )}
      </PageFormik>
    );
  };

  listView = () => {
    const { keywords } = this.props;
    const { universalPart } = this.state;

    return (
      <div className="h-full flex flex-col">
        <div className="m-5 mb-0">{this.topRow()}</div>
        {!!universalPart && <div className="p-5 pt-4">{this.universalPartCardView()}</div>}
        {!universalPart && (
          <div className="application__table-wrapper flex flex-col flex-1">
            <ApplicationList
              keywords={keywords}
              selectApplication={this.props.selectListApplication}
            />
          </div>
        )}
      </div>
    );
  };

  gridView = () => {
    const { keywords } = this.props;
    const { universalPart } = this.state;

    return (
      <div className="h-full flex flex-col">
        <div className="m-5 mb-0">{this.topRow()}</div>
        {!!universalPart && <div className="p-5 pt-4">{this.universalPartCardView()}</div>}
        {!universalPart && (
          <div className="application__table-wrapper flex flex-col flex-1">
            <ApplicationGridList
              keywords={keywords}
              selectApplication={this.props.selectListApplication}
              cloneApplication={this.props.cloneApplication}
              deleteApplication={this.deleteApplicationNoConfirm}
              deleteApplications={this.deleteApplications as () => Promise<any>}
            />
          </div>
        )}
      </div>
    );
  };

  topRow = () => {
    const {
      t,
      showApplicationListView,
      showApplicationGridView,
      fetching,
      parentApplicationItemBases,
    } = this.props;
    const { universalPart, bundle, canManageBundle } = this.state;
    const canManageBaseItems = hasPermission(this.props.user, 'can_manage_base_items');
    const canManageApplicationGrid = hasPermission(this.props.user, 'can_access_application_grid');

    return (
      <div className="application__overview-top">
        <div className="application__overview-buttons">
          <div className="application__overview_search">
            <SearchInput
              id="applicationSearch"
              className="flex-1"
              value={this.props.keywords}
              onChange={event => this.handleSearch(event.target.value)}
              onPressEnter={() => this.fetchApplications()}
              placeholder={t('common:search')}
              allowClear
              size="small"
              search
            />
          </div>
          {showApplicationGridView ? (
            <Dropdown
              menu={{
                items: [
                  { key: 'vehicle', label: t('application:vehicle') },
                  { key: 'equipment', label: t('application:equipment') },
                ],
                onClick: e => {
                  if (e.key === 'vehicle') this.createApplication(ApplicationType.VEHICLE);
                  else if (e.key === 'equipment') this.createApplication(ApplicationType.EQUIPMENT);
                },
              }}
            >
              <Button
                className="application__create-button"
                type="primary"
                disabled={fetching}
                ghost
                size="small"
              >
                {t('application:addButton')}
                <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Button
              className="application__create-button"
              type="primary"
              onClick={() => this.createApplication()}
              disabled={fetching}
              ghost
              size="small"
            >
              {t('application:addButton')}
            </Button>
          )}
          <div />
          <div className="application__overview_actions">
            <ApplicationActions
              universalPart={!!universalPart}
              bundle={bundle}
              canManageBaseItems={canManageBaseItems}
              canManageBundle={canManageBundle}
              disableBundle={!!parentApplicationItemBases.length}
              handleDefaultValues={() => this.setState({ showApplicationDefaultsDrawer: true })}
              handleLinkToApplication={() => this.setState({ showApplicationLinkDrawer: true })}
              updateUniversalPart={this.updateUniversalPart}
              updateBundle={this.updateBundle}
            />
            {canManageApplicationGrid && (
              <Button
                onClick={() => {
                  this.props.showGridView(true);
                  this.props.showListView(false);
                }}
                type={showApplicationGridView ? 'primary' : 'default'}
                icon={<TableOutlined />}
                ghost={showApplicationGridView}
                size="small"
              />
            )}
            <Button
              onClick={() => {
                this.props.showListView(true);
                this.props.showGridView(false);
              }}
              type={showApplicationListView ? 'primary' : 'default'}
              icon={<MenuOutlined />}
              ghost={showApplicationListView}
              size="small"
            />
            <Button
              onClick={() => {
                this.props.showListView(false);
                this.props.showGridView(false);
                this.fetchApplications();
              }}
              type={!showApplicationListView && !showApplicationGridView ? 'primary' : 'default'}
              icon={<AppstoreOutlined />}
              ghost={!showApplicationListView && !showApplicationGridView}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isManufacturer, showApplicationListView, showApplicationGridView } = this.props;
    const canManageBaseItems = hasPermission(this.props.user, 'can_manage_base_items');

    return (
      <div className="application__overview-wrapper">
        <Page contentNoSpacing showAnalysis>
          {!showApplicationListView && !showApplicationGridView && this.cardView()}
          {showApplicationListView && this.listView()}
          {showApplicationGridView && this.gridView()}
        </Page>

        {!canManageBaseItems && (
          <EnterprisePromotionDrawer
            visible={this.state.showApplicationLinkComponent}
            onClose={() => this.setState({ showApplicationLinkComponent: false })}
          />
        )}

        {isManufacturer && (
          <ApplicationLinkDrawer
            visible={this.state.showApplicationLinkDrawer}
            onClose={() => this.setState({ showApplicationLinkDrawer: false })}
            fetchApplications={this.fetchApplications}
          />
        )}

        <ApplicationDefaultsDrawer
          visible={this.state.showApplicationDefaultsDrawer}
          onClose={() => this.setState({ showApplicationDefaultsDrawer: false })}
        />
      </div>
    );
  }
}

export default withTranslation()(withErrorBoundary(withAntdContext(ApplicationOverview)));
