import { Menu } from 'antd';
import React from 'react';
import type { MenuProps } from 'antd';
import { UserPermissionRole } from '../../../../types/user';

type RoleDrawerMenuProps = {
  roles: UserPermissionRole[];
  noSelection: boolean;
  handleClick: (id: number) => void;
};
type MenuItem = Required<MenuProps>['items'][number];

const RoleDrawerMenu: React.FC<RoleDrawerMenuProps> = ({ roles, noSelection, handleClick }) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(
    roles.length > 0 ? [roles[0].id.toString()] : []
  );

  const roleItems: MenuItem[] = roles.map(role => ({ key: role.id, label: role.name }));

  return (
    <Menu
      className="overflow-auto overflow-x-hidden flex-1"
      onClick={({ key }) => handleClick(Number(key))}
      selectedKeys={noSelection ? [] : selectedKeys}
      onSelect={({ key }) => setSelectedKeys([key.toString()])}
      mode="inline"
      items={roleItems}
    />
  );
};

export default RoleDrawerMenu;
