import { Field, FieldProps, FormikHelpers } from 'formik';
import { Form, TimePicker } from 'antd';
import React from 'react';
import { get as _get } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type TimeInputProps = {
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
};

const TimeInput: React.FC<TimeInputProps> = props => {
  const { name, label, setFieldValue, disabled } = props;

  return (
    <Field name="schedule.deliveryTime">
      {({ form }: FieldProps) => {
        const { values } = form;
        // use lodash because name can include dots eg. foo.bar
        const value = _get(values, name);
        const valueDayjs = dayjs(value, 'HH:mm');

        return (
          <Form.Item label={label}>
            <TimePicker
              value={valueDayjs}
              disabled={disabled}
              format="hh:mm a"
              use12Hours
              onChange={time => {
                if (time === null) time = dayjs('00:00', 'HH:mm');
                const utcString = dayjs.utc(time).local().format('HH:mm');
                setFieldValue(name, utcString);
              }}
            />
          </Form.Item>
        );
      }}
    </Field>
  );
};

export default TimeInput;
