import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LogsTable from './LogsTable';
import {
  ExportErrorLogDetail,
  ExportLog,
  FileExportOption,
  ImportErrorLogDetail,
  ImportLog,
} from '../../../../types/import_export';
import ErrorLogModal from '../delivery_logs/ErrorLogModal';
import { ApplicationState } from '../../../reducers';
import CustomizeLogModal from './CustomizeLogModal';
import { StandardResourceDescription } from '../../../../types/resources';

type ImportExportLogsProps = {
  logs: ImportLog[] | ExportLog[];
  fetchingLogs: boolean;
  fetchingFileTypeValues?: boolean;
  fetchingErrors: boolean;
  errorLogDetails: ImportErrorLogDetail[] | ExportErrorLogDetail[];
  handleErrorDownload: (logId: number) => void;
  handleFileDownload: (logId: number) => void;
  handleFetchLogDetailsJSON: (logId: number) => void;
  handleOpenNotification?: (logId: number) => void;
  exportLogs?: boolean;
  type?: string;
  hideFileType?: boolean;
  hideItemCount?: boolean;
  hideApplicationCount?: boolean;
  fileTypeValues?: StandardResourceDescription[] | FileExportOption[];
  handleFilterByType?: (ids: number[]) => void;
  fetchLogs?: (page: number) => Promise<any>;
  selectedTypeFilterIds?: number[];
  pageSize?: number;
};

const ImportExportLogs: React.FC<ImportExportLogsProps> = props => {
  const { exportLogs } = props;
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showCstomizeModal, setShowCustomizeModal] = React.useState<boolean>(false);
  const [logId, setLogId] = React.useState<number | null>(null);
  const [displayRowNumber, setDisplayRowNumber] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState([
    ...(!props.hideFileType
      ? [
          {
            dataKey: 'fileTypeName',
            label:
              props.type === 'externalSourceImport' ? t('importer:source') : t('importer:fileType'),
            visible: true,
          },
        ]
      : []),
    { dataKey: 'file_name', label: t('importer:fileName'), visible: true },
    { dataKey: 'file_size', label: t('importer:size'), visible: true },
    {
      dataKey: 'startedTime',
      label: exportLogs ? t('exporter:exportStarted') : t('importer:importStarted'),
      visible: true,
    },
    {
      dataKey: 'completedTime',
      label: exportLogs ? t('exporter:exportFinished') : t('importer:importFinished'),
      visible: true,
    },
    { dataKey: 'user_id', label: t('glossary:user'), visible: true },
    ...(!props.hideItemCount
      ? [{ dataKey: 'item_count', label: t('importer:items'), visible: true }]
      : []),
    ...(!props.hideApplicationCount
      ? [{ dataKey: 'application_count', label: t('importer:applications'), visible: true }]
      : []),
    { dataKey: 'displayError', label: t('importer:status'), visible: true },
    { dataKey: 'downloadFile', label: t('common:action'), visible: true },
  ]);

  const { globalResources, userNameList } = useSelector((state: ApplicationState) => ({
    globalResources: state.resources.data.global,
    userNameList: state.user.userNameList,
  }));

  const showLogs = (logs = props.logs) => {
    const fetchErrorLogDetails = (logId: number, fileTypeId: number) => {
      setShowModal(true);
      setLogId(logId);
      setDisplayRowNumber(fileTypeId === 10);
      props.handleFetchLogDetailsJSON(logId);
    };

    const handleTableScroll = ({
      startIndex,
      stopIndex,
    }: {
      startIndex: number;
      stopIndex: number;
    }) => {
      if (props.pageSize) {
        const nextPage = Math.ceil(stopIndex / props.pageSize);
        const currentPage = Math.ceil(startIndex / props.pageSize);
        const lastPage = currentPage > startIndex / props.pageSize;

        if (!lastPage && !props.fetchingLogs) return props.fetchLogs!(nextPage);
        return Promise.resolve();
      }
      return Promise.resolve();
    };

    return (
      <LogsTable
        logs={logs}
        fetchingLogs={props.fetchingLogs}
        handleDisplayError={fetchErrorLogDetails}
        handleImportFileDownload={props.handleFileDownload}
        handleOpenNotification={props.handleOpenNotification}
        exportLogs={props.exportLogs}
        columns={columns}
        userNameList={userNameList}
        fileTypeValues={props.fileTypeValues}
        handleFilterByType={
          props.handleFilterByType ? ids => props.handleFilterByType!(ids) : undefined
        }
        pageSize={props.pageSize}
        selectedTypeFilterIds={props.selectedTypeFilterIds}
        handleTableScroll={handleTableScroll}
      />
    );
  };

  return (
    <div className="excel-import__table bg-white flex flex-col">
      <div className="p-5 text-right">
        <Button icon={<TableOutlined />} onClick={() => setShowCustomizeModal(true)}>
          {t('common:customize')}
        </Button>
      </div>
      <div className="flex-1 px-5">
        {props.fetchingFileTypeValues ? (
          <Spin className="spinner-center" style={{ marginTop: '20px' }} />
        ) : (
          showLogs()
        )}

        <ErrorLogModal
          showModal={showModal}
          fetching={props.fetchingErrors}
          errors={props.errorLogDetails || []}
          errorTypes={globalResources?.log_error_types || []}
          handleErrorModalState={() => {
            setShowModal(!showModal);
            setLogId(null);
            setDisplayRowNumber(false);
          }}
          handleErrorsDownload={() => props.handleErrorDownload(logId!)}
          type={props.exportLogs ? 'exportLog' : 'importLog'}
          displayRowNumber={displayRowNumber}
        />
      </div>

      <CustomizeLogModal
        showModal={showCstomizeModal}
        columns={columns}
        onCancel={() => setShowCustomizeModal(false)}
        handleSave={columns => {
          setColumns(columns);
          setShowCustomizeModal(false);
        }}
      />
    </div>
  );
};

export default ImportExportLogs;
