import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button, Alert } from 'antd';
import * as Yup from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';

import validationMessages from '../constants/ValidationMessages.json';
import { login } from '../actions/user';
import { getApiRoot } from '../constants/ParamountReactConstants';
import LoginFooter from './LoginFooter';

import FormInput from '../components/global/Forms/FormInput';
import FormPasswordInput from '../components/global/Forms/FormPasswordInput';
import { AsyncDispatch } from '../../types/global';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required(validationMessages.required),
  password: Yup.string().required(validationMessages.required),
});

type LoginPageProps = {
  dispatch: AsyncDispatch;
  okta?: boolean;
} & WithTranslation;

type LoginPageState = {
  error: string | null;
};

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  state: LoginPageState = {
    error: null,
  };

  errorMessage = () => {
    if (this.state.error) {
      return (
        <Alert type="error" showIcon message={this.state.error} style={{ marginBottom: 20 }} />
      );
    }
    return null;
  };

  render() {
    const { t } = this.props;

    return (
      <div className="login__background">
        <div className="login__box">
          <div className="login__box-header">
            <div className="login-logo" />
          </div>
          <div className="login__box-content">
            <div className="login--header-content">
              <div className="login__title">{t('login:login')}</div>
              <div className="mt-1">{t('login:welcome_txt1')}</div>
            </div>
            {this.errorMessage()}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                this.setState({ error: null });
                this.props
                  // @ts-ignore
                  .dispatch(login(values.email, values.password))
                  .then(() => {
                    setSubmitting(false);
                  })
                  .catch(err => {
                    const { message } = err.response.data || {};

                    this.setState({ error: message || t('login:login_failed') });
                    setSubmitting(false);
                  });
              }}
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onFinish={handleSubmit} layout="vertical">
                  <FormInput
                    name="email"
                    label={t('common:email')}
                    placeholder={t('common:email')}
                    size="large"
                    required
                  />
                  <FormPasswordInput
                    name="password"
                    label={t('common:password')}
                    placeholder={t('common:password')}
                    size="large"
                    required
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={!(isValid && dirty) || isSubmitting}
                    loading={isSubmitting}
                    style={{ width: '100%', marginTop: 24 }}
                  >
                    {t('login:login')}
                  </Button>
                  <div className="register__login-container forgot-password">
                    <Link className="register__login-text" to="/forgot-password">
                      {t('login:forgotPassword')}
                    </Link>
                  </div>
                  <div className="text-center">
                    <Link className="register__login-text" to="/register">
                      {t('login:create_account')}
                    </Link>
                    {` ${t('login:register_txt')}`}
                  </div>
                  {this.props.okta && (
                    <div className="text-center mt-3">
                      <Button href={`${getApiRoot()}/auth/saml`}>{t('login:oktaLogin')}</Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <LoginFooter />
      </div>
    );
  }
}

export default connect()(withTranslation()(LoginPage as any));
