import React from 'react';
import { connect } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { withRouter } from '../withRouter';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import * as actions from '../../actions/app/navigationBar';
import { navigationBarData } from '../../constants/ParamountReactConstants';
import { isReceiver } from '../../utils/UserUtils.ts';
import { hasPermission } from '../../utils/Permissions';

const { Sider } = Layout;

class BrandProductsSideBar extends React.Component {
  getHiddenSegments = () => {
    const { user, roles } = this.props;
    if (user.admin) return [];
    const userRoles = roles.filter(role => user.user_role_ids.includes(role.id));
    const segmentPermissions = userRoles.flatMap(role =>
      role.segments.map(segment => segment.segment_id)
    );

    return navigationBarData
      .filter(segment => segment.id && !segmentPermissions.includes(segment.id))
      .map(segment => segment.key);
  };

  scoreStyleClass = segmentId => {
    const { segmentScores, selectedItems } = this.props;

    let currentSegmentScore = segmentScores.find(
      segmentScore => segmentScore.segment_id === segmentId
    );

    if (!currentSegmentScore || selectedItems.length === 0) return '';

    // show in description also marketing copy score
    if (segmentId === 4) {
      // description
      const marketingCopySegmentScore = segmentScores.find(
        segmentScore => segmentScore.segment_id === 11
      ); // marketing copy
      currentSegmentScore = {
        error_count:
          marketingCopySegmentScore &&
          marketingCopySegmentScore.error_count > currentSegmentScore.error_count
            ? marketingCopySegmentScore.error_count
            : currentSegmentScore.error_count,
        warning_count:
          marketingCopySegmentScore &&
          marketingCopySegmentScore.warning_count > currentSegmentScore.warning_count
            ? marketingCopySegmentScore.warning_count
            : currentSegmentScore.warning_count,
        info_count:
          marketingCopySegmentScore &&
          marketingCopySegmentScore.info_count > currentSegmentScore.info_count
            ? marketingCopySegmentScore.info_count
            : currentSegmentScore.info_count,
      };
    }

    let color = '';
    if (currentSegmentScore.error_count > 0) {
      color = '#f5222d';
    } else if (currentSegmentScore.warning_count > 0) {
      color = '#fa8c16';
    } else if (currentSegmentScore.info_count > 0) {
      color = '#188ca6';
    }

    return color && <WarningOutlined style={{ color }} />;
  };

  handleMenuClick = key => {
    this.props.dispatch(actions.setActiveArea(key));
  };

  render() {
    const { location, isReceiver, user } = this.props;

    const canManageCustomFields = isReceiver && hasPermission(user, 'can_manage_custom_fields');
    const segmentsNotVisibleForReceiver = canManageCustomFields
      ? ['channel-receiver']
      : ['custom_fields', 'channel-receiver'];
    const hiddenSegments = isReceiver ? segmentsNotVisibleForReceiver : this.getHiddenSegments();

    const navigation = navigationBarData.filter(({ key }) => !hiddenSegments.includes(key));

    const sideBarItems = navigation.map(nav => ({
      className: classNames({ 'sidebar-brand-products__divider': nav.key === 'ebay' }),
      key: `${nav.link}`,
      onClick: () => this.handleMenuClick(nav.key),
      label: (
        <Link
          to={{
            ...location,
            pathname: `${nav.link}`,
          }}
        >
          <div className="sidebar-brand-products__menu-item">
            <span>{nav.value}</span>
            {this.scoreStyleClass(nav.id)}
          </div>
        </Link>
      ),
    }));

    return (
      <Sider theme="light" className="sidebar-brand-products">
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname.split('/').pop()]}
          mode="inline"
          items={sideBarItems}
        />
      </Sider>
    );
  }
}

const mapStateToProps = state => {
  const selectedItems = getSelectedItems(state);
  return {
    segmentScores: state.items.analyses.segmentScores,
    smallShopPreview: state.items.shopPreview.smallShopPreview,
    selectedItems,
    isReceiver: state.user.user && isReceiver(state.user.user),
    user: state.user.user,
    roles: state.user.roles,
  };
};

export default withRouter(connect(mapStateToProps)(BrandProductsSideBar));
