import React, { useState } from 'react';
import { Select, Spin, Empty, Form } from 'antd';
import { get as _get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldProps } from 'formik';
import { fetchItemsByKeyword } from '../../../actions/items/application/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { ApplicationState } from '../../../reducers';
import { Item } from '../../../../types/item';
import { typingDone } from '../../../utils/Utils';
import { createBrandTitle } from '../../../utils/String';

type BundlePartNumberSelectProps = {
  name: string;
  className?: string;
  partNumber: string;
  brandCode: string;
  brandName: string;
  defaultWhiteLabelCode: string | null;
  crossLinkBrandsEnabled?: boolean;
  onChange: (
    id: number,
    partNumber: string,
    partType: null | string,
    categoryId: null | number,
    brandCode: string,
    brandName: string,
    defaultWhiteLabelCode: string | null
  ) => void;
};

const BundlePartNumberSelect: React.FC<BundlePartNumberSelectProps> = props => {
  const {
    name,
    className,
    crossLinkBrandsEnabled,
    onChange,
    partNumber,
    brandCode,
    brandName,
    defaultWhiteLabelCode,
  } = props;

  const dispatch: AsyncDispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<Item[]>([]);

  const { selectedBrandId } = useSelector((state: ApplicationState) => {
    return {
      selectedBrandId: state.parent.brands.selectedBrandId,
    };
  });

  const loadItemsByKeyword = (keyword: string, selectedItemId: number) => {
    typingDone(() => {
      setFetching(true);
      setItems([]);
      const brandId = crossLinkBrandsEnabled ? null : selectedBrandId;

      dispatch(fetchItemsByKeyword(brandId, [selectedItemId], keyword)).then(result => {
        setItems(result.value.data.items);
        setFetching(false);
      });
    });
  };

  const handleChange = (itemId: number) => {
    const item = items.find(item => item.id === itemId);
    onChange(
      itemId,
      item!.part_number,
      item!.part_type,
      item!.category_id,
      item!.brand_code,
      item!.brand_name!,
      item!.default_whitelabel_brand_code!
    );
  };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { onBlur } = field;
        const { errors, touched } = form;
        const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
        const currentItems = open
          ? items
          : [
              {
                id: field.value,
                part_number: partNumber,
                brand_name: brandName,
                brand_code: brandCode,
                default_whitelabel_brand_code: defaultWhiteLabelCode,
              } as Item,
            ];

        return (
          <Form.Item
            className={`application__bundle-select ${className || ''}`}
            validateStatus={showValidationInfo ? 'error' : ''}
            help={(showValidationInfo && _get(errors, name)) || undefined}
          >
            <Select
              value={field.value}
              onChange={value => handleChange(value)}
              onBlur={onBlur}
              notFoundContent={fetching ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              onSearch={value => loadItemsByKeyword(value, field.value)}
              filterOption={false}
              size="small"
              showSearch
              onDropdownVisibleChange={open => setOpen(open)}
              popupMatchSelectWidth={800}
              options={currentItems.map((item: Item) => ({
                key: item.id || 'temp',
                value: item.id,
                title: `${
                  crossLinkBrandsEnabled
                    ? `${createBrandTitle(
                        item.brand_name!,
                        item.brand_code,
                        item.default_whitelabel_brand_code!
                      )}: `
                    : ''
                } ${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`,
                label: !open
                  ? `${
                      crossLinkBrandsEnabled
                        ? `${createBrandTitle(brandName, brandCode, defaultWhiteLabelCode)}: `
                        : ''
                    }${partNumber}`
                  : `${
                      crossLinkBrandsEnabled
                        ? `${createBrandTitle(
                            item.brand_name!,
                            item.brand_code,
                            item.default_whitelabel_brand_code!
                          )}: `
                        : ''
                    } ${item.part_number} ${item.short_name ? `(${item.short_name})` : ''}`,
              }))}
            />
          </Form.Item>
        );
      }}
    </Field>
  );
};

export default BundlePartNumberSelect;
