import React from 'react';
import classNames from 'classnames';
import AnalysisQuickFix from './AnalysisQuickFix';
import { Analysis as AnalysisResources } from '../../../../types/resources';
import { BrandItemTag } from '../../../../types/brand_catalogue';
import { Analysis, ConfirmedVehicleBase } from '../../../../types/analyses';

type AnalysesProps = {
  canManageQuickFixes: boolean;
  fetchingReviewCategories: boolean;
  analyses: Analysis[];
  analysesResources: AnalysisResources;
  brandItemTags: BrandItemTag[];
  confirmedVehicleBases: ConfirmedVehicleBase[];
  fetchNext: (event: any) => void;
  fetchReviewCategories: (keywords?: string) => void;
  fetchNextReviewCategories: (event: any, keywords: string) => void;
  fetchReviewItems: (groupKey: string) => void;
  updateReviewCategory: (
    groupKey: string,
    referenceId: number,
    categoryId: number,
    typeId: number
  ) => void;
  categories: any;
  // confirmAllReviews: (typeId: number, groupKey: string) => void;
  declineAllReviews: (typeId: number, groupKey: string) => void;
  confirmReviews: (Object: {
    typeId: number;
    analysisIds: number[];
    validValueId?: number;
    vehicleBaseIds?: number[];
  }) => void;
  // reopenReviews: (analysisIds: number[]) => void;
  declineReviews: (analysisIds: number[]) => void;
  addToTempFilter: (itemIds: number[]) => void;
  addItemMarks: (itemIds: number[]) => void;
  removeItemMarks: (itemIds: number[]) => void;
  handleAddTag: (itemIds: number[], tagName: string) => void;
  handleRemoveTag: (itemIds: number[], tagName: string) => void;
};

type AnalysesState = {
  dropdownOpen: boolean;
};

class Analyses extends React.Component<AnalysesProps, AnalysesState> {
  state = {
    dropdownOpen: false,
  };

  setDropdownState = (state: boolean) => {
    this.setState({ dropdownOpen: state });
  };

  getAnalysisResource = (typeId: number) =>
    this.props.analysesResources.analysis_types.find(
      analyseResource => analyseResource.id === typeId
    );

  analyses = () =>
    this.props.analyses.map(analysis => (
      <AnalysisQuickFix
        key={analysis.reference_id || ''}
        canManageQuickFixes={this.props.canManageQuickFixes}
        fetchingReviewCategories={this.props.fetchingReviewCategories}
        analysis={analysis}
        confirmedVehicleBases={this.props.confirmedVehicleBases}
        getAnalysisResource={this.getAnalysisResource}
        fetchReviewCategories={this.props.fetchReviewCategories}
        fetchNextReviewCategories={this.props.fetchNextReviewCategories}
        categories={this.props.categories}
        brandItemTags={this.props.brandItemTags}
        updateReviewCategory={this.props.updateReviewCategory}
        confirmReviews={this.props.confirmReviews}
        declineReviews={this.props.declineReviews}
        declineAllReviews={this.props.declineAllReviews}
        fetchReviewItems={this.props.fetchReviewItems}
        setDropdownState={this.setDropdownState}
        addItemMarks={this.props.addItemMarks}
        removeItemMarks={this.props.removeItemMarks}
        addToTempFilter={this.props.addToTempFilter}
        handleAddTag={this.props.handleAddTag}
        handleRemoveTag={this.props.handleRemoveTag}
      />
    ));

  render() {
    const analyseCss = classNames('analyses', { 'analyses--no-scroll': this.state.dropdownOpen });
    return (
      <div className={analyseCss} onScroll={e => this.props.fetchNext(e)}>
        {this.analyses()}
      </div>
    );
  }
}

export default Analyses;
