import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { DefaultValue } from '../../../types/brand_settings';

export type AccountDefaultValuesState = {
  accountDefaultValues: DefaultValue[];
  fetchingAccDefaultValues: boolean;
};

const initialState: AccountDefaultValuesState = {
  accountDefaultValues: [],
  fetchingAccDefaultValues: false,
};

const reducer: Reducer<AccountDefaultValuesState, FluxStandardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_DEFAULT_VALUES_PENDING':
      return {
        ...state,
        fetchingAccDefaultValues: true,
      };
    case 'FETCH_ACCOUNT_DEFAULT_VALUES_FULFILLED':
      return {
        ...state,
        fetchingAccDefaultValues: false,
        accountDefaultValues: action.payload.data,
      };
    case 'UPDATE_ACCOUNT_DEFAULT_VALUE_PENDING': {
      const accountDefaultValues = state.accountDefaultValues.map(value =>
        value.id === action.meta.defaultValueId ? { ...value, value: action.meta.value } : value
      );
      return {
        ...state,
        accountDefaultValues,
      };
    }
  }
  return state;
};

export default reducer;
