import { Button, Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

type FTPCredentialsProps = {
  server: string;
  user: string;
  password: string;
  port: number | null;
  subText: string;
  customDirectory?: string;
  showIntercom?: () => void;
};

const FTPCredentials: React.FC<FTPCredentialsProps> = props => {
  const { t } = useTranslation();

  const labelText = (label: string, text: string | number | null) => (
    <div className="flex flex-row mb-4">
      <div className="w-24">{`${label}:`}</div>
      <Paragraph copyable={!!text}>{text}</Paragraph>
    </div>
  );
  return (
    <div className="sources__ftp-credentials">
      <div className="font-medium ant-text-black">{t('externalSources:ftpCredentials')}</div>
      <div className="mt-2">{props.subText}</div>
      <div className="flex space-x-2 mt-6">
        <Card style={{ width: 500 }}>
          {labelText(t('common:server'), props.server)}
          {props.customDirectory && labelText(t('common:path'), props.customDirectory)}
          {labelText(t('common:port'), props.port)}
          {labelText(t('common:user'), props.user)}
          {labelText(t('common:password'), props.password)}
        </Card>
        {props.showIntercom && (
          <Button onClick={() => props.showIntercom!()} icon={<InfoCircleOutlined />}>
            {t('externalSources:moreInstructionButton')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FTPCredentials;
