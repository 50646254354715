import { connect } from 'react-redux';
import React from 'react';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Divider, message, Tooltip } from 'antd';
import { FormikValues, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { booleanToInt } from '../../../utils/Utils';
import { createUser, resetUserPassword, updateMappedUserPermissions } from '../../../actions/user';
import { ListUser, UserPermissionRole } from '../../../../types/user';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormSwitch from '../../global/Forms/FormSwitch';
import AntTooltip from '../../global/AntTooltip';
import AntSelect from '../../global/AntSelect';
import { StandardResourceCode } from '../../../../types/resources';
import { Brand } from '../../../../types/brand';
import { isReceiver } from '../../../utils/UserUtils';
import { hasPermission } from '../../../utils/Permissions';
import { activeBrands } from '../../../selectors/brand/brandSelector';
import withAntdContext, { WithAntdContextProps } from '../../../containers/withAntdContext';

type UserDrawerProps = {
  dispatch: AsyncDispatch;
  visible: boolean;
  onClose: () => void;
  currentUserId: number;
  maintenanceTypes: StandardResourceCode[];
  brands: Brand[];
  roles: UserPermissionRole[];
  user?: ListUser;
  isReceiver: boolean;
  canManageUsers: boolean;
  updateUserState: (active: number) => Promise<any>;
  showRoleDrawer: () => void;
} & WithTranslation &
  WithAntdContextProps;

class UserDrawer extends React.Component<UserDrawerProps> {
  extendedMaintenanceTypes = [...this.props.maintenanceTypes, { id: 3, code: '', name: 'Hide' }];

  handleUserChange = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { user } = this.props;
    const { firstName, lastName, email, emailNotification, password, admin, roles, brands } =
      values;
    const { setSubmitting, setFieldError } = formikActions;

    const brandPermissions = Object.keys(brands)
      .filter(key => brands[Number(key)] !== 3)
      .map(key => ({
        brand_id: Number(key),
        maintenance_type_id: brands[Number(key)],
      }));

    if (user) {
      await this.props.dispatch(
        updateMappedUserPermissions({
          id: user.id,
          first_name: firstName,
          last_name: lastName,
          email_notification: emailNotification,
          admin: booleanToInt(admin),
          user_role_ids: roles,
          brand_permissions: brandPermissions,
        })
      );
      this.props.onClose();
    }

    if (!user)
      await this.props
        .dispatch(
          createUser({
            emailNotification,
            firstName,
            lastName,
            email,
            password,
            admin: booleanToInt(admin)!,
          })
        )
        // Update should be removed when create controller is updated
        .then(result =>
          this.props
            .dispatch(
              updateMappedUserPermissions({
                id: result.value.data.id,
                first_name: firstName,
                last_name: lastName,
                admin: booleanToInt(admin),
                user_role_ids: roles,
                brand_permissions: brandPermissions,
                email_notification: emailNotification,
              })
            )
            .then(() => this.props.onClose())
        )
        .catch(result => {
          const message = result.response.data.message;
          const status = result.response.status;
          if (message && status === 422) setFieldError('email', message);
        });

    setSubmitting(false);
  };

  handleUserStateChange = () => {
    const { user, updateUserState, onClose, t } = this.props;
    const { modal } = this.props.appContext;
    const active = user && user.active ? 0 : 1;

    modal.confirm({
      title: !active ? t('user:disableUser') : t('user:enableUser'),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const userUpdateResponse = await updateUserState(active);
        onClose();
        return userUpdateResponse;
      },
    });
  };

  handlePasswordReset = () => {
    const { user, t } = this.props;
    const email = (user && user.email) || '';
    this.props.dispatch(resetUserPassword(email)).then(() => {
      message.info(t('user:resetPasswordMessage'));
      this.props.onClose();
    });
  };

  handleBrandSelect = (
    maintenanceTypeId: number,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    const { brands } = this.props;
    brands.forEach(brand => setFieldValue(`brands.${brand.id}`, maintenanceTypeId, false));
  };

  brandSelectState = (values: { [key: number]: number }) => {
    const maintenanceTypeIds = Object.values(values);
    const uniq = [...new Set(maintenanceTypeIds)];
    return uniq.length === 1 ? uniq[0].toString() : undefined;
  };

  getInitialBrandValues = () => {
    const { user, brands } = this.props;

    const brandPermissions = brands.map(brand => {
      const perm = user ? user.brand_permissions.find(b => b.brand_id === brand.id) : undefined;
      return {
        brandId: brand.id,
        maintenanceTypeId: perm ? perm.maintenance_type_id : 3,
      };
    });

    const brandObj = brandPermissions.reduce(
      (obj, item) => ({ ...obj, [item.brandId]: item.maintenanceTypeId }),
      {}
    );

    return brandObj;
  };

  getValidationSchema = () => {
    const { t } = this.props;

    return Yup.object().shape({
      firstName: Yup.string()
        .min(2, t('validation:tooShort'))
        .max(50, t('validation:tooLong'))
        .required(t('validation:required')),
      lastName: Yup.string()
        .min(2, t('validation:tooShort'))
        .max(50, t('validation:tooLong'))
        .required(t('validation:required')),
      email: Yup.string().email(t('validation:invalidEmail')).required(t('validation:required')),
      password: this.props.user
        ? Yup.string()
        : Yup.string().min(6, t('validation:passwordLength')).required(t('validation:required')),
      admin: Yup.bool().required(t('validation:required')),
    });
  };

  render() {
    const { user, currentUserId, brands, roles, isReceiver, canManageUsers, t } = this.props;

    return (
      <DrawerFormik
        title={user ? `${user.first_name} ${user.last_name}` : t('user:addUser')}
        width={500}
        visible={this.props.visible}
        onClose={this.props.onClose}
        destroyOnClose
        initialValues={{
          firstName: user ? user.first_name : '',
          lastName: user ? user.last_name : '',
          emailNotification: user ? user.email_notification : 0,
          email: user ? user.email : '',
          password: '',
          admin: user ? !!user.admin : isReceiver,
          brands: this.getInitialBrandValues(),
          roles: user ? user.user_role_ids : [],
        }}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, actions) => this.handleUserChange(values, actions)}
        handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
          canManageUsers && formik.dirty
        }
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <React.Fragment>
            <Form className="user__settings-form" onFinish={() => handleSubmit()} layout="vertical">
              <FormInput name="firstName" label={t('user:firstName')} required />
              <FormInput name="lastName" label={t('user:lastName')} required />
              <FormInput name="email" label={t('user:email')} required disabled={!!user} />
              {!user && <FormInput name="password" label={t('user:password')} required />}

              <div className="user__drawer-button-row">
                <Button
                  className="user__reset-pw-button"
                  onClick={() => this.handlePasswordReset()}
                >
                  {t('user:resetPassword')}
                </Button>
              </div>

              {!isReceiver && (
                <div className="user__permission-row user__admin-permission flex">
                  <div className="user__permission-name">
                    {t('user:admin')}
                    <span className="user__permission-info">
                      <AntTooltip title={t('user:adminInfo')}>
                        <InfoCircleOutlined />
                      </AntTooltip>
                    </span>
                  </div>
                  <FormSwitch
                    name="admin"
                    checkedChildren={t('common:yes')}
                    unCheckedChildren={t('common:no')}
                  />
                </div>
              )}
              <div className="user__permission-row flex">
                <div className="user__permission-name">
                  {t('accountSettings:form.sendEmailNotification')}
                  <span className="user__permission-info">
                    <Tooltip title={t('accountSettings:form.emailNotificationInfoText')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <FormSwitch
                  name="emailNotification"
                  handleChange={(checked: boolean) => {
                    setFieldValue('emailNotification', booleanToInt(checked));
                  }}
                  checkedChildren={t('common:yes')}
                  unCheckedChildren={t('common:no')}
                />
              </div>

              {!values.admin && !isReceiver && (
                <React.Fragment>
                  <FormMultiSelect
                    name="roles"
                    values={roles}
                    label={t('user:userRole')}
                    placeholder={t('user:selectRoles')}
                  />
                  <div className="user__drawer-button-row">
                    <Button onClick={() => this.props.showRoleDrawer()}>
                      {t('user:manageRoles')}
                    </Button>
                  </div>

                  <div className="user__divider_wrapper flex">
                    <div className="flex-1">
                      <Divider className="user__divider" orientation="left">
                        {t('user:allBrandsHeader')}
                      </Divider>
                    </div>
                    <div>
                      <AntSelect
                        onChange={(value: string) =>
                          this.handleBrandSelect(Number(value), setFieldValue)
                        }
                        elements={this.extendedMaintenanceTypes.map(type => ({
                          ...type,
                          id: type.id.toString(),
                        }))}
                        value={this.brandSelectState(values.brands)}
                        placeholder={t('user:select')}
                      />
                    </div>
                  </div>
                  {brands.map(brand => (
                    <div key={brand.id} className="user__permission-row flex">
                      <div className="user__permission-name">{brand.name}</div>
                      <FormSelect
                        name={`brands.${brand.id}`}
                        values={this.extendedMaintenanceTypes}
                        fastField
                      />
                    </div>
                  ))}
                </React.Fragment>
              )}

              <div className="user__drawer-disable-button">
                {user && user.id !== currentUserId && (
                  <Button
                    className="user__cancel-button"
                    onClick={() => this.handleUserStateChange()}
                    danger
                    ghost
                  >
                    {user.active ? t('user:setInactive') : t('user:setActive')}
                  </Button>
                )}
              </div>
            </Form>
          </React.Fragment>
        )}
      </DrawerFormik>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  roles: state.user.roles,
  maintenanceTypes: state.resources.data.global.maintenance_types,
  brands: activeBrands(state),
  isReceiver: state.user.user && isReceiver(state.user.user),
  canManageUsers: hasPermission(state.user.user, 'can_manage_users'),
});

export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(withAntdContext(UserDrawer)))
);
