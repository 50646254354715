import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { fetchItemsByFilterId } from '../actions/catalogue/catalogue/fetch';
import {
  updateClientFilterChannelsReceivers,
  updateClientFilterBrandIds,
  setOrder,
  resetOrder,
} from '../actions/catalogue/catalogue/update';
import { Order } from '../../types/catalogue';
import { fetchAvailableChannelsReceivers } from '../actions/receiver_data_stream/productsCatalogue/fetch';
import { setChannelsReceiverToEmpty } from '../actions/receiver_data_stream/productsCatalogue/update';
import { DefaultValue } from '../../types/brand_settings';
import { brandAccDefaultValues } from '../selectors/default_values/defaultValuesSelector';

const useCatalogueQueryParams = () => {
  const dispatch = useDispatch();
  const filterId = useSelector((state: ApplicationState) => state.catalogue.filter.filterGo?.id);
  const catalogueQueryParams = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.catalogueQueryParams
  );
  const receiverAuthorizedBrands = useSelector(
    (state: ApplicationState) => state.receiverDataStream.authorizedBrands
  );
  const defaultValues = useSelector((state: ApplicationState) => brandAccDefaultValues(state));

  const fetchAvailableChannelsReceiversByFilteredBrands = (
    brandIds = catalogueQueryParams.filterBrandIds
  ) => {
    // based on the selected brands we want to fetch all channels/receivers available for the brands
    // we need to filter all not authorized brands otherwise the backend gives as a 404
    //  => if no brand is authorized we clear all receivers/channels

    const receiverAuthorizedBrandIds = receiverAuthorizedBrands.map(({ id }) => id);
    const authorizedBrandIds = brandIds.filter(id => receiverAuthorizedBrandIds.includes(id));

    if (!brandIds.length) {
      dispatch(fetchAvailableChannelsReceivers());
    } else if (authorizedBrandIds.length) {
      dispatch(fetchAvailableChannelsReceivers(authorizedBrandIds));
    } else {
      dispatch(setChannelsReceiverToEmpty());
    }
  };

  const fetchItemsByFilter = ({
    filterBrandIds = catalogueQueryParams.filterBrandIds,
    order = catalogueQueryParams.order,
    selectedChannelId = catalogueQueryParams.selectedChannelId,
    selectedReceiverId = catalogueQueryParams.selectedReceiverId,
  }) => {
    const defLangId = defaultValues.find(
      (d: DefaultValue) => d.resource_table === 'languages'
    )?.value;

    if (filterId)
      dispatch(
        fetchItemsByFilterId({
          filterId,
          filterBrandIds,
          order,
          selectedChannelId,
          selectedReceiverId,
          languageId: defLangId,
        })
      );
  };

  const updateBrandIds = (brandIds: number[]): void => {
    dispatch(updateClientFilterBrandIds(brandIds));
    fetchItemsByFilter({ filterBrandIds: brandIds });
    fetchAvailableChannelsReceiversByFilteredBrands(brandIds);
  };

  const updateOrder = (order: Order) => {
    if (order.condition && order.option) {
      dispatch(setOrder(order));
      fetchItemsByFilter({ order });
    } else {
      dispatch(resetOrder());
      fetchItemsByFilter({ order: null });
    }
  };

  const updateChannelsReceivers = ({
    selectedChannelId,
    selectedReceiverId,
  }: {
    selectedChannelId?: number | null;
    selectedReceiverId?: number | null;
  }) => {
    dispatch(
      updateClientFilterChannelsReceivers({
        selectedChannelId,
        selectedReceiverId,
      })
    );
    fetchItemsByFilter({
      selectedChannelId,
      selectedReceiverId,
    });
  };
  return {
    updateBrandIds,
    updateOrder,
    updateChannelsReceivers,
    fetchChannelReceivers: fetchAvailableChannelsReceiversByFilteredBrands,
  };
};

export default useCatalogueQueryParams;
