import axios from 'axios';
import { message } from 'antd';
import { getApiRoot, getNonGwApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import digitalAssetConstants from '../../../constants/DigitalAssetsTranslation.json';

const CancelToken = axios.CancelToken;
let fetchDigitalAssetCancelToken;

export const fetchDigitalAssetsParent = (fileName, page = 1) => {
  if (fetchDigitalAssetCancelToken) fetchDigitalAssetCancelToken();

  const params = utils.createURL([
    { name: 'file_name', values: fileName },
    { name: 'page', values: page },
    { name: 'limit', values: 60 },
  ]);

  return {
    type: 'FETCH_DIGITAL_ASSET_PARENTS',
    meta: { page },
    payload: axios.get(`${getNonGwApiRoot()}/parents/digital_assets${params}`, {
      cancelToken: new CancelToken(c => {
        fetchDigitalAssetCancelToken = c;
      }),
    }),
  };
};

export const selectDigitalAssetParent = selectedId => ({
  type: 'SELECT_DIGITAL_ASSET_PARENT',
  payload: { selectedId },
});

export const setDigitalAssetParent = assets => ({
  type: 'SET_DIGITAL_ASSET_PARENT',
  payload: { assets },
});

export const setDigitalAssetExternal = (setExternal = false) => ({
  type: 'SET_DIGITAL_ASSET_PARENT_EXTERNAL',
  payload: { setExternal },
});

export const selectDigitalAssetParentAndSetAssets = (selectedId, assets) => dispatch => {
  dispatch(selectDigitalAssetParent(selectedId));
  dispatch(setDigitalAssetParent(assets));
  dispatch(setDigitalAssetExternal(true));
};

export const updateDigitalAssetParent = (digitalAssetId, key, value) => {
  const argument = { [key]: value };
  return dispatch => {
    dispatch({
      type: 'UPDATE_DIGITAL_ASSET_PARENT',
      meta: {
        digitalAssetId,
        key,
        value,
      },
      payload: axios.patch(
        `${getNonGwApiRoot()}/parents/digital_assets/${digitalAssetId}`,
        argument
      ),
    }).catch(err => {
      const serverError = err && err.response && err.response.data;
      if (serverError.type === 'asset_name') {
        message.error(digitalAssetConstants.upload.asset_name_existing);
      }
    });
  };
};

export function showParentDigitalAssets(displayDrawer) {
  return {
    type: 'SHOW_PARENT_DIGITAL_ASSETS',
    meta: { display: displayDrawer },
  };
}
