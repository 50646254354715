import { InfoCircleOutlined } from '@ant-design/icons';
import { App, Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UpgradePlan: React.FC = () => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const showInfo = () => {
    modal.info({
      title: t('common:contactForUpgrade'),
      icon: <InfoCircleOutlined />,
      onOk() {},
    });
  };

  return <Button onClick={() => showInfo()}>{t('welcome:upgradePlan')}</Button>;
};

export default UpgradePlan;
