import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import ItemAnalysis from '../../components/body/item_analysis/ItemAnalysis';
import actions from '../../actions/items/analyses';
import { withContainerWrapper } from '../ContainerWrapper';
import {
  filterBySegmentAndDuplicateTypeIds,
  filterBySegment,
} from '../../selectors/item_analysis/itemAnalysisSelector';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import { Analysis as AnalysisResources, AnalysisType } from '../../../types/resources';
import { Item } from '../../../types/item';
import { Analysis } from '../../../types/analyses';

type ItemAnalysisContainerProps = {
  dispatch: AsyncDispatch;
  fetchingResources: boolean;
  fetchingAnalyses: boolean;
  analysisResources: AnalysisResources;
  selectedItems: Item[];
  selectedItemIds: number[];
  selectedApplicationId: number | null;
  activeArea: string;
  analysisBySegmentNoDuplicates: (Analysis & AnalysisType)[];
  analysisBySegment: (Analysis & AnalysisType)[];
} & WithTranslation;

class ItemAnalysisContainer extends React.Component<ItemAnalysisContainerProps> {
  hideAnalysis = (typeId: number) => {
    const { selectedItemIds } = this.props;
    const ids = this.props.analysisBySegment
      .filter(analysis => analysis.type_id === typeId)
      .map(analysis => analysis.items.map(item => item.analysis_ids))
      .reduce((a, b) => a.concat(...b), [])
      .flat();
    // @ts-ignore
    this.props.dispatch(actions.hideAnalysisAndFetch(selectedItemIds, ids, typeId));
  };

  filterApplicationAnalyses = (analyses: (Analysis & AnalysisType)[]) => {
    const { selectedApplicationId } = this.props;
    return analyses.filter(
      analysis => analysis.reference_id === selectedApplicationId || analysis.reference_id === null
    );
  };

  showItemAnalysis = () => {
    if (this.props.selectedItems.length === 0 || this.props.fetchingResources) {
      return <div className="item-analysis-container" />;
    }
    const analyses =
      this.props.activeArea === 'application' && this.props.selectedApplicationId
        ? this.filterApplicationAnalyses(this.props.analysisBySegmentNoDuplicates)
        : this.props.analysisBySegmentNoDuplicates;

    return (
      <ItemAnalysis
        analyses={analyses}
        analysisResources={this.props.analysisResources}
        hideAnalysis={this.hideAnalysis}
      />
    );
  };

  render() {
    const { t, fetchingAnalyses } = this.props;

    return (
      <div className="item-analysis">
        {fetchingAnalyses && (
          <div>
            <span className="mr-2">{t('common:loading')}</span>
            <Spin size="small" />
          </div>
        )}
        {this.showItemAnalysis()}
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  const selectedItems = getSelectedItems(state);
  return {
    fetchingResources: state.resources.fetchingApplicationResources,
    fetchingAnalyses: state.items.analyses.fetchingAnalyses,
    analysisResources: state.resources.analysis,
    selectedItems,
    selectedItemIds: state.catalogue.catalogue.selectedItemIds,
    selectedApplicationId: state.items.application.selectedApplicationId,
    activeArea: state.app.navigationBar.activeArea,
    analysisBySegmentNoDuplicates: filterBySegmentAndDuplicateTypeIds(state),
    analysisBySegment: filterBySegment(state),
  };
}

export { ItemAnalysisContainer };
export default withTranslation()(
  connect(mapStateToProps)(withContainerWrapper(ItemAnalysisContainer))
);
