import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Switch, Form, Select, Input, Tooltip, Row, Col, App } from 'antd';
import * as Yup from 'yup';
import TextArea from 'antd/lib/input/TextArea';
import { FormikValues, FormikHelpers } from 'formik';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { createInterchangeItem } from '../../../actions/items/interchange/create';
import VSelect from '../../global/VSelect';
import {
  fetchRefrenceItems,
  fetchInterchangeSubBrand,
  fetchInformations,
  clearInterchangeSubBrands,
} from '../../../actions/items/interchange/fetch';
import { Informations } from '../../../../types/interchange';
import { deleteInterchangeItem } from '../../../actions/items/interchange/delete';
import { updateInterchangeItem } from '../../../actions/items/interchange/update';
import { AsyncDispatch } from '../../../../types/global';
import SimpleDivider from '../../global/SimpleDivider';
import { intercomEvent } from '../../../utils/IntercomUtils';

const { Option } = Select;

type InterChangeDrawerProps = {
  onClose: () => void;
  interchangeInfo?: Informations;
  edit?: boolean;
};

const InterChangeDrawer: React.FC<InterChangeDrawerProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const [brandOnList, setBrandOnList] = React.useState(!props.interchangeInfo?.custom_brand_label);
  const {
    selectedItem,
    allBrands,
    listUoms,
    selectedBrandId,
    listQuality,
    listTypes,
    // referenceItems,
    listSubBrands,
    // referenceItemsObj,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      selectedBrandId: state.parent.brands.selectedBrandId,
      allBrands: state.resources.data.global.parent_owner_brands.map(brand => ({
        ...brand,
        name: `${brand.code} | ${brand.name}`,
      })),
      listUoms: state.resources.data.global.measurement_uom_codes,
      listQuality: state.resources.data.interchange_information.qualitity_grade_levels,
      interchangeInfos: state.items.interchange.informations,
      listTypes: state.resources.data.interchange_information.types,
      // referenceItems: state.items.interchange.referenceItems.items,
      listSubBrands: state.items.interchange.subBrands,
      // referenceItemsObj: state.items.interchange.referenceItems.referenceItems,
    };
  });
  // const listReferenceItems = referenceItems.map((item: string) => ({ name: item, id: item }));

  React.useEffect(() => {
    dispatch(fetchRefrenceItems(selectedBrandId));
  }, [dispatch, selectedBrandId]);

  React.useEffect(() => {
    const interchangeBrandId = props.interchangeInfo?.brand_id;

    if (interchangeBrandId) dispatch(fetchInterchangeSubBrand(interchangeBrandId));
    else dispatch(clearInterchangeSubBrands());
  }, [dispatch, props.interchangeInfo?.brand_id]);

  const getValidationSchema = () => {
    return Yup.object().shape(
      {
        external_brand_id: Yup.number()
          .nullable()
          .when('custom_brand_label', {
            is: (val: string) => !val,
            then: schema => schema.nullable().required(t('validation:required')),
          }),
        custom_brand_label: Yup.string().when('external_brand_id', {
          is: (val: any) => !val,
          then: schema => schema.required(t('validation:required')),
        }),
        part_number: Yup.string().required(t('validation:required')),
        notes: Yup.string().max(240, t('interchange:maxLength')),
        internal_notes: Yup.string().max(240, t('interchange:maxLength')),
      },
      [['external_brand_id', 'custom_brand_label']]
    );
  };

  const handleSubmit = async (values: FormikValues, formikActions: FormikHelpers<any>) => {
    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'interchange',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    const { setSubmitting } = formikActions;
    setSubmitting(true);
    if (props.interchangeInfo) {
      return dispatch(
        updateInterchangeItem(props.interchangeInfo.id, values, {
          internal_notes: values.internal_notes,
          notes: values.notes,
        })
      ).then(() => {
        setSubmitting(false);
        dispatch(fetchInformations(selectedItem.id));
        props.onClose();
      });
    }
    return dispatch(
      createInterchangeItem(values, {
        internal_notes: values.internal_notes,
        notes: values.notes,
      })
    ).then(() => {
      dispatch(fetchInformations(selectedItem.id));
      setSubmitting(false);
      props.onClose();
    });
  };

  const handleDelete = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-deleted',
      location: 'deleted_interchange',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });

    if (props.interchangeInfo) {
      modal.confirm({
        title: t('interchange:deleteInterchange'),
        icon: <ExclamationCircleOutlined />,
        okText: t('common:delete'),
        cancelText: t('common:cancel'),
        okButtonProps: { danger: true },
        onOk() {
          return dispatch(deleteInterchangeItem(props.interchangeInfo!.id)).then(() =>
            props.onClose()
          );
        },
      });
    }
  };

  return (
    <DrawerFormik
      title={t('interchange:interchange')}
      visible
      onClose={() => {
        dispatch(fetchInformations(selectedItem.id));
        props.onClose();
      }}
      onDelete={props.edit ? () => handleDelete() : undefined}
      initialValues={{
        // snake case name because values are passed directly to the backend
        item_part_interchange_id: props.interchangeInfo?.id || undefined,
        item_id: selectedItem.id,
        quantity:
          props.interchangeInfo?.quantity ||
          (props.interchangeInfo?.quantity === 0 && props.interchangeInfo.quantity.toString()) ||
          '',
        quantity_uom_id: (props.interchangeInfo && props.interchangeInfo.quantity_uom_id) || null,
        item_equivalent_uom_id:
          (props.interchangeInfo && props.interchangeInfo.item_equivalent_uom_id) ||
          (!props.edit && selectedItem.quantity_size_uom_id) ||
          null,
        item_part_number: selectedItem.part_number || null,
        quality_grade_level_id:
          (props.interchangeInfo && props.interchangeInfo.quality_grade_level_id) || null,
        external_brand_id: (props.interchangeInfo && props.interchangeInfo.brand_id) || null,
        part_number: (props.interchangeInfo && props.interchangeInfo.part_number) || '',
        vmrs_brand_code: (props.interchangeInfo && props.interchangeInfo.vmrs_brand_code) || '',
        type_id: (props.interchangeInfo && props.interchangeInfo.type_id) || null,
        // reference_item: (props.interchangeInfo && props.interchangeInfo.reference_item) || null,
        external_sub_brand_id:
          (props.interchangeInfo && props.interchangeInfo.sub_brand_id) || null,
        internal_notes: (props.interchangeInfo && props.interchangeInfo.internal_notes) || '',
        notes: (props.interchangeInfo && props.interchangeInfo.notes) || '',
        custom_brand_label:
          (props.interchangeInfo && props.interchangeInfo.custom_brand_label) || '',
      }}
      validationSchema={getValidationSchema()}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ values, setFieldValue, setFieldTouched, errors, touched }) => {
        // const referenceItem = referenceItemsObj[values.reference_item];
        // const yourProducts =
        //   referenceItem &&
        //   referenceItem.item_part_numbers.map((partNumber: string) => ({
        //     key: partNumber,
        //     value: partNumber,
        //   }));
        // const interchangeProducts =
        //   referenceItem &&
        //   referenceItem.interchange_parts.map((brand: any) => ({
        //     key: `${brand.part_number}|${brand.brand}`,
        //     value: `${brand.part_number} | ${brand.brand}`,
        //   }));

        return (
          <Form layout="vertical" className="interchange__edit-form">
            <SimpleDivider
              title={t('interchange:yourProduct')}
              className="interchange__form-no-spacing"
            />
            <Row gutter={16}>
              <Col span={12}>
                <FormInput label={t('interchange:partNumber')} name="item_part_number" disabled />
              </Col>
              <Col span={12}>
                <FormSelect
                  label={
                    <span>
                      {t('interchange:equivalentUom')}
                      <span className="text-gray-600 whitespace-nowrap">
                        {selectedItem.quantity_size_uom_id &&
                          !props.interchangeInfo &&
                          ` (${t('interchange:defaultUomFound')})`}
                      </span>
                    </span>
                  }
                  name="item_equivalent_uom_id"
                  showSearch
                  values={listUoms}
                  placeholder={t('interchange:selectUOM')}
                  allowClear
                />
              </Col>
            </Row>

            <SimpleDivider
              title={t('interchange:interchangingProduct')}
              className="interchange__form-big-spacing"
            />

            <Row gutter={16}>
              <Col span={12}>
                <FormInput
                  label={t('interchange:partNumber')}
                  name="part_number"
                  placeholder={t('interchange:partNumber')}
                  required
                />
              </Col>
              <Col span={12}>
                <Row className="interchange_input_quantity">
                  <Col span={12}>
                    <Form.Item label={t('interchange:quantity')}>
                      <Input
                        data-cy="quantity"
                        value={values.quantity}
                        placeholder={t('interchange:quantity')}
                        onChange={e => {
                          const qty = e.target.value;
                          const reg = /^[0-9]+$|^$/;
                          if (reg.test(qty)) setFieldValue('quantity', qty);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t('interchange:uom')}>
                      <Select
                        className="interchange_select_uom"
                        showSearch
                        value={values.quantity_uom_id || undefined}
                        placeholder={t('interchange:selectUOM')}
                        onChange={(uom: number) => setFieldValue('quantity_uom_id', uom || null)}
                        optionFilterProp="label"
                        allowClear
                        options={listUoms.map(uom => ({
                          key: uom.id,
                          value: uom.id,
                          label: uom.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <div className="inline-flex w-full">
                      <div>{t('interchange:brand')}</div>
                      <div className="flex-1 flex justify-end">
                        <label className="pr-2">{t('interchange:brandNotonList')}</label>
                        <Switch
                          size="small"
                          defaultChecked={!!values.custom_brand_label}
                          onChange={() => {
                            if (values.custom_brand_label) setFieldValue('custom_brand_label', '');
                            setBrandOnList(!brandOnList);
                          }}
                        />
                      </div>
                    </div>
                  }
                  hasFeedback
                  validateStatus={
                    (errors.external_brand_id && touched.external_brand_id) ||
                    (errors.custom_brand_label && touched.custom_brand_label)
                      ? 'error'
                      : undefined
                  }
                  help={
                    (touched.external_brand_id && errors.external_brand_id) ||
                    (touched.custom_brand_label && errors.custom_brand_label) ||
                    undefined
                  }
                  required
                >
                  <React.Fragment>
                    {brandOnList && (
                      <VSelect
                        data={allBrands}
                        value={values.external_brand_id}
                        onChange={id => {
                          setFieldValue('external_brand_id', id);
                          setFieldValue('external_sub_brand_id', null);
                          setFieldValue('custom_brand_label', '');
                          if (id) dispatch(fetchInterchangeSubBrand(id));
                        }}
                        onBlur={() => setFieldTouched('external_brand_id')}
                        placeholder={t('interchange:selectBrand')}
                      />
                    )}
                    {!brandOnList && (
                      <Input
                        maxLength={60}
                        value={values.custom_brand_label || undefined}
                        placeholder={t('interchange:customBrand')}
                        onChange={e => {
                          setFieldValue('custom_brand_label', e.target.value);
                          setFieldValue('external_brand_id', null);
                          setFieldValue('external_sub_brand_id', null);
                        }}
                        onBlur={() => setFieldTouched('custom_brand_label')}
                      />
                    )}
                  </React.Fragment>
                </Form.Item>
              </Col>
              <Col span={12}>
                {brandOnList && (
                  <FormSelect
                    label={t('interchange:subBrand')}
                    name="external_sub_brand_id"
                    values={(listSubBrands && listSubBrands.length > 0 && listSubBrands) || []}
                    placeholder={t('interchange:subBrand')}
                    allowClear
                  />
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormInput
                  label={t('interchange:vmrsBrand')}
                  name="vmrs_brand_code"
                  placeholder={t('interchange:vmrsBrand')}
                  maxLength={5}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormSelect
                  label={
                    <span>
                      {t('interchange:quality')}
                      <Tooltip title={t('interchange:qualityInfoText')} className="ml-1">
                        <InfoCircleOutlined className="ant-info-tooltip" />
                      </Tooltip>
                    </span>
                  }
                  name="quality_grade_level_id"
                  values={listQuality}
                  placeholder={t('interchange:quality')}
                  allowClear
                />
              </Col>
              <Col span={12}>
                <FormSelect
                  label={
                    <span>
                      {t('interchange:type')}
                      <Tooltip
                        title={
                          <div>
                            <div>{t('interchange:typeInfoText')}</div>
                            <div>{t('interchange:typeInfoTextO')}</div>
                            <div>{t('interchange:typeInfoTextS')}</div>
                            <div>{t('interchange:typeInfoTextU')}</div>
                          </div>
                        }
                        className="ml-1"
                      >
                        <InfoCircleOutlined className="ant-info-tooltip" />
                      </Tooltip>
                    </span>
                  }
                  name="type_id"
                  values={listTypes}
                  placeholder={t('interchange:type')}
                  allowClear
                />
              </Col>
            </Row>

            <SimpleDivider
              title={t('interchange:additionalInformation')}
              className="interchange__form-big-spacing"
            />

            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      {t('interchange:referenceItem')}
                      <Tooltip title={t('interchange:referenceItemInfoText')} className="ml-2">
                        <Icon className="ant-info-tooltip" type="info-circle" />
                      </Tooltip>
                    </span>
                  }
                >
                  <Select
                    showSearch
                    mode="tags"
                    value={values.reference_item || undefined}
                    placeholder={t('interchange:referenceItem')}
                    onChange={(values: any[]) => {
                      const value = values.length ? values[values.length - 1] : null;
                      setFieldValue('reference_item', value);
                    }}
                    optionFilterProp="label"
                    allowClear
                    options: {listReferenceItems.map((refItems: any) => ({
                      key: refItems.id,
                      value: refItems.id,
                      label: refItems.name
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('interchange:multiYourProduct')}>
                  <List
                    bordered
                    itemLayout="horizontal"
                    dataSource={yourProducts || []}
                    renderItem={(item: any) => <List.Item key={item.key}>{item.value}</List.Item>}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('interchange:multiInterchangeProducts')}>
                  <List
                    bordered
                    itemLayout="horizontal"
                    dataSource={interchangeProducts || []}
                    renderItem={(item: any) => <List.Item key={item.key}>{item.value}</List.Item>}
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item
              label={t('interchange:publicNote')}
              validateStatus={errors.notes && touched.notes ? 'error' : undefined}
              help={(touched.notes && errors.notes) || undefined}
            >
              <TextArea
                data-cy="publicNote"
                placeholder={t('interchange:publicNote')}
                allowClear
                value={values.notes || undefined}
                onChange={e => setFieldValue('notes', e.target.value)}
                showCount
                maxLength={240}
              />
            </Form.Item>
            <Form.Item
              label={t('interchange:internalNote')}
              validateStatus={errors.internal_notes && touched.internal_notes ? 'error' : undefined}
              help={(touched.internal_notes && errors.internal_notes) || undefined}
            >
              <TextArea
                data-cy="internalNote"
                placeholder={t('interchange:internalNote')}
                value={values.internal_notes || undefined}
                onChange={e => setFieldValue('internal_notes', e.target.value)}
                maxLength={240}
                showCount
                allowClear
              />
            </Form.Item>
          </Form>
        );
      }}
    </DrawerFormik>
  );
};

export default InterChangeDrawer;
