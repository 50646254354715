import { Button, StepProps, Steps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuickStartStep, QuickStartGuide } from '../../../../types/home';
import StartGuideContent from './StartGuideContent';
import StartGuideProgressBar from './StartGuideProgressBar';

type QuickStartStepsProps = {
  guideSteps: QuickStartGuide;
  handleSkipStep: (stepId: number) => void;
};

const QuickStartSteps: React.FC<QuickStartStepsProps> = props => {
  const { t } = useTranslation();

  const currentStep = props.guideSteps.quick_start_steps.find(
    s => s.id === props.guideSteps.current_step
  );

  const [selectedStepId, setSelectedStepId] = React.useState<number>(
    currentStep?.stepId ? currentStep?.stepId : 0
  );

  const handleStep = (currentStep: number) => {
    setSelectedStepId(currentStep);
  };

  const getStatus = (step: number) => {
    let status: StepProps['status'] = 'process';
    if (currentStep?.stepId && step < currentStep?.stepId) status = 'finish';
    else if (
      currentStep?.stepId &&
      currentStep?.stepId === props.guideSteps.quick_start_steps?.length
    )
      status = 'finish';

    return status;
  };

  const renderDescription = (step: QuickStartStep) => {
    return (
      <React.Fragment>
        <div className="flex flex-row items-start justify-between">
          <div className="welcome__step-sub-title">{step.sub_title}</div>
          {selectedStepId === step.stepId &&
            selectedStepId !== 0 &&
            selectedStepId !== props.guideSteps.quick_start_steps.length - 1 &&
            currentStep!.stepId! === selectedStepId && (
              <Button
                type="text"
                onClick={() => {
                  handleStep(step.stepId! + 1);
                  props.handleSkipStep(step.id);
                }}
                className="skip-step"
                data-testid={step.id}
              >
                <span className="leading-5 font-medium">{t('welcome:nextStep')}</span>
              </Button>
            )}
        </div>

        {selectedStepId === step.stepId && (
          <StartGuideContent
            stepDetails={step}
            disableNavigationButton={currentStep!.stepId! < selectedStepId}
          />
        )}
      </React.Fragment>
    );
  };

  const steps =
    props.guideSteps?.quick_start_steps?.map(step => {
      return {
        ...step,
        description: renderDescription(step),
      };
    }) || [];

  const items = steps.map(item => ({
    key: item.id,
    title: <h5 className="welcome__steps-header-title">{item.title}</h5>,
    description: item.description,
    status: getStatus(item.stepId!),
  }));

  return (
    <div className="welcome__quick-start-guide">
      <h5 className="welcome__steps-header-title pt-6 pl-6 pb-6 m-0">
        {t('welcome:quickStartGuide')}
      </h5>
      <StartGuideProgressBar progress={props.guideSteps.total_process} />
      <Steps
        current={selectedStepId}
        onChange={handleStep}
        direction="vertical"
        style={{ marginTop: '34px' }}
        items={items}
        className="welcome__steps-items"
      />
    </div>
  );
};

export default QuickStartSteps;
