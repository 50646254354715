import { combineReducers } from 'redux';

import marketingCopy, { MarketingCopyState } from './MarketingCopyReducer';
import digitalAsset from './DigitalAssetParentReducer';
import digitalAssetUpload from './DigitalAssetUploadReducer';
import priceSheets, { PriceSheetsState } from './PriceSheetsReducer';
import brands, { BrandState } from './BrandsReducer';
import attributeManagement, { AttributeManagementState } from './AttributeManagementReducer';
import languageSettings, { LanguageSettingsState } from './LanguageSettingsReducer';
import languageTranslation, { LanguageTranlationState } from './TranslationReducer';
import parentImportLogs from './ParentImportLogsReducer';
import parentExportLogs from './ParentExportLogsReducer';
import parentLogs from './ParentLogsReducer';
import accountDefaultValues, { AccountDefaultValuesState } from './AccountDefaultValuesReducer';

export type ParentState = {
  marketingCopy: MarketingCopyState;
  digitalAsset: any;
  digitalAssetUpload: any;
  priceSheets: PriceSheetsState;
  brands: BrandState;
  attributeManagement: AttributeManagementState;
  languageSettings: LanguageSettingsState;
  languageTranslation: LanguageTranlationState;
  parentImportLogs: any;
  parentExportLogs: any;
  parentLogs: any;
  accountDefaultValues: AccountDefaultValuesState;
};

const ParentReducer = combineReducers({
  marketingCopy,
  digitalAsset,
  digitalAssetUpload,
  priceSheets,
  brands,
  attributeManagement,
  languageSettings,
  languageTranslation,
  parentImportLogs,
  parentExportLogs,
  parentLogs,
  accountDefaultValues,
});

export default ParentReducer;
