import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Popover, Tag } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { uniqBy as _uniqBy } from 'lodash';
import constants from '../../../constants/ApplicationTranslation.json';
import {
  ApplicationCategory,
  ConfigValue,
  StructuredApplication,
  VehicleBases,
  ApplicationDigitalAsset,
  EquipmentBases,
  Qualifier,
  ApplicationNote,
  ApplicationQualifier,
} from '../../../../types/application';
import { Analysis } from '../../../../types/analyses';
import { ItemCategory } from '../../../../types/item';
import { ApplicationState } from '../../../reducers';
import ItemAnalysis from '../item_analysis/ItemAnalysis';
import ApplicationCardConfig from './ApplicationCardConfig';
import ApplicationCardQualifier from './ApplicationCardQualifier';
import { createYearRanges, stringifyYearRanges } from '../../../utils/ApplicationUtils';
import { Analysis as AnalysisResources } from '../../../../types/resources';
import ImageThumbnail from '../../global/ImageThumbnail';
import noImage from '../../../../images/icons/no-image.svg';

type ApplicationCardProps = {
  application: StructuredApplication;
  defaultQty: number | null;
  defaultMfr: string | null;
  defaultPosition?: ConfigValue | null;
  defaultPartType?: ItemCategory;
  parentDefaultPosition?: ConfigValue | null;
  analyses: Analysis[];
  analysisResources: AnalysisResources;
  index?: number;
  noButtons?: boolean;
  inheritedApplication?: boolean;
  defaultQualifiers: Qualifier[];
  defaultNotes: ApplicationNote[];
  deleteApplication: (id: number) => void;
  selectConfig: (id: number, config: string, defaultValue?: boolean) => void;
};

class ApplicationCard extends React.Component<ApplicationCardProps> {
  selectConfig = (config: string, defaultValue?: boolean) => {
    if (this.props.noButtons) return;
    const selectedConfig =
      config.includes('engine') || config.includes('others') ? 'engines' : config;
    this.props.selectConfig(
      this.props.application.id,
      selectedConfig,
      !this.props.inheritedApplication && defaultValue
    );
  };

  checkDefault = (key: string) => {
    const { application, defaultPosition, inheritedApplication } = this.props;
    const defaultKeys = ['mfr_label', 'positions'];
    if (!defaultKeys.includes(key)) return false;
    if (key === 'mfr_label' && !application.mfr_label) return true;
    if (key === 'positions' && inheritedApplication) {
      if (defaultPosition) return true;
      return false;
    }
    if (key === 'positions' && !application.positions) return true;
  };

  showAnalysis = () => {
    const { analyses } = this.props;
    if (analyses.length > 0) {
      return (
        <div className="application__analysis-container">
          <ItemAnalysis
            analyses={_uniqBy(analyses, 'type_id')}
            analysisResources={this.props.analysisResources}
            noCloseIcon
          />
        </div>
      );
    }
  };

  listYears = (years: number[]) => {
    const yearRanges = createYearRanges(years);
    return yearRanges.length ? stringifyYearRanges(yearRanges) : '';
  };

  engineConfigs = (configs: { [x: string]: { [x: string]: ConfigValue[] } }[]) => {
    const row = configs.map(configObj => {
      const subConfigs: any[] = [];
      Object.values(configObj).forEach(subConfigObj => {
        Object.keys(subConfigObj).forEach(subConfig => {
          if (Object.keys(subConfigObj[subConfig]).length > 0) {
            subConfigs.push({ key: [subConfig], values: subConfigObj[subConfig] });
          }
        });
      });
      if (subConfigs.length > 0) {
        const key = Object.keys(configObj)[0];

        return (
          <ApplicationCardConfig
            key={key}
            configs={subConfigs}
            configKey={key}
            selectConfig={this.selectConfig}
            noEdit={this.props.noButtons}
          />
        );
      }
    });
    return row;
  };

  headerRow = (
    vehicleBases: VehicleBases,
    equipment: EquipmentBases,
    assets?: ApplicationDigitalAsset[],
    qty?: number
  ) => {
    const { defaultQty } = this.props;

    const equipmentApplication = Object.keys(equipment).find(key =>
      ['mfrs', 'equipment_models'].includes(key)
    );

    const makes = vehicleBases.makes ? vehicleBases.makes.map(make => make.name) : [];
    const models = vehicleBases.models ? vehicleBases.models.map(model => model.name) : [];
    const asset = assets && assets[0];

    // if only one model is selected extract the year ranges
    const years = this.listYears(vehicleBases.years || []);
    const submodels =
      models.length === 1 &&
      vehicleBases.sub_models &&
      vehicleBases.sub_models.map(submodel => submodel.name).join('/');
    const regions =
      models.length === 1 &&
      vehicleBases.regions &&
      vehicleBases.regions.map(region => region.name).join('/');

    const mfrs = equipment.mfrs ? equipment.mfrs.map(mfr => mfr.name) : [];
    const equipmentModels = equipment.equipment_models
      ? equipment.equipment_models.map(model => model.name)
      : [];
    const vehicleTypes = equipment.vehicle_types
      ? equipment.vehicle_types.map(type => type.name)
      : [];
    const equipmentYears = equipment.years && this.listYears(equipment.years || []);
    const equipmentRegions = equipment.regions ? equipment.regions.map(region => region.name) : [];

    return (
      <div className="application__card-title flex">
        {asset?.file_preview_url && (
          <div className="mr-2 cursor-default">
            <Popover
              content={
                <img
                  src={asset?.file_preview_url || noImage}
                  alt=""
                  className="application__asset-preview-image"
                />
              }
            >
              <div>
                <ImageThumbnail
                  thumbnail={asset?.file_thumbnail_url || ''}
                  height={35}
                  width={35}
                />
              </div>
            </Popover>
          </div>
        )}
        <div className="application__overview-title-container">
          <span
            className="application__overview-title"
            onClick={() => this.selectConfig('vehicle_bases')}
          >
            {equipmentApplication &&
              `${equipmentYears || ''} ${mfrs.join('/')} ${equipmentModels.join('/')} ${
                vehicleTypes || ''
              } ${equipmentRegions || ''}`}
            {!equipmentApplication &&
              `${years || ''} ${makes.join('/')} ${models.join('/')} ${submodels || ''} ${
                regions || ''
              }`}
            {!equipmentApplication &&
              makes.length === 0 &&
              models.length === 0 &&
              years.length === 0 &&
              constants.select_make}
          </span>
          <div>
            {this.props.index !== undefined && (
              <div className="application__card_index text-right">
                <Tag>{`ID: ${this.props.index + 1}`}</Tag>
              </div>
            )}

            {(qty || qty === 0 || defaultQty) && (
              <div
                className="application__card_qty"
                onClick={() => this.selectConfig('category', !qty && qty !== 0 && !!defaultQty)}
              >
                {constants.qty}
                {!qty && qty !== 0 && defaultQty && (
                  <span className="application__card_label-default">
                    {` (${constants.default_prefix})`}
                  </span>
                )}
                <span>: </span>
                <span className="application__card_qty-value">
                  {qty || qty === 0 ? qty : defaultQty}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  partTypeRow = (partType?: ApplicationCategory) => {
    const { defaultPartType } = this.props;
    const level1_name = partType?.level1_name || defaultPartType?.level1_name || '';
    const level2_name = partType?.level2_name || defaultPartType?.level2_name || '';
    const level3_name = partType?.level3_name || defaultPartType?.level3_name || '';

    const categoryName = level3_name
      ? `${level1_name} | ${level2_name} | ${level3_name}`
      : constants.no_part_type;

    return (
      <ApplicationCardConfig
        configs={[{ value: categoryName }]}
        configKey="category"
        selectConfig={this.selectConfig}
        noEdit={this.props.noButtons}
        defaultValue={!partType}
        disableSelection={!partType}
      />
    );
  };

  applicationBlocks = (application: StructuredApplication) => {
    const {
      defaultQty,
      defaultMfr,
      defaultPosition,
      inheritedApplication,
      parentDefaultPosition,
      defaultQualifiers,
      defaultNotes,
    } = this.props;
    if (!application.qty && application.qty !== 0)
      application = { ...application, qty: defaultQty };
    if (!application.mfr_label && defaultMfr)
      application = { ...application, mfr_label: defaultMfr };
    if (!application.qualifiers && defaultQualifiers?.length > 0)
      application = { ...application, qualifiers: [] };
    if (!application.notes && defaultNotes.length > 0) application = { ...application, notes: [] };
    if (!inheritedApplication) {
      if (!application.positions && defaultPosition)
        application = { ...application, positions: [defaultPosition] };
    } else if (defaultPosition) {
      // default position overrides inherited application position
      application = { ...application, positions: [defaultPosition] };
    } else if (!application.positions && parentDefaultPosition)
      application = { ...application, positions: [parentDefaultPosition] };

    return Object.keys(application)
      .filter(key => !['vehicle_bases', 'equipments', 'id', 'qty', 'category'].includes(key))
      .map(key => {
        const engineConfigs: { [key: string]: { [x: string]: ConfigValue[] } }[] = [];
        const subConfigs = [];

        if (key === 'mfr_label' || key === 'qty') {
          subConfigs.push({ value: application[key] });
        } else if (Array.isArray(application[key])) {
          subConfigs.push({ values: application[key] });
        } else {
          Object.keys(application[key]).map(type => {
            if (key === 'engines') {
              engineConfigs.push({ [type]: application[key]![type] });
            } else if (Object.keys(application[key][type]).length > 0) {
              subConfigs.push({ key: type, values: application[key][type] });
            }
          });
        }

        if (subConfigs.length > 0) {
          if (key === 'qualifiers' && application.qualifiers) {
            return (
              <React.Fragment key={key}>
                {application.qualifiers.length > 0 && (
                  <ApplicationCardQualifier
                    qualifiers={application.qualifiers}
                    configKey={key}
                    selectConfig={this.selectConfig}
                    noEdit={this.props.noButtons}
                  />
                )}
                {defaultQualifiers?.length > 0 && (
                  <ApplicationCardQualifier
                    qualifiers={defaultQualifiers as ApplicationQualifier[]}
                    configKey={key}
                    selectConfig={this.selectConfig}
                    noEdit={this.props.noButtons}
                    defaultValue
                  />
                )}
              </React.Fragment>
            );
          }
          if (key === 'notes' && application.notes) {
            return (
              <React.Fragment key={key}>
                {application.notes.length > 0 && (
                  <ApplicationCardConfig
                    configs={subConfigs}
                    configKey={key}
                    selectConfig={this.selectConfig}
                    noEdit={this.props.noButtons}
                  />
                )}
                {defaultNotes?.length > 0 && (
                  <ApplicationCardConfig
                    configs={[{ values: defaultNotes }]}
                    configKey={key}
                    selectConfig={this.selectConfig}
                    defaultValue
                    noEdit={this.props.noButtons}
                  />
                )}
              </React.Fragment>
            );
          }

          return (
            <ApplicationCardConfig
              key={key}
              configs={subConfigs}
              configKey={key}
              selectConfig={this.selectConfig}
              defaultValue={this.checkDefault(key)}
              noEdit={this.props.noButtons}
            />
          );
        }
        if (engineConfigs.length > 0) {
          return this.engineConfigs(engineConfigs);
        }
      });
  };

  render() {
    const { application, noButtons } = this.props;

    return (
      <div key={application.id} className="application__card">
        <Card>
          {this.showAnalysis()}
          {this.headerRow(
            application.vehicle_bases || {},
            application.equipments || {},
            application.digital_assets,
            application.qty
          )}
          {this.partTypeRow(application.category)}
          <div className="application__card_configs">{this.applicationBlocks(application)}</div>
          {!noButtons && (
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => this.props.selectConfig(application.id, 'vehicle_bases')}
                  ghost
                  block
                >
                  {constants.edit_button}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => this.props.deleteApplication(application.id)}
                  ghost
                  block
                  danger
                >
                  {constants.delete_button}
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    analysisResources: state.resources.analysis,
  };
};

export { ApplicationCard };

export default connect(mapStateToProps)(ApplicationCard);
