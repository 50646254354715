import axios from 'axios';
import * as utils from '../../utils/Utils';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import { FTPTestSettings } from '../../../types/channel';

const removeFailedPending = (logId: number) => ({
  type: 'REMOVE_FAILED_PENDING',
  payload: logId,
});

const removeDownloadRejected = (brandId: number) => ({
  type: 'REMOVE_DOWNLOAD_REJECTED',
  payload: brandId,
});

export const downloadChannelExportFile = (
  brandId: number,
  exportLogId: number,
  exportFile: any,
  fileName: string
) => ({
  type: 'DOWNLOAD_CHANNEL_EXPORT_FILE',
  payload: utils.downloadArrayBuffer(exportFile, fileName),
  meta: { logId: exportLogId, brandId },
});

export const testFTPConnection = (ftpSettings: FTPTestSettings) => {
  return {
    type: 'TEST_FTP_CONNECTION',
    payload: axios.post(`${getApiRoot()}/channels/ftp_settings/test_connection`, {
      ftp_settings: ftpSettings,
    }),
  };
};

export default {
  removeFailedPending,
  removeDownloadRejected,
  downloadChannelExportFile,
};
