import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ColDef,
  GridReadyEvent,
  SetFilterValuesFuncParams,
  IServerSideDatasource,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AutoResizer } from 'react-base-table';
import { useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationElement } from '../../../../types/all_application_validation';
import { Vehicle } from '../../../../types/resources';
import resourcesMap from '../../../constants/ApplicationResourcesMapping.json';
import { ApplicationState } from '../../../reducers';
import {
  mapApplicationResourcesGrid,
  mapApplicationResourcesGridGroupData,
} from '../../../selectors/all_applications/allApplicationsSelector';
import { ApplicationGroup } from '../../../../types/application';
import AntTooltip from '../../global/AntTooltip';

const resourcesMapping = resourcesMap as { [key: string]: string };

type AGTableGridListProps = {
  applicationRows: ApplicationElement[];
  mappedApplicationRows: ApplicationGroup[];
  columns: Array<{ [key: string]: string[] } | string>;
  handleApplicationSelect: (id: number) => void;
  handleRowSelect: (ids: string[]) => void;
  deleteApplication: (id: number) => Promise<any>;
  fetchNextListings: (page: number) => Promise<any>;
  fetchApplicationGroupData: (applicationId: number, page: number) => Promise<any>;
};

const AGTableGridList: React.FC<AGTableGridListProps> = props => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const {
    applicationRows,
    mappedApplicationRows,
    handleApplicationSelect,
    handleRowSelect,
    deleteApplication,
    fetchNextListings,
    fetchApplicationGroupData,
  } = props;
  const gridRef = useRef<AgGridReact>(null);

  const allSubconfigValues = (configName: string) => {
    // get all values to a specific config name e.g. position
    // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
    if (resourcesMapping.hasOwnProperty(configName)) {
      configName = resourcesMapping[configName];
    }

    return vehicleResources && vehicleResources[configName] ? vehicleResources[configName] : [];
  };

  const { vehicleResources } = useSelector((state: ApplicationState) => {
    return {
      vehicleResources: state.resources.applicationVehicle,
    };
  });

  const valueGetter = (params: ValueGetterParams) => {
    const field = params.colDef.field || '';
    if (field === 'category') {
      return params.data?.category?.part_type;
    }
    if (field === 'notes') {
      return params.data?.notes?.map((n: any) => n.note).join('\r\n');
    }
    if (field === 'qualifiers') {
      return params.data.qualifiers_readable?.map((q: any) => q.text).join('\r\n');
    }
    return '';
  };

  const [tableInitialized, setTableInitialized] = useState(false);
  const [columnDefs, setColumnDef] = useState<ColDef[]>(
    [
      'application_id',
      ...props.columns.map(c => (typeof c === 'string' ? c : Object.values(c))).flat(2),
    ]
      .filter(c => c !== 'part_number')
      .map(c => {
        if (c === 'year') c = 'years';
        const pinnedCols = [
          'makes',
          'models',
          'years',
          'sub_models',
          'mfrs',
          'equipment_models',
          'years',
          'regions',
        ];

        return {
          headerName: t(`applicationConfigs:${c}`),
          field: c,
          pinned: pinnedCols.includes(c) ? 'left' : undefined,
          rowGroup: c === 'application_id',
          hide: c === 'application_id',
          suppressColumnsToolPanel: c === 'application_id',
          suppressFiltersToolPanel: c === 'application_id',
          width: c === 'years' ? 85 : undefined,
          filterParams: {
            defaultToNothingSelected: true,
            values: (params: SetFilterValuesFuncParams) => {
              const values = allSubconfigValues(params.colDef.field || '');

              setTimeout(() => {
                params.success(values.map((v: { name: any }) => v.name));
              }, 30);
            },
            refreshValuesOnOpen: true,
          },
          valueGetter:
            c === 'category' || c === 'notes' || c === 'qualifiers' ? valueGetter : undefined,
        };
      })
  );

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 180,
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: false,
      resizable: true,
      editable: false,
      filter: false,
      floatingFilter: false,
      enableRowGroup: true,
      headerCheckboxSelectionFilteredOnly: true,
      minWidth: 80,
      width: 140,
    }),
    []
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const selectedRowsServer = gridRef.current!.api.getServerSideSelectionState() || [];

    const selectedApplicationIds = [...new Set(selectedRows.map(r => r.application_id))].filter(
      Boolean
    );
    handleRowSelect(selectedApplicationIds);
  }, [handleRowSelect]);

  const deleteApplicationConfirm = useCallback(
    (id: number) => {
      modal.confirm({
        title: t('application:deleteMessage'),
        okText: t('common:delete'),
        cancelText: t('common:cancel'),
        okButtonProps: { danger: true },
        icon: <ExclamationCircleOutlined />,
        async onOk() {
          const deleteResult = await deleteApplication(id);
          gridRef.current!.api.refreshServerSide({ purge: true });
          return deleteResult;
        },
      });
    },
    [deleteApplication, modal, t]
  );

  const groupRowRenderer = useCallback(
    (params: ICellRendererParams) => {
      const dataRow = mappedApplicationRows.find(
        r => r.application_id === params.data.application_id
      );

      return (
        <div className="application__group-title-analyses">
          {dataRow?.analyses.length > 0 && (
            <AntTooltip
              title={
                <div>
                  {dataRow?.analyses.map((analysis: { id: number; name: string }) => (
                    <div key={analysis.id}>{analysis.name}</div>
                  ))}
                </div>
              }
            >
              <WarningOutlined className="application_listing__warning-icon mr-2" />
            </AntTooltip>
          )}

          <span className="application__group-title">{dataRow?.grid_application_summary}</span>
          {!params.data.linked_application && (
            <div>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleApplicationSelect(params.data.application_id)}
              >
                {t('common:edit')}
              </Button>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => {
                  deleteApplicationConfirm(params.data.application_id);
                }}
              >
                {t('common:remove')}
              </Button>
            </div>
          )}
          {params.data.linked_application && (
            <div className="text-gray-600 italic">{t('application:inheritedApplication')}</div>
          )}
        </div>
      );
    },
    [deleteApplicationConfirm, handleApplicationSelect, mappedApplicationRows, t]
  );

  const groupRowRendererParams = useMemo(() => {
    return {
      innerRenderer: groupRowRenderer,
      // suppressCount: true,
      checkbox: true,
    };
  }, [groupRowRenderer]);

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
        'filters',
      ],
      defaultToolPanel: '',
    }),
    []
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: params => {
          const page = (params.request.endRow || 100) / 100;

          if (params.request.groupKeys.length > 0) {
            // fetch extended group application list
            const applicationId = params.request.groupKeys[0];

            fetchApplicationGroupData(Number(applicationId), page).then(response => {
              const groupRows = response.value.data.applications;

              const mapped = mapApplicationResourcesGridGroupData(
                groupRows,
                vehicleResources as Vehicle
              );

              params.success({ rowData: mapped });
            });
          } else if (page === 1 && !params.context.tableInitialized) {
            // fetch application groups
            setTableInitialized(true);
            params.success({ rowData: mappedApplicationRows });
          } else {
            fetchNextListings(page).then(response => {
              const applications = response.value.data.application_group;
              const analysis = response.value.data.analysis;

              const mappedApplications = mapApplicationResourcesGrid(
                applications,
                vehicleResources as Vehicle,
                analysis
              );

              let lastRow = -1;
              if (applicationRows.length < 100)
                lastRow = applicationRows.length + mappedApplications.length;

              params.success({ rowData: mappedApplications });
            });
          }
        },
      };
      params.api.setServerSideDatasource(dataSource);
    },
    [
      applicationRows.length,
      fetchApplicationGroupData,
      fetchNextListings,
      mappedApplicationRows,
      vehicleResources,
    ]
  );

  return (
    <AutoResizer>
      {resProps => (
        <div style={{ height: resProps.height - 20, width: resProps.width }}>
          <div className="ag-theme-alpine h-full">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowSelection="multiple"
              rowModelType="serverSide"
              groupDisplayType="groupRows"
              autoGroupColumnDef={autoGroupColumnDef}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              showOpenedGroup
              groupRowRendererParams={groupRowRendererParams}
              onGridReady={onGridReady}
              cacheBlockSize={100}
              maxConcurrentDatasourceRequests={1}
              infiniteInitialRowCount={1}
              suppressRowClickSelection
              suppressContextMenu
              groupSelectsChildren
              onFilterChanged={e => {
                const filterModel = e.api.getFilterModel();
              }}
              onFilterModified={e => {}}
              onSelectionChanged={onSelectionChanged}
              context={{ applicationRows, mappedApplicationRows, tableInitialized }}
              isRowSelectable={node => {
                const linkedApplication = node.data?.linked_application;
                return !linkedApplication;
              }}
            />
          </div>
        </div>
      )}
    </AutoResizer>
  );
};

export default AGTableGridList;
