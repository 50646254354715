import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { message, Form, Button } from 'antd';

import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalOutlined } from '@ant-design/icons';
import FormInput from '../../global/Forms/FormInput';

import catalogueTranslation from '../../../constants/CatalogueTranslations.json';
import validationMessages from '../../../constants/ValidationMessages.json';
import { fillInPlaceholders } from '../../../utils/String';
import FormCheckbox from '../../global/Forms/FormCheckbox';
import { booleanToInt } from '../../../utils/Utils';

type CatalogueSaveFilterProps = {
  handleSaveNewFilter: (name: string, publicFilter: number) => Promise<undefined | ProgressEvent>;
  canManageGlobalFilter?: boolean;
} & WithTranslation;

class CatalogueSaveFilter extends React.Component<CatalogueSaveFilterProps> {
  validationSchema = Yup.object().shape({
    filterName: Yup.string().required(validationMessages.required).min(3),
  });

  handleSaveButton = (
    values: { filterName: string; publicFilter: number },
    formikActions: FormikHelpers<{ filterName: string; publicFilter: number }>
  ) => {
    const { filterName, publicFilter } = values;
    const { setErrors, setSubmitting } = formikActions;
    this.props
      .handleSaveNewFilter(filterName, publicFilter)
      .then(() => {
        message.success(
          fillInPlaceholders(catalogueTranslation.filterCreationSuccessfully, {
            filterName,
          })
        );
        setSubmitting(false);
      })
      .catch(() => {
        setErrors({
          // Workaround for backend, waiting for better error handling
          filterName: catalogueTranslation.filterAlreadyExists,
        });
        setSubmitting(false);
      });
  };

  render() {
    const { t } = this.props;
    return (
      <Formik
        initialValues={{ filterName: '', publicFilter: 0 }}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSaveButton}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form className="save-filter-popover__form" layout="vertical">
            <FormInput
              name="filterName"
              label={`${catalogueTranslation.catalogueBar.filterName}:`}
              placeholder={catalogueTranslation.catalogueBar.filterName}
              required
            />
            {this.props.canManageGlobalFilter && (
              <FormCheckbox
                name="publicFilter"
                handleChange={e => setFieldValue('publicFilter', booleanToInt(e.target.checked))}
                content={
                  <div>
                    {t('catalogue:makeThisVisible')} <GlobalOutlined />
                  </div>
                }
              />
            )}
            <Button
              className="save-filter-popover__form__submit"
              type="primary"
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {catalogueTranslation.catalogueBar.save}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(CatalogueSaveFilter);
