import React from 'react';
import classNames from 'classnames';
import { Input, Button } from 'antd';

import { withTranslation } from 'react-i18next';
import noImage from '../../../../../images/icons/no-image.svg';
import digitalAssetConstants from '../../../../constants/DigitalAssetsTranslation.json';
import { DigitalAssetsDownloadListButton } from './DigitalAssetsDownloadListButton';
import AntTooltip from '../../../global/AntTooltip';

const { parent: translation } = digitalAssetConstants;
const Search = Input.Search;

class DigitalAssetList extends React.Component {
  render() {
    const {
      digitalAssets,
      selectedDigitalAsset,
      selectDigitalAsset,
      isLoading,
      handleScroll,
      handleSearch,
      t,
      handleSelectAsset,
    } = this.props;
    return (
      <div className="digital-asset-parent__list-container">
        <div className="digital-asset-parent__list-search">
          <Search placeholder="search" onChange={handleSearch} />
        </div>
        <div className="flex mb-4">
          <DigitalAssetsDownloadListButton className={classNames('w-auto', 'ml-auto')} />
        </div>
        <div className="digital-asset-parent__list" onScroll={handleScroll}>
          {digitalAssets.map(digitalAsset => {
            const isSelected = selectedDigitalAsset && digitalAsset.id === selectedDigitalAsset.id;
            const listElementCss = classNames('justify-between', {
              'digital-asset-parent__list-single': !isSelected,
              'digital-asset-parent__list-single--selected': isSelected,
            });
            const imgTitle = (
              <span>
                {digitalAsset.defaultWhiteLabelBrandCode
                  ? `${digitalAsset.defaultWhiteLabelBrandCode} (${digitalAsset.brandCode}) | `
                  : (digitalAsset.brandCode && `${digitalAsset.brandCode} | `) || ''}
                {digitalAsset.file_name}
              </span>
            );
            return (
              <div
                key={digitalAsset.id}
                className={listElementCss}
                onClick={() => selectDigitalAsset(digitalAsset.id)}
              >
                <div
                  className={classNames('flex flex-row items-center', {
                    'digital-asset-parent__list-single--img-title': handleSelectAsset,
                  })}
                >
                  <img
                    className="thumbnail"
                    src={digitalAsset.thumbnail || noImage}
                    alt={digitalAsset.file_name}
                    onError={event => (event.target.src = noImage)}
                  />
                  <AntTooltip title={imgTitle} mouseEnterDelay={1}>
                    <div className="text" title={digitalAsset.file_name}>
                      {imgTitle}
                    </div>
                  </AntTooltip>
                </div>
                {handleSelectAsset && (
                  <Button
                    size="small"
                    className="mr-1"
                    onClick={() => handleSelectAsset(digitalAsset)}
                  >
                    {t('digitalAssets:selectAsset')}
                  </Button>
                )}
              </div>
            );
          })}
          {!isLoading && digitalAssets.length === 0 && (
            <div className="title">{translation.no_assets}</div>
          )}
          {isLoading && <div className="loader" />}
        </div>
      </div>
    );
  }
}

export default withTranslation()(DigitalAssetList);
