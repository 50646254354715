import React, { ReactChild } from 'react';
import { Formik, FormikValues, FormikProps, FormikHelpers } from 'formik';
import { App } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import DrawerSave from './DrawerSave';
import translation from '../../../constants/SaveTranslation.json';
import FormErrorFocus from '../Forms/FormErrorFocus';
import { ApplicationState } from '../../../reducers';
import { hasBrandEditPermission } from '../../../selectors/userSelector';

type DrawerFormikProps = {
  initialValues: FormikValues;
  visible: boolean;
  validationSchema?: any;
  className?: string;
  title?: React.ReactNode;
  width?: string | number;
  style?: React.CSSProperties;
  isLoading?: boolean;
  destroyOnClose?: boolean;
  push?: boolean | { distance: number | string };
  onSubmit: (values: FormikValues, formikActions: FormikHelpers<FormikValues>) => void;
  onClose: () => void;
  onDelete?: (values?: FormikValues) => void;
  onNew?: (dirty?: boolean) => void;
  onNext?: (dirty?: boolean) => void;
  onPrev?: (dirty?: boolean) => void;
  children: (formikBag: FormikProps<FormikValues>) => ReactChild;
  handleSaveButtonEnabled?: (formik: FormikProps<FormikValues>) => boolean;
  handleAdditionalFooterData?: (formik: FormikProps<FormikValues>) => React.ReactNode;
  saveButtonEnabled?: boolean;
  deleteButtonEnabled?: boolean;
  newButtonEnabled?: boolean;
  additionalFooterData?: React.ReactNode;
  enableReinitialize?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
};

const DrawerFormik: React.FC<DrawerFormikProps> = props => {
  const { modal } = App.useApp();

  const { brandId, brandEditPermission } = useSelector((state: ApplicationState) => ({
    brandId: state.parent.brands.selectedBrandId,
    brandEditPermission: hasBrandEditPermission(state),
  }));

  const handleClose = (dirty: boolean, force?: boolean) => {
    if (!dirty || force) {
      props.onClose();
    } else {
      modal.confirm({
        title: translation.closeWarningTitle,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          props.onClose();
        },
      });
    }
  };

  return props.visible ? (
    <Formik
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={props.onSubmit}
      enableReinitialize={props.enableReinitialize}
      validateOnBlur={props.validateOnBlur}
      validateOnChange={props.validateOnChange}
    >
      {formikBag => {
        const saveButtonEnabled = props.handleSaveButtonEnabled
          ? props.handleSaveButtonEnabled(formikBag)
          : props.saveButtonEnabled ||
            (formikBag.dirty && (!brandId || (brandId && brandEditPermission)));
        const newButtonEnabled = formikBag.dirty || props.newButtonEnabled;
        const deleteButtonEnabled =
          props.deleteButtonEnabled !== undefined ? props.deleteButtonEnabled : !!props.onDelete;
        const additionalFooterData = props.handleAdditionalFooterData
          ? props.handleAdditionalFooterData(formikBag)
          : props.additionalFooterData;

        return (
          <React.Fragment>
            <DrawerSave
              title={props.title}
              className={props.className}
              onClose={force => handleClose(formikBag.dirty, force)}
              onSave={() => formikBag.handleSubmit()}
              onDelete={props.onDelete ? () => props.onDelete!(formikBag.values) : undefined}
              onNext={props.onNext ? () => props.onNext!(formikBag.dirty) : undefined}
              onNew={props.onNew ? () => props.onNew!(formikBag.dirty) : undefined}
              onPrev={props.onPrev ? () => props.onPrev!(formikBag.dirty) : undefined}
              visible={props.visible}
              width={props.width}
              style={props.style}
              saveButtonEnabled={saveButtonEnabled}
              deleteButtonEnabled={deleteButtonEnabled}
              newButtonEnabled={newButtonEnabled}
              additionalFooterData={additionalFooterData}
              isSubmitting={formikBag.isSubmitting}
              isLoading={props.isLoading}
              destroyOnClose={props.destroyOnClose}
              push={props.push}
            >
              {props.children(formikBag)}
            </DrawerSave>
            <FormErrorFocus />
          </React.Fragment>
        );
      }}
    </Formik>
  ) : null;
};

export default DrawerFormik;
