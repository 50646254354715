import React from 'react';
import { Form, Button, message, Typography, App } from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { FormikValues, FieldArray, FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { updateCustomField, deleteCustomField } from '../../../actions/brand/custom_fields';
import { AsyncDispatch } from '../../../../types/global';
import {
  BrandCustomField,
  CustomFieldType,
  CustomFieldOption,
  CustomFieldGroup,
} from '../../../../types/custom_fields';
import SettingsCustomFieldOptions from './SettingsCustomFieldOptions';
import globalTranslations from '../../../constants/GlobalTranslation.json';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';
import FormSwitch from '../../global/Forms/FormSwitch';
import AntTooltip from '../../global/AntTooltip';
import { isManufacturer } from '../../../utils/UserUtils';

type SettingsCustomFieldEditDrawerProps = {
  visible: boolean;
  canEditSettingsCustomFields: boolean;
  closeDrawer: () => void;
  handleAddField: (id: number, groupId: number | null) => void;
  handleDeleteField: (id: number, groupId: number | null) => void;
  customFieldsLength: number;
  customField?: BrandCustomField;
  customFields: BrandCustomField[];
  groups: CustomFieldGroup[];
};

const SettingsCustomFieldEditDrawer: React.FC<SettingsCustomFieldEditDrawerProps> = ({
  visible,
  canEditSettingsCustomFields,
  closeDrawer,
  handleAddField,
  handleDeleteField,
  customFieldsLength,
  customField,
  customFields,
  groups,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const { modal } = App.useApp();

  const { customFieldTypes, brandId, brandCode, fetchingScripts, scripts, user } = useSelector(
    (state: ApplicationState) => {
      return {
        customFieldTypes: state.resources.data.custom_field?.types || [],
        brandId: state.parent.brands.selectedBrandId,
        brandCode: getSelectedBrandCode(state),
        fetchingScripts: state.distribution.apiSettings.fetchingScripts,
        scripts: state.distribution.apiSettings.scripts,
        user: state.user.user,
      };
    }
  );

  const manufacturer = React.useMemo(() => isManufacturer(user!), [user]);
  const pdmUser = React.useMemo(() => user?.email.endsWith('pdm.ai'), [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation:required'))
      .test(
        'check-unique-name',
        t('settings:customFields.validation.customFieldIsUsed'),
        (val: any) => {
          return !customField || (customField && customField.name !== val)
            ? customFields.every(({ name }) => name !== val)
            : true;
        }
      ),
    customFieldTypeId: Yup.number().nullable().required(t('validation:required')),
    options: Yup.array().when(['customFieldTypeId'], ([customFieldTypeId], schema) =>
      customFieldTypeId === CustomFieldType.Dropdown
        ? Yup.array()
            .of(Yup.object().shape({ name: Yup.string().required() }))
            .test(
              'check-unique-option-name',
              t('settings:customFields.validation.dontUseDuplicateOptions'),
              array => array!.length === new Set(array!.map((a: any) => a.name)).size
            )
            .min(1, t('settings:customFields.validation.atLeastOneOptionIsRequired'))
        : schema
    ),
  });

  const drawerProps = {
    visible,
    width: 450,
    title: t('glossary:segments.customField_one'),
    validationSchema,
    destroyOnClose: true,
    initialValues: {
      name: customField ? customField.name : '',
      customFieldTypeId: customField ? customField.custom_field_type_id : null,
      options: customField && customField.options ? customField.options : [],
      publishChanges: customField ? !customField.ignore_on_sync : true,
      scriptId: customField && customField.script_id ? customField.script_id : null,
      groupId: customField ? customField.group_id : null,
    },
    deleteButtonEnabled: canEditSettingsCustomFields,
    additionalFooterData:
      customField?.dynamic_configuration && !pdmUser
        ? t('settings:customFields:dynamicFieldDeleteInfo')
        : undefined,
    handleSaveButtonEnabled: (formik: FormikProps<FormikValues>) =>
      canEditSettingsCustomFields && formik.dirty,
    onSubmit: (values: FormikValues) => {
      dispatch(
        updateCustomField({
          customFieldId: customField ? customField.id : null,
          name: values.name,
          brandId,
          customFieldTypeId: values.customFieldTypeId,
          recordNumber: customField ? customField.record_number : customFieldsLength,
          options: values.customFieldTypeId === CustomFieldType.Dropdown ? values.options : null,
          ignoreOnSync: values.publishChanges ? 0 : 1,
          scriptId: values.customFieldTypeId === CustomFieldType.Button ? values.scriptId : null,
          groupId: values.groupId === 'other' ? null : values.groupId,
        })
      ).then(result => {
        const newFieldId = result.value.data.custom_field_id;
        handleAddField(newFieldId, values.groupId);
        closeDrawer();
      });
    },
    onDelete:
      customField && (!customField.dynamic_configuration || pdmUser)
        ? () => {
            intercomEvent('viewed-brand-settings', {
              location: 'settings-custom-fields',
              brand_code: brandCode!,
              action: 'delete',
            });

            modal.confirm({
              title: t('settings:customFields.confirmDelete'),
              icon: <ExclamationCircleOutlined />,
              okText: t('common:delete'),
              okButtonProps: { danger: true },
              onOk() {
                dispatch(deleteCustomField(Number(customField.id)))
                  .then(() => {
                    handleDeleteField(Number(customField.id), customField.group_id);
                    closeDrawer();
                  })
                  .catch(e => {
                    const error = e.response.data.message || globalTranslations.unknownError;
                    message.error(error);
                  });
              },
            });
          }
        : undefined,
    onClose: closeDrawer,
  };

  return (
    <DrawerFormik {...drawerProps}>
      {({ values, handleSubmit, setFieldValue, errors, submitCount }) => (
        <Form layout="vertical" onFinish={() => handleSubmit()}>
          {customField?.dynamic_configuration && (
            <h3 className="mb-7">{t('settings:customFields.dynamicCustomField')}</h3>
          )}
          <FormInput
            name="name"
            label={t('common:name')}
            disabled={!!customField?.dynamic_configuration && !pdmUser}
            required
          />
          <FormSelect
            name="customFieldTypeId"
            label={t('common:type')}
            values={customFieldTypes}
            disabled={!!customField?.dynamic_configuration && !pdmUser}
            required
          />
          {values.customFieldTypeId === CustomFieldType.Dropdown && (
            <FieldArray
              name="options"
              render={arrayHelpers => (
                <div className="flex-col">
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      arrayHelpers.push({
                        name: '',
                        record_number: values.options.length + 1,
                      })
                    }
                    disabled={!!customField?.dynamic_configuration && !pdmUser}
                  >
                    {t('settings:customFields.addNewOption')}
                  </Button>
                  {values.options.length > 0 && (
                    <SettingsCustomFieldOptions
                      options={values.options}
                      disabled={!!customField?.dynamic_configuration && !pdmUser}
                      updateOptions={(options: CustomFieldOption[]) => {
                        setFieldValue('options', options);
                      }}
                    />
                  )}
                </div>
              )}
            />
          )}
          {values.customFieldTypeId === CustomFieldType.Button && (
            <FormSelect
              name="scriptId"
              label={t('api:script')}
              values={scripts}
              loading={fetchingScripts}
              disabled={!!customField?.dynamic_configuration && !pdmUser}
              required
            />
          )}
          <FormSelect
            name="groupId"
            label={t('common:group')}
            values={groups.map(g => ({
              ...g,
              id: g.id === 'other' || typeof g.id === 'number' ? g.id : Number(g.id.substring(1)),
            }))}
            required
          />

          <Typography.Title level={5} className="mt-5">
            {t('settings:customFields.customfieldSettings')}
          </Typography.Title>
          <div className="flex">
            <FormSwitch
              name="publishChanges"
              checkedChildren={t('common:yes')}
              unCheckedChildren={t('common:no')}
            />
            <span className="h-8 ml-3 leading-8">
              {manufacturer
                ? t('settings:customFields.publishManufacturerChanges')
                : t('settings:customFields.updateTimeStamp')}
              <AntTooltip
                title={
                  manufacturer
                    ? t('settings:customFields.publishChangesInfo')
                    : t('settings:customFields.publishReceiverInfo')
                }
              >
                <InfoCircleOutlined className="icon__blue ml-1" />
              </AntTooltip>
            </span>
          </div>
          {submitCount > 0 && errors.options && (
            <div className="has-error">
              <span className="ant-form-explain ">
                {Array.isArray(errors.options)
                  ? t('settings:customFields.validation.fillInAllInputFields')
                  : errors.options}
              </span>
            </div>
          )}
        </Form>
      )}
    </DrawerFormik>
  );
};

export default SettingsCustomFieldEditDrawer;
