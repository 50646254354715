import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Empty, Tooltip, Spin } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { checkKeywordLength, getPageLimit, typingDone } from '../../../utils/Utils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import Page from '../../global/page/Page';
import InterChangeDrawer from './InterChangeDrawer';
import { Item } from '../../../../types/item';
import { ApplicationState } from '../../../reducers';
import { fetchInformations } from '../../../actions/items/interchange/fetch';
import { StandardResource, AnalysisType } from '../../../../types/resources';
import { Informations } from '../../../../types/interchange';
import { AsyncDispatch } from '../../../../types/global';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { Analysis } from '../../../../types/analyses';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import AnalysesAlertIcon from '../../global/AnalysesAlertIcon';
import SearchInput from '../../global/Forms/SearchInput';

type InterChangePageProps = {
  itemId: number;
} & Item;

const InterChangePage = (props: InterChangePageProps) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const tableRef = React.useRef<HTMLDivElement>(null);

  const [interchangeDrawerVisible, setInterchangeDrawerVisible] = React.useState(false);
  const [selectedInterchangeRecord, selectInterchangeRecord] = React.useState(undefined);
  const [newInterchange, setNewInterchange] = React.useState(false);
  const [searchKey, setSearchkey] = React.useState('');
  const [count, setCount] = React.useState(0);
  const [prevSelectedItemId, setPrevSelectedItemId] = React.useState<number>();

  const {
    listUoms,
    listQuality,
    listTypes,
    interchangeInfos,
    totalCount,
    fetchingInformation,
    selectedItem,
    analysesBySegment,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItem: getSelectedItems(state)[0],
      listUoms: state.resources.data.global.measurement_uom_codes,
      listQuality: state.resources.data.interchange_information.qualitity_grade_levels,
      listTypes: state.resources.data.interchange_information.types,
      interchangeInfos: state.items.interchange.informations,
      totalCount: state.items.interchange.totalCount,
      fetchingInformation: state.items.interchange.fetchingInformations,
      analysesBySegment: extendedAnalysesBySegment(state),
    };
  });

  React.useEffect(() => {
    if (props.itemId !== prevSelectedItemId) {
      setSearchkey('');
      setPrevSelectedItemId(props.itemId);
      dispatch(fetchInformations(props.itemId)).then(result => {
        setCount(result.value.data.part_interchanges.length);
      });
    }
  }, [count, dispatch, prevSelectedItemId, props.itemId]);

  const nodes = tableRef.current?.getElementsByClassName('ant-table-body');
  const node = nodes && nodes[0];

  React.useEffect(() => {
    const handleFetchNext = () => {
      if (node) {
        const perc = (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;

        if (perc >= 100 && !fetchingInformation) {
          const pageSize = getPageLimit();
          const currentPage = Math.ceil(interchangeInfos.length / pageSize);
          const lastPage = interchangeInfos.length === totalCount;

          if (!lastPage)
            dispatch(fetchInformations(props.itemId, '', currentPage + 1)).then(result => {
              setCount(count + result.value.data.part_interchanges.length);
            });
        }
      }
    };

    if (node) {
      node.addEventListener('scroll', handleFetchNext);
    }

    return () => {
      if (node) node.removeEventListener('scroll', handleFetchNext);
    };
  }, [count, dispatch, fetchingInformation, interchangeInfos, node, props.itemId, totalCount]);

  const handleAddInterchange = () => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'interchange_new',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
    setNewInterchange(true);
    setInterchangeDrawerVisible(true);
  };

  const handleEditInterchange = (record: any) => {
    intercomEvent('viewed-all-product', {
      action: 'item-edited',
      location: 'interchange',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
    selectInterchangeRecord(record);
    setNewInterchange(false);
    setInterchangeDrawerVisible(true);
  };

  const newData: Informations[] = interchangeInfos.map((data: Informations) => {
    const itemEquivalentUom = listUoms.find(uom => data.item_equivalent_uom_id === uom.id);

    const qualityGradeLevel = listQuality.find(
      (qlty: StandardResource) => data.quality_grade_level_id === qlty.id
    );

    const quantityUomIdname = listUoms.find(uom => data.quantity_uom_id === uom.id);

    const typeIdName = listTypes.find((types: StandardResource) => data.type_id === types.id);

    const analyses = analysesBySegment.filter(
      (a: Analysis & AnalysisType) => a.reference_id === data.id
    );

    const changedData: any = {
      ...data,
      key: data.id.toString(),
      yourProductPartNumber: props.part_number,
      quantityUomIdname: quantityUomIdname && quantityUomIdname.name,
      qualityGradeLevelIdName: qualityGradeLevel && qualityGradeLevel.name,
      typeIdName: typeIdName && typeIdName.name,
      itemEquivalentUomIdName: itemEquivalentUom && itemEquivalentUom.name,
      brandCodeName: data.brand_id
        ? `${data.brand_code} | ${data.brand_name}`
        : data.custom_brand_label,
      analyses,
    };
    return changedData;
  });

  const columns: any = [
    {
      title: t('interchange:yourProduct'),
      children: [
        {
          width: 22,
          dataIndex: 'analyses',
          key: 'analyses',
          ellipsis: true,
          fixed: 'left',
          render: (analyses: any) => <AnalysesAlertIcon analyses={analyses} />,
          className: 'interchange__analyses-alert-icon',
        },
        {
          title: t('interchange:partNumber'),
          width: 180,
          dataIndex: 'yourProductPartNumber',
          key: 'yourProductPartNumber',
          ellipsis: true,
          fixed: 'left',
          render: (yourProductPartNumber: string) => (
            <Tooltip title={yourProductPartNumber}>{yourProductPartNumber}</Tooltip>
          ),
        },
        {
          title: t('interchange:equivalentUom'),
          width: 130,
          dataIndex: 'itemEquivalentUomIdName',
          key: 'itemEquivalentUomIdName',
          ellipsis: true,
          fixed: 'left',
        },
      ],
    },
    {
      title: t('interchange:multiInterchangeProducts'),
      children: [
        {
          title: t('interchange:partNumber'),
          dataIndex: 'part_number',
          key: '1',
          ellipsis: true,
        },
        {
          title: t('interchange:brand'),
          dataIndex: 'brandCodeName',
          key: '2',
          ellipsis: true,
        },
        {
          title: t('interchange:quantityUom'),
          dataIndex: 'quantityUomIdname',
          key: '3',
          ellipsis: true,
        },
        {
          title: t('interchange:quality'),
          dataIndex: 'qualityGradeLevelIdName',
          key: '4',
          ellipsis: true,
        },
        {
          title: t('interchange:type'),
          dataIndex: 'typeIdName',
          key: '5',
          ellipsis: true,
        },
      ],
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 60,
      render: (text: any, record: any) => (
        <Button
          className="interchangePage__edit__button"
          type="primary"
          size="small"
          onClick={() => handleEditInterchange(record)}
        >
          {t('common:edit')}
        </Button>
      ),
    },
  ];

  const handleInterchangeSearch = (keyword: string) => {
    setSearchkey(keyword);
    const minKeywordLengthReached = checkKeywordLength(keyword);

    typingDone(() => {
      if (minKeywordLengthReached) handleFetchInterchange(keyword);
    });
  };

  const handleFetchInterchange = (updatedKeyword?: string) => {
    const keyword = updatedKeyword || searchKey;

    dispatch(fetchInformations(props.itemId, keyword));
  };

  return (
    <Page showAnalysis contentNoSpacing>
      <div className="overflow-hidden h-full pt-3">
        <div className="flex flex-row mb-2 ml-3 ">
          <SearchInput
            className="interchange__search"
            placeholder={t('interchange:searchInterchange')}
            onChange={e => handleInterchangeSearch(e.target.value)}
            onPressEnter={() => handleFetchInterchange()}
            value={searchKey}
            size="small"
            search
          />

          <Button
            type="primary"
            data-cy="addItem"
            ghost
            onClick={() => handleAddInterchange()}
            size="small"
          >
            <span className="px-10">{t('interchange:addItem')}</span>
          </Button>
          {interchangeDrawerVisible && (
            <InterChangeDrawer
              onClose={() => {
                selectInterchangeRecord(undefined);
                setInterchangeDrawerVisible(false);
              }}
              edit={!newInterchange}
              interchangeInfo={selectedInterchangeRecord}
            />
          )}
        </div>

        {fetchingInformation && !interchangeInfos.length && (
          <Spin className="spinner-center" style={{ marginTop: '20px' }} />
        )}
        {interchangeInfos.length > 0 && (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <div ref={tableRef} style={{ height, width }} className="interchange__page__table">
                  <Table
                    loading={fetchingInformation}
                    size="small"
                    columns={columns}
                    dataSource={newData}
                    scroll={{
                      x: width > 1057 ? undefined : 1057,
                      y: height > interchangeInfos.length * 40 + 110 ? undefined : height - 110,
                      scrollToFirstRowOnChange: false,
                    }}
                    pagination={false}
                  />
                </div>
              );
            }}
          </AutoSizer>
        )}

        {!fetchingInformation && !interchangeInfos.length && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Page>
  );
};

export default InterChangePage;
