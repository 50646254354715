import { connect } from 'react-redux';
import React from 'react';
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Drawer, Button, Input, Tooltip, message, Skeleton } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { FilterType, FilterTypeGo } from '../../../types/filter';
import { ApplicationElement } from '../../../types/all_application_validation';
import { AvailableReceiver } from '../../../types/receiver';
import { AvailableChannel } from '../../../types/channel';
import { BrandCustomField } from '../../../types/custom_fields';
import { UserType } from '../../../types/user';
import { Item } from '../../../types/item';
import { SubBrand } from '../../../types/brand';
import { Resources, ApplicationResources } from '../../../types/resources';
import { DefaultValue } from '../../../types/brand_settings';
import { getPageLimit, typingDone } from '../../utils/Utils';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import AntSelect from '../../components/global/AntSelect';
import AllApplicationsTable from '../../components/body/all_application_validation/AllApplicationsTable';
import {
  fetchApplicationListing,
  fetchApplicationItem,
} from '../../actions/all_applications/fetch';
import {
  resetApplicationListings,
  setListingTypeId,
  setListingTypeOptionId,
} from '../../actions/all_applications/update';
import { fetchAndSelectFilter } from '../../actions/catalogue/filter/fetch';
import { updateFilter, setEmptyFilter } from '../../actions/catalogue/filter/update';
import {
  deleteFilterCategoryParameter,
  deleteFilterApplicationParameter,
  deleteFilterAnalysisParameter,
  deleteFilterCustomParameter,
} from '../../actions/catalogue/filter/delete';
import { fetchApplication } from '../../actions/items/application/fetch';
import {
  getApplicationListFilter,
  getAllProductsFilter,
} from '../../selectors/catalogue/filterSelector';
import { mappedApplicationList } from '../../selectors/all_applications/allApplicationsSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import FilterContainer from '../header/FilterContainer';
import CatalogueFilterPreview from '../../components/body/catalogue/CatalogueFilterPreview';
import ValidationExplanation from '../../components/body/all_application_validation/ValidationExplanation';
import ApplicationEditDrawer from './ApplicationEditDrawer';
import { selectApplication } from '../../actions/items/application/update';
import { fetchItemsByFilterId } from '../../actions/catalogue/catalogue/fetch';
import { intercomEvent } from '../../utils/IntercomUtils';
import { withBrandRouteWrapper } from '../BrandRouteWrapper';
import { fetchItemAnalysesBySegment } from '../../actions/items/analyses/fetch';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

type AllApplicationsProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  applicationItem?: Item;
  allFilter: FilterType;
  listFilter: FilterType;
  applicationRows: ApplicationElement[];
  mappedApplicationRows: ApplicationElement[];
  columns: Array<{ [key: string]: string[] } | string>;
  resources: Resources;
  applicationResources: ApplicationResources;
  selectedFilter: FilterType;
  selectedFilterGo: FilterTypeGo;
  selectedApplicationId: number | null;
  fetching: boolean;
  fetchListingError: boolean;
  fetchingApplications: boolean;
  fetchingFilters: boolean;
  fetchingApplicationItem: boolean;
  brandCustomFields: BrandCustomField[];
  fetchingCustomFields: boolean;
  availableReceivers: AvailableReceiver[];
  availableChannels: AvailableChannel[];
  subBrands: SubBrand[];
  brandCode?: string;
  listingTypeId: number;
  listingTypeOptionId?: number;
  defaultValues: DefaultValue[];
  goOnly: boolean;
} & WithTranslation;

type AllApplicationsState = {
  filterKeywords: string;
  pageSize: number;
  showFilterDrawer: boolean;
  startValidation: boolean;
  filterChanged: boolean;
  prevSelectedFilterId?: number;
};

export class AllApplicationsContainer extends React.Component<
  AllApplicationsProps,
  AllApplicationsState
> {
  constructor(props: AllApplicationsProps) {
    super(props);
    this.state = {
      filterKeywords: '',
      pageSize: getPageLimit(),
      showFilterDrawer: false,
      prevSelectedFilterId: undefined,
      startValidation: !(props.fetching || props.applicationRows.length),
      filterChanged: false,
    };
  }

  componentDidMount() {
    const { selectedFilterGo, fetchingFilters, brandCode } = this.props;

    // store previous selected filter id and refetch it when this component unmounts
    if (selectedFilterGo) this.setState({ prevSelectedFilterId: selectedFilterGo.id });
    if (!fetchingFilters) {
      this.fetchAndSelectFilter();
    }
    intercomEvent('viewed-all-applications', { brand_code: brandCode! });
  }

  componentWillUnmount() {
    const { allFilter, defaultValues, goOnly } = this.props;
    const { prevSelectedFilterId } = this.state;
    const defLanguageId = defaultValues.find(d => d.resource_table === 'languages')?.value;

    this.props.dispatch(selectApplication(null));
    if (prevSelectedFilterId) {
      this.props.dispatch(setEmptyFilter());
      if (!goOnly) this.props.dispatch(fetchAndSelectFilter(prevSelectedFilterId));
      // this.props.dispatch(fetchFilterGo(prevSelectedFilterId));
    } else {
      // this.props.dispatch(fetchFilterGo(allFilter.id));
      if (!goOnly) this.props.dispatch(fetchAndSelectFilter(allFilter.id));
      this.props.dispatch(
        fetchItemsByFilterId({ filterId: allFilter.id, languageId: defLanguageId })
      );
    }
  }

  componentDidUpdate(prevProps: AllApplicationsProps) {
    if (prevProps.fetchingFilters && !this.props.fetchingFilters) {
      this.fetchAndSelectFilter();
    }
    if (prevProps.selectedApplicationId && !this.props.selectedApplicationId) {
      const selectedApplicationRowData = prevProps.applicationRows.find(
        row => row.application_id === prevProps.selectedApplicationId
      );
      if (selectedApplicationRowData) {
        this.props.dispatch(
          // @ts-ignore
          fetchItemAnalysesBySegment([selectedApplicationRowData?.item_id], 'application')
        );
      }
    }
  }

  fetchAndSelectFilter = () => {
    const { listFilter, goOnly } = this.props;

    if (!goOnly)
      this.props.dispatch(fetchAndSelectFilter(listFilter.id)).then(result => {
        const filter = result.value.data;
        this.setState({ filterKeywords: filter.keywords || '' });
      });
    // this.props.dispatch(fetchFilterGo(listFilter.id)).then(result => {
    //   const filter = result.value.data;
    //   this.setState({ filterKeywords: filter.keywords || '' });
    // });
  };

  fetchListing = () => {
    const { t, listFilter, listingTypeId, listingTypeOptionId } = this.props;

    if (listingTypeId === 4) {
      const filterValues = this.getFilterValues();
      const parameterKeys = Object.keys(filterValues);
      if (
        !parameterKeys.includes('3') ||
        !parameterKeys.includes('makes') ||
        !parameterKeys.includes('models')
      ) {
        message.warning({
          content: t('applicationValidation:gapFilterRequiredMessage'),
          style: { marginTop: '20px' },
        });
        return;
      }
    }

    this.props.dispatch(fetchApplicationListing(listFilter.id, listingTypeId, listingTypeOptionId));

    this.setState({ startValidation: false, filterChanged: false });
  };

  fetchNextListings = () => {
    const { listFilter, applicationRows, listingTypeId, listingTypeOptionId } = this.props;
    const { pageSize } = this.state;
    const currentPage = Math.ceil(applicationRows.length / pageSize);
    const lastPage = currentPage > applicationRows.length / pageSize;

    if (!lastPage)
      this.props.dispatch(
        fetchApplicationListing(listFilter.id, listingTypeId, listingTypeOptionId, currentPage + 1)
      );
  };

  updateKeywordInput = async (keywords: string) => {
    const { listFilter, goOnly } = this.props;
    const { filterChanged } = this.state;

    this.setState({ filterKeywords: keywords });
    typingDone(async () => {
      if (goOnly) {
        // await this.props.dispatch(updateFilterGo({ ...selectedFilterGo, keywords }));
      } else {
        await this.props.dispatch(updateFilter(listFilter.id, { keywords }));
        // this.props.dispatch(updateFilterGo(convertToGoFilterStructure(response.value.data.filter)));
      }
      if (!filterChanged) this.setState({ filterChanged: true });
    });
  };

  getFilterValues = () => {
    const { selectedFilterGo } = this.props;

    const filterValues: { [key: string]: any } = {};
    const applicationFilter = selectedFilterGo.filter_application;
    const categoryFilter = selectedFilterGo.filter_category;
    if (applicationFilter) {
      applicationFilter[0].parameters.forEach(parameter => {
        // option_id 3 = with any value equals no value for the gaps validation endpoint
        if (parameter.option_id !== 3) filterValues[parameter.resource] = parameter.values;
      });
    }
    if (categoryFilter) {
      categoryFilter[0].parameters.forEach(parameter => {
        // option_id 3 = with any value equals no value for the gaps validation endpoint
        if (parameter.option_id !== 3) filterValues[parameter.level] = parameter.values;
      });
    }

    return filterValues;
  };

  handleApplicationSelect = (id: number) => {
    const { applicationRows } = this.props;
    const selectedApplicationRowData = applicationRows.find(row => row.application_id === id);
    this.props.dispatch(fetchApplication(selectedApplicationRowData!.item_id, id));
    // fetch extended item to get default values (mfr label, qty, category)
    if (selectedApplicationRowData)
      this.props.dispatch(fetchApplicationItem(selectedApplicationRowData.item_id));
    this.props.dispatch(selectApplication(id));
  };

  handleListingTypeIdSelect = (id: number) => {
    const { applicationResources } = this.props;
    const typeOption = applicationResources.listing_type_options.find(opt => opt.type_id === id);

    this.props.dispatch(setListingTypeId(id));
    this.props.dispatch(setListingTypeOptionId(typeOption ? typeOption.id : undefined));
    this.props.dispatch(resetApplicationListings());
    this.setState({
      startValidation: true,
    });
  };

  handleTypeOptionSelect = (id: number) => {
    this.props.dispatch(setListingTypeOptionId(id));
    this.props.dispatch(resetApplicationListings());
    this.setState({ startValidation: true });
  };

  getFilterParameterId = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => {
    const { selectedFilter } = this.props;
    let filterParameterId;
    // @ts-ignore
    Object.values(selectedFilter[sectionKey]).forEach((block: any) => {
      Object.keys(block.parameters || []).forEach(paramId => {
        const parameter = block.parameters[paramId];

        if (sectionKey === 'filter_category') {
          if (level === parameter.level) filterParameterId = paramId;
        } else if (sectionKey === 'filter_application') {
          if (resource === parameter.resource) filterParameterId = paramId;
        } else if (sectionKey === 'filter_analysis') {
          if (referenceId === parameter.reference_id) filterParameterId = paramId;
        } else if (sectionKey === 'filter_custom') {
          if (
            filterCustomReferenceId === parameter.filter_custom_reference_id &&
            referenceId === parameter.reference_id
          )
            filterParameterId = paramId;
        }
      });
    });

    return filterParameterId;
  };

  handleDeleteFilterParameter = async ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number | null;
    filterCustomReferenceId?: number;
  }) => {
    const { selectedFilterGo, goOnly } = this.props;

    // if (goOnly) {
    //   const updatedFilter = removeFilterParameter({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     filterGo: selectedFilterGo,
    //   });

    //   await this.props.dispatch(updateFilterGo(updatedFilter));
    //   return this.setState({ filterChanged: true });
    // }
    const paramId = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });

    let response;
    if (sectionKey === 'filter_category') {
      response = await this.props.dispatch(deleteFilterCategoryParameter(paramId));
    } else if (sectionKey === 'filter_application') {
      response = await this.props.dispatch(deleteFilterApplicationParameter(paramId));
    } else if (sectionKey === 'filter_analysis') {
      response = await this.props.dispatch(deleteFilterAnalysisParameter(paramId));
    } else if (sectionKey === 'filter_custom') {
      response = await this.props.dispatch(deleteFilterCustomParameter(paramId));
    }

    if (response) {
      this.setState({ filterChanged: true });
      // this.props.dispatch(updateFilterGo(convertToGoFilterStructure(response.value.data.filter)));
    }
  };

  handleOpenFilter = () => {
    const { showFilterDrawer } = this.state;

    if (showFilterDrawer) {
      this.setState({ filterChanged: true, showFilterDrawer: false });
      // if (!goOnly) this.props.dispatch(updateFilterGo(convertToGoFilterStructure(selectedFilter)));
    } else {
      this.setState({ showFilterDrawer: true });
    }
  };

  closeFilterDrawerAndUpdate = async () => {
    this.setState({ showFilterDrawer: false, filterChanged: true });
    // if (!goOnly)
    //   await this.props.dispatch(updateFilterGo(convertToGoFilterStructure(selectedFilter)));
    this.fetchListing();
  };

  render() {
    const {
      fetching,
      fetchListingError,
      fetchingApplications,
      fetchingApplicationItem,
      selectedApplicationId,
      applicationRows,
      mappedApplicationRows,
      columns,
      applicationResources,
      resources,
      selectedFilterGo,
      applicationItem,
      listingTypeId,
      listingTypeOptionId,
      user,
      t,
    } = this.props;
    const { filterKeywords, startValidation, filterChanged } = this.state;
    const showOptionsSelect = !!applicationResources.listing_type_options.find(
      opt => opt.type_id === listingTypeId
    );
    const applicationEdited = !!applicationRows.find(app => app.updated);
    const selectedApplicationRowData =
      selectedApplicationId &&
      applicationRows.find(row => row.application_id === selectedApplicationId);
    const canStartValidation = hasPermission(user, 'can_start_all_application_validation');

    return (
      <div className="page-layout">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <div className="application_listing__validation-selects">
              <AntSelect
                className="application_listing__type-select"
                elements={applicationResources.listing_types}
                value={listingTypeId}
                onChange={value => this.handleListingTypeIdSelect(Number(value))}
                size="small"
              />
              {showOptionsSelect && (
                <AntSelect
                  className="application_listing__option-select"
                  elements={applicationResources.listing_type_options.filter(
                    opt => opt.type_id === listingTypeId
                  )}
                  value={listingTypeOptionId}
                  onChange={value => this.handleTypeOptionSelect(Number(value))}
                  size="small"
                />
              )}
              <Tooltip title={t('applicationValidation:validationTimeHint')}>
                <Button
                  className={classNames('application_listing__validate-button', {
                    'ant-btn-green': applicationEdited,
                  })}
                  onClick={this.fetchListing}
                  disabled={
                    !canStartValidation ||
                    (!filterChanged && !startValidation && !applicationEdited)
                  }
                  htmlType="button"
                  size="small"
                  type="primary"
                  ghost={!applicationEdited}
                >
                  {startValidation
                    ? t('applicationValidation:startValidation')
                    : t('applicationValidation:updateValidation')}
                </Button>
              </Tooltip>
            </div>

            <div className="application_listing__filter-elements flex-1">
              <Input
                className="application_listing__search-input"
                value={filterKeywords}
                onChange={e => this.updateKeywordInput(e.target.value)}
                placeholder={t('applicationValidation:searchPlaceholder')}
                suffix={
                  filterChanged ? (
                    <Tooltip title={t('applicationValidation:updateValidationHint')}>
                      <InfoCircleOutlined className="help__icon" />
                    </Tooltip>
                  ) : null
                }
                allowClear
                size="small"
              />
              <Button
                icon={<FilterOutlined />}
                className="button-add-filter"
                onClick={this.handleOpenFilter}
                htmlType="button"
                size="small"
              >
                {t('applicationValidation:addFilter')}
              </Button>
              <div className="flex-1">
                {this.props.fetchingCustomFields ? (
                  <Skeleton.Input active size="small" style={{ width: '40px' }} className="ml-1" />
                ) : (
                  <CatalogueFilterPreview
                    selectedFilter={selectedFilterGo}
                    resources={resources}
                    handleDeleteFilterParameter={this.handleDeleteFilterParameter}
                    brandCustomFields={this.props.brandCustomFields}
                    availableReceivers={this.props.availableReceivers}
                    availableChannels={this.props.availableChannels}
                    subBrands={this.props.subBrands}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="page-layout__content flex">
          {!startValidation && (
            <AllApplicationsTable
              columns={columns}
              applicationRows={applicationRows}
              mappedApplicationRows={mappedApplicationRows}
              handleApplicationSelect={this.handleApplicationSelect}
              fetchNextListings={this.fetchNextListings}
              fetching={fetching}
              fetchListingError={fetchListingError}
              overlap={listingTypeId === 3}
              gapValidation={listingTypeId === 4}
            />
          )}
          {startValidation && <ValidationExplanation />}
        </div>

        <Drawer
          open={this.state.showFilterDrawer}
          onClose={this.handleOpenFilter}
          title={t('applicationValidation:filterDrawerTitle')}
          width="80vw"
          destroyOnClose
        >
          {this.state.showFilterDrawer && <FilterContainer />}
          <div className="application_listing__filter-drawer-bottom">
            <Button onClick={this.handleOpenFilter} size="small" htmlType="button">
              {t('applicationValidation:back')}
            </Button>
            <Button
              onClick={this.closeFilterDrawerAndUpdate}
              type="primary"
              size="small"
              htmlType="button"
            >
              {t('applicationValidation:updateValidation')}
            </Button>
          </div>
        </Drawer>

        <ApplicationEditDrawer
          visible={!!selectedApplicationId}
          selectedApplicationId={
            !fetchingApplications && !fetchingApplicationItem ? selectedApplicationId : null
          }
          selectedItem={selectedApplicationRowData ? applicationItem || {} : {}}
          hideActionButtons
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  items: state.catalogue.catalogue.items,
  isFetchingFullItem: state.catalogue.catalogue.isFetchingFullItem,
  applicationResources: state.resources.data.application,
  applicationRows: state.allApplications.applicationList.applicationRows,
  mappedApplicationRows: mappedApplicationList(state),
  columns: state.allApplications.applicationList.columns,
  fetching: state.allApplications.applicationList.fetching,
  fetchListingError: state.allApplications.applicationList.fetchListingError,
  fetchingApplicationItem: state.allApplications.applicationList.fetchingApplicationItem,
  applicationItem: state.allApplications.applicationList.applicationItem,
  listingTypeId: state.allApplications.applicationList.listingTypeId,
  listingTypeOptionId: state.allApplications.applicationList.listingTypeOptionId,
  filters: state.catalogue.filter.filters,
  allFilter:
    state.catalogue.filter && state.catalogue.filter.filters ? getAllProductsFilter(state) : [],
  listFilter:
    state.catalogue.filter && state.catalogue.filter.filters ? getApplicationListFilter(state) : [],
  resources: state.resources.data,
  selectedFilter: state.catalogue.filter.filter,
  selectedFilterGo: state.catalogue.filter.filterGo,
  fetchingApplications: state.items.application.fetchingApplications,
  selectedApplicationId: state.items.application.selectedApplicationId,
  fetchingFilters: state.catalogue.filter.fetchingFilters,
  brandCustomFields: state.brand.customFields.customFields,
  fetchingCustomFields: state.brand.customFields.fetchingCustomFields,
  availableChannels: state.brand.currentBrand.availableChannels,
  availableReceivers: state.brand.currentBrand.availableReceivers,
  subBrands: state.brand.currentBrand.subBrands,
  brandCode: getSelectedBrandCode(state),
  defaultValues: brandAccDefaultValues(state),
  goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
});

export default withTranslation()(
  connect(mapStateToProps)(withBrandRouteWrapper(AllApplicationsContainer))
);
