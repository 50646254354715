import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip, Tag, Checkbox, Button } from 'antd';
import { useFormikContext } from 'formik';
import { InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../reducers';
import SelectList from '../../global/SelectList';
import { typingDone } from '../../../utils/Utils';
import { Category, SubCategory, PartType as TPartType } from '../../../../types/item';
import { fetchItemCategories } from '../../../actions/items/item/fetch';
import { DefaultValue } from '../../../../types/brand_settings';
import { fetchUsedVehicles } from '../../../actions/exporter';
import { AsyncDispatch } from '../../../../types/global';
import { brandAccDefaultValues } from '../../../selectors/default_values/defaultValuesSelector';

const ROW_HEIGHT = 50;

type ActiveLevel = 'category' | 'subCategory' | 'partType';

const GapCoverageReportStep2: React.FC = () => {
  const { t } = useTranslation();

  const dispatch: AsyncDispatch = useDispatch();

  const { values, setFieldValue } = useFormikContext<any>();

  const [checkedPartType, setCheckedPartType] = React.useState<TPartType[]>([]);
  const [checkedSelectedPartType, setCheckedSelectedPartType] = React.useState<TPartType[]>([]);
  const [filterSelectedPartTypeKeyword, setFilterSelectedPartTypeKeyword] =
    React.useState<string>('');
  const {
    categories,
    subCategories,
    partTypesList,
    selectedBrandId,
    defaultValues,
    fetchingCategory,
    fetchingSubCategory,
    fetchingPartType,
  } = useSelector((state: ApplicationState) => {
    return {
      categories: state.items.item.categories,
      subCategories: state.items.item.subCategories,
      partTypesList: state.items.item.partTypes,
      selectedBrandId: state.parent.brands.selectedBrandId,
      defaultValues: brandAccDefaultValues(state),
      fetchingCategory: state.items.item.fetchingCategory,
      fetchingSubCategory: state.items.item.fetchingSubCategory,
      fetchingPartType: state.items.item.fetchingPartType,
    };
  });

  const showPartTypeId = defaultValues.find((d: DefaultValue) => d.id === 11)?.value === '1';

  const fetchCategories = (values: any) => {
    dispatch(
      fetchItemCategories({
        ...values,
        brandId: selectedBrandId,
      })
    );
  };

  const searchCategory = (categorySearch: string, activelevel: ActiveLevel) => {
    typingDone(() => {
      setFieldValue('keywordCategory', categorySearch);
      fetchCategories({
        ...values,
        keywordCategory: categorySearch,
        activeLevel: activelevel,
      });
    });
  };

  const handleSubCategorySelect = (subCategory: SubCategory, activelevel: ActiveLevel) => {
    setFieldValue('categoryId', '');
    setFieldValue('partTypeId', '');
    const categoryValues = {
      ...values,
      categoryId: subCategory.category_id,
      subCategoryId: subCategory.id,
      partTypeId: undefined,
      activeLevel: activelevel,
    };
    if (values.subCategoryId === subCategory.id) {
      setFieldValue('subCategoryId', '');
      fetchCategories({ ...categoryValues, subCategoryId: undefined });
    } else {
      setFieldValue('subCategoryId', subCategory.id);
      fetchCategories(categoryValues);
    }
  };

  const handleCategorySelect = (category: Category, activelevel: ActiveLevel) => {
    setFieldValue('subCategoryId', '');
    setFieldValue('partTypeId', '');
    const categoryValues = {
      ...values,
      categoryId: category.id,
      subCategoryId: undefined,
      partTypeId: undefined,
      activeLevel: activelevel,
    };
    if (values.categoryId === category.id) {
      setFieldValue('categoryId', '');
      fetchCategories({ ...categoryValues, categoryId: undefined });
    } else {
      setFieldValue('categoryId', category.id);
      fetchCategories(categoryValues);
    }
  };

  const choosePartType = (partType: TPartType) => {
    setFieldValue('subCategoryId', '');
    setFieldValue('categoryId', '');
    if (values.partTypeId === partType.id) {
      setFieldValue('partTypeId', '');
      setFieldValue('level1_name', '');
      setFieldValue('level2_name', '');
      setFieldValue('level3_name', '');
      setFieldValue('visiblePartTypeId', '');
      fetchCategories({ ...values, partTypeId: undefined });
    } else {
      setFieldValue('partTypeId', partType.id);
      setFieldValue('level1_name', partType.category_name);
      setFieldValue('level2_name', partType.sub_category_name);
      setFieldValue('level3_name', partType.part_type_name);
      setFieldValue('visiblePartTypeId', partType.part_type_id);
    }
  };

  const searchSubCategory = (subCatgeorySearch: string, activelevel: ActiveLevel) => {
    typingDone(() => {
      setFieldValue('keywordSubCategory', subCatgeorySearch);
      fetchCategories({
        ...values,
        keywordSubCategory: subCatgeorySearch,
        activeLevel: activelevel,
      });
    });
  };

  const searchPartType = (partTypeSearch: string, activelevel: ActiveLevel) => {
    typingDone(() => {
      setFieldValue('keywordPartType', partTypeSearch);
      fetchCategories({
        ...values,
        keywordPartType: partTypeSearch,
        activeLevel: activelevel,
      });
    });
  };

  const updateCheckedPartType = (partType: TPartType) => {
    const updatedSelectedParttypes = checkedPartType.find(cp => cp.id === partType.id)
      ? checkedPartType.filter(cp => cp.id !== partType.id)
      : [...checkedPartType, partType];
    setCheckedPartType(updatedSelectedParttypes);
  };

  const updateSelectedPartType = (partType: TPartType) => {
    const updatedSelectedParttypes = checkedSelectedPartType.find(cp => cp.id === partType.id)
      ? checkedSelectedPartType.filter(cp => cp.id !== partType.id)
      : [...checkedSelectedPartType, partType];
    setCheckedSelectedPartType(updatedSelectedParttypes);
  };

  return (
    <div className="flex flex-col h-full gap-coverage-step-2">
      <div className="mt-2 mb-6 text-gray-700">
        {t('gapCoverageReport:step2Description1')}
        <span className="pl-1 italic ...">{t('gapCoverageReport:step2Description2')}</span>
      </div>
      <div className="flex h-full">
        <div className=" flex flex-col flex-1 pr-6 h-full space-y-2">
          <SelectList
            title={t('partType:category')}
            placeholder={t('partType:searchCategory')}
            rowHeight={ROW_HEIGHT}
            value={values.categoryId}
            loading={fetchingCategory}
            showSearch
            border
            onSearchChange={value => searchCategory(value, 'category')}
            dataSource={categories}
            onClick={(value, data) => handleCategorySelect(data, 'subCategory')}
            dataSourceValue="category_name"
            renderItem={data => {
              return (
                <div
                  key={data.id}
                  className="flex flex-1 p-2 w-full"
                  data-testid="category-row-field"
                >
                  <div className="flex-1 text-black truncate ...">{data.category_name}</div>
                  {data.used_by_brand > 0 && (
                    <Tooltip
                      title={`${data.used_by_brand} ${t('partType:assignedProductsTextCategory')}`}
                    >
                      <Tag className="self-center">{data.used_by_brand}</Tag>
                    </Tooltip>
                  )}
                </div>
              );
            }}
          />

          <SelectList
            title={t('partType:subCategory')}
            rowHeight={ROW_HEIGHT}
            placeholder={t('partType:searchSubCategory')}
            onPagination={(value, page) => {
              return dispatch(
                fetchItemCategories({
                  ...values,
                  brandId: selectedBrandId,
                  page,
                })
              );
            }}
            loading={fetchingSubCategory}
            showSearch
            border
            value={values.subCategoryId}
            onSearchChange={value => searchSubCategory(value, 'category')}
            dataSource={subCategories}
            onClick={(value, data) => handleSubCategorySelect(data, 'partType')}
            dataSourceValue="sub_category_name"
            renderItem={data => {
              return (
                <div key={data.id} className="flex flex-1 p-2 w-full">
                  <div className="flex-1 truncate ...">
                    <div className="text-gray-600 text-sm truncate ...">{data.category_name}</div>
                    <div className="text-black truncate ...">{data.sub_category_name}</div>
                  </div>

                  {data.used_by_brand > 0 && (
                    <Tooltip
                      title={`${data.used_by_brand} ${t(
                        'partType:assignedProductsTextSubCategory'
                      )}`}
                    >
                      <Tag className="self-center">{data.used_by_brand}</Tag>
                    </Tooltip>
                  )}
                </div>
              );
            }}
          />
        </div>
        <div className="flex-1 pr-2 h-full">
          <SelectList
            showCount
            count={checkedPartType.length}
            title={t('partType:partType')}
            rowHeight={ROW_HEIGHT}
            placeholder={t('partType:searchPartType')}
            onPagination={(value, page) => {
              return dispatch(
                fetchItemCategories({
                  ...values,
                  brandId: selectedBrandId,
                  page,
                })
              );
            }}
            value={values.partTypeId}
            loading={fetchingPartType}
            showSearch
            border
            onClick={(value, data) => choosePartType(data)}
            onSearchChange={value => searchPartType(value, 'category')}
            dataSource={partTypesList.filter(
              p => !values.selectedPartTypes.find((sp: TPartType) => sp.id === p.id)
            )}
            dataSourceValue="part_type_name"
            renderItem={data => {
              const partTypeName = `${data.part_type_name}${
                showPartTypeId && data.part_type_id ? ` (${data.part_type_id})` : ''
              }`;
              return (
                <div
                  key={data.id}
                  className="flex flex-1 justify-between items-center p-2 overflow-hidden"
                  data-testid="part-type-data"
                >
                  <div className="flex flex-row w-full" onClick={() => updateCheckedPartType(data)}>
                    <Checkbox
                      onChange={() => updateCheckedPartType(data)}
                      checked={!!checkedPartType.find(cp => cp.id === data.id)}
                    />
                    <div className="flex flex-1 justify-between truncate ...">
                      <div className="flex-1 overflow-hidden mr-4 ml-2">
                        <Tooltip title={`${data.category_name} - ${data.sub_category_name}`}>
                          <div
                            className="text-gray-600 text-sm truncate ..."
                            title={`${data.category_name} - ${data.sub_category_name}`}
                          >
                            {`${data.category_name} - ${data.sub_category_name}`}
                          </div>
                        </Tooltip>
                        <div className="text-black">
                          <Tooltip title={partTypeName}>
                            <div
                              className="sm:truncate md:truncate truncate ..."
                              title={partTypeName}
                            >
                              {partTypeName}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="self-center">
                        <div className="flex justify-between items-center">
                          {data.description && (
                            <Tooltip title={data.description} className="mr-2">
                              <InfoCircleOutlined />
                            </Tooltip>
                          )}
                          {data.used_by_brand > 0 && (
                            <Tooltip
                              title={`${data.used_by_brand} ${t(
                                'partType:assignedProductsTextPartType'
                              )}`}
                            >
                              <Tag>{data.used_by_brand}</Tag>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-col self-center pr-2 space-y-2">
          <Button
            icon={<RightOutlined />}
            size="small"
            disabled={checkedPartType.length < 1}
            onClick={() => {
              const updateSelection = [...values.selectedPartTypes, ...checkedPartType];
              setCheckedPartType([]);
              setFieldValue('selectedPartTypes', updateSelection);
              const getDefaultSelectedYears = (startYear: number) => {
                const currentYear = new Date().getFullYear();
                const years: number[] = [];
                while (startYear <= currentYear) {
                  years.push(startYear++);
                }
                return years;
              };
              dispatch(fetchUsedVehicles(updateSelection.map((p: TPartType) => p.id))).then(res => {
                const subConfigs = res.value.data.sub_configs;
                const selectedYears =
                  subConfigs.years && subConfigs.years.length > 25
                    ? subConfigs.years.sort((a: number, b: number) => b - a).slice(0, 25)
                    : subConfigs.years;
                setFieldValue('exportApplication', {
                  ...values.exportApplication,
                  makes: subConfigs.makes || [],
                  models: subConfigs.models || [],
                  vehicle_types: subConfigs.vehicle_types || [5, 6, 7],
                  vehicle_type_groups: subConfigs.vehicle_groups || [2],
                  years: selectedYears || getDefaultSelectedYears(new Date().getFullYear() - 24),
                });
              });
            }}
            data-testid="select-part-type"
          />
          <Button
            icon={<LeftOutlined />}
            size="small"
            disabled={checkedSelectedPartType.length < 1}
            onClick={() => {
              const updateSelection = values.selectedPartTypes.filter(
                (sp: TPartType) => !checkedSelectedPartType.find(cp => cp.id === sp.id)
              );
              setCheckedSelectedPartType([]);
              setFieldValue('selectedPartTypes', updateSelection);
              if (updateSelection.length > 0)
                dispatch(fetchUsedVehicles(updateSelection.map((p: TPartType) => p.id))).then(
                  res => {
                    const subConfigs = res.value.data.sub_configs;
                    setFieldValue('exportApplication', {
                      ...values.exportApplication,
                      makes: subConfigs.makes || [],
                      models: subConfigs.models || [],
                      vehicle_types: subConfigs.vehicle_types || [5, 6, 7],
                      vehicle_type_groups: subConfigs.vehicle_groups || [2],
                    });
                  }
                );
            }}
          />
        </div>
        <div className="flex-1 h-full">
          <SelectList
            showCount
            count={checkedSelectedPartType.length}
            title={t('gapCoverageReport:selected')}
            rowHeight={ROW_HEIGHT}
            placeholder={t('partType:searchPartType')}
            value={values.partTypeId}
            showSearch
            border
            onClick={(value, data) => choosePartType(data)}
            onSearchChange={value => setFilterSelectedPartTypeKeyword(value?.toLowerCase() || '')}
            dataSource={values.selectedPartTypes.filter((pt: TPartType) =>
              pt.description?.toLowerCase().includes(filterSelectedPartTypeKeyword)
            )}
            dataSourceValue="part_type_name"
            renderItem={data => {
              const partTypeName = `${data.part_type_name}${
                showPartTypeId && data.part_type_id ? ` (${data.part_type_id})` : ''
              }`;
              return (
                <div
                  key={data.id}
                  className="flex flex-1 justify-between items-center p-2 overflow-hidden"
                  data-testid="part-type-data"
                >
                  <div
                    className="flex justify-between flex-row  items-center w-full"
                    onClick={() => updateSelectedPartType(data)}
                  >
                    <Checkbox
                      onChange={() => updateSelectedPartType(data)}
                      checked={!!checkedSelectedPartType.find(cp => cp.id === data.id)}
                    />
                    <div className="flex-1 overflow-hidden mr-4 ml-2 justify-between truncate ...">
                      <Tooltip title={`${data.category_name} - ${data.sub_category_name}`}>
                        <div
                          className="text-gray-600 text-sm truncate ..."
                          title={`${data.category_name} - ${data.sub_category_name}`}
                        >
                          {`${data.category_name} - ${data.sub_category_name}`}
                        </div>
                      </Tooltip>
                      <div className="text-black">
                        <Tooltip title={partTypeName}>
                          <div className="sm:truncate md:truncate ..." title={partTypeName}>
                            {partTypeName}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="self-center">
                      <div className="flex justify-between items-center">
                        {data.description && (
                          <Tooltip title={data.description} className="mr-2">
                            <InfoCircleOutlined />
                          </Tooltip>
                        )}
                        {data.used_by_brand > 0 && (
                          <Tooltip
                            title={`${data.used_by_brand} ${t(
                              'partType:assignedProductsTextPartType'
                            )}`}
                          >
                            <Tag>{data.used_by_brand}</Tag>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GapCoverageReportStep2;
