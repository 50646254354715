import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import HomeContainer from './HomeContainer';
import WelcomePage from '../../components/body/welcome/WelcomePage';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { fetchQuickStartGuideSteps } from '../../actions/brand/welcome/fetch';
import HeaderPage from '../../components/global/page/HeaderPage';
import { updateGuideSteps } from '../../actions/brand/welcome/update';
import SkipWelcomeGuideModal from '../../components/body/welcome/SkipWelcomeGuideModal';
import { QuickStartGuide } from '../../../types/home';
import helpCenterConstants from '../../constants/HelpCenterConstants.json';

const { articles } = helpCenterConstants;

const WelcomeContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = React.useState<number | undefined>(1);
  const [showSkipModal, setShowSkipModal] = React.useState(false);

  const { brandId, fetchingGuideSteps, guideSteps } = useSelector((state: ApplicationState) => {
    const gSteps = state.brand.welcome.guideSteps || ({} as QuickStartGuide); // todo: fix typescript , its a temp quickfix
    const updateWithStepIds = {
      ...gSteps,
      quick_start_steps:
        state.brand.welcome?.guideSteps?.quick_start_steps?.map((qs, index) => ({
          ...qs,
          stepId: index,
        })) || [], // todo: fix typescript , its a temp quickfix
    };
    return {
      brandId: state.parent.brands.selectedBrandId,
      guideSteps: updateWithStepIds,
      fetchingGuideSteps: state.brand.welcome.fetchingGuideSteps,
    };
  });

  React.useEffect(() => {
    if (brandId) {
      dispatch(fetchQuickStartGuideSteps(brandId)).then(result => {
        const steps = result.value.data;
        if (steps.skip !== 1) setSelectedTab(1);
        else setSelectedTab(undefined);
      });
    }
  }, [brandId, dispatch]);

  const currentStep = guideSteps?.quick_start_steps?.find(s => s.id === guideSteps.current_step);

  const getNextStepId = (stepId: number) => {
    const step = guideSteps?.quick_start_steps?.find(s => s.id === stepId);
    return step ? guideSteps?.quick_start_steps[step.stepId + 1].id : undefined;
  };

  const getUpdatedData = (
    hideWelcomeMessage?: boolean,
    skipWelcomeStepId?: number,
    skipFullGuide?: boolean
  ) => {
    return {
      ...guideSteps,
      hide_welcome_message: hideWelcomeMessage ? 1 : guideSteps.hide_welcome_message,
      current_step: skipWelcomeStepId
        ? getNextStepId(skipWelcomeStepId) || guideSteps.current_step
        : guideSteps.current_step,
      skip: skipFullGuide ? 1 : guideSteps.skip,
    };
  };

  const getLastStepId = () => {
    return guideSteps.quick_start_steps[guideSteps.quick_start_steps.length - 1].id;
  };

  const handleShowHiddenGuide = () => {
    const updatedData = { ...guideSteps, skip: 1 };

    dispatch(updateGuideSteps(brandId, { skip_welcome_step_id: null }, updatedData)).then(() =>
      dispatch(fetchQuickStartGuideSteps(brandId))
    );
  };

  const handleHideGuide = () => {
    handleSave({ skipFullGuide: true });
    setSelectedTab(undefined);
  };

  const handleSave = ({
    hideWelcomeMessage,
    skipWelcomeStepId,
    skipFullGuide,
  }: {
    hideWelcomeMessage?: boolean;
    skipWelcomeStepId?: number;
    skipFullGuide?: boolean;
  }) => {
    const updatedData = getUpdatedData(hideWelcomeMessage, skipWelcomeStepId, skipFullGuide);

    dispatch(
      updateGuideSteps(
        brandId,
        {
          hide_welcome_message: hideWelcomeMessage ? 1 : guideSteps.hide_welcome_message,
          skip_welcome_step_id: skipWelcomeStepId || skipFullGuide ? getLastStepId() : undefined,
        },
        updatedData
      )
    );
  };

  const WelcomeTabOptions = [
    {
      id: 1,
      name: 'Welcome',
      content: (
        <React.Fragment>
          <WelcomePage
            guideSteps={guideSteps}
            handleHideWelcomeMessage={() => handleSave({ hideWelcomeMessage: true })}
            handleSkipStep={stepId => handleSave({ skipWelcomeStepId: stepId })}
            displayHideGuide={
              currentStep ? currentStep.stepId! === guideSteps.quick_start_steps.length - 1 : false
            }
            handleHideScreen={hide => (hide ? handleHideGuide() : setSelectedTab(2))}
          />
          <SkipWelcomeGuideModal
            showModal={showSkipModal}
            onCancel={() => setShowSkipModal(false)}
            handleSkip={() => {
              handleHideGuide();
              setShowSkipModal(false);
            }}
          />
        </React.Fragment>
      ),
    },
    { id: 2, name: 'Dashboard', content: <HomeContainer /> },
  ];

  if (fetchingGuideSteps) return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <HeaderPage
      title={selectedTab === 1 ? t('welcome:welcomePdm') : t('homeContainer:dashboard')}
      description={
        selectedTab === 1 ? t('welcome:welcomeSubText') : t('homeContainer:subDescription')
      }
      helpArticle={{
        name:
          selectedTab === 1
            ? t('welcome:readQuickStartGuide')
            : t('homeContainer:howToUseDashboard'),
        path: selectedTab === 1 ? articles.first_steps : articles.dashboard_overview,
      }}
      actionButtons={
        selectedTab === 1 ? (
          <Button type="text" onClick={() => setShowSkipModal(true)} data-testid="hide-guide">
            <span className="leading-5 font-medium">{t('welcome:hideGuide')}</span>
          </Button>
        ) : (
          <Button
            type="text"
            onClick={() => {
              handleShowHiddenGuide();
              setSelectedTab(1);
            }}
          >
            <span className="leading-5 font-medium">{t('welcome:showGuide')}</span>
          </Button>
        )
      }
      tabs={
        guideSteps.current_step > 1 && guideSteps.skip !== 1 && selectedTab
          ? WelcomeTabOptions
          : undefined
      }
      selectedTabKey={selectedTab}
      handleSelectTab={(id: number) => setSelectedTab(id)}
    >
      <div className="welcome-container">
        {showSkipModal && (
          <SkipWelcomeGuideModal
            showModal={showSkipModal}
            onCancel={() => setShowSkipModal(false)}
            handleSkip={() => {
              handleHideGuide();
              setShowSkipModal(false);
            }}
          />
        )}
        {guideSteps.current_step === 1 && guideSteps.skip !== 1 && (
          <div>
            <WelcomePage
              handleHideWelcomeMessage={() => handleSave({ hideWelcomeMessage: true })}
              handleSkipStep={stepId => handleSave({ skipWelcomeStepId: stepId })}
              displayHideGuide={
                currentStep
                  ? currentStep.stepId! === guideSteps.quick_start_steps.length - 1
                  : false
              }
              handleHideScreen={hide => (hide ? handleHideGuide() : setSelectedTab(2))}
              guideSteps={guideSteps}
            />
          </div>
        )}
        {guideSteps.skip === 1 && <HomeContainer />}
      </div>
    </HeaderPage>
  );
};

export default WelcomeContainer;
