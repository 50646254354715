import { Col, Input, Row, Select, Tooltip, Space } from 'antd';
import React from 'react';
import { StandardResource } from '../../../../types/resources';
import PackageDrawerReplaceButton from './PackageDrawerReplaceButton';

type PackageDrawerDimensionsProps = {
  infoText: string;
  heightInputValue?: string | number;
  widthInputValue?: string | number;
  lengthInputValue?: string | number;
  disabled?: boolean;
  selectValue?: number;
  options: StandardResource[];
  displayOk?: boolean;
  handleInputChange: (value: any, dim: string) => void;
  handleSelectChange: (value: any) => void;
  handleReplaceAll: () => void;
  disableReplaceAll?: boolean;
};

const PackageDrawerDimensions: React.FC<PackageDrawerDimensionsProps> = ({
  infoText,
  heightInputValue,
  widthInputValue,
  lengthInputValue,
  disabled,
  selectValue,
  options,
  displayOk,
  handleInputChange,
  handleSelectChange,
  handleReplaceAll,
  disableReplaceAll,
}) => {
  const renderXInput = () => (
    <Input
      style={{
        width: 20,
        borderLeft: 0,
        borderRight: 0,
        pointerEvents: 'none',
      }}
      placeholder="x"
      disabled
      size="small"
    />
  );

  return (
    <div className="package-drawer-field">
      <Tooltip title={infoText}>
        <Space.Compact className="package__input-group-wrapper" block>
          <Input
            disabled={disabled}
            size="small"
            value={heightInputValue}
            onChange={e => {
              const value = e.target.value;
              handleInputChange(value, 'height');
            }}
            style={{ width: '16%' }}
          />
          {renderXInput()}
          <Input
            className="site-input-right"
            disabled={disabled}
            size="small"
            value={widthInputValue}
            onChange={e => {
              const value = e.target.value;
              handleInputChange(value, 'width');
            }}
            style={{ width: '16%' }}
          />
          {renderXInput()}
          <Input
            disabled={disabled}
            size="small"
            value={lengthInputValue}
            onChange={e => {
              const value = e.target.value;
              handleInputChange(value, 'length');
            }}
            style={{ width: '16%' }}
            className="site-input-right"
          />
          <div style={{ width: '42%' }}>
            <Row>
              <Col flex="auto">
                <Select
                  size="small"
                  disabled={disabled}
                  allowClear
                  value={selectValue}
                  showSearch
                  onChange={value => handleSelectChange(value)}
                  style={{ width: '100%' }}
                  options={options.map(uom => ({
                    key: uom.id,
                    value: uom.id,
                    label: uom.name,
                  }))}
                />
              </Col>
              {displayOk && (
                <Col>
                  <PackageDrawerReplaceButton
                    handleReplace={handleReplaceAll}
                    disableReplaceAll={disableReplaceAll}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Space.Compact>
      </Tooltip>
    </div>
  );
};

export default PackageDrawerDimensions;
