import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FormikValues } from 'formik';
import { ApplicationState } from '../../../reducers';
import PiesXMLHeaders from './PiesXMLHeaders';
import AcesXMLHeaders from './AcesXMLHeaders';
import PageFormik, { CustomFormikActions } from '../../global/page/PageFormik';
import { AsyncDispatch } from '../../../../types/global';
import { Header } from '../../../../types/xml_header_settings';
import { updateXMLHeaders } from '../../../actions/settings/xmlHeaders';
import { hasPermission } from '../../../utils/Permissions';

const { TabPane } = Tabs;

const XMLHeaderSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { piesHeaders, brandId, acesHeaders, fetchingHeaders, user } = useSelector(
    (state: ApplicationState) => {
      return {
        piesHeaders: state.settings.xmlHeaders.acesPiesHeaders.pies_header,
        acesHeaders: state.settings.xmlHeaders.acesPiesHeaders.aces_header,
        brandId: state.parent.brands.selectedBrandId,
        fetchingHeaders: state.settings.xmlHeaders.fetchingHeaders,
        user: state.user.user,
      };
    }
  );

  const getHeaders = () => {
    if (piesHeaders && acesHeaders) {
      const piesHeader: Header[] = piesHeaders!.filter((header: Header) => header.editable !== 0);
      const acesHeader: Header[] = acesHeaders!.filter((header: Header) => header.editable !== 0);
      return { piesHeader, acesHeader };
    }

    return { piesHeader: [], acesHeader: [] };
  };

  const getInitialValues = (headers: Header[]) => {
    const initValues: Record<string, string | null> = {};
    headers.forEach(header => {
      initValues[header.id] = header.value;
    });

    return initValues;
  };

  const setInitialValues = () => {
    const editableHeaders = getHeaders();
    const acesIntitalValues = getInitialValues(editableHeaders.acesHeader);
    const piesInitialValues = getInitialValues(editableHeaders.piesHeader);

    return { piesHeader: piesInitialValues, acesHeader: acesIntitalValues };
  };

  const formatUpdateHeaderValues = (headerValues: Record<string, string | null>) => {
    return Object.keys(headerValues).map(value => ({
      id: Number(value),
      value: headerValues[value],
    }));
  };

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError } = formikActions;
    setSubmitPending();
    const acesHeaderValues = formatUpdateHeaderValues(values.acesHeader);
    const piesHeaderValues = formatUpdateHeaderValues(values.piesHeader);

    const updateHeaderValues = {
      pies_header: piesHeaderValues,
      aces_header: acesHeaderValues,
    };

    dispatch(updateXMLHeaders(updateHeaderValues, brandId))
      .then(() => setSubmitSuccess())
      .catch(() => setSubmitError());
  };

  if (fetchingHeaders) return <Spin className="spinner-center mt-2" />;

  const canManageXmlHeader = hasPermission(user, 'can_manage_aces_pies_xml_header');

  return (
    <PageFormik
      contentNoSpacing
      enableReinitialize
      initialValues={setInitialValues()}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className="xml-header-settings-page pt-2 px-2 h-full overflow-y-hidden">
            <Tabs
              type="card"
              items={[
                {
                  label: t('settings:acesPiesXMLHeader.pies'),
                  key: 'pies_header',
                  children: (
                    <PiesXMLHeaders
                      canManageXmlHeader={canManageXmlHeader}
                      values={values}
                      onChange={(valueId: string, value: string) =>
                        setFieldValue('piesHeader', {
                          ...values.piesHeader,
                          [valueId]: value,
                        })
                      }
                    />
                  ),
                },
                {
                  label: t('settings:acesPiesXMLHeader.aces'),
                  key: 'aces_header',
                  children: (
                    <AcesXMLHeaders
                      canManageXmlHeader={canManageXmlHeader}
                      values={values}
                      onChange={(valueId: string, value: string) =>
                        setFieldValue('acesHeader', {
                          ...values.acesHeader,
                          [valueId]: value,
                        })
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        );
      }}
    </PageFormik>
  );
};

export default XMLHeaderSettingsPage;
