import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Card, Button, Row, Col, Form, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { Translation } from '../../../../types/application';
import { hasPermission } from '../../../utils/Permissions';
import ModalTranslationTable from './ModalTranslationTable';
import { fetchTranslations } from '../../../actions/parent/language/fetch';
import { typingDone } from '../../../utils/Utils';
import { AsyncDispatch } from '../../../../types/global';
import { LanguageTranslation } from '../../../../types/language';

type QualifierPopoverProps = {
  type?: string;
  value: string;
  metaUomId?: number;
  valueTranslations: Translation[];
  translationTypeId: number;
  initialVisible?: boolean;
  handleChange: ({ value, metaUomId }: { value: string; metaUomId?: number }) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const QualifierPopover: React.FC<QualifierPopoverProps> = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AsyncDispatch = useDispatch();

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [translations, setTranslations] = React.useState(props.valueTranslations || []);

  const { qualifierMetaUoms, user } = useSelector((state: ApplicationState) => ({
    qualifierMetaUoms: state.resources.data.application.qualifier_meta_uoms,
    user: state.user.user,
  }));

  const canManageTranslations = hasPermission(user, 'can_manage_translation');

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('qualifierValueInput');
      if (popoverVisible && input) input.focus();
    }, 100);
  }, [popoverVisible]);

  React.useEffect(() => {
    if (props.initialVisible) setPopoverVisible(true);
  }, [props.initialVisible]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
    if (visible && props.onOpen) props.onOpen();
    else if (props.onClose) props.onClose();
  };

  const validationSchema = Yup.object().shape({
    value:
      props.type === 'num'
        ? Yup.string()
            .matches(
              /(^\d+-\d+\/\d+$|^\d+\/\d+$|^\d+[.]\d+$|^\d+$)/g,
              t('validation:invalidNumber')
            )
            .required(t('validation:required'))
        : Yup.string().required(t('validation:required')),
  });

  return (
    <Popover
      open={popoverVisible}
      onOpenChange={visible => handleVisibleChange(visible)}
      overlayClassName="help application_listing__popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        <Card className="qualifier__value-popover" bordered={false}>
          <Formik
            initialValues={{
              value: props.value || '',
              metaUomId: props.metaUomId,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
              const { setSubmitting } = formikActions;
              setSubmitting(true);
              props.handleChange(values);
              setSubmitting(false);
              handleVisibleChange(false);
            }}
          >
            {({ handleSubmit, dirty, isSubmitting, setFieldValue }) => {
              if (!popoverVisible) return null;

              const handleChangedValue = (value: string) => {
                setFieldValue('value', value);
                typingDone(() =>
                  dispatch(
                    fetchTranslations({
                      translationTypeId: props.translationTypeId,
                      keywords: value,
                    })
                  ).then(response => {
                    const translation = response.value.data.find((data: LanguageTranslation) =>
                      data.translations?.find(t => t.language_id === 1 && t.value === value)
                    );
                    setTranslations(translation?.translations || []);
                  })
                );
              };

              return (
                <div>
                  <Form layout="vertical" className="application__defaults-form">
                    <Row gutter={20} align="top">
                      <Col span={props.type !== 'note' ? 12 : 24}>
                        <FormInput
                          id="qualifierValueInput"
                          name="value"
                          label={t('application:qualifier.valueLabel')}
                          required
                          handleChange={e => handleChangedValue(e.target.value || '')}
                        />
                      </Col>
                      {props.type !== 'note' && (
                        <Col span={12}>
                          <FormSelect
                            name="metaUomId"
                            values={qualifierMetaUoms}
                            label={t('common:unit')}
                            allowClear
                            showSearch
                            // todo: disable
                          />
                        </Col>
                      )}
                    </Row>
                    {canManageTranslations && (
                      <React.Fragment>
                        <div className="flex justify-between">
                          <div>{t('language:translations')}</div>
                          <Popconfirm
                            title={t('application:confirmSwitchToTanslations')}
                            okText={t('common:yes')}
                            cancelText={t('common:cancel')}
                            onConfirm={() =>
                              navigate('/settings/translations/translation-settings', {
                                state: { segmentId: 4 },
                              })
                            }
                          >
                            <Button size="small" className="truncate">
                              {t('language:editTranslations')}
                            </Button>
                          </Popconfirm>
                        </div>
                        <div className="popover-modal-translation-table">
                          <ModalTranslationTable valueTranslations={translations || []} />
                        </div>
                      </React.Fragment>
                    )}
                  </Form>

                  <div className="flex-col items-end">
                    <div>
                      <Button
                        onClick={() => handleVisibleChange(false)}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleSubmit()}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Card>
      }
      trigger="click"
    >
      {props.children}
    </Popover>
  );
};

export default QualifierPopover;
