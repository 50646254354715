import { DownloadOutlined } from '@ant-design/icons';
import { Spin, Empty, Tooltip } from 'antd';
import React from 'react';
import { AutoSizer, Table, Column, InfiniteLoader } from 'react-virtualized';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DeliveryLog, DeliveryLogError } from '../../../../types/delivery_logs';
import { ExtendedResources } from '../../../../types/resources';
import HelpCenter from '../../../containers/HelpCenter';
import { dateToString } from '../parentSettings/importLogs/ImportExportUtils';
import ErrorLogModal from './ErrorLogModal';
import JobStatus from '../../global/JobStatus';
import JobInfo from '../../global/JobInfo';
import { kilobytesToMegabytes } from '../../../utils/Utils';

type DeliveryLogTableProps = {
  deliveryLogs: DeliveryLog[];
  deliveryLogErrors: DeliveryLogError[];
  fetchingLogs: boolean;
  fetchingLogErrors: boolean;
  fileTypes: ExtendedResources[];
  errorTypes: ExtendedResources[];
  pageSize: number;
  handleTableScroll: ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<any>;
  fetchErrorLogs: (logId: number) => void;
  fetchFileUrl: (logId: number) => void;
  handleErrorsDownload: (logId: number) => void;
  isReceiver: boolean;
  canAccessLogDetails: boolean;
} & WithTranslation;

type DeliveryLogTableState = {
  showErrorModal: boolean;
  logId: number | null;
};

class DeliveryLogTable extends React.Component<DeliveryLogTableProps, DeliveryLogTableState> {
  constructor(props: DeliveryLogTableProps) {
    super(props);

    this.state = {
      showErrorModal: false,
      logId: null,
    };
  }

  handleShowErrors = (id: number) => {
    const { canAccessLogDetails } = this.props;

    if (canAccessLogDetails) {
      this.props.fetchErrorLogs(id);
      this.setState({ showErrorModal: true, logId: id });
    }
  };

  handleErrorModalState = () => {
    this.setState(prevProps => ({ showErrorModal: !prevProps.showErrorModal }));
    this.setState({ logId: null });
  };

  handleErrorsDownload = () => {
    this.props.handleErrorsDownload(this.state.logId!);
  };

  noData = () => {
    const { deliveryLogs, fetchingLogs } = this.props;

    if (fetchingLogs && deliveryLogs.length === 0)
      return (
        <Spin
          spinning={fetchingLogs && deliveryLogs.length === 0}
          className="delivery__log-top-spinner"
          size="large"
        />
      );

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  createTableRowData = (log: DeliveryLog) => {
    const { fileTypes, t } = this.props;
    const fileType = fileTypes.find(type => type.id === log.file_type_id);

    return {
      key: log.id,
      plan: log.plan_name ? (
        <Tooltip title={log.plan_name}>{log.plan_name} </Tooltip>
      ) : (
        log.receiver_name && (
          <span className="delivery__receiver-plan-label">{t('deliveryLog:receiverPlan')}</span>
        )
      ),
      channel: (
        <Tooltip title={log.channel_name || log.receiver_name}>
          {log.channel_name || log.receiver_name}
        </Tooltip>
      ),
      brand: log.brand_name,
      fileType: fileType && fileType.name,
      fileName: log.file_name && (
        <Tooltip title={log.file_name}>
          <span>{log.file_name}</span>
        </Tooltip>
      ),
      startedTime: dateToString(log.export_started_at),
      completedTime: dateToString(log.export_started_at ? log.export_completed_at : null),
      itemCount: log.item_count,
      applicationCount: log.application_count,
      fileSize: log.file_size && (
        <Tooltip title={t('deliveryLog:kilobyte', { value: log.file_size })}>
          <span>{kilobytesToMegabytes(log.file_size)}</span>
        </Tooltip>
      ),
      statusActions: <JobStatus statusId={log.status_id} status={log.status} />,
      actions: (
        <div className="flex items-center justify-end space-x-1">
          <JobInfo
            displayInfo={log.info_count > 0 || log.warning_count > 0 || log.error_count > 0}
            statusId={log.status_id}
            handleClick={() => this.handleShowErrors(log.id)}
          />
          {!!log.download && log.file_id && (
            <DownloadOutlined onClick={() => this.props.fetchFileUrl(log.file_id!)} />
          )}
        </div>
      ),
    };
  };

  render() {
    const { t, deliveryLogs, deliveryLogErrors, pageSize, fetchingLogs, fetchingLogErrors } =
      this.props;
    const { showErrorModal } = this.state;

    const dataSource = deliveryLogs.map(log => {
      return this.createTableRowData(log);
    });

    return (
      <div className="delivery-content__wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <div className="delivery__log-table">
                <InfiniteLoader
                  isRowLoaded={({ index }) => !!dataSource[index]}
                  loadMoreRows={this.props.handleTableScroll}
                  rowCount={dataSource.length + pageSize}
                  minimumBatchSize={pageSize}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <React.Fragment>
                      <Table
                        height={height}
                        width={width - 2}
                        onRowsRendered={onRowsRendered}
                        headerClassName="delivery__log-table-header"
                        ref={registerChild}
                        rowClassName="delivery__log-table-row"
                        headerHeight={50}
                        rowCount={deliveryLogs.length}
                        dataSource={dataSource}
                        rowHeight={45}
                        rowGetter={({ index }) => dataSource[index]}
                        noRowsRenderer={this.noData}
                      >
                        <Column
                          label={t('deliveryLog:plan')}
                          dataKey="plan"
                          width={180}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => cellData}
                        />
                        <Column
                          label={
                            this.props.isReceiver
                              ? t('deliveryLog:channel')
                              : t('deliveryLog:channelReceiver')
                          }
                          dataKey="channel"
                          width={180}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => cellData}
                        />
                        <Column label={t('deliveryLog:brand')} dataKey="brand" width={180} />
                        <Column label={t('deliveryLog:fileType')} dataKey="fileType" width={125} />
                        <Column
                          label={t('deliveryLog:fileName')}
                          dataKey="fileName"
                          width={180}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => cellData}
                        />
                        <Column
                          label={t('deliveryLog:exportStarted')}
                          dataKey="startedTime"
                          width={170}
                        />
                        <Column
                          label={t('deliveryLog:exportCompleted')}
                          dataKey="completedTime"
                          width={170}
                        />
                        <Column
                          label={t('deliveryLog:items')}
                          dataKey="itemCount"
                          cellRenderer={({ cellData }) => cellData}
                          width={45}
                        />
                        <Column
                          label={t('deliveryLog:applications')}
                          dataKey="applicationCount"
                          cellRenderer={({ cellData }) => cellData}
                          width={90}
                        />
                        <Column
                          label={t('deliveryLog:size')}
                          dataKey="fileSize"
                          cellRenderer={({ cellData }) => cellData}
                          width={90}
                        />
                        <Column
                          headerClassName="logs-table-status"
                          className="logs-table-status"
                          label={t('common:status')}
                          dataKey="statusActions"
                          cellRenderer={({ cellData }) => cellData}
                          width={110}
                          flexShrink={0}
                        />
                        <Column
                          label={
                            <div>
                              {t('common:action')}
                              <HelpCenter id="file_download_info" placement="topLeft" />
                            </div>
                          }
                          flexShrink={0}
                          dataKey="actions"
                          cellRenderer={({ cellData }) => cellData}
                          width={61}
                        />
                      </Table>

                      <Spin
                        spinning={fetchingLogs && deliveryLogs.length > 0}
                        className="spinner"
                        style={{
                          left: width / 2,
                          position: 'absolute',
                          bottom: '20px',
                        }}
                      />
                    </React.Fragment>
                  )}
                </InfiniteLoader>
              </div>
            </div>
          )}
        </AutoSizer>

        {showErrorModal && (
          <ErrorLogModal
            showModal={showErrorModal}
            fetching={fetchingLogErrors}
            errors={deliveryLogErrors}
            errorTypes={this.props.errorTypes}
            handleErrorModalState={this.handleErrorModalState}
            handleErrorsDownload={this.handleErrorsDownload}
            type="deliveryLog"
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(DeliveryLogTable);
