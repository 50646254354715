import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { ApplicationState } from '../../reducers';
import { hasPermission } from '../../utils/Permissions';
import EnterprisePromotion from '../../components/global/EnterprisePromotion/EnterprisePromotion';
import ExportSteps from '../../components/body/import_export/ExportSteps';
import { fetchExportOptions } from '../../actions/brand/export/fetch';
import { AsyncDispatch } from '../../../types/global';
import { isManufacturer, isReceiver } from '../../utils/UserUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const { Content } = Layout;

const ExportContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const { hasExportAccess, brandId, brandCode, fileExportOptionsBrandIds, user } = useSelector(
    (state: ApplicationState) => {
      return {
        hasExportAccess: hasPermission(state.user.user, 'has_export_access'),
        brandId: state.parent.brands.selectedBrandId,
        brandCode: getSelectedBrandCode(state),
        fileExportOptionsBrandIds: state.brand.brandExport.fileExportOptionsBrandIds,
        user: state.user.user,
      };
    }
  );

  const canManageAdvancedExportOptions = hasPermission(user, 'can_manage_advanced_export_options');
  const receiver = isReceiver(user!);
  const manufacturer = isManufacturer(user!);

  React.useEffect(() => {
    intercomEvent('viewed-import-export', { location: 'export', brand_code: brandCode! });
  }, [brandCode]);

  React.useEffect(() => {
    if (manufacturer && (!fileExportOptionsBrandIds || fileExportOptionsBrandIds[0] !== brandId))
      dispatch(fetchExportOptions('export', brandId));
  }, [brandId, dispatch, fileExportOptionsBrandIds, manufacturer]);

  return (
    <Content className="bg-white pl-6 pt-6 pb-4 h-full">
      {!hasExportAccess ? (
        <EnterprisePromotion />
      ) : (
        <ExportSteps
          canManageAdvancedExportOptions={canManageAdvancedExportOptions}
          receiver={receiver}
          manufacturer={manufacturer}
        />
      )}
    </Content>
  );
};

export default ExportContainer;
